import React, { Component } from "react";
import MetaHelmet from "../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Device } from "../utils/Device";
import { logout, showLogin } from "../user/UserActions";
import "./Homepage.scss";
import ContentSlider from '../ui/ContentSlider';
import Root from "../layout/Root";
import { AppState } from "../RootReducer";
import { getCasinoCategory } from "../actions/CasinoCategoryActions";
import { CasinoCategoryDispatchTypes, TopGames, PlayAgainGames, NewGames, MostPopularGames, CasinoGameCategory } from "../actions/CasinoCategoryActions";
import { getContainer } from "../ioc/IOCSetup";
import Banner from "../ui/Banner"
import { ContentModelName, HomePayload, GlobalPayload, SlidersMaharajaPayload } from "../contenful/types";
import { throws } from "assert";
import { fetchContent } from "../contenful/ContentfulActions";
import { Dispatch } from "redux";
import { CasinoLobby, CasinoGame, UserInfo } from "../http/protocol";
import { CasinoState, CasinoStatus } from "../casino/CasinoReducer";
import { loadCasinoGames, selectCategory } from "../casino/CasinoActions";

import { LiveCasinoState, LiveCasinoStatus } from "../livecasino/LiveCasinoReducer";
import { addPlayedGame, loadLiveCasinoGames, toggleFavouriteGame } from "../livecasino/LiveCasinoActions";
import { Link } from "react-router-dom";

import { t } from "../i18n";
import HomeAdsSlider from "../ui/HomeAdsSlider";


interface dispatchProps {
    showLogin: () => void;


}

let homeAds1 = [

    {
        link: "/livecasino/37/andar-bahar%20&%20teen%20patti",
        image: "https://content.adminemx.com/maharajafortune/ads/1800x330_TeenPatti.jpg",
    },

    {
        link: "/promotions/casino-welcomebonus",
        image: "https://content.adminemx.com/maharajafortune/ads/1800x330_100WelcomeBonus25K-Casino.jpg",
    },
  


]


let homeAds2 = [
    {
        link: "/promotions/casino-welcomebonus",
        image: "https://content.adminemx.com/maharajafortune/ads/1800x330_100WelcomeBonus25K-Casino.jpg",
    },

    {
        link: "/livecasino/37/andar-bahar%20&%20teen%20patti",
        image: "https://content.adminemx.com/maharajafortune/ads/1800x330_TeenPatti.jpg",
    },




]


let homeAds3 = [
   
    {
        link: "/promotions/casino-welcomebonus",
        image: "https://content.adminemx.com/maharajafortune/ads/1800x330_100WelcomeBonus25K-Casino.jpg",
    },

    {
        link: "/livecasino/37/andar-bahar%20&%20teen%20patti",
        image: "https://content.adminemx.com/maharajafortune/ads/1800x330_TeenPatti.jpg",
    }



]

interface State {
    hideAfterWentToOtherPage: boolean;
    animate: boolean;
    promotionbanner?: any;
}
interface Props {
    topGames: TopGames,
    newGames: NewGames,
    playAgainGames: PlayAgainGames,
    mostPopularGames: MostPopularGames,
    cats: CasinoGameCategory,
    banners: any,
    getCasinoCategory: () => void;
    loadLiveCasinoGames?: () => void
    isloggedIn: boolean;
    lng: string;
    homepage: HomePayload;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    slidersMaharaja?: SlidersMaharajaPayload;
    loadCasinoGames?: () => void;
    lobby?: CasinoLobby;
    optInBonusPlans?: any;
    status?: CasinoStatus;
    popularLiveGames: NewGames;
    indianGames: NewGames;
    user?: UserInfo;
}
type AllProps = Props & dispatchProps;

export class HomePage extends Component<AllProps, State> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            animate: false,
            hideAfterWentToOtherPage: false,
        }


    }

    componentDidMount() {
        this.props.getCasinoCategory();
        let promotions = getContainer().getSettingsService().getPromotions();
        let homepromo = promotions && promotions.filter(function (obj: any) {
            return obj.placement.includes("HOME") && obj.isEnabled;
        })
        this.setState({ promotionbanner: homepromo })

        // eslint-disable-next-line
        getContainer().getSettingsService().getActiveLocale().code;

        if (this.props.status != CasinoStatus.DONE) {
            this.props.loadCasinoGames && this.props.loadCasinoGames();
            this.props.loadLiveCasinoGames && this.props.loadLiveCasinoGames();
        }



        if (!this.props.isFetching) {

            if (this.props.homepage === undefined) {
                this.props.fetchContent(ContentModelName.HOME, getContainer().getSettingsService().getActiveLocale().code);
            }
            if (this.props.slidersMaharaja === undefined) {
                this.props.fetchContent(ContentModelName.SLIDERSMAHARAJA, getContainer().getSettingsService().getActiveLocale().code);
            }
        }

    }


    renderLandingPageBanner() {

    }

    render() {

        const title: string = t("meta.homePage.title") || "";
        const subtitle: string = t("meta.homePage.subtitle") || "";
        const fullTitle: string = t("meta.homePage.fullTitle") || "";
        const description: string = t("meta.homePage.description") || "";
        const keywords: string = t("meta.homePage.keywords") || "";
        const baserHref: string = t("meta.homePage.baserHref") || "";

        return (

            <Root footer={true} header={true}>

                {MetaHelmet(title, fullTitle, description, keywords)}


                <section id="home" className="iq-main-slider p-0">
                    <div id="home-slider" className="slider m-0 p-0">
                        {/* {this.props.banners ? <Banner data={this.props.banners} loggedIn={this.props.isloggedIn} showLogin={()=>this.props.showLogin()} lng={this.props.lng} /> : null} */}
                        {this.state.promotionbanner ? <Banner data={this.state.promotionbanner} optInBonusPlans={this.props.optInBonusPlans} loggedIn={this.props.isloggedIn} user={this.props.user} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}
                    </div>
                </section>
                {/* Main Content */}
                <div className="home-content">
                    <section id="iq-upcoming-movie" className="s-margin">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <div className="iq-main-header d-flex align-items-center justify-content-between">

                                        <h4 className="main-title">{t("homePageTexts.popularLiveGames")} <a className="btn btn-secondary btn-sm btn-thin red-bg" href="/casino/12/popular-live%20games">{t("homePageTexts.viewMore")}</a></h4>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="upcoming-contens">
                            {this.props.popularLiveGames ? <ContentSlider slides={this.props.popularLiveGames} loggedIn={this.props.isloggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}


                        </div>

                    </section>

                    <section id="iq-favorites" className="s-margin">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <div className="iq-main-header d-flex align-items-center justify-content-between">

                                        <h4 className="main-title">{"Crash Games"} <a className="btn btn-secondary btn-sm btn-thin red-bg" href={"/casino/10/most-popular"}>{t("homePageTexts.viewMore")}</a></h4>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="favorites-contens">
                            {this.props.mostPopularGames ? <ContentSlider slides={this.props.mostPopularGames} loggedIn={this.props.isloggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}

                        </div>

                    </section>

                    {/* Home Ads */}
                    <div className="suggestede-contens">
                        {homeAds1 ? <HomeAdsSlider slides={homeAds1} loggedIn={this.props.isloggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}
                    </div>
                    {/* Home Ads */}

                    <section id="iq-suggestede" className="s-margin">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <div className="iq-main-header d-flex align-items-center justify-content-between">

                                        <h4 className="main-title">{t("homePageTexts.indianGames")} <a className="btn btn-secondary btn-sm btn-thin red-bg" href="/casino/14/indian-games">{t("homePageTexts.viewMore")}</a></h4>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="suggestede-contens">
                            {this.props.indianGames ? <ContentSlider slides={this.props.indianGames} loggedIn={this.props.isloggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}
                        </div>


                    </section>
                    {/* Home Ads */}
                    <div className="suggestede-contens">
                        {homeAds2 ? <HomeAdsSlider slides={homeAds2} loggedIn={this.props.isloggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}
                    </div>
                    {/* Home Ads */}
                    <section id="iq-mostpopular" className="s-margin">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <div className="iq-main-header d-flex align-items-center justify-content-between">

                                        <h4 className="main-title">{t("homePageTexts.newRelease")}  <a className="btn btn-secondary btn-sm btn-thin red-bg" href="/casino/15/new-releases">{t("homePageTexts.viewMore")}</a></h4>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="suggestede-contens">
                            {this.props.newGames ? <ContentSlider slides={this.props.newGames} loggedIn={this.props.isloggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}

                        </div>

                    </section>

                    {/* Home Ads */}
                    <div className="suggestede-contens">
                        {homeAds3 ? <HomeAdsSlider slides={homeAds3} loggedIn={this.props.isloggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null}
                    </div>
                    {/* Home Ads */}
                    <section id="trustedCasino">
                        <div className="container ">
                            <div className="row">
                                <div className="col-sm-12 d-flex justify-content-center flex-wrap">
                                    <img className="img-fluid align-items-center"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/trusted-casino.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-center"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/secure-payment.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                </div>
                                <p>{t("homePageTexts.section2.paymentMethodText")}</p>
                                <div className="col-sm-12 d-flex justify-content-center flex-wrap bank-logo">
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-Paytm.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/axisbank-1.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-GPay.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-PhonePe.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-BHIM.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/2560px-ICICI_Bank_Logo.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/netbanking_c.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/upi_c.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/yesbank-1.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                </div>
                            </div>
                        </div>

                    </section>











                </div>

            </Root>

        )
    }

}

const mapStateToProps = (casinoCat: AppState) => {
    let newGames: CasinoGame[] = [];
    let allNewGames = casinoCat.casino.lobby && casinoCat.casino.lobby.games ? casinoCat.casino.lobby.games : newGames;

    newGames = allNewGames.filter(g => g.Tags?.includes("isHomeNewRelease"));


    let homeTopGames: CasinoGame[] = [];
    let allTopGames = casinoCat.casino.lobby && casinoCat.casino.lobby.games ? casinoCat.casino.lobby.games : homeTopGames;
    homeTopGames = allTopGames.filter(g => g.Tags?.includes("isHomeTopGames"));

    let homeMostPopular: CasinoGame[] = [];
    let allMostGames = casinoCat.casino.lobby && casinoCat.casino.lobby.games ? casinoCat.casino.lobby.games : homeMostPopular;

    homeMostPopular = allMostGames.filter(g => g.Tags?.includes("isHomeCrashGames"));

    let homeRecommended: CasinoGame[] = [];
    let allRecommendedGames = casinoCat.casino.lobby && casinoCat.casino.lobby.games ? casinoCat.casino.lobby.games : homeMostPopular;

    homeRecommended = allRecommendedGames.filter(g => g.Tags?.includes("isHomeRecommended"));


    let isHomeIndianGames: CasinoGame[] = [];
    let allHomeIndianGame = casinoCat.casino.lobby && casinoCat.casino.lobby.games ? casinoCat.casino.lobby.games : isHomeIndianGames;

    isHomeIndianGames = allHomeIndianGame.filter(g => g.Tags?.includes("isHomeIndianGames"));


    let isHomePopularLiveGames: CasinoGame[] = [];
    let allHomePopularLiveGames = casinoCat.casino.lobby && casinoCat.casino.lobby.games ? casinoCat.casino.lobby.games : isHomePopularLiveGames;

    isHomePopularLiveGames = allHomePopularLiveGames.filter(g => g.Tags?.includes("isHomePopularLiveGames"));




    return {
        status: casinoCat.casino.status,
        optInBonusPlans: casinoCat.user.optInBonusPlans,
        topGames: homeTopGames,
        playAgainGames: homeRecommended,
        newGames: newGames,
        mostPopularGames: homeMostPopular,
        indianGames: isHomeIndianGames,
        popularLiveGames: isHomePopularLiveGames,
        cats: casinoCat.casinoCat.casinoCategory !== undefined ? casinoCat.casinoCat.casinoCategory.category : undefined,
        banners: casinoCat.casinoCat.casinoCategory !== undefined ? casinoCat.casinoCat.casinoCategory.banner : undefined,
        isloggedIn: casinoCat.user.user && casinoCat.user.user.loggedIn,
        user: casinoCat.user.user,
        lng: getContainer().getSettingsService().getActiveLocale().code,
        isFetching: casinoCat.contentful.isFetching,
        homepage: casinoCat.contentful.home,
        slidersMaharaja: casinoCat.contentful.slidersMaharaja,
    }
};


const mapDispatchToProps = (dispatch: Dispatch) => ({
    showLogin: () => dispatch(showLogin(true)),
    getCasinoCategory: () => dispatch(getCasinoCategory() as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
    loadCasinoGames: () => dispatch(loadCasinoGames() as any),
    loadLiveCasinoGames: () => dispatch(loadLiveCasinoGames() as any),
});


export default connect(mapStateToProps, mapDispatchToProps)(HomePage as any);