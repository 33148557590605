import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { DepositPhase, DepositState, PaymentsState } from '../../payments/PaymentsReducer';
import { AppState } from '../../RootReducer';
import { CurrencyDefinition, DepositMethods, PaymentMethod, Settings, UserInfo, KycStatus, LaunchUrl, kycCheck } from '../../http/protocol';
import { connect } from 'react-redux';
import { t } from '../../i18n';
import '../../ui/deposit/styles/Deposit.scss';
import {
    fetchProviders,
    reset,
    selectCurrency,
    selectPaymentMethod,
    showCashier,
    processDeposit
} from '../../payments/DepositActions';
import { ContentModelName, GlobalPayload, OnboardingPagePayload } from '../../contenful/types';
import { Redirect } from 'react-router';
import { LoginStatus, Status } from '../../http/Types';
import './styles/Method.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import getLoggedInRedirect from '../../utils/LoggedInRedirect';
import { fetchBalance, setActiveCurrency, setNewUser, showCashierModal } from '../../user/UserActions';
import Page from '../../layout/Page';
import { fetchContent } from '../../contenful/ContentfulActions';
import { getContainer } from '../../ioc/IOCSetup';
import { Spinner } from "../../ui/Spinner";

interface State {
    cashierPayMinimum?: any;
    cashierPayMaximum?: any;
    cashierPayFeeRate?: any;
    showCurrency: boolean;
    startPlaying: boolean;
    cryptoCurrency: boolean;
    register: boolean;
    skipDeposit: boolean;
    fromBonus?: boolean;
    newUser?: boolean;
    methodsFromProvider: DepositMethods[];
    selectedDepositMethod: DepositMethods;
    showMethods: boolean;
    depositAmount: number;
    errorMessage: string;
    showDepositRedirect: boolean;
    iframeLoaded: boolean;
    hooyuLink?: string;
    ipsKycCheckStatus?: string;
    bonusPlanId?: any;
    staticDepositCode?: any;
    selectedBonus?: any;
    promotions?: any;
    payments?: PaymentsState;
}


type AllProps = PropsFromState & PropsFromDispatch & OtherProps;
type Providers = 'strike' | 'coinpayments' | 'simbet' | 'gbl' | 'test' | 'cryptochill' | 'iSttle' | string;
type Codes = '' | '' | string;
let minDepo: any;
let maxDepo: any;
export class DepositMethod extends Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            showCurrency: false,
            startPlaying: false,
            cryptoCurrency: false,
            register: false,
            skipDeposit: false,
            fromBonus: false,
            methodsFromProvider: [],
            selectedDepositMethod: null as any,
            showMethods: true,
            depositAmount: 0,
            errorMessage: null as any,
            showDepositRedirect: false,
            iframeLoaded: false,
            cashierPayMinimum: '0',
            cashierPayMaximum: '0',
            bonusPlanId: null as any,
            staticDepositCode: '',
            selectedBonus: null as any
        };

        this.props.fetchProviders && this.props.fetchProviders();
    }

    componentDidMount(): void {
        if (this.props.partyId) {
            // getContainer().getPaymentsService().checkKYCStatus(this.props.partyId).then((r: kycCheck) => {
            // 	this.setState({ ipsKycCheckStatus: r.ipsKycCheck && r.ipsKycCheck.status });
            // 	if(r.ipsKycCheck &&  r.ipsKycCheck.status === null && this.props.partyId && !localStorage.getItem("hooyuLink")) {
            // 	  getContainer().getPaymentsService().performHooyu(this.props.partyId).then((r: LaunchUrl) => {

            // 		this.setState({ hooyuLink:r.link });
            // 	  });
            // 	}
            //   });

        }
        let promotions = getContainer().getSettingsService().getPromotions();
        let enablePromo = promotions && promotions.filter(function (obj: any) {
            return obj.isPromo && obj.isEnabled;
        })
        this.setState({ promotions: enablePromo });
        this.updateMinDepo();

        let fromBonus: boolean = false;
        if (this.props.location.state) {
            fromBonus = true;
        }
        this.props.activeCurrency && setActiveCurrency(this.props.activeCurrency);
        this.handleCheckActiveCurrency();
        let newUser = this.props.newUser || false;
        if (this.props.location.state && this.props.location.state.newUser !== undefined) {
            newUser = this.props.location.state.newUser;
        }
        const showCurrency =
            (newUser && this.props.user && this.props.user.currencies && this.props.user.currencies.length > 1) ||
            false;
        this.props.reset && this.props.reset();
        let methodList = this.props.deposit.depositMethods as DepositMethods[];
        let cashierPay = methodList.find(el => el.code === "CASHIERPAY" || el.code === "DELUXEPAY365_UPI");

        if (sessionStorage.getItem("bonusPlanId")) {
            this.handleClickDepositMethod(methodList && methodList[0]);
        }
        this.setState({
            register: newUser,
            showCurrency: showCurrency,
            fromBonus: fromBonus,
            newUser: newUser,
            cashierPayMinimum: cashierPay?.minimumDeposit,
            cashierPayMaximum: cashierPay?.maximumDeposit,
            cashierPayFeeRate: cashierPay?.feeRate,
            staticDepositCode: cashierPay?.code
        });



        this.setState({
            methodsFromProvider: methodList,
        });
        if (!this.props.isFetching) {
            console.log('this.props.depositPage ', this.props.depositPage);
            if (this.props.depositPage === undefined) {
                this.props.fetchContent(
                    ContentModelName.DEPOSITPAGE,
                    getContainer().getSettingsService().getActiveLocale().code
                );
                console.log('CONTENTFUL : ', this.props);
            }
        }
    }
    updateMinDepo() {
        minDepo =
            this.state.selectedDepositMethod &&
            this.state.selectedDepositMethod.minimumDeposit &&
            this.state.selectedDepositMethod.minimumDeposit.replace(/[^0-9\.]+/g, '');
        maxDepo =
            this.state.selectedDepositMethod &&
            this.state.selectedDepositMethod.maximumDeposit &&
            this.state.selectedDepositMethod.maximumDeposit.replace(/[^0-9\.]+/g, '');
        if (this.state.selectedBonus && this.state.selectedBonus.minDeposit > minDepo) {
            minDepo = this.state.selectedBonus.minDeposit;
        }

        if (this.state.selectedBonus && this.state.selectedBonus.maxDeposit < maxDepo) {
            maxDepo = this.state.selectedBonus.maxDeposit;
        }
    }
    componentDidUpdate(prevProps: AllProps, prevpState: State) {

        if (sessionStorage.getItem("bonusPlanId") && !this.state.bonusPlanId) {
            this.setState({ bonusPlanId: sessionStorage.getItem("bonusPlanId") });

            this.handleSelectedBonus(sessionStorage.getItem("bonusPlanId"));
        }
        if (this.state.selectedDepositMethod !== prevpState.selectedDepositMethod) {
            this.updateMinDepo();
        }
        if (this.props.deposit.activeCurrency !== prevProps.deposit.activeCurrency) {
            this.props.activeCurrency && setActiveCurrency(this.props.activeCurrency);
            this.handleCheckActiveCurrency();
        }
        if (this.props.optInBonusPlans !== prevProps.optInBonusPlans) {
            let promotions = getContainer().getSettingsService().getPromotions();
            let enablePromo = promotions && promotions.filter(function (obj: any) {
                return obj.isPromo && obj.isEnabled;
            })

            enablePromo.filter((arrayEl: any) => {
                return this.props.optInBonusPlans && this.props.optInBonusPlans.some((filterEl: any) => filterEl.bonusPlanId.toString() === arrayEl.bonusPlanId) || arrayEl.isDepositTrigger !== true;

            });

            this.setState({ promotions: enablePromo });

        }
        if (this.props.deposit.continueUrl !== prevProps.deposit.continueUrl) {
            console.error(this.props.deposit.continueUrl);
            this.setState({ continueUrl: this.props.deposit.continueUrl });
        }
    }

    componentWillUnmount() {
        this.props.setNewUser && this.props.setNewUser(false);
    }

    handleClickStartPlaying = () => {
        this.props.fetchBalance && this.props.fetchBalance();
        if (this.state.fromBonus) {
            this.props.history.push('/bonus');
        } else {
            this.setState({ startPlaying: true });
        }
    };

    handlePaymentCheck = () => {
        if (this.props.deposit.depositResult?.paymentId) {
            getContainer().getPaymentsService().checkPaymentStatus(
                this.props.deposit.depositResult?.paymentId
            ).then((r: any) => {
                // r.paymentStatus
            });
        }
    }

    handleCopy = () => {
        this.refs.continueId.select();
        document.execCommand("copy");
    }

    handleGoBack = () => {
        if (this.state.showCurrency && this.props.newUser) {
            this.props.history.goBack();
        }
        if (this.state.showCurrency) {
            this.setState({ showCurrency: false });
        } else {
            if (this.props.show && this.props.show) {
                // cashiermodal is open and active
                this.props.hide();
            } else {
                this.props.history.goBack();
            }
        }
    };

    handleCheckActiveCurrency = () => {
        if (
            this.props.deposit !== undefined &&
            this.props.user !== undefined &&
            this.props.user.currencies !== undefined
        ) {
            const activeCurrency = localStorage.getItem('activeCurrency') || this.props.activeCurrency;

            // let cur: CurrencyDefinition | undefined = this.props.user.currencies.find((cur) => cur.code === activeCurrency);

            // if(cur !== undefined && cur.cryptoCurrency) {
            //     this.setState({cryptoCurrency: true});
            // } else {
            //     this.setState({cryptoCurrency: false});
            // }
        }
    };
    handleSelectedBonus(selectedBonus: any) {
        let selected = parseInt(selectedBonus);

        this.setState({
            selectedBonus: this.props.optInBonusPlans.filter(function (obj: any) {
                return obj.bonusPlanId === selected;
            })[0]
        });
        this.updateMinDepo();

    }
    handleClickChangeCurrency = () => {
        this.setState({ showCurrency: !this.state.showCurrency });
    };


    skipDeposit() {
        console.log('skip deposit!');
        this.props.setNewUser && this.props.setNewUser(false);
        this.setState({ skipDeposit: true }, () => console.log('state skipDeposit: ', this.state.skipDeposit));
    }

    // handleDepositMethodSelect = (e: any) => {
    //     this.setState({selectedMethod: e.target.value});
    //     console.log(this.state);
    // }
    handleHooyuBtn = () => {
        if (this.state.hooyuLink) {
            localStorage.setItem("hooyuLink", this.state.hooyuLink);
        }
    }

    handleAmountInput = (e: any) => {
        this.setState({ depositAmount: e.target.value });
        console.log(this.state);
    };

    handleFormSubmit = (depositFromProps: DepositState) => {
        window.scrollTo({ top: 100, behavior: 'auto' });
        if (
            this.state.depositAmount >= parseInt(minDepo as any) &&
            this.state.depositAmount <= parseInt(maxDepo as any)
        ) {
            console.log('AMOUNT TO PASS', this.state.depositAmount);
            console.log('CODE TO PASS', this.state.selectedDepositMethod.code);
            // this.state.selectedDepositMethod.code = this.state.staticDepositCode;
            depositFromProps.selectedDepositMethod = this.state.selectedDepositMethod;
            depositFromProps.amount = this.state.depositAmount.toString();
            let processDeposit = this.props.processDeposit(!this.doNotRedirectToPayment());
            console.log('display response deposit', depositFromProps);
            console.log(this.props);
            this.setState({
                errorMessage: null as any,
                showDepositRedirect: true,
                payments: processDeposit.payments,
                showMethods: false,
            });
        } else {
            this.validateDepositAmount(maxDepo, minDepo);
        }
    };

    validateDepositAmount(maxDepo?: string, minDepo?: string) {
        if (this.state.depositAmount > parseInt(maxDepo as any)) {
            if (this.state.selectedBonus && this.state.selectedBonus.maxDeposit) {
                this.setState({ errorMessage: `${t("depositMethod.err1")} ${this.formatMoney(this.state.selectedBonus.maxDeposit)} ${t("depositMethod.cliamBonus")}` });
            } else {
                this.setState({ errorMessage: t("depositMethod.err1") });
            }


        } else if (this.state.depositAmount < parseInt(minDepo as any)) {
            if (this.state.selectedBonus && this.state.selectedBonus.minDeposit) {
                this.setState({ errorMessage: `${t("depositMethod.err2")} (${this.formatMoney(this.state.selectedBonus.minDeposit)}) ${t("depositMethod.cliamBonus")}` });
            } else {
                this.setState({ errorMessage: t("depositMethod.err2") });
            }

        }
    }

    handleClickDepositMethod = (e: DepositMethods) => {

        this.setState({ selectedDepositMethod: e, showMethods: false });
    };

    doNotRedirectToPayment() {
        return this.state?.selectedDepositMethod && this.state?.selectedDepositMethod.code === 'CASHIP_PIX';
    }

    showDepositMethods = () => {

        return this.state.showMethods ? (
            <div className="row ">
                {this.state.methodsFromProvider.map((e) => {
                    if (e.paymentMethod === "DELUXEPAY365_UPI" || e.paymentMethod === "DELUXEPAY365_BANKTRANSFER") {
                        return null
                    } else {
                        return (
                            <div
                                key={e.method}
                                className=" col-lg-12"
                                onClick={() => this.handleClickDepositMethod(e)}
                            >
                                <div className="depMethodsBG ">
                                    <div className="depoTitleHolder">
                                        <img
                                            className="img-fluid"
                                            src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + e.paymentMethod + '.svg'}
                                            alt={e.code}
                                            title=""
                                        />

                                    </div>

                                    <div className="text-method">
                                        <span className="subtext-deposit-method">
                                            {t("depositMethod.min")} {e.minimumDeposit}
                                        </span>

                                        <span className="subtext-deposit-method">
                                            {t("depositMethod.max")} {e.maximumDeposit}
                                        </span>

                                        <span className="subtext-deposit-method">
                                            {t("depositMethod.fee")} {e.feeRate === '0.00' ? t("depositMethod.free") : e.feeRate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        ) : null;
    };
    promoThumbnailGenerator = () => {
        let lang = getContainer().getSettingsService().getLocalesData();
        let activeLang = getContainer().getSettingsService().getActiveLocale().code;
        return (<div className='col-12 d-flex justify-content-center  mb-3 flex-wrap' >
            <div className='col-lg-12'>
                <h4>{t("depositMethod.title")}</h4>
            </div>
            {this.state.promotions && this.state.promotions.filter((arrayEl: any) => {
                return this.props.optInBonusPlans && this.props.optInBonusPlans.some((filterEl: any) => filterEl.bonusPlanId.toString() === arrayEl.bonusPlanId && arrayEl.firstDeposit || filterEl.bonusPlanId.toString() === arrayEl.bonusPlanId && arrayEl.bonusPlanId == sessionStorage.getItem("bonusPlanId"));
            }).map((e: any) => {
                return (
                    <div className={`promotion ${activeLang == 'pt-BR' ? 'col-lg-6' : 'col-lg-4'} col-md-4 col-sm-6 col-12`} key={e.bonusPlanId}>
                        <div className="promotion-box">
                            <div className='promo-product'>{t(`${e.product}`)}</div>
                            {e.bonusPlanId && sessionStorage.getItem("bonusPlanId") == e.bonusPlanId ? (
                                <img
                                    className="img-fluid claimed-badge"
                                    src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Claimed-badge.png'}
                                    alt={'Welcome Bonus'}
                                    title=""
                                />
                            ) : null}

                            <img
                                className="img-fluid"
                                src={e.thumbnailImage}
                                alt={'Welcome Bonus'}
                                title=""
                            />
                            <div className="row promodesc">
                                <div className="col-6 col-lg-12">
                                    {lang && lang.length >= 2 ? (<h5> {e.promotionTCL[activeLang]}</h5>) : (<h5> {e.promotionTCL}</h5>)}


                                    <p>{t("depositMethod.min")} {e.minimumDeposit}</p>
                                </div>
                                <div className="col-6 col-lg-12">
                                    {this.state.bonusPlanId == e.bonusPlanId ?
                                        (<a
                                            className="btn btn-primary  btn-rounded promo-btn"
                                            onClick={() => { this.handleUnclaim() }}
                                        >
                                            {t("unClaim")}
                                        </a>) : (<a
                                            className="btn btn-primary  btn-rounded promo-btn"
                                            onClick={() => { this.handleBonusPlanId(e.bonusPlanId) }}
                                        >
                                            {t("claimNow")}
                                        </a>)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>)

    }

    handleUnclaim = () => {
        sessionStorage.removeItem("bonusPlanId");
        this.setState({ bonusPlanId: null, selectedBonus: null })
    };
    handleInstanAmount = (amount: any) => {

        this.setState({ depositAmount: amount })
    };
    handleBackClicked = () => {
        this.setState({
            selectedDepositMethod: null as any,
            showMethods: true,
            showDepositRedirect: false,
            depositAmount: 0,
            iframeLoaded: false
        });
    };
    handleBonusPlanId = (id: any) => {
        sessionStorage.setItem("bonusPlanId", id);
        this.setState({ bonusPlanId: id });
        this.handleSelectedBonus(id);
    };
    formatMoney(amount: any, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign +
                (j ? i.substr(0, j) + thousands : '') +
                i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
                (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    };
    showDepositForm = (selectedDepositMethod: DepositMethods) => {
        this.updateMinDepo();
        let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
        return (
            <div className="deposit-details-group m-auto">
                <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">

                    <div className="logoTitleContainer d-flex ">
                        <div onClick={() => { this.handleBackClicked() }} className=" back-btn align-self-center">{t("back")}</div>
                        <span className="method-title align-self-center">{selectedDepositMethod.method === "DELUXEPAY365_BANKTRANSFER" ? ("BANK TRANSFER") :
                            selectedDepositMethod.method === "DELUXEPAY365_NETBANKING" ? ("NET BANKING") : (selectedDepositMethod.method && selectedDepositMethod.method.replaceAll("DELUXEPAY365_", ""))}</span>
                        <img
                            className="img-fluid align-items-end"
                            src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + selectedDepositMethod.paymentMethod + '.svg'}
                            alt={selectedDepositMethod.code}
                            title=""
                        />
                    </div>

                    <div className="row ">
                        {mainLobby === "https://joiabet.com/" ? (<div className="col-12 d-flex justify-content-center  mb-3 flex-wrap">
                            <button
                                type="button"
                                className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) ? 'activeAmt' : ''} `}
                                onClick={() => this.handleInstanAmount(parseInt(minDepo))}
                            >
                                {parseInt(minDepo)}
                            </button>
                            <button
                                type="button"
                                className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) * 5 ? 'activeAmt' : ''} `}
                                onClick={() => this.handleInstanAmount(parseInt(minDepo) * 5)}
                            >
                                {parseInt(minDepo) * 5}
                            </button>
                            <button
                                type="button"
                                className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) * 10 ? 'activeAmt' : ''} `}
                                onClick={() => this.handleInstanAmount(parseInt(minDepo) * 10)}
                            >
                                {parseInt(minDepo) * 10}
                            </button>
                            <button
                                type="button"
                                className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) * 25 ? 'activeAmt' : ''} `}
                                onClick={() => this.handleInstanAmount(parseInt(minDepo) * 25)}
                            >
                                {parseInt(minDepo) * 25}
                            </button>
                        </div>) : (
                            <div className="col-12 d-flex justify-content-center  mb-3 flex-wrap">
                                <button
                                    type="button"
                                    className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) ? 'activeAmt' : ''} `}
                                    onClick={() => this.handleInstanAmount(parseInt(minDepo))}
                                >
                                    {parseInt(minDepo)}
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) * 2 ? 'activeAmt' : ''} `}
                                    onClick={() => this.handleInstanAmount(parseInt(minDepo) * 2)}
                                >
                                    {parseInt(minDepo) * 2}
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) * 3 ? 'activeAmt' : ''} `}
                                    onClick={() => this.handleInstanAmount(parseInt(minDepo) * 3)}
                                >
                                    {parseInt(minDepo) * 3}
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-amount ${this.state.depositAmount === parseInt(minDepo) * 4 ? 'activeAmt' : ''} `}
                                    onClick={() => this.handleInstanAmount(parseInt(minDepo) * 4)}
                                >
                                    {parseInt(minDepo) * 4}
                                </button>
                            </div>)}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <form id="form-send-money">
                                <div className="form-group">
                                    <label htmlFor="youSend">
                                        <b>{t("depositInputDeposit")}</b>
                                    </label>
                                    {this.state.errorMessage ? (
                                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                                    ) : (
                                        false
                                    )}
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            {' '}
                                            <span className="input-group-text">
                                                {localStorage.getItem('activeCurrency')}
                                            </span>{' '}
                                        </div>
                                        <input
                                            type="number"
                                            id="youSend"
                                            onChange={this.handleAmountInput.bind(this)}
                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                            className="form-control"
                                            value={this.state.depositAmount > 0 ? this.state.depositAmount : ''}
                                            data-bv-field="youSend"
                                            placeholder={`${this.formatMoney(minDepo)} - ${this.formatMoney(maxDepo)}`}
                                        />
                                    </div>
                                </div>

                                {this.promoThumbnailGenerator()}

                                <div className="col-lg-12 col-md-12 col-sm-12 no-padding">
                                    <div className="payment-info">

                                        <div>
                                            {' '}
                                            {t("depositMethod.fee")}{' '}
                                            <span>{selectedDepositMethod.feeRate}</span>
                                        </div>

                                        <div>
                                            {' '}
                                            {t("depositMethod.max")}{' '}
                                            <span>{this.formatMoney(maxDepo)}</span>
                                        </div>

                                        <div>
                                            {' '}
                                            {t("depositMethod.min")}{' '}
                                            <span>{this.formatMoney(minDepo)}</span>
                                        </div>
                                    </div>
                                </div>


                                <button
                                    type="button"
                                    className="btn btn-depo"
                                    onClick={() => this.handleFormSubmit(this.props.deposit)}
                                >
                                    {t("continue")}
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    handleContinueRedirect = () => {
        console.log('FROM HANDLE CONTINUE REDIRECT', this.props);
        if (this.props.deposit.continueUrl && this.props.deposit.continueUrl.length > 0 && !this.doNotRedirectToPayment()) {
            // window.open(this.props.deposit.continueUrl, "_blank");
           
            return (
                <div className="deposit-success-container">
                    {!this.state.showMethods ? (
                        <div
                            onClick={() => {
                                this.handleBackClicked();
                            }}
                            className=" back-btn align-self-center"
                        >
                            {t("buttonBack")}
                        </div>
                    ) : null}
                    <div className="success-image-sub">
                        <img
                            className="img-fluid align-items-end"
                            src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + this.state.selectedDepositMethod?.paymentMethod + '.svg'}
                            alt={"selected"}
                            title=""
                        />
                    </div>


                    <p dangerouslySetInnerHTML={
                        { __html: t("depositMethod.text1", { interpolation: { escapeValue: false } }) }}>
                    </p>

                    <div>
                        <div className="buttons">
                            <>
                                <a onClick={() => this.handleBackClicked()}>
                                    <div className="btn btn-white" >{t("depositMethod.try")}</div>
                                </a>
                                <a onClick={() => this.props.hide()}>
                                    <div className="btn white-text" >{t("back")}</div>
                                </a>

                            </>
                        </div>
                    </div>
                </div>
            );
        } else if (this.doNotRedirectToPayment() && !this.props.deposit.error) {
            if (this.state.payments && this.state.payments.deposit.continueUrl) {
    
                return (
                    <div className="deposit-success-container">
             
                        {!this.state.showMethods ? (
                            <div
                                onClick={() => {
                                    this.handleBackClicked();
                                }}
                                className=" back-btn align-self-center"
                            >
                                {t("buttonBack")}
                            </div>
                        ) : null}
                        <div className="title">
                            <img
                                src={this.state.payments && this.state.payments.deposit.continueUrl}
                                alt=""
                            />

                            <form id="form-upload-doc">
                                <label htmlFor="continueId">
                                    <b>PIX Code</b>
                                </label>
                                <input
                                    ref="continueId"
                                    id="continueId"
                                    type="text"
                                    className="form-control"
                                    readOnly={true}
                                    value={this.state.payments && this.state.payments.deposit.continueId}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.currentTarget.select();
                                    }}
                                />
                            </form>
                            <a onClick={(e) => {
                                this.handleCopy();
                            }}>
                                <div className="btn btn-white">{t("Copy")}</div>
                            </a>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <Spinner />
                )
            }

        } else if (this.props.deposit.error) {
            return (
                <div className="deposit-success-container">
                    <img
                        className="img-fluid align-items-end"
                        src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + this.state.selectedDepositMethod?.paymentMethod + '.svg'}
                        alt={"selected"}
                        title=""
                    />
                    <p className='title'>{this.props.deposit.error}</p>


                    <div>
                        <div className="buttons">
                            <a onClick={() => this.handleBackClicked()}>
                                <div className="btn btn-cta ">{t("depositMethod.try")}</div>
                            </a>
                            <br />
                            <p>{t("depositMethod.text3")}</p>

                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Spinner />
            );
        }
    };

    render() {
        if (this.props.user && this.props.user.registrationStatus === "QUICK_REG") {
            return <Redirect to={"/signup/full"} />
        }
        let loggedIn = this.props.loggedIn;

        if (!this.props.user) {
            return <Redirect to={'/'} />;
        }

        if (this.state.skipDeposit && this.props.settings) {
            console.log('redirecting because skipdeposit is true');
            return <Redirect to={getLoggedInRedirect(this.props.settings, true)} />;
        }
        if (this.state.startPlaying && this.props.settings) {
            return this.props && <Redirect to={getLoggedInRedirect(this.props.settings)} />;
        }

        let deposit = this.props.deposit;
        console.log(this.props, 'PROPS###');

        if (!deposit || !deposit.activeCurrency || !deposit.methods || !deposit.currencies) {
            return <span />;
        }
        let phase = deposit.phase;
        let isStore = this.props.settings && this.props.settings.useStoreInsteadOfDeposit;
        const activeCurrency = localStorage.getItem('activeCurrency') || deposit.activeCurrency;

        // if (this.props.kycStatus === KycStatus.ID_CHECK_REQUESTED) {
        // 	return (
        // 		<Page noPaddingTop={true}>
        // 		<div className={'deposit-wrapper'}>
        // 			"ID_CHECK_REQUESTED"
        // 			</div>
        // 			</Page>

        // 	)
        // }




        return (
            <Page noPaddingTop={true}>
                <div className={'deposit-wrapper'}>

                    <div>

                        <div id="content">


                            <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                                {this.state.methodsFromProvider.length == 1 && this.state.showMethods ?
                                    (this.handleClickDepositMethod(this.state.methodsFromProvider[0])) : null
                                }

                                {this.showDepositMethods()}

                                {this.state.selectedDepositMethod && !this.state.showDepositRedirect ? (
                                    this.showDepositForm(this.state.selectedDepositMethod)
                                ) : null}

                                {this.state.showDepositRedirect ? this.handleContinueRedirect() : null}
                            </div>
                        </div>

                        {/* Content end */}
                    </div>
                </div>
            </Page>
        );
    }
}

interface OtherProps extends RouteComponentProps { }

interface PropsFromState {
    deposit: DepositState;
    // onboardingpage?: OnboardingPagePayload;
    global?: GlobalPayload;
    settings?: Settings;
    registrationStatus: boolean;
    user?: UserInfo;
    show?: boolean;
    activeCurrency?: string;
    newUser?: boolean;
    depositPage: any;
    isFetching: boolean;
    fetchContent: typeof fetchContent;
    loggedIn?: boolean;
    partyId?: number;
    optInBonusPlans?: any;
    kycStatus?: string;
}

interface PropsFromDispatch {
    fetchProviders?: () => void;
    fetchBalance?: () => void;
    selectCurrency?: (currencyCode: string) => void;
    choosePaymentMethod?: (method: PaymentMethod) => void;
    hide: () => void;
    setNewUser?: typeof setNewUser;
    reset?: typeof reset;
    processDeposit: (redirect?: boolean) => any;
    continueUrl?: string;
}

const mapStateToProps = ({ payments, settings, user, contentful }: AppState) => {
    let status = false;
    if (user.registration && user.registration.status) {
        if (user.registration.status == Status.SUCCESS) {
            status = true;
        }
    }

    // console.log('test2', payments.deposit);

    return {
        deposit: payments.deposit,
        settings: settings.settings,
        loggedIn: LoginStatus.LOGGED_IN == user.status,
        registrationStatus: status,
        user: user.user,
        show: payments.deposit.showCashierModal,
        activeCurrency: user.activeCurrency,
        newUser: user.newUser,
        isFetching: contentful.isFetching,
        depositPage: contentful.depositPage,
        partyId: user.user?.partyId,
        kycStatus: user.user?.kycStatus,
        optInBonusPlans: user.optInBonusPlans,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchProviders: () => dispatch(fetchProviders() as any),
    fetchBalance: () => dispatch(fetchBalance() as any),

    selectCurrency: (currencyCode: string) => dispatch(selectCurrency(currencyCode)),
    choosePaymentMethod: (method: PaymentMethod) => dispatch(selectPaymentMethod(method)),
    hide: () => dispatch(showCashierModal(false)),
    setNewUser: (newUser: boolean) => dispatch(setNewUser(newUser) as any),
    reset: () => dispatch(reset() as any),
    processDeposit: (redirect = true) => dispatch(processDeposit(redirect) as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) =>
        dispatch(fetchContent(contentModelName, locale) as any)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter<AllProps>(DepositMethod));
