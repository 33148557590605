import {
  CasinoBannerPayload,
  FooterPayload,
  GlobalPayload,
  LandingPagePayload,
  OnboardingPagePayload,
  PokerPagePayload,
  ProfilePagePayload,
  WithdrawPagePayload,
  PopupAppInstall,
  HomePayload,
  SlidersMaharajaPayload,
  CasinoGamesPayload,
  LiveCasinoGamesPayload,
  PopupManager,
  PopupNotification,
  SportsPagePayload,
  RegsiterSuccessPayload,
  TermsAndConditionPayload,
  WithdrawPagePayloadV2,
  DepositPagePayload,
  FAQPayload,
  RegisterPayload,
  CompleteRegistrationPayload,
} from "./types";
import { ContenfulActions, ContentfulActionTypes } from "./ContentfulActions";

export interface ContentfulState {
  landingpage?: LandingPagePayload;
  onboardingpage?: OnboardingPagePayload;
  withdrawPage?: WithdrawPagePayloadV2;
  depositPage?: DepositPagePayload;
  global?: GlobalPayload;
  pokerPage?: PokerPagePayload;
  profilePage?: ProfilePagePayload;
  footerPayload?: FooterPayload;
  isFetching: boolean;
  isFetchingMultiple?: boolean;
  error?: string;
  errorMultiple?: string;
  casinoBanner?: CasinoBannerPayload[];
  popupAppInstall?: PopupAppInstall;
  popupManager?: PopupManager;
  popupNotification?: PopupNotification;
  sportsPage?: SportsPagePayload;
  registersuccessMaharajaFortune?: RegsiterSuccessPayload;
  termsAndConditionsMaharajaFortune?: TermsAndConditionPayload;
  termsAndConditionsJoiabet?: TermsAndConditionPayload;
  FAQ?: FAQPayload;
  home?: HomePayload;
  slidersMaharaja?: SlidersMaharajaPayload;
  casinoGames?:CasinoGamesPayload;
  livecasinoGames?:LiveCasinoGamesPayload;
  register?: RegisterPayload;
  completeRegistration?: CompleteRegistrationPayload;
}

const initialState: ContentfulState = {
  landingpage: undefined,
  isFetching: false,
  error: undefined,
};

export function contentfulReducer(
  state = initialState,
  action: ContenfulActions
) {
  switch (action.type) {
    case ContentfulActionTypes.FETCH_ENTRIES_REQUEST:
      return { ...state, isFetching: true };
    case ContentfulActionTypes.FETCH_ENTRIES_SUCCESS:
      return {
        ...state,
        error: undefined,
        isFetching: false,
        [action.contentModelName]: action.payload,
      };
    case ContentfulActionTypes.FETCH_ENTRIES_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_REQUEST:
      return { ...state, isFetchingMultiple: true };
    case ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_SUCCESS:
      return {
        ...state,
        isFetchingMultiple: false,
        [action.contentModelName]: action.payload,
      };
    case ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_ERROR:
      return {
        ...state,
        isFetchingMultiple: false,
        errorMultiple: action.error,
      };
    case ContentfulActionTypes.CHANGE_LNG:
      return {
        ...state,
        landingpage: undefined,
        onboardingpage: undefined,
        withdrawPage: undefined,
        global: undefined,
        pokerPage: undefined,
        profilePage: undefined,
        footer: undefined,
        infoPage: undefined,
        sportsPage: undefined,
        termsAndConditionsMaharajaFortune: undefined,
        termsAndConditionsJoiabet:undefined,
        registersuccessMaharajaFortune: undefined,
        home: undefined,
        FAQ: undefined,
        slidersMaharaja: undefined,
        register: undefined,
        casinoGames:undefined,
        livecasinoGames:undefined,
        completeRegistration: undefined,
      };
    default:
      return state;
  }
}
