
import {ContentfulClientApi, createClient, Entry, EntryCollection, LocaleCollection} from "contentful";
import {ContentModelName} from "./types";

export default class ContentfulClientService {
    public client: any;
    public authorized: any;
    public currentSpace: any;

    constructor(space: string, accessToken: string, preview: boolean) {
        this.initClient(space, accessToken, preview);
    }

    private initClient = (space: string, accessToken: string, preview: boolean)  => {
        this.client = createClient({
            space: space,
            accessToken: accessToken,
            host: preview ? 'preview.contentful.com' : 'cdn.contentful.com'
        })
    }
    public getEntries(id: string, locale?: string) {
        return this.client.getEntries({"content_type": id, "locale": locale});
    }
    public getInfoPage(id : string, locale?: string) : Promise<EntryCollection<any>> {
        return this.client.getEntries({"fields.id" : id,"content_type": ContentModelName.INFOPAGE, "locale": locale});
    }

    public getLocales() : LocaleCollection{
        return this.client.getLocales();
    }

    public getContentType(id: string) {
        return this.client.getContentType(id);
    }

    public getClient = () => {
        return this.authorized && this.client;
    }

    public getCurrentSpaceName = (): string => {
        let currentSpaceName = (this.currentSpace && this.currentSpace.name) ? this.currentSpace.name : "";
        return currentSpaceName
    }

    public resetClient = () => {
        window.sessionStorage.clear()
        this.authorized = false
    }
}




// export { initClient, getClient, resetClient, getCurrentSpaceName }