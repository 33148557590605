import * as React from "react";
import  "./GenericButton.scss";
import {connect} from "react-redux";
import {AppState} from "../RootReducer";
import {t} from "../i18n";
import {getContainer} from '../ioc/IOCSetup';
interface Props {
    type: string;
    children?: any;
    disabled?: boolean;
    hideText?: boolean;
}

enum ButtonType {
    LOGIN = "login",
    RECOVER_PASSWORD = "recover_password",
    RESET_PASSWORD = "reset_password",
    PROFILE_PAGE = "profile_page",
    VERIFY_EMAIL = "verify_email",
    SAVE_SCREEN_NAME = "save_screen_name"
}

type AllProps = Props & PropsFromState;

export const GenericSubmitButton = (props: AllProps) => {
    let text = "";
    let btn = "btn btn-primary";
    if(props.type === ButtonType.LOGIN) {
        text = props.lng === "ja-JP" ? props.loginButtonJP : props.loginButton;
        btn = "btn btn-cta red-bg btn-lg btn-block height";
    } else if(props.type === ButtonType.RECOVER_PASSWORD) {
        text = props.lng === "ja-JP" ? props.passwordRecoverButtonJP : t("genericSubmitBtn.resetLink");
        btn = "btn btn-cta red-bg   btn-lg btn-block height";
    } else if(props.type === ButtonType.RESET_PASSWORD) {
        text = props.lng === "ja-JP" ? props.passwordRecoverButtonJP : t("resetPasswordPageComp.reset");
        btn = "btn btn-primary btn-lg height";
    } else if(props.type === ButtonType.PROFILE_PAGE) {
        text = props.profileSubmitButton;
    } else if(props.type === ButtonType.VERIFY_EMAIL) {
        text = t("account.verifyEmail");
        btn = "btn btn-cta btn-lg height";
    } else if(props.type === ButtonType.SAVE_SCREEN_NAME) {
        text = t("forms.screenName.button");
    }
    return (
        <button disabled={props.disabled && props.disabled} type="submit" className={btn}>
            {!props.hideText &&   <span>{text}</span>}
            {
                props.children
            }
        </button>
    )
}


interface PropsFromState {
    loginButton: string;
    passwordRecoverButton: string;
    passwordResetButton: string;
    profileSubmitButton: string;
    loginButtonJP: string;
    passwordRecoverButtonJP: string;
    passwordResetButtonJP: string;
    profileSubmitButtonJP: string;
    lng: string;
}

const mapStateToProps = (state : AppState) : PropsFromState => (
    {
    
        loginButton :  "Login",
        passwordRecoverButton : "Reset password",
        passwordResetButton : "Set password",
        profileSubmitButton : "Update",
        loginButtonJP :  "ログイン",
        passwordRecoverButtonJP : "パスワードを再設定する",
        passwordResetButtonJP : "Set password",
        profileSubmitButtonJP : "Update",
        lng:getContainer().getSettingsService().getActiveLocale().code,
        
    }
)

export default connect(
    mapStateToProps
)(GenericSubmitButton)

