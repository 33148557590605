import React from "react";
import Root from "../layout/Root";
import './Withdraw.scss';
import {RouteComponentProps} from "react-router-dom";
import {t} from "../i18n";
import {EntryCollection} from "contentful";
import ContentfulClientService from "../contenful/ContentfulClientService";
import {getContainer} from "../ioc/IOCSetup";


type AllProps = RouteComponentProps<{country : string}>;



export class CountryBlocked extends React.Component<AllProps, { entries? :EntryCollection<any>, loading? : boolean }> {
    service : ContentfulClientService;
    constructor(props : AllProps) {
        super(props);
        this.service = getContainer().getContentfulClientService();
        this.state = {
            loading : true,
        }
    }

    componentDidMount(): void {

    }


    render() {

        let title = t("country-block.title", { country : this.props.match.params.country});
        let description = t("country-block.description")
        return (
            <React.Fragment>
                <Root footer={true} header={true} className={"country-blocked-page"}>
                    <div className={"country-blocked-container"}>
                        <h2>{t("countryBlocked.err")}</h2>
                        <p>{t("countryBlocked.errText")}</p>
                    </div>
                </Root>
            </React.Fragment>
        )
    }
}


export default CountryBlocked;



