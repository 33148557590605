import React, { useRef, useReducer, useState, useEffect } from "react";
import Footer from "../footer/Footer";
import HeaderView from '../Header/HeaderView';
interface Props {
    children: any;
    footer: boolean;
    className? : string;
    style? : any;
    header:any;
}

const Root: React.FC<Props> = (props: Props) => {
 

    return (
 
        <div  id={"pageContainer"} style={props.style ? props.style : {}}>
                   { props.header
                    ? <HeaderView/>  : '' }
                <div>
                {
                    props.children
                }
                </div>
                <br/>
                {
                    props.footer
                    ? <Footer />
                    : ''
                }
        </div>
     
    )
}

export default Root;