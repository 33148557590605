/* tslint:disable */

export interface BuyBundleRequest {
  provider?: string;
  method?: string;
}

export interface PaymentDialog {
  uid?: string;
  payment?: Payment;
  iframe?: string;
  iframeUrl?: string;
  customContext?: any;
}

export interface PendingWithdrawal {
  status?: string;
  withdrawals?: any;
}


export interface ListBundlesResponse extends ListResponse {
  items?: Bundle[];
}

export interface Bundle {
  id?: number;
  nameId?: string;
  name?: string;
  description?: string;
  imageUrl?: string;
  label?: string;
  created?: Date;
  enabled?: boolean;
  price?: Monetary;
  awardFunds1?: Monetary;
  awardFunds2?: Monetary;
  awardPokerItemId?: string;
  awardPokerItemList?: PokerItem[];
  featured?: boolean;
  type?: BundleType;
}

export interface Bonuses {
bonus:Bonus;
}
export interface Bonus {
  id?: number;
  bonusPlanName?: string;
  status?: string;
  expiryDate?: string;
  triggerDate?: string;
  amount?: Monetary;
  wagerRequirement?: Monetary;
  triggerType?: string;
}
export interface CasinoLobby {
  games?: CasinoGame[];
  casinoGameBanner?: CasinoGame[];
  gamesInfo?: CasinoGame[];
  categories?: CasinoLobbyCategory[];
  studios?: CasinoStudio[];
  currencies?: CasinoCurrencies[];
}

export interface LaunchCasinoGameResponse {
  url?: string;
  countryBlock?: boolean;
}

export interface ClientApplicationConfig {
  clientBaseUrl?: string;
  operatorId?: string;
  facebookAppId?: string;
  googleAnalyticsId?: string;
  googleTagManagerId?: string;
  manifest?: string;
}

export interface CountryInfo {
  blocked?: boolean;
  countryCode?: string;
  countryName?: string;
}

export interface PushSubscription {
  endpoint?: string;
  expirationTime?: number;
  auth?: string;
  key?: string;
}

export interface PlayerOrder {
  type?: PaymentType;
  amount?: number;
  currencyCode?: string;
  recipientAddress?: string;
  method?: string;
  purchaseBundleId?: number;
  purchaseBundleName?: string;
  custom?: any;
}

export interface CustomerInfo {
  userId?: number;
  firstName?: string;
  lastName?: string;
  address?: string;
  countryCode?: string;
  state?: string;
  email?: string;
  languageCode?: string;
  mobileNumber?: string;
  dateOfBirth?: Date;
  ipAddress?: string;
  personId?: string;
  paymentPermitted?: PaymentPermitted;
  nickname?: string;
  bank?: BankInfo;
  phone?: string;
}

export interface DepositResult {
  paymentId?: number;
  paymentUid?: string;
  result?: DepositResultCode;
  referenceId?: string;
  message?: string;
}

export interface RequestWithdrawResponse {
  accepted?: boolean;
  result?: string;
  paymentId?: number;
  paymentUid?: string;
  referenceId?: string;
}

export interface PlayerTransactionsRequest {
  type?: Product;
  offset?: number;
  limit?: number;
  currency?: string;
}

export interface PlayerTransactionsResponse {
  transactions?: PlayerTransaction[];
}

export interface LaunchUrl {
  url?: string;
  token?: string;
  launchToken?:string;
  link?:string;
}

export interface kycCheck {
  ipsKycCheck?:ipsKycCheck
  status?:string
}
export interface ipsKycCheck {
  comments?: string;
  confirmed?: string;
  date?:string;
  fullName?:string;
  kycStatus?:string;
  lastUpdateDate?:string;
  score?:number;
  status?:string;
}

export interface PokerPlayerCount {
  online?: number;
  seated?: number;
}

export interface Settings {
  title?: string;
  cssOverrideUrl?: string;
  pokerEnabled?: boolean;
  casinoEnabled?: boolean;
  sportsbookEnabled?: boolean;
  bonusEnabled?: boolean;
  racingEnabled?: boolean;
  contenfulSpaceId?: string;
  contentfulAccessToken?: string;
  requireUserChannelVerification?: boolean;
  emailSignupAvailable?: boolean;
  phoneSignupAvailable?: boolean;
  kycOnWithdrawRequired?: boolean;
  faqPageEnabled?: boolean;
  currencies?: CurrencyDefinition[];
  transferLimits?: TransferLimit[];
  facebookAppId?: string;
  useStoreInsteadOfDeposit?: boolean;
  useInfoTextSignup?: boolean;
  showCurrencySelectOnSignup?: boolean;
  freshChatEnabled?: boolean;
  freshChatWidgetToken?: string;
  freshChatHost?: string;
  matiEnabled?: boolean;
  matiClientId?: string;
  bundleBonusCurrency?: string;
  skipDepositPageOnRegister?: boolean;
  forceWithdrawCurrency?: string;
  googleAnalyticsId?: string;
  customScripts?: string;
  acquisitionScript?: string;
  menu?: Menu;
  loginRedirectUrl?: string;
  useAmplitude?: boolean;
  amplitudeApiKey?: string;
  clientManifest?: string;
  pwaEnabled?: boolean;
  currencyOrder?: string;
  pushNotificationsEnabled?: boolean;
  loginsRequiredForPushPrompt?: number;
  noOfTimeBeforeHidingPrompt?: number;
  noOfTimeBeforePromptingAgain?: number;
  appPopupEnabled?: boolean;
  appNativePromptEnabled?: boolean;
  delayAppInstallPopup?: number;
  appInstallButtonsEnabled?: boolean;
  countryBlockEnabled?: boolean;
  pwaEnabledIOS?: boolean;
  pwaEnabledAndroid?: boolean;
  pwaEnabledWindows?: boolean;
  iosMediaJSON?: string;
  requiredUserAttributes?: UserAttributeDefinition[];
  tokenLoginEnabled?: boolean;
  sportsbookProvider?: SportsbookProvider;
  operatorName?: string;
  operatorIconUrl?: string;
  mfaEnabled?: boolean;
}

export interface SiteMap {
  url?: SiteMapUrl[];
}

export interface AuthToken {
  id?: number;
  device?: string;
}

export interface UserBonusView {
  wagerRequirementNumber?:number;
  expiryDate?:string;
  expiryDateFormatted?:string;
  releaseDateFormatted?:string;
  status?:string;
  triggerType?:string;
  wagerRequirement?:string;
  amount?:string;
  amountNumber?:number;
  bonusPlanName?:string;
  bonusPlanId?:number;
  id?:number;
}

export interface UserBonusListResponse extends ListResponse {
  items?: UserBonusView[];
}

export interface MultiFactorAuthRequest {
  provider?: MultiFactorAuthProvider;
  verificationCode?: number;
}

export interface MultiFactorAuthResponse {
  provider?: MultiFactorAuthProvider;
  secret?: string;
  issuer?: string;
  username?: string;
  imageUrl?: string;
  timeToLiveSeconds?: number;
}

export interface BalanceAndBonus {
  activeOfferList?: BonusOffer[];
  activeBonusList?: UserBonusView[];
  accountBalanceList?: AccountBalance[];
  totalBalance?: number;
  casinoPlayableBonusBalance?:number;
  sportsbookPlayableBonusBalance?:number;
  bonusBalance?:number;
}

export interface UserInfo extends Serializable {
  loggedIn?: boolean;
  errorMessage?: string;
  userId?: number;
  blocked?: boolean;
  emailVerified?: boolean;
  newUser?: boolean;
  username?: string;
  blockedStatus?: BlockedStatus;
  attributes?: { [index: string]: string };
  responseStatus?: LoginResponseStatus;
  userSettings?: { [index: string]: string };
  email?: string;
  phoneNumber?: string;
  kycVerified?: boolean;
  country?: string;
  kycStatus?: KycStatus;
  lastLogin?:string;
  mobilePhone?:string;
  mobileCountryCode?:string;
  mobileLocalNumber?:string;
  token?: string;
  firstName?:string;
  lastName?:string;
  secret?: string;
  address?:string;
  city?:string;
  province?:string;
  postalCode?:string;
  language:string;
  regDate?:string;
  level?:number;
  registrationStatus?:string;
  gender?:string;
  birthDate?:string;
  partyId?:number;
  tokenRefId?: number;
  currencies?: Currency[];
  mfaStatus?: MultiFactorAuthStatus;
  mfaProvider?: MultiFactorAuthProvider;
  avatarUrl?: string;
  sessionKey?: string;
  currency?: string;
  status?:string;
  message?:string;
  madeDeposit?:boolean;
  optInBonusPlans?:any;
  bonuses?:any;
  activeBonus?:any;
}

export interface ManualLoginRequest extends LoginRequest {
  email?: string;
  password?: string;
}

export interface ForgotPasswordRequest {
  email?: string;

}
export interface TokenLoginRequest extends LoginRequest {
  token?: string;
  secret?: string;
}

export interface FacebookLoginRequest extends LoginRequest {
  facebookToken?: string;
}

export interface RegistrationRequest {
  email?: string;
  phoneNumber?: string;
  password?: string;
  repeatPassword?: string;
  attributes?: { [index: string]: string };
  username?: string;
  country?: string;
  currency?: string;
  receiveEmail?:boolean;
  firstName?:string;
  lastName?:string;
  gender?:string;
  birthDate?:string;
  address?:string;
  city?:string;
  postal?:string;
  mobileNumber?:string;
  securityQuestion?:string;
  securityAnswer?:string;
  language?:string;
  cpfnumber?:string;
}

export interface RegistrationResponse {
  status?: Status;
  userInfo?: UserInfo;
  message?:string;
}

export interface RequestEmailVerification {
  email?: string;
}

export interface ResetPasswordRequest {
  email?: number;
  resetPasswordKey?: string;
  newPassword?: string;
  confirmationCode?:string;
}

export interface Payment {
  id?: number;
  externalPaymentId?: string;
  userId?: number;
  paymentTxId?: number;
  paymentReverseTxId?: number;
  provider?: string;
  uid?: string;
  type?: PaymentType;
  status?: PaymentStatus;
  amount?: number;
  currency?: string;
  created?: Date;
  updated?: Date;
  order?: Order;
  attributes?: { [index: string]: string };
  log?: PaymentLog[];
}

export interface ListResponse {
  nrOfResults?: number;
}

export interface Monetary {
  currency?: string;
  amount?: number;
}

export interface PokerItem {
  id?: string;
  nameId?: string;
  name?: string;
  description?: string;
  imageUrl?: string;
  category?: string;
  value?: number;
  isEquippable?: boolean;
  operatorId?: number;
  allowMultiple?: boolean;
  voucherCurrencyCode?: string;
  voucherValue?: number;
}

export interface CasinoGame {
  id?: number;
  GameId?:string;
  Categories:number[];
  Name?: string;
  nameJP?:string;
  gameName?:string;
  gameNameJP?:string;
  GameLaunchId?:string;
  gameCategoryId?:number;
  gameCategoryName?:string;
  segmentId?:string;
  segmentName?:string;
  productId?:string;
  enabled?: boolean;
  ProductCode?:string;
  SubProductCode?:string;
  subProductId?:string;
  isMobile?:boolean;
  isFreespinEnable?:boolean;
  mobile?: boolean;
  desktop?: boolean;
  playForFun?: boolean;
  isDemoEnabled?:boolean;
  imageUrl?: string;
  gameType?: CasinoGameType;
  aspectRatio?: CasinoGameAspectRatio;
  GameTrailerNameMOBILE?: string;
  GameTrailerNameDESKTOP?: string;
  InGameScreenshot?: string;
  GameIconName?: string;
  updated?: Date;
  externalId?: string;
  mobileExternalId?: string;
  integration?: CasinoIntegration;
  removed?: boolean;
  attributes?: { [index: string]: string };
  created?: Date;
  studio?: CasinoStudio;
  translations?: CasinoGameTranslation[];
  ReleaseDate?: Date;
  gameTags?: GameTag[];
  backgroundImageUrl?: string;
  GameBannerName?:string;
  mobileOnly?:boolean;
  isTopGame?:boolean;
  isNewGame?:boolean;
  isJackpots?:boolean;
  isMobileDesktop?:boolean;
  isDesktopOnly?:boolean;
  isMobileOnly?:boolean;
  homeTopGames?: boolean;
  homeMostPopular?:boolean;
  homeRecommended?:boolean;
  isPopularGame?:boolean;

  Tags?:string[];

}

export interface CasinoLobbyCategory {
  Id?: number;
  Name?: string;
  translationKey?: string;
  LobbyOrder?: number;
  Types?: string;
  games?: number[];
  Tags?: number[];
  translations?: { [index: string]: string };
  Translation?:string;
}

export interface CasinoStudio {
  Id?: number;
  Name?: string;
  externalId?: string;
  SubProductId?: string;
  integration?: CasinoIntegration;
  enabled?: boolean;
  imageUrl?: string;
  blockedCountries?: string;
  allowedCountries?: string;
  blockedCurrencies?: string;
  iconName?:string;
}

export interface CasinoCurrencies {
  integration?: CasinoIntegration;
  currencies?: string[];
  studioName?: number;
}

export interface PaymentMethod {
  provider?: string;
  code?: string;
  name?: string;
  depositMethods?:string;
  amountRequired?: boolean;
  depositEnabled?: boolean;
  withdrawEnabled?: boolean;
  achBankTransfer?: boolean;

}

export interface WithdrawalMethod {
  paymentMethod?: string;
  code?: string;
  minimumWithdrawal?: string;
  maximumWithdrawal?:string;
  conversionRequired?: boolean;
  methods?: [];
  method?:string;
}

export interface DepositMethods {
  code?: string;
  conversionRequired?: boolean;
  feeRate?: string;
  maximumDeposit?: string;
  method?: string;
  minimumDeposit?: string;
  paymentMethod?:string;
  popupRequired?: boolean;
}

export interface BankInfo {
  accountAddress1?: string;
  accountAddress2?: string;
  accountCity?: string;
  accountState?: string;
  accountZip?: string;
  accountCountry?: string;
  name?: string;
  routingNumber?: string;
  accountNumber?: string;
  accountOwner?: string;
}

export interface PlayerTransaction {
  id?: number;
  attributes?: { [index: string]: string };
  amount?: Money;
  timestamp?: Date;
}

export interface CurrencyDefinition {
  code?: string;
  fractionalDigits?: number;
  visible?: boolean;
  cryptoCurrency?: boolean;
  name?: string;
  prefixSymbol?: string;
  postfixSymbol?: string;
  irlCurrencyCode?: string;
  irlCurrencyFactor?: number;
}

export interface TransferLimit {
  currency?: string;
  minDepositAmount?: number;
  maxDepositAmount?: number;
  minWithdrawAmount?: number;
  maxWithdrawAmount?: number;
  defaultDepositAmount?: number;
}

export interface Menu {
  items?: MenuItem[];
}

export interface UserAttributeDefinition {
  id?: number;
  type?: AttributeType;
  attributeKey?: string;
  userWriteAccess?: UserWriteAccess;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  regex?: string;
  nameTranslationKey?: string;
  descriptionTranslationKey?: string;
  options?: string;
}

export interface SiteMapUrl {
  loc?: string;
}

export interface BonusConfig {
  id?: number;
  status?: BonusStatus;
  validFromDate?: Date;
  validToDate?: Date;
  expiresAfterDays?: number;
  name?: string;
  summary?: string;
  description?: string;
  imageUrl?: string;
  translations?: BonusConfigTranslation[];
  monetaryConfig?: MonetaryConfig;
  turnoverContributions?: TurnoverContributions;
  trigger?: BonusTrigger;
  bonusStatusAlert?: BonusStatusAlert;
  bonusAlertDescription?: string;
  hasOfferedBonus?: boolean;
}

export interface BonusOffer {
  id?: number;
  userId?: number;
  timestamp?: Date;
  expires?: Date;
  amount?: number;
  currency?: string;
  bonusConfig?: BonusConfig;
  status?: BonusOfferStatus;
  turnoverRequirement?: number;
}

export interface AccountBalance {
  role?: AccountRole;
  balance?: Money;
}

export interface Currency extends Serializable {
  code?: string;
  fractionalDigits?: number;
  visible?: boolean;
  cryptoCurrency?: boolean;
  name?: string;
  prefixSymbol?: string;
  postfixSymbol?: string;
  irlCurrencyCode?: string;
  irlCurrencyFactor?: number;
  alertThreshold: number;
  isDefault?: boolean;
  isVirtual: boolean;
  isoCode?: string;
  numberDecimalPoint?: number;
  numericCode?: string;
  refreshInterval?: number;
  stopThreshold?: number;
  symbol?: string;
  symbolCode?: string;
  type?: string;
}

export interface Serializable {}

export interface LoginRequest {
  homescreen?: boolean;
  generateLoginToken?: boolean;
  device?: string;
  mfaProvider?: MultiFactorAuthProvider;
  mfaVerificationCode?: number;
  deviceId?: string;
}

export interface Order {
  uid?: string;
  type?: PaymentType;
  amount?: number;
  currencyCode?: string;
  customerInfo?: CustomerInfo;
  method?: string;
  requestKyc?: boolean;
  successUrl?: string;
  failUrl?: string;
  recipientAddress?: string;
  destinationTag?: string;
  purchaseBundleId?: number;
  purchaseBundleName?: string;
  custom?: any;
}

export interface PaymentLog {
  id?: number;
  paymentId?: number;
  timestamp?: Date;
  type?: PaymentLogEventType;
  error?: boolean;
  message?: string;
  data?: string;
  status?: PaymentStatus;
  referenceId?: string;
}

export interface CasinoGameTranslation {
  id?: number;
  language?: string;
  name?: string;
  imageUrl?: string;
}
export interface LiveCasinoGameTranslation {
  id?: number;
  language?: string;
  name?: string;
  imageUrl?: string;
}

export interface GameTag {
  id?: number;
  name?: string;
  nameId?: string;
}

export interface Money extends Serializable {
  currencyCode?: string;
  fractionalDigits?: number;
  amount?: number;
}

export interface MenuItem {
  type?: MenuItemType;
  url?: string;
  target?: string;
  name?: string;
  translations?: { [index: string]: string };
}

export interface BonusConfigTranslation {
  id?: number;
  language?: string;
  name?: string;
  summary?: string;
  description?: string;
  imageUrl?: string;
}

export interface MonetaryConfig {
  id?: number;
  name?: string;
  currencyCode?: string;
  turnoverRequirementFactor?: number;
  maxInputAmount?: number;
  awardType?: AwardType;
  awardAmountFraction?: number;
  maxWagerAmount?: number;
}

export interface TurnoverContributions {
  id?: number;
  name?: string;
  contributions?: Contribution[];
}

export interface BonusTrigger {
  id?: number;
  name?: string;
  event?: string;
  triggerType?: RuleTriggerType;
  count?: number;
  expiresAfterDays?: number;
}

export interface Contribution {
  id?: number;
  product?: ContributionProduct;
  includeCasinoTags?: string;
  excludeCasinoTags?: string;
  minOdds?: number;
  maxOdds?: number;
  liveBetting?: boolean;
  preMatchBetting?: boolean;
  contributionFactor?: number;
  description?: string;
  translations?: ContributionTranslation[];
}

export interface ContributionTranslation {
  id?: number;
  language?: string;
  description?: string;
}

export enum BundleType {
  purchase = "purchase",
  signOnBonus = "signOnBonus",
}

export enum PaymentType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
  AUTH = "AUTH",
  BONUS = "BONUS",
  CORRECTION = "CORRECTION",
}

export enum PaymentPermitted {
  YES = "YES",
  NO = "NO",
  NO_WRONG_CURRENCY = "NO_WRONG_CURRENCY",
  NO_GENERAL_ERROR = "NO_GENERAL_ERROR",
  NO_BLOCKED = "NO_BLOCKED",
  NO_USER_NOT_FOUND = "NO_USER_NOT_FOUND",
  NO_LIMIT = "NO_LIMIT",
}

export enum DepositResultCode {
  OK = "OK",
  DECLINED = "DECLINED",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  ERROR = "ERROR",
  NOT_SUPPORTED = "NOT_SUPPORTED",
  OVER_LIMIT = "OVER_LIMIT",
  ACCOUNT_ALREADY_EXISTS = "ACCOUNT_ALREADY_EXISTS",
}

export enum Product {
  POKER = "POKER",
  CASINO = "CASINO",
  SPORTSBOOK = "SPORTSBOOK",
  PAYMENT = "PAYMENT",
  RACING = "RACING",
}

export enum SportsbookProvider {
  SONG88 = "SONG88",
  ULTRAPLAY = "ULTRAPLAY",
}

export enum UserBonusStatus {
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}

export enum MultiFactorAuthProvider {
  OTP = "OTP",
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum BlockedStatus {
  NONE = "NONE",
  BLOCKED_BY_ADMIN = "BLOCKED_BY_ADMIN",
  SELF_EXCLUSION = "SELF_EXCLUSION",
  BLOCKED_COUNTRY = "BLOCKED_COUNTRY",
}

export enum LoginResponseStatus {
  NONE = "NONE",
  SUCCESS = "SUCCESS",
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  VERIFICATION_REQUIRED = "VERIFICATION_REQUIRED",
  FAILED = "FAILED",
  BLOCKED = "BLOCKED",
  SELF_EXCLUDED = "SELF_EXCLUDED",
  FB_EMAIL_ALREADY_EXIST = "FB_EMAIL_ALREADY_EXIST",
  BLOCKED_COUNTRY = "BLOCKED_COUNTRY",
  MULTI_FACTOR_AUTH_REQUIRED = "MULTI_FACTOR_AUTH_REQUIRED",
  MULTI_FACTOR_AUTH_FAILED = "MULTI_FACTOR_AUTH_FAILED",
}

export enum KycStatus {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  REVIEW_NEEDED = "REVIEW_NEEDED",
  REJECTED = "REJECTED",
  VERIFIED = "VERIFIED",
  PASS = "PASS",
  ID_CHECK_REQUESTED = "ID_CHECK_REQUESTED",
  NOT_APPLICABLE = "N/A",
  COMPLETED = "COMPLETED"
}

export enum MultiFactorAuthStatus {
  DISABLED = "DISABLED",
  PENDING = "PENDING",
  ENABLED = "ENABLED",
}

export enum Status {
  SUCCESS = "SUCCESS",
  SCREEN_NAME_EXIST = "SCREEN_NAME_EXIST",
  EMAIL_EXIST = "EMAIL_EXIST",
  INVALID_DATA = "INVALID_DATA",
  INVALID_CHARACTER = "INVALID_CHARACTER",
  BLOCKED_DOMAIN = "BLOCKED_DOMAIN",
  BLOCKED_IP = "BLOCKED_IP",
  VALIDATION_FAIL = "VALIDATION_FAIL"
}

export enum PaymentStatus {
  INITIATED = "INITIATED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  FAILED_PERMANENTLY = "FAILED_PERMANENTLY",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  CANCELED = "CANCELED",
  RESOLVED = "RESOLVED",
  PLACEHOLDER = "PLACEHOLDER",
  APPLIED_WAITING_TO_CLEAR = "APPLIED_WAITING_TO_CLEAR",
}

export enum CasinoGameType {
  ARCADE_GAMES = "ARCADE_GAMES",
  SLOTS = "SLOTS",
  LIVE_CASINO = "LIVE_CASINO",
  TABLE_GAMES = "TABLE_GAMES",
  VIDEO_POKER = "VIDEO_POKER",
  OTHER = "OTHER",
}

export enum CasinoGameAspectRatio {
  R_16_9 = "R_16_9",
  R_4_3 = "R_4_3",
  R_3_4 = "R_3_4",
  R_9_16 = "R_9_16",
}
export enum LiveCasinoGameType {
  ARCADE_GAMES = "ARCADE_GAMES",
  SLOTS = "SLOTS",
  LIVE_CASINO = "LIVE_CASINO",
  TABLE_GAMES = "TABLE_GAMES",
  VIDEO_POKER = "VIDEO_POKER",
  OTHER = "OTHER",
}

export enum LiveCasinoGameAspectRatio {
  R_16_9 = "R_16_9",
  R_4_3 = "R_4_3",
  R_3_4 = "R_3_4",
  R_9_16 = "R_9_16",
}
export enum CasinoIntegration {
  BOOONGO = "BOOONGO",
  CUBEIA = "CUBEIA",
  SA_GAMING = "SA_GAMING",
  NUCLEUS = "NUCLEUS",
  FLOW_GAMING = "FLOW_GAMING",
  QTECH = "QTECH",
  SPRIBE = "SPRIBE",
}
export enum LiveCasinoIntegration {
  BOOONGO = "BOOONGO",
  CUBEIA = "CUBEIA",
  SA_GAMING = "SA_GAMING",
  NUCLEUS = "NUCLEUS",
  FLOW_GAMING = "FLOW_GAMING",
  QTECH = "QTECH",
  SPRIBE = "SPRIBE",
}

export enum AttributeType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  SYSTEM = "SYSTEM",
  SYSTEM_INTERNAL = "SYSTEM_INTERNAL",
}

export enum UserWriteAccess {
  NONE = "NONE",
  CREATE = "CREATE",
  CREATE_AND_UPDATE = "CREATE_AND_UPDATE",
}

export enum BonusStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  ARCHIVED = "ARCHIVED",
}

export enum BonusStatusAlert {
  GREY = "GREY",
  RED = "RED",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
}

export enum BonusOfferStatus {
  OPEN = "OPEN",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
  CLAIMED = "CLAIMED",
}

export enum AccountRole {
  MAIN = "MAIN",
  RAKE = "RAKE",
  WALLET = "WALLET",
  BONUS = "BONUS",
  PROMOTION = "PROMOTION",
}

export enum PaymentLogEventType {
  INITIATED = "INITIATED",
  CALLBACK_RECEIVED = "CALLBACK_RECEIVED",
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
  DECLINED = "DECLINED",
  INTERNAL_TRANSFER_COMPLETE = "INTERNAL_TRANSFER_COMPLETE",
  INTERNAL_TRANSFER_FAILED = "INTERNAL_TRANSFER_FAILED",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  RESOLVED = "RESOLVED",
  GENERIC = "GENERIC",
}

export enum MenuItemType {
  POKER = "POKER",
  CASINO = "CASINO",
  SPORTSBOOK = "SPORTSBOOK",
  RACING = "RACING",
  CUSTOM = "CUSTOM",
}

export enum AwardType {
  CLAIMED = "CLAIMED",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
}

export enum RuleTriggerType {
  ABSOLUTE_COUNT = "ABSOLUTE_COUNT",
  EVERY_TIME = "EVERY_TIME",
  MANUAL = "MANUAL",
}

export enum ContributionProduct {
  CASINO = "CASINO",
  SPORTSBOOK = "SPORTSBOOK",
  POKER = "POKER",
  RACING = "RACING",
}

export interface DepositRequest {
  paymentMethod?: string,
  amount?: string,
  conversionId?: string
}

export interface ProcessDepResult {
  status?: string,
  continueUrl?: string,
  continueId?: string,
  paymentId?: string,
  message?:string
}

export interface PrepareDepositResponse {
  conversionId: string
}

export interface WithdrawalRequest {
  paymentMethod?: string,
  amount?: string,
  bankCode?:string,
  accountNumber?:string,
  accountHolderName?:string,
  mobileNumber?:string,
  ifscCode?:string,
  accountId?:string,
}

export interface ProcessWithdrawalResult {
  status?: string,
  continueUrl?: string,
  paymentId?: string,
  message?: string
}

export interface UpdatePasswordRequest {
  oldPassword: string,
  newPassword: string
}

export interface UpdatePasswordResult {
  status?: string
  errors?: Error
}

export interface FileUploadRequest {
  expiryDate: string,
  documentNumber: string,
  file?: any,
  documentType: string,
  file2?: File,
}

export interface FileUploadResult {
  status: string,
  errors: Error
}

export interface DocumentTypeResult {
  documentType: string,
  issueDateRequird: boolean,
  expiryDateRequired: boolean,
  documentNumberRequired: boolean,
  multipleFileRequired: boolean
}

export interface TransactionResult {
  timestamp: Date,
  tranType: String,
  amountReal: String,
  currency: String,
  postBalanceReal: String
}

export interface UpdateProfileRequest {
  firstName: String,
  lastName: String,
  email: String,
  mobilePhone: String,
  address: String,
  city: String,
  postalCode: String
}

export interface UpdateProfileResult {
  message: String,
  status: String,
  errors: Error
}



export interface LiveCasinoGame {
  id?: number;
  GameId?:string;
  Categories:number[];
  Name?: string;
  nameJP?:string;
  gameName?:string;
  gameNameJP?:string;
  GameLaunchId?:string;
  gameCategoryId?:number;
  gameCategoryName?:string;
  segmentId?:string;
  segmentName?:string;
  productId?:string;
  enabled?: boolean;
  ProductCode?:string;
  SubProductCode?:string;
  subProductId?:string;
  isMobile?:boolean;
  isFreespinEnable?:boolean;
  mobile?: boolean;
  desktop?: boolean;
  playForFun?: boolean;
  isDemoEnabled?:boolean;
  imageUrl?: string;
  gameType?: LiveCasinoGameType;
  aspectRatio?: LiveCasinoGameAspectRatio;
  GameTrailerNameMOBILE?: string;
  GameTrailerNameDESKTOP?: string;
  InGameScreenshot?: string;
  GameIconName?: string;
  updated?: Date;
  externalId?: string;
  mobileExternalId?: string;
  integration?: LiveCasinoIntegration;
  removed?: boolean;
  attributes?: { [index: string]: string };
  created?: Date;
  studio?: LiveCasinoStudio;
  translations?: LiveCasinoGameTranslation[];
  ReleaseDate?: Date;
  gameTags?: GameTag[];
  backgroundImageUrl?: string;
  GameBannerName?:string;
  mobileOnly?:boolean;
  isTopGame?:boolean;
  isNewGame?:boolean;
  isJackpots?:boolean;
  isMobileDesktop?:boolean;
  isDesktopOnly?:boolean;
  isMobileOnly?:boolean;
  homeTopGames?: boolean;
  homeMostPopular?:boolean;
  homeRecommended?:boolean;
  isPopularGame?:boolean;

  Tags?:string[];
}

export interface LiveCasinoLobbyCategory {
  Id?: number;
  Name?: string;
  translationKey?: string;
  LobbyOrder?: number;
  Types?: string;
  games?: number[];
  Tags?: number[];
  translations?: { [index: string]: string };
  Translation?:string;
}

export interface LiveCasinoStudio {
  id?: number;
  name?: string;
  externalId?: string;
  subProductId?: string;
  integration?: LiveCasinoIntegration;
  enabled?: boolean;
  imageUrl?: string;
  blockedCountries?: string;
  allowedCountries?: string;
  blockedCurrencies?: string;
  Name?:string;
}

export interface LiveCasinoCurrencies {
  integration?: LiveCasinoIntegration;
  currencies?: string[];
  studioId?: number;
}

export interface LiveCasinoLobby {
  games?: LiveCasinoGame[];
  casinoGameBanner?: LiveCasinoGame[];
  gamesInfo?: LiveCasinoGame[];
  categories?: LiveCasinoLobbyCategory[];
  studios?: LiveCasinoStudio[];
  currencies?: LiveCasinoCurrencies[];
}

export interface LaunchLiveCasinoGameResponse {
  url?: string;
  countryBlock?: boolean;
}
