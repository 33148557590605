import {
    ContentModelName,
    LandingPagePayload,
    OnboardingPagePayload,
    PokerPagePayload,
    PopupAppInstall,
    ProfilePagePayload,
    WithdrawPagePayload,
    PopupManager,
    InfoPagePayload,
    TermsAndConditionPayload
} from "./types";
import {Dispatch} from "redux";
import ContentfulClientService from "../contenful/ContentfulClientService";
import {getContainer} from "../ioc/IOCSetup";


export enum ContentfulActionTypes {
    FETCH_ENTRIES_REQUEST = "@contentful/entries/request",
    FETCH_ENTRIES_SUCCESS = "@contentful/entries/response",
    FETCH_ENTRIES_ERROR = "@contentful/entries/error",
    FETCH_MULTIPLE_ENTRIES_REQUEST = "@contentful/multipleentries/request",
    FETCH_MULTIPLE_ENTRIES_SUCCESS = "@contentful/multipleentries/response",
    FETCH_MULTIPLE_ENTRIES_ERROR = "@contentful/multipleentries/error",
    CHANGE_LNG = "@contentful/changelng/set",
}

export type ContenfulActions =
    | { type : ContentfulActionTypes.FETCH_ENTRIES_REQUEST }
    | { type : ContentfulActionTypes.FETCH_ENTRIES_SUCCESS, contentModelName: string, payload: LandingPagePayload | OnboardingPagePayload | WithdrawPagePayload |
        PokerPagePayload | ProfilePagePayload | PopupAppInstall | PopupManager | InfoPagePayload | TermsAndConditionPayload}
    | { type : ContentfulActionTypes.FETCH_ENTRIES_ERROR, error: string }
    | { type : ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_REQUEST }
    | { type : ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_SUCCESS, contentModelName: string, payload: any}
    | { type : ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_ERROR, error: string }
    | { type : ContentfulActionTypes.CHANGE_LNG }


export const fetchContent = (contentModelName: ContentModelName, locale?: string, listType? : boolean) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type: ContentfulActionTypes.FETCH_ENTRIES_REQUEST})
            let contentfulClientService: ContentfulClientService = getContainer().getContentfulClientService();
            let data;
            try{
                data = await contentfulClientService.getEntries(contentModelName, locale);
            } catch (e) {
                data = await contentfulClientService.getEntries(contentModelName);
            }

            if(data && data.items && data.items.length > 0) {
                dispatch({type: ContentfulActionTypes.FETCH_ENTRIES_SUCCESS, contentModelName: contentModelName, payload: listType ? data.items.map((d:any) => d.fields) : data.items[0].fields});
                console.log("payload ", data.items , contentModelName);
            } else {
                dispatch({type: ContentfulActionTypes.FETCH_ENTRIES_ERROR, error: "no content"});
            }
        } catch(e) {
            console.log("error ", e)
            dispatch({type: ContentfulActionTypes.FETCH_ENTRIES_ERROR, error: e});
        }
    }
}


export const fetchMultipleContent = (contentModelName: ContentModelName, locale?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type: ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_REQUEST})
            let contentfulClientService: ContentfulClientService = getContainer().getContentfulClientService();
            let data;
            try{
                data = await contentfulClientService.getEntries(contentModelName, locale);
            } catch (e) {
                data = await contentfulClientService.getEntries(contentModelName);
            }
            let pages: any = {};
            pages["pages"] = data.items.map((item: any) => (item.fields));
            dispatch({type: ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_SUCCESS, contentModelName: contentModelName, payload: pages });
        } catch(e) {
            dispatch({type: ContentfulActionTypes.FETCH_MULTIPLE_ENTRIES_ERROR, error: e});
        }
    }
}

export const changeLng = () => (
    {type: ContentfulActionTypes.CHANGE_LNG}
)


