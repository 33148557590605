import React from "react";
import "./styles/Loader.scss";

const Loader = (props: {style?: any}) => {
    return (
        <div style={props.style} className={"loader"}>
            <div className="spinner-border" role="status">
            </div>
        </div>
    )
}

export default Loader;