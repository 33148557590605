
import {Dispatch} from "redux";
import {AmplitudeEvent} from "./AmplitudeEvent";
import {getContainer} from "../ioc/IOCSetup";
import {AmplitudeService} from "./AmplitudeService";

export enum AmplitudeActionTypes {
    LOGEVENT = "@amplitude/logevent/request",
}

export const amplitudeLogEvent = (event: AmplitudeEvent, eventData?: object) => {
    return async (dispatch: Dispatch) => {
        try {
            try{
                let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
                if(amplitudeService) {
                    amplitudeService.logEvent(event, eventData);
                }
            } catch (e) {
                console.log("error ", e);
            }
        } catch(e) {
            console.log("error ", e);
        }
    }
}

export const amplitudeRegistrationEvent = (event: AmplitudeEvent, userId?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            try{
                let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
                if(amplitudeService) {
                    if(userId) {
                        amplitudeService.setUserId(userId.toString());
                        amplitudeService.logEvent(event);
                    }
                }
            } catch (e) {
                console.log("error ", e);
            }
        } catch(e) {
            console.log("error ", e);
        }
    }
}

export const amplitudeLoginEvent = (userId?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            try{
                let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
                if(amplitudeService) {
                    if(userId) {
                        amplitudeService.setUserId(userId.toString());
                        amplitudeService.logEvent(AmplitudeEvent.LOGGED_IN);
                    }
                }
            } catch (e) {
                console.log("error ", e);
            }
        } catch(e) {
            console.log("error ", e);
        }
    }
}

export const amplitudeLogOutEvent = () => {
    return async (dispatch: Dispatch) => {
        try {
            try{
                let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
                if(amplitudeService) {
                    amplitudeService.logEvent(AmplitudeEvent.LOGGED_OUT);
                    amplitudeService.setUserId(null);
                }
            } catch (e) {
                console.log("error ", e);
            }
        } catch(e) {
            console.log("error ", e);
        }
    }
}

