import React, { lazy } from "react";
import "./RegisterView.scss";
import { Settings, Status } from "../../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../../RootReducer";
import { Link, RouteComponentProps } from "react-router-dom";
import { Redirect } from "react-router";
import getLoggedInRedirect from "../../utils/LoggedInRedirect";
import './RegisterSuccess.scss';
import { ContentModelName, GlobalPayload, RegsiterSuccessPayload } from "../../contenful/types";
import { t } from "../../i18n";
import { Dispatch } from "redux";
import { setNewUser } from "../UserActions";
import { fetchContent } from "../../contenful/ContentfulActions";
import Root from "../../layout/Root";
import Header from "../../layout/Header";
import Page from "../../layout/Page";
import { getContainer } from "../../ioc/IOCSetup";
import ReactGA from "react-ga4";
import { showSignUp } from "../UserActions";
interface Props extends RouteComponentProps {
    status?: Status;
    regSuccess?: RegsiterSuccessPayload;
    global?: GlobalPayload;
    loggedIn?: boolean;
    setNewUser?: typeof setNewUser;
    fetchContent: typeof fetchContent;
    lng: string;
    optInBonusPlans?: any;
    hide: () => void;
}

interface State {
    startPlaying: boolean;
    deposit: boolean;
    goBack: boolean;
    bonusPlanId?: any;
}

export class RegisterSuccess extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = { startPlaying: false, deposit: false, goBack: false }
    }

    componentDidMount() {
        // if(this.props.global === undefined) {
        //     this.props.fetchContent(ContentModelName.GLOBAL);
        // }


        if (sessionStorage.getItem("bonusPlanId") && !this.state.bonusPlanId) {
            this.setState({ bonusPlanId: sessionStorage.getItem("bonusPlanId") });
        }
        if (this.props.regSuccess === undefined) {
            this.props.fetchContent(ContentModelName.REGISTERSUCCESS, getContainer().getSettingsService().getActiveLocale().code);

        }
    }
    handleBonusPlanId = (id: any) => {
        sessionStorage.setItem("bonusPlanId", id);
        this.setState({ bonusPlanId: id })
    };
    handleUnclaim = () => {
        sessionStorage.removeItem("bonusPlanId");
        this.setState({ bonusPlanId: null })
    };
    onStartPlaying() {
        this.props.setNewUser && this.props.setNewUser(false);
        this.setState({ startPlaying: true });

    }
    componentDidUpdate() {
        if (sessionStorage.getItem("bonusPlanId") && !this.state.bonusPlanId) {
            this.setState({ bonusPlanId: sessionStorage.getItem("bonusPlanId") });
        }
    }
    onDeposit() {
        this.props.setNewUser && this.props.setNewUser(false);
        this.setState({ deposit: true });
        this.props.hide && this.props.hide();
    }

    render() {
        // if(this.state.goBack) {
        //     return <Redirect to={"/"} />
        // }
        // if(this.state.startPlaying) {
        //     return <Redirect to={"/signup/full"} />
        // }
        if (this.state.deposit) {
            return <Redirect to={"/deposit"} />
        }
        // if(!this.props.loggedIn) {
        //     return <Redirect to={"/"} />
        // }
        const imgUrl = this.props.regSuccess && this.props.regSuccess.successImg.fields.file.url;
        const depositText = this.props.global && this.props.global.depositButton;
        const startText = this.props.global && this.props.global.startPlaying;

        return (
            <React.Fragment>

                <div className="registration-success-container">
                    <div className="success-image">

                        <div className='row'>
                            <div className="d-lg-block col-md-12 ">
                                <h3 className="title">
                                    {t("registerSuccess.title")}
                                </h3>
                                <h4 className="info">
                                    {t("registerSuccess.title2")}
                                </h4>
                                {this.props.optInBonusPlans && this.props.optInBonusPlans.length > 0 ? (
                                    <div className="row">
                                        {/* {promo2} */}

                                        <div className="promotion col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className={`promotion-box ${this.state.bonusPlanId == "3" ? "claimed" : ""}`}>
                                                <div className='promo-product'>{t("registerSuccess.product1")}</div>
                                                {this.state.bonusPlanId == "3" ? (
                                                    <img
                                                        className="img-fluid claimed-badge"
                                                        src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Claimed-badge.png'}
                                                        alt={'Welcome Bonus'}
                                                        title=""
                                                    />
                                                ) : null}

                                                <img
                                                    className="img-fluid"
                                                    src={'https://content.adminemx.com/maharajafortune/images/promotionpage/LiveCasino-WelcomeBonus-Banner.png'}
                                                    alt={'Welcome Bonus'}
                                                    title=""
                                                />
                                                <div className="row promodesc">
                                                    <div className="col-6">
                                                        <h5>{'Welcome Bonus'}</h5>
                                                        <p>{t("registerSuccess.text1")}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        {this.state.bonusPlanId == "3" ?
                                                            (<a
                                                                className="btn btn-primary  btn-rounded promo-btn"
                                                                onClick={() => { this.handleUnclaim() }}
                                                            >
                                                                {t("registerSuccess.unClaim")}
                                                            </a>) : (<a
                                                                className="btn btn-primary  btn-rounded promo-btn"
                                                                onClick={() => { this.handleBonusPlanId(3) }}
                                                            >
                                                                {t("registerSuccess.claim")}
                                                            </a>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {Promo2} */}

                                        {/* {promo2} */}
                                        <div className="promotion col-lg-6 col-md-6 col-sm-6  col-12">
                                            <div className={`promotion-box ${this.state.bonusPlanId == "4" ? "claimed" : ""}`}>
                                                <div className='promo-product'>{t("registerSuccess.product2")}</div>
                                                {this.state.bonusPlanId == "4" ? (
                                                    <img
                                                        className="img-fluid claimed-badge"
                                                        src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Claimed-badge.png'}
                                                        alt={'Welcome Bonus'}
                                                        title=""
                                                    />
                                                ) : null}

                                                {/* <img
                                                    className="img-fluid"
                                                    src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Sports-WelcomeBonus-Banner.png'}
                                                    alt={'Welcome Bonus'}
                                                    title=""
                                                /> */}
                                                <div className="row promodesc">
                                                    <div className="col-6">
                                                        <h5>{'Welcome Bonus'}</h5>
                                                        <p>{t("registerSuccess.text1")}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        {this.state.bonusPlanId == "4" ?
                                                            (<a
                                                                className="btn btn-primary  btn-rounded promo-btn"
                                                                onClick={() => { this.handleUnclaim() }}
                                                            >
                                                                {t("registerSuccess.unClaim")}
                                                            </a>) : (<a
                                                                className="btn btn-primary  btn-rounded promo-btn"
                                                                onClick={() => { this.handleBonusPlanId(4) }}
                                                            >
                                                                {t("registerSuccess.claim")}
                                                            </a>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {Promo2} */}

                                    </div>) : null}
                                <div className="how-to-claim-bonus-container">
                                        <p dangerouslySetInnerHTML={
                                			{ __html: t("registerSuccess.claimBonus", { interpolation: { escapeValue: false } })}}>
                            			</p>
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <div className="row">
                                <button onClick={() => this.onDeposit()} className="btn btn-cta btn-lg">{t("registerSuccess.button1")}</button>
                            </div>
                            <div className="row">
                                <span onClick={() => this.onDeposit()} className="deposit">{t("registerSuccess.button2")}</span>
                            </div>
               
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ user, settings, contentful }: AppState) => (
    {
        status: user.registration && user.registration.status,
        settings: settings.settings,
        optInBonusPlans: user.optInBonusPlans,
        regSuccess: contentful.registersuccessMaharajaFortune,
        loggedIn: user.user && user.user.loggedIn,
        lng: getContainer().getSettingsService().getActiveLocale().code,
    }
);


let dispatchToProps = (dispatch: Dispatch) => ({
    hide: () => { dispatch(showSignUp(false)) },
    setNewUser: (newUser: boolean) => dispatch(setNewUser(newUser) as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
});

export default connect(mapStateToProps, dispatchToProps)(RegisterSuccess as any);