import React from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {loadCasinoGames, selectCategory} from "../CasinoActions";
import {fetchContent} from "../../contenful/ContentfulActions";
import {ContentModelName, PopupManager, SlidersMaharajaPayload} from "../../contenful/types";
import Root from '../../layout/Root';
import Page from '../../layout/Page';
import {AppState} from "../../RootReducer";
import {fetchSettings} from "../../settings/SettingsActions";
import {CasinoLobbyCategory, Settings} from "../../http/protocol";
import {Route, RouteComponentProps} from "react-router-dom";
import {CasinoStatus} from "../CasinoReducer";
import {LoadingCasinoLobby} from "../LoadingCasinoLobby";
import {UserInfo} from "../../http/protocol";
import {LoginStatus} from "../../http/Types";
import {t} from "../../i18n";
import MetaHelmet from "../../utils/MetaHelmet";
import "./styles/CasinoPage.scss";
import CasinoCategoryPage from "./CasinoCategoryPage";
import CarouselComponent from "./CarouselComponent";
import CasinoCategories from "./CasinoCategories";
import Banner from "../../ui/Banner"
import { logout, showLogin } from "../../user/UserActions";
import { getContainer } from '../../ioc/IOCSetup';
interface Props extends RouteComponentProps  {
    loadCasinoGames? : () => void,
    settings : Settings,
    fetchSettings : typeof fetchSettings,
    selectedCategory?: number;
    categories? : CasinoLobbyCategory[],
    selectCategory? : (cat : number) => void;
    status?: CasinoStatus;
    loadingCarousel?: boolean;
    popupManager?: PopupManager;
    fetchContent : typeof fetchContent;
    lng: string;
    loggedIn : boolean;
    slidersMaharaja?:SlidersMaharajaPayload;
    isloggedIn:boolean;
    user?:UserInfo;
    optInBonusPlans?:any;
    showLogin: () => void;
};
interface State {
    hideAfterWentToOtherPage: boolean;
    promotionbanner?:any;
}

let staticSlideContent = {
    "banner": [
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/weekend-bonus",
            "copyline": "/assets/images/HomePromo/welcomebonus/weekend-bonus-copyline.png",
            "mainBanner": "/assets//images/HomePromo/welcomebonus/1920x1080_Weekend-Bonus.jpg",
            "bonusPlanId": "11",
            "termsAndCondition": "/promotions/weekend-bonus"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/second-welcome",
            "copyline": "/assets/images/HomePromo/welcomebonus/second-welcome-copyline.png",
            "mainBanner": "/assets//images/HomePromo/welcomebonus/1920x1080_Second-Welcome.jpg",
            "bonusPlanId": "12",
            "termsAndCondition": "/promotions/second-welcome"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/every-monday-deposit",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/moday-promo-copyline.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/1920x1080_Monday-TopUp.jpg",
            "bonusPlanId": "8",
            "termsAndCondition": "/promotions/every-monday-deposit"
        },
      
        {
            "isPromo": true,
            "claimNow": "/promotions/welcomebonus",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/Copyline.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/Hero-banner-Live-Casino-Promo.jpg",
            "bonusPlanId": "3",
            "isWelcomeBonus": true,
            "termsAndCondition": "/promotions/casino-welcomebonus"
        },
  
        {
            "gameId": "8721",
            "labeled": "New",
            "copyline": "https://content.adminemx.com/maharajafortune/banners/Aviator_Copyline.png",
            "gameName": "AVIATOR",
            "isMobile": false,
            "isDetails": true,
            "labeledJP": "新しい",
            "segmentId": 1,
            "gameNameJP": "アトランティスライジング",
            "productCode": "DASHUR_V2",
            "staticBanner": "https://content.adminemx.com/maharajafortune/banners/1920x1080_Aviator.jpg",
            "isDemoEnabled": true,
            "supportMobile": true,
            "freespinEnabled": true,
            "isFreespinEnable": true
        },
        {
            "gameId": "5216",
            "labeled": "New",
            "copyline": "https://content.adminemx.com/maharajafortune/banners/book_of_dead_copyline.png",
            "gameName": "Book Of Dead",
            "isMobile": false,
            "isDetails": true,
            "labeledJP": "新しい",
            "segmentId": 1,
            "gameNameJP": "アトランティスライジング",
            "productCode": "DASHUR_V2",
            "staticBanner": "https://content.adminemx.com/maharajafortune/banners/1920x1080_Book-of-Dead.jpg",
            "isDemoEnabled": true,
            "supportMobile": true,
            "freespinEnabled": true,
            "isFreespinEnable": true
        }
    ]
}

export class CasinoPage extends React.Component<Props, State>{
    constructor(props : Props){
        super(props);
        this.state = {hideAfterWentToOtherPage: false};
    }
    componentDidMount(): void {
        let promotions = getContainer().getSettingsService().getPromotions();
        let casino = promotions && promotions.filter(function( obj:any ) {
            return obj.placement.includes("CASINO") && obj.isEnabled;
          })
          this.setState({promotionbanner: casino});
          
        this.props.loggedIn && localStorage.setItem("lastProductUsed", "casino");

        if(this.props.status != CasinoStatus.DONE) {
            this.props.loadCasinoGames && this.props.loadCasinoGames();
        }
        if(this.props.settings == undefined) {
            // this.props.fetchSettings && this.props.fetchSettings();
        }
        if(this.props.slidersMaharaja === undefined) {
            this.props.fetchContent(ContentModelName.SLIDERSMAHARAJA, this.props.lng);
        }
        if(this.props.popupManager == undefined) {
            // this.props.fetchContent(ContentModelName.POPUPMANAGER, this.props.lng);
        } else {
            this.setState({ hideAfterWentToOtherPage: true })
        }
    }

    render() {
        const title:string = t("meta.casinoPage.title") || "";
        const subtitle:string = t("meta.casinoPage.title") || "";
        const fullTitle:string = `${title}${subtitle.length > 0 ? " - "+subtitle : ""}`;
        const description:string = t("meta.casinoPage.description") ||"";
        const keywords:string = t("meta.casinoPage.keywords") || "";
        return (
            <Root footer={true} header={true}>
                {MetaHelmet(title, description, keywords)}

                    <Page>
             
                        <div className={"casino-container"} >
                        <section id="home" className="iq-main-slider p-0">
                    <div id="home-slider" className="slider m-0 p-0">
                        {this.state.promotionbanner  ? <Banner data={this.state.promotionbanner} optInBonusPlans={this.props.optInBonusPlans} user={this.props.user}  loggedIn={this.props.isloggedIn} showLogin={()=>this.props.showLogin()} lng={this.props.lng} /> : null}
                        </div>
                        </section>
                            {this.props.status == CasinoStatus.DONE && (
                                <React.Fragment>
                                    <CasinoCategories />
                                    <Route exact path={"/casino/:catId?/:name?"} component={CasinoCategoryPage}/>
                                </React.Fragment>
                            )}
                            {this.props.status == CasinoStatus.LOADING && (
                                <LoadingCasinoLobby />
                            )}
                            {this.props.status == CasinoStatus.ERROR && (
                                <div className={"container"}>
                                   <div className={"row"}>
                                       <div className={"col-md-12"}>
                                           <div className={"alert alert-warning"}>
                                           {t("casinoPage.alertWarning")}
                                           </div>
                                       </div>
                                   </div>
                                </div>
                            )}

                        </div>
                    </Page>
               
       
            </Root>
        )
    }
}

const stateToProps = (state : AppState) => {

    return {
  
        status : state.casino.status,
        settings: state.settings.settings ? state.settings.settings : {},
        selectedCategory : state.casino.selectedCategory,
        categories : state.casino.lobby ? state.casino.lobby.categories : [],
        isloggedIn: state.user.user && state.user.user.loggedIn,
        loggedIn : LoginStatus.LOGGED_IN == state.user.status,
        slidersMaharaja:state.contentful.slidersMaharaja,
        user:state.user.user,
        optInBonusPlans:state.user.optInBonusPlans, 
     
    }
};

const dispatchToProps = (dispatch : Dispatch) => (
    {
        loadCasinoGames : ()=> dispatch(loadCasinoGames() as any),
        fetchSettings : () => dispatch(fetchSettings() as any),
        selectCategory : (cat : number) => dispatch(selectCategory(cat)),
        fetchContent : (contentModelName : ContentModelName) => dispatch(fetchContent(contentModelName) as any),
        showLogin: () => dispatch(showLogin(true)),
    }
);

export default connect(stateToProps, dispatchToProps)(CasinoPage as any)

