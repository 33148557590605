import { DocumentTypeResult } from "../http/protocol";
import { FileUploadActions, FileUploadActionType } from "./FileUploadActions";

export interface FileUploadState {
  documentTypes: DocumentTypeResult[],
  uploadSuccess: boolean,
  isFetching: boolean,
  result?:any,
  error?:any,
}

const initialState : FileUploadState = {documentTypes: [], uploadSuccess: false, isFetching: false};

export function fileUploadReducer (state = initialState, action: FileUploadActions): FileUploadState {
  
  switch (action.type) {
      case FileUploadActionType.GET_DOCUMENT_TYPES_SUCCESS:
        return {...state, documentTypes: action.documentTypes, isFetching: false}
      case FileUploadActionType.UPLOAD_FILE_REQUEST:
        return {...state, uploadSuccess: false, isFetching: true}
      case FileUploadActionType.UPLOAD_FILE_SUCCESS:
        console.error(action.result);
        return {...state, result:action.result, uploadSuccess: true, isFetching: false}
      case FileUploadActionType.UPLOAD_FILE_ERROR:
        return {...state,error:action.error, uploadSuccess: false, isFetching: false}
      default:
        return state;
  }
}