import MobileDetect from "mobile-detect";

export class Device {
    private static md: MobileDetect = new MobileDetect(window.navigator.userAgent);

    public static isMobile(): boolean {
        if(Device.md.mobile() != null) {
            return true;
        }
        return false;
    }

    public static isTablet(): boolean {
        if(Device.md.tablet() != null) {
            return true;
        }
        return false;
    }

    public static isIOS(): boolean {
        if(Device.md.os() === "iOS") {
            return true;
        }
        return false;
    }

    public static isAndroid(): boolean {
        if(Device.md.os() === "AndroidOS") {
            return true;
        }
        return false;
    }

    public static isLandscape(): boolean {
        return window.innerWidth >= window.innerHeight;
    }

    public static isChromeForIOS(): boolean {
        if(Device.isIOS() && Device.md.userAgent() === "Chrome") {
            return true;
        }
        return false;
    }

}