
export enum AmplitudeEvent {
    LOADED_SITE = "Loaded Site",
    PLAY_FOR_FUN = "Play For Fun",
    START_CREATE_ACCOUNT = "Start Create Account",
    COMPLETED_CREATE_ACCOUNT = "Completed Create Account",
    COMPLETED_CREATE_ACCOUNT_REQUIRE_VERIFICATION = "Completed Create Account Require Verification",
    CREATE_ACCOUNT_FAILED = "Create Account Failed",
    VERIFIED_EMAIL = "Verified Email",
    LOGIN_REQUEST = "Login Request",
    LOGGED_IN = "Logged In",
    LOGGED_OUT = "Logged Out",
    LOGIN_FAILED = "Login Failed",
    APP_INSTALL_CUSTOM = "App Install Initiated Using Custom Prompt",
    APP_INSTALL_DONE = "App Finished Installing",
    PUSH_RECEIVED = "Push Message Received",
    PUSH_CLICKED = "Push Message Opened",
}