import {PaymentMethod, WithdrawalMethod} from "./protocol";

export enum LoginStatus {
    LOGGED_OUT, IN_PROGRESS, LOGGED_IN, LOGIN_ERROR, VERIFICATION_REQUIRED, MFA_VERIFICATION_REQUIRED, MFA_VERIFICATION_ERROR
}
const keyName = `field`;
export interface SettingsUrl {
    baseUrl : string;
    casinoUrl: string;
    liveCasinoUrl: string;
    brandId:number;
    hostname:string;
    song88Url:string;
    googleAnalytic?:string;
    defaultCurrency?:string;
    kycEnabled?: boolean;
    kycTrigger?:KycTrigger;
    kycProvider?:string;
    lang?:any;
    googleTagManager?:string;
    themeCSS?:string;
    brandLogo?:string;
    themeTemplate?:any;
    headerMenu?:any;
    mainLobby?:string;
    maintenance?:boolean;
}
export interface KycTrigger {
    profile:boolean;
    withdraw:boolean;
}
export interface LandingPages {
    backgroundImage:string;
    copyline:string;
    logo:string;
    stepsEnable:boolean;
}

export interface ManifestUrl {
    apple_icon_url : string;
}
export enum Status {
    SUCCESS = "SUCCESS",
    SCREEN_NAME_EXIST = "SCREEN_NAME_EXIST",
    EMAIL_EXIST = "EMAIL_EXIST",
    INVALID_DATA = "INVALID_DATA",
}
export enum ResetPasswordStatus {
    NOT_USED, IN_PROGRESS, ERROR, SENT
}
export enum VerifyEmailStatus {
    NOT_USED, IN_PROGRESS, ERROR, SENT, COMPLETE
}
export type PaymentMehtodsByCurrency = {[key:string]: PaymentMethod[]};

export type WithdrawalMethodsByCurrency = {[key:string]: WithdrawalMethod[]};