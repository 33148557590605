import React from "react";
import Modal from "react-bootstrap/Modal"
import {connect} from "react-redux";
import {AppState} from "../RootReducer";
import {Dispatch} from "redux";
import {login, showLogin, forgotPassword, showForgotPassword, showSignUp} from "../user/UserActions";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import {LoginStatus} from "../http/Types";
import {LoginError} from "../user/UserReducer";
import {Link} from "react-router-dom";
import './LoginModal.scss'
import {getContainer} from '../ioc/IOCSetup';

import { t } from "../i18n";

interface Props {
    show?: boolean,
    hide ? : ()=>void;
    login? : (email : string, password : string) => void;
    status?: LoginStatus;
    loginError?: LoginError;
    forgotPasswordShow?: ()=>void;
    forgotPasswordHide?: ()=>void;
    showForgotPassword?: boolean;
    forgotPassword?: ()=>void;
    showSignUp?: ()=>void;
    lng:string;
    brandLogo?:string;

};


export const LoginModal = (props : Props) => (
    
    <Modal size="xl"
           aria-labelledby="contained-modal-title-vcenter"
           centered
           show={props.show}
           onHide={()=> props.hide && props.hide()}
           className="login-modal"
        >
        <Modal.Header closeButton>
  
        </Modal.Header>

        <Modal.Body className="login-modal-container">
        <h3>{t("loginModal.logIn")}</h3>
        {props.brandLogo ? ( <img
                          className="img-fluid login-logo"
                        src={props.brandLogo}
                        alt="Logo"
                      />):(  <img
                        className="img-fluid login-logo"
                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/logo-home-big.png"}
                        alt="Maharaja Fortune"
                        title="Maharaja Fortune"
                    />)}
      
          {props.showForgotPassword ?<ForgotPasswordForm />: <LoginForm  />}
          {props.showForgotPassword ? <div onClick={()=>props.forgotPasswordHide && props.forgotPasswordHide()} className={"btn"}>{t("loginModal.back")}</div>:<div onClick={()=>props.forgotPasswordShow && props.forgotPasswordShow()} className={"btn"}>
          {t("loginModal.forgotPass")}</div>}
          <div className={"divider"}></div>
          {t("loginModal.create")} 
            <a onClick={() => {props.hide && props.hide(); props.showSignUp && props.showSignUp();} } className="btn" >{t("loginModal.signUp")}</a>
        </Modal.Body>
    </Modal>
);
const mapStateToProps = (state : AppState) : Props => (
    {
        showForgotPassword: state.user.showForgotPassword,
        show : state.user.displayLogin,
        status: state.user.status,
        loginError: state.user.loginError,
        lng:getContainer().getSettingsService().getActiveLocale().code,
        brandLogo:getContainer().getSettingsService().getBrandLogo()
    }
)
const mapDispatchToProps = (dispatch : Dispatch) => (
    {
          // eslint-disable-next-line
        hide : () => {dispatch(showLogin(false)), dispatch(showForgotPassword(false)) },
        login : (email: string, password : string) => dispatch(login({email : email , password : password}) as any),
        forgotPassword : (email: string) => dispatch(forgotPassword({email : email }) as any),
        forgotPasswordShow:() => dispatch(showForgotPassword(true)),
        forgotPasswordHide:() => dispatch(showForgotPassword(false)),
        showSignUp: () => dispatch(showSignUp(true)),
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal)