import {SettingsActions, SettingsActionTypes} from "./SettingsActions";
import {Settings} from "../http/protocol";
import {DepositActions, DepositActionType} from "../payments/DepositActions";
import {UserActionTypes} from "../user/UserActions";

// export enum WindowSize {
//     WIDTH_SM = 540,
//     WIDTH_MD = 720,
//     WIDTH_LG = 960,
//     WIDTH_XL = 1140
// }

export enum WindowSize {
    WIDTH_XS = 575,
    WIDTH_SM = 576,
    WIDTH_MD = 768,
    WIDTH_LG = 992,
    WIDTH_XL = 1200
}

export interface SettingsState {
    settings?: Settings;
    isFetching: boolean;
    error?: string;
    windowSize: number;
    updateBalance?: boolean;
}

const initialState : SettingsState = {settings: undefined, isFetching: false, error: undefined, windowSize: WindowSize.WIDTH_XL, updateBalance: true};


export function settingsReducer (state = initialState, action: SettingsActions) {
    switch (action.type) {
        case SettingsActionTypes.FETCH_SETTINGS_REQUEST:
            return {...state, isFetching: true};
        case SettingsActionTypes.FETCH_SETTINGS_SUCCESS:
            return {...state, isFetching: false, settings: action.payload};
        case SettingsActionTypes.FETCH_SETTINGS_ERROR:
            return {...state, isFetching: false, error: action.payload};
        case SettingsActionTypes.SET_VIEW_SIZE_BR:
            return {...state, windowSize: action.windowSize};
        case SettingsActionTypes.TOGGLE_UPDATE_BALANCE:
            return {...state, updateBalance:action.payload};
        default:
            return state;
    }
}