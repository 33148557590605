import React from "react";
import {Helmet} from "react-helmet";
import { getContainer } from "../ioc/IOCSetup";


export const MetaHelmet = (title: string, description:string, keywords:string, lang:string = "en") => {
    let activeLang = getContainer().getSettingsService().getActiveLocale().code;
    document.documentElement.lang = activeLang.slice(0, 2);
    return(
        <Helmet>
            <meta charSet="utf-8" />
            <title lang={activeLang.slice(0, 2)}>{title}</title>
     
            <meta name="description" content={description} lang={activeLang.slice(0, 2)} />
            <meta name="keywords" content={keywords} lang={activeLang.slice(0, 2)}/>
        </Helmet>
    )

}

export default MetaHelmet;