import React from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {loadLiveCasinoGames, selectCategory, selectCategoryName} from "../LiveCasinoActions";
import LiveCasinoGames from "./LiveCasinoGames";
import {AppState} from "../../RootReducer";
import {fetchSettings} from "../../settings/SettingsActions";
import {LiveCasinoLobby, LiveCasinoLobbyCategory, Settings} from "../../http/protocol";
import  "./styles/LiveCasinoCategoryPage.scss";
import {RouteComponentProps} from "react-router-dom";


interface Props extends RouteComponentProps<{ name?: string, catId : string}>  {
    loadLiveCasinoGames? : () => void,
    settings : Settings,
    fetchSettings : typeof fetchSettings,
    selectedCategory?: number;
    categories? : LiveCasinoLobbyCategory[],
    selectCategory? : (cat : number) => void;
    selectCategoryName? : (catName : string) => void;
    lobby?: LiveCasinoLobby;
    selectedCategoryName?:string;

};

export class LiveCasinoCategoryPage extends React.Component<Props>{
    constructor(props : Props){
        super(props);
    }
    componentDidMount(): void {
        if(this.props.lobby == null) {
            this.props.loadLiveCasinoGames && this.props.loadLiveCasinoGames();
        }

        if(this.props.settings == undefined) {
            this.props.fetchSettings();
        }

        this.checkParam();
    }

    checkParam = () => {
        let catId = this.props.match.params.catId;
        let catName = this.props.match.params.name;
        // console.error(catName);
        if(catId) {
            if(Number(catId)) {
                this.props.selectCategory && this.props.selectCategory(Number(catId));
            }
        }
        if(catName) {
            this.props.selectCategoryName && this.props.selectCategoryName(catName);
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.lobby !== prevProps.lobby) {
            if(this.props.lobby) {
                this.checkParam();
            }
        }
    }





    handleGoBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <div className={"category-page-container"}>
                <LiveCasinoGames />
            </div>

        )
    }
}

const stateToProps = (state : AppState) => {
    return {
        settings: state.settings.settings ? state.settings.settings : {},
        selectedCategory : state.livecasino.selectedCategory,
        categories : state.livecasino.lobby ? state.livecasino.lobby.categories : [],
        lobby: state.livecasino.lobby,
        selectedCategoryName:state.livecasino.selectedCategoryName
    }
};

const dispatchToProps = (dispatch : Dispatch) => (
    {
        loadLiveCasinoGames : ()=> dispatch(loadLiveCasinoGames() as any),
        fetchSettings : () => dispatch(fetchSettings() as any),
        selectCategory : (cat : number) => dispatch(selectCategory(cat)),

        selectCategoryName: (catName: string) => dispatch(selectCategoryName(catName)),
    }
);

export default connect(stateToProps, dispatchToProps)(LiveCasinoCategoryPage)

