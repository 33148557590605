import {BonusConfig, BonusOffer, UserBonusListResponse, UserBonusView} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class UserBonusService {
    constructor(private http : HttpService) {}

    getActiveBonusOffers() : Promise<BonusOffer[]>{
        return this.http.get("/bonus/offers");
    }
    getNextBonuses(currency : string)  : Promise<BonusConfig[]> {
        return this.http.get("/bonus/next/"+currency);
    }
    getActiveBonuses() : Promise<UserBonusView[]> {
        return this.http.get("/bonus/active");
    }

    getCasinoGamesByBonus(bonusId: number) : Promise<{[k:number]:number[]}>{
        return this.http.get("/bonus/casino-games/"+bonusId);
    }
    claimBonusOffer(offerId : number) : Promise<UserBonusView> {
        return this.http.get("/bonus/claim/"+offerId);
    }
    cancelBonus(userBonusId? : number) : Promise<{}> {
        return this.http.get("/bonus/cancel/"+userBonusId);
    }
    cancelBonusOffer(offerId? : number) : Promise<{}> {
        return this.http.get("/bonus/cancel/offer/"+offerId);
    }
    cancelAllBonusOffers() : Promise<{}> {
        return this.http.get("/bonus/cancel/offer/all");
    }
    listBonuses() : Promise<UserBonusListResponse> {
        return this.http.get("/bonus/list");
    }
}



