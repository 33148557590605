import {HttpService} from "../http/HttpService";
import { UpdatePasswordRequest, UpdatePasswordResult } from "../http/protocol";

export class PasswordService  {
  constructor(private http : HttpService) {}

  updatePassword(request : UpdatePasswordRequest) : Promise<UpdatePasswordResult> {
    return this.http.get("/ips/updatePassword?sessionKey=" + localStorage.getItem('sessionKey') + 
      "&oldPassword=" + request.oldPassword + "&newPassword=" + request.newPassword);
  }
}