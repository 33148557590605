import React from 'react'
import {Field, InjectedFormProps, reduxForm} from 'redux-form'
import {login, tokenLogin} from "../user/UserActions";
import {LoginResponseStatus, ManualLoginRequest} from "../http/protocol";
import {Dispatch} from "redux";
import GenericSubmitButton from "../layout/GenericSubmitButton";
import Loader from "../layout/Loader";
import {ShowHidePassword} from '../user/ui/ShowHidePassword';
import {t} from "../i18n";
import {connect} from "react-redux";
import {AppState} from "../RootReducer";
import {Redirect} from 'react-router';
import getLoggedInRedirect from "../utils/LoggedInRedirect";
import {SettingsState} from "../settings/SettingsReducer";
import {renderCheckbox, renderField , renderField2, renderPostal, RenderUserAttributesField} from "../user/ui/form";
import {LoginStatus} from "../http/Types";
import {MFAState} from "../user/UserReducer";
import {isMobile} from "react-device-detect";
import {getContainer} from '../ioc/IOCSetup';
interface Props extends InjectedFormProps {
    remoteError?: string;
    text?: string;
    loggedIn:boolean;
    settings?:SettingsState;
    homescreen?: boolean;
    emailVerified:boolean;
    status?: LoginStatus;
    errorMessage? : string;
    mfa? : MFAState;
    lng:string;

}
export const LoginForm = (props : Props) => {
    const {loggedIn, emailVerified} = props;
    // if(loggedIn && props.settings && props.settings.settings) {
    //     return <Redirect to={getLoggedInRedirect(props.settings.settings, emailVerified)} />
    // }


    return (
        <form style={{display: "flex", flexDirection: "column"}} className={"form login"} onSubmit={props.handleSubmit}>
            {/* {props.errorMessage && (
                <div className={"alert alert-danger"}>
                    {props.errorMessage}
                </div>
            )} */}
                {props.error && (
                <div className={"alert alert-danger"}>
                    {props.error}
                </div>
            )}
            {(props.status == LoginStatus.MFA_VERIFICATION_REQUIRED || props.status == LoginStatus.MFA_VERIFICATION_ERROR) && (
                <div>
                    {props.status == LoginStatus.MFA_VERIFICATION_ERROR && (
                        <div className={"alert alert-danger"}>
                            {t("forms.verificationCode.error")}
                        </div>
                    )}
                    <div className={"form-group"}>
                        <label>{t("forms.verificationCode.help")}</label>
                        <Field placeholder={t("forms.verificationCode.label")} className={"form-control"} name="mfaVerificationCode" 	component={renderField} type="text" autoComplete={"verificationCode"}/>
                    </div>
                </div>

            )}
            {(props.status != LoginStatus.MFA_VERIFICATION_REQUIRED && props.status != LoginStatus.MFA_VERIFICATION_ERROR) && (
                <div>
                    <div className={"form-group"}>
                    <div className="formHeaderSection">{t("username")}</div>
                        <Field 	  className={"form-control"} name="email" component="input" type="text" autoComplete={"email"}/>
                    </div>
                    <div className="formHeaderSection">{t("password")}</div>
                    <ShowHidePassword>
                        <Field  className={"form-control"} name="password" component="input" type="password" autoComplete={"password"}/>
                    </ShowHidePassword>
                </div>
            )}

            {props.submitting && <GenericSubmitButton type={"login"} hideText={true} ><Loader /></GenericSubmitButton>}
            {!props.submitting && <GenericSubmitButton type={"login"} disabled={props.pristine}/>}
            {/*{homescreen && props.settings && props.settings.settings && props.settings.settings.tokenLoginEnabled && (*/}

            {props.homescreen &&
                <div className="form-group" style={{alignSelf:"center", marginTop:"1rem"}}>
                    <Field props={{ label : t("forms.rememberMe")}}
                        name="generateLoginToken"
                        hideHelp={"true"}
                        component={renderCheckbox}
                        type="checkbox"
                        required={false}
                    />
                </div>
            }
        </form>
    )
}

const mapStateToProps = ({user,settings} : AppState) => {

    return {
        mfa : user.user && user.mfa,
        status : user.user && user.status,
        errorMessage : user.user && user.user.message,
        loggedIn: user.user && user.user.loggedIn,
        settings: settings,
        emailVerified:user.user && user.user.emailVerified,
        lng:getContainer().getSettingsService().getActiveLocale().code,
    }
}

const form = reduxForm<any,ManualLoginRequest>({
    form: 'login',
    initialValues:{generateLoginToken: isMobile},
    onSubmit : (req: ManualLoginRequest, dispatch: Dispatch, props: any) => {
 
        if(props.mfa && (props.status == LoginStatus.MFA_VERIFICATION_REQUIRED || props.status == LoginStatus.MFA_VERIFICATION_ERROR)) {
            req = {...req, mfaProvider : props.mfa.provider, email : props.mfa.username, password : props.mfa.password, mfaVerificationCode : parseInt(""  + req.mfaVerificationCode) };
        }
        if(!props.homescreen) {
            req.generateLoginToken = false;
        }
        return dispatch(login(req) as any)
    }
})(LoginForm as any);

export default connect(mapStateToProps, {})(form)