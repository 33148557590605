import React from "react";
import {connect} from "react-redux";
import {fetchUserInfo, setMatiStatus} from "../user/UserActions";
import {Dispatch} from "redux";
import {AppState} from "../RootReducer";
import {LoginStatus} from "../http/Types";
import {KycStatus, UserInfo} from "../http/protocol";

interface Props {
    loggedIn: boolean;
    matiStatus: KycStatus;
    setMatiStatus: (status: KycStatus) => void;
    fetchUserInfo: typeof  fetchUserInfo;
    user? : UserInfo;
    sessionKey?:UserInfo;
}

export class MatiUpdater extends React.Component<Props>{
    componentDidUpdate(prevProps: Props): void {
        if(this.props.matiStatus !== prevProps.matiStatus) {
            if(this.props.matiStatus&& this.props.matiStatus === KycStatus.PENDING) {
                console.log("MatiUpdater 1 componentDidUpdate")
                this.handleFakeMatiCall();
            }
        }

        if(this.props.user !== prevProps.user) {
            if(this.props.user&& this.props.user.kycStatus) {
                console.log("MatiUpdater 2 componentDidUpdate")
                this.props.setMatiStatus(this.props.user.kycStatus);
            }
        }
    }

    handleFakeMatiCall = () => {
        setTimeout(() => {
            if(this.props.fetchUserInfo && localStorage.getItem("sessionKey")) {
                console.log("MatiUpdater 3 componentDidUpdate",this.props.sessionKey)
                this.props.fetchUserInfo(localStorage.getItem("sessionKey"));
            }
    }, 30000);
    }

    render() {
        return <div> </div>
    }
}

const mapStateToProps = ({user} : AppState) => {
    return {
        loggedIn : LoginStatus.LOGGED_IN == user.status,
        matiStatus: user.matiStatus,
        user : user.user && user.user,
        sessionKey:user.user && user.user.sessionKey,
    }
}


let dispatchToProps = (dispatch : Dispatch) => (
    {
        setMatiStatus : (status: KycStatus)=>dispatch(setMatiStatus(status) as any),
        fetchUserInfo: (sessionKey:UserInfo)=>dispatch(fetchUserInfo(sessionKey) as any),
    });

export default connect(mapStateToProps, dispatchToProps)(MatiUpdater as any);