import React, { Component, lazy } from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ContentModelName, GlobalPayload, TermsAndConditionPayload } from '../../contenful/types';
import { AppState } from '../../RootReducer';
import { fetchContent } from '../../contenful/ContentfulActions';
import { Settings } from '../../http/protocol';
import { LoginStatus } from '../../http/Types';
import { fetchSettings } from '../../settings/SettingsActions';
import { Redirect } from 'react-router';
import { History } from 'history';
import { getContainer } from '../../ioc/IOCSetup';

import Root from '../../layout/Root';
import MetaHelmet from '../../utils/MetaHelmet';
import { Helmet } from 'react-helmet';

import { t } from "../../i18n";

type AllProps = PropsFromState & PropsFromDispatch & OtherProps;

interface State { }

export class AboutUs extends Component<AllProps, State> {
	constructor(props: AllProps) {
		super(props);
	}
	componentDidMount(): void {

	}

	render() {

		const title: string = t("meta.aboutUsView.title") || '';
		const subtitle: string = t("meta.aboutUsView.subtitle") || '';
		const fullTitle: string = t("meta.aboutUsView.fullTitle") || '';
		const description: string = t("meta.aboutUsView.description") || '';
		const keywords: string = t("meta.aboutUsView.keywords") || '';
		const baserHref: string = t("meta.aboutUsView.baserHref") || '';

		return (
			<div>
				<Root footer={true} header={true}>
					{MetaHelmet(fullTitle, description, keywords)}
					<Helmet />
					<div className="main-content">
						<div className="container">
							<div className="row">
								<div className="col-sm-12 ">
									<h1>{t("aboutUsSec.aboutUsTitle")}</h1>
									<hr />
									<div className="promo-text-wrap">
									<p dangerouslySetInnerHTML={
                                		{ __html: t("aboutUsSec.aboutUsText", { interpolation: { escapeValue: false } })}}>
                            		</p>
									</div>
									<div className="col-sm-12 ">
									<img src={"https://content.adminemx.com/maharajafortune/images/about-us-banner.png"} 	className="img-fluid" alt={'Maharaja Fortune'}
							title=" About Us Image"/>
							</div>
								</div>
							</div>
						</div>
					</div>
				</Root>
			</div>
		);
	}
}

interface OtherProps {
	history: History;
}

interface PropsFromState {
	termsAndConditions: TermsAndConditionPayload;

	loggedIn: boolean;
	settings?: Settings;
	screenNameSet?: boolean;
	global?: GlobalPayload;

	lng: string;
}

interface PropsFromDispatch {
	fetchContent: typeof fetchContent;
	fetchSettings: typeof fetchSettings;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		fetchContent: (contentModelName: ContentModelName, locale?: string) =>
			dispatch(fetchContent(contentModelName, locale) as any),
		fetchSettings: () => dispatch(fetchSettings() as any)
	};
};

const mapStateToProps = (app: AppState) => {
	return {
		balances: app.user.balances,
		currencies: app.user.user != undefined ? app.user.user.currencies : undefined,
		loggedIn: LoginStatus.LOGGED_IN == app.user.status,
		settings: app.settings.settings ? app.settings.settings : {},
		activeCurrency: app.user.activeCurrency,
		lng: getContainer().getSettingsService().getActiveLocale().code,
		termsAndConditions: app.contentful.termsAndConditionsMaharajaFortune
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs as any);
