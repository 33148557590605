import * as React from 'react';
import { Field, InjectedFormProps, reduxForm, FormState } from 'redux-form';

import { asyncValidate, isPasswordValid, isValid, matches, isPasswordHasAHash } from './utils';
import { EMAIL_REGEXP, renderCheckbox, renderField, renderField2, renderPostal, RenderUserAttributesField } from "./form";
import { RegistrationRequest, Settings } from '../../http/protocol';

import { Dispatch } from 'redux';
import { registerAccount } from '../UserActions';
import { ButtonSpinner } from '../../ui/ButtonSpinner';
import { AppState } from '../../RootReducer';
import { connect } from 'react-redux';
import { RegisterPayload, CompleteRegistrationPayload } from '../../contenful/types';
import { getContainer } from '../../ioc/IOCSetup';
import './styles/RegisterForm.scss';
import { Redirect } from 'react-router';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { MultiStepForm, Step } from 'react-multi-form';
import { DropdownDate, DropdownComponent, YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import RegisterSuccess   from './RegisterSuccess'
import ReactGA from "react-ga4";

import { t } from "../../i18n";

let errorForm: Number;
let dialCode = "";
const requiredText = (key: string): string | any => {
	return `Required ${key}`;
};
const illegalChars = (key: string): string | any => {
	return t('forms.illegal', { label: t(key) });
};
let nextstep = 1;
let availToSend = false;
let notValidUser = false;
export const validateRegisterForm = (values: RegistrationRequest & { terms: boolean }) => {
	let error: any = {};
	let internal: any = Object.assign({}, values);
	let password = internal.password;
	let attributes = values.attributes;
	var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	var validMnumber = /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	let validatePassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/;
	var email = internal.eml ? internal.eml : "";
	var minAndMax= /^.{6,16}$/;
	const currencies = getContainer().getSettingsService().getCurrencies();
	const defaultCurrency = getContainer().getSettingsService().getDefaultCurreny();
	let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
	if(mainLobby == "https://joiabet.com/") {
		internal.cntry = "BR";
	}
	if(currencies && currencies.length == 1 || defaultCurrency && defaultCurrency.length == 1) {
		internal.crn =defaultCurrency &&  defaultCurrency[0];
	}
	if (internal.usr && minAndMax.test(internal.usr) && internal.crn && mailformat.test(email) && email.slice(-4) !== '.con' && email.slice(-4) !== '.cob' && internal.pwd) {
		nextstep = 2;
	}  else {
		nextstep = 1;
	}

	if (internal.fname && internal.lname && internal.gndr && bday && internal.adrs && internal.cty  && internal.mNumber && validMnumber.test(internal.mNumber)) {
		availToSend = true
	} else {
		availToSend = false
	}
	if (!isValid(values.email, 4, 200)) {
		error.email = requiredText('email');
	}
	// if (!matches(values.email, EMAIL_REGEXP)) {

	// 	error.email = 'Invalid Email';
	// }
	if (!isValid(internal.usr, 6, 50)) {
		error.username = requiredText('username');
	}
	if (!isPasswordValid(internal.pwd)) {

		error.password = 'password is too short';
	}
	if (!isPasswordHasAHash(internal.pwd)) {

		error.password = 'Hashtag # cannot be use';
	}
	if (!internal.legalAge) {

		error.legalAge = 'Legal Age is Required';
	}
	if (!internal.crn) {

		error.crn = 'Please Select Currency';
	}

	return error;
};

interface Props extends InjectedFormProps {
	settings: Settings;
	position?: string;
	userAttributesKey?: string;
	userAttributesHelp?: string;
	regex?: string;
	loggedIn?: boolean;
	registrationResponse: any;
	isNewUser: boolean;
	registerContent?: RegisterPayload;
	activeLng?: string;
	completeRegisterContent?: CompleteRegistrationPayload;
}

interface State {
	registrationReq: any;
}
let bday = new Date();
export const RegisterForm = (props: Props) => {
	const [submittingState, setSubmittingState] = useState(false);
	const [newpassword, setNewPassword] = useState('');
	const [username, setUserName] = useState('');
	const [confirmpassword, setConfirmPassword] = useState('');



	const onVerifyUserName = () => {
		var minAndMax= /^.{6,16}$/;
			if(!minAndMax.test(username) && username.length > 0){
				notValidUser = true;
			} else {
				notValidUser = false;
			}
	}


	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		submitFailed,
		asyncValidating,
		error,
		valid,
		position,
		userAttributesKey,
		userAttributesHelp,
		settings
	} = props;
	let submitInProgress = submitting;
	const containerClass = position ? position : '';
	const [value, onChangeDate] = useState(new Date());
	const [form, enableNextForm] = useState(1);
	const [year, onChangeYear] = useState(0);
	const [month, onChangeMonth] = useState(-1);
	const [day, onChangeDay] = useState(0);
	let monthplusone = +month + 1 as number;
	bday = new Date(monthplusone + "/" + day + "/" + year);
	let validatePassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/;
	const [step, onStep] = useState(1);
	let country = getContainer().getSettingsService().getCountryData();
	const [mobileNumber, getMobileNumber] = useState('');
	const [emailtest, getEmail] = useState('');
	var validMnumber = /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	const [dial_code, set_code] = useState("");

	let mailIsNotValid = !mailformat.test(emailtest);

	const buttonClassList = position === 'landing' ? 'btn-thin' : '';
	const requiredUserAttributesField = () => {
		if (settings && settings.requiredUserAttributes && settings.requiredUserAttributes.length > 0) {
			return settings.requiredUserAttributes.map((item) => {
				const fieldName = item.nameTranslationKey ? item.nameTranslationKey : null;
				const withDropdown = item.options != '' || undefined ? true : false;
				let options;
				if (withDropdown) {
					options = item.options && item.options.split(',');
				}
				return (
					<Field
						key={item.id}
						props={{
							label: fieldName,
							position: position,
							autoCorrect: 'off',
							userAttributesKey: item.nameTranslationKey,
							userAttributesHelp: item.descriptionTranslationKey,
							regex: item.regex
						}}
						value=""
						name={item.attributeKey}
						pristine={true}
						maxLength={item.maxLength && item.maxLength}
						minLength={item.minLength && item.minLength}
						withDropdown={withDropdown}
						optionsDropdown={options && options}
						component={RenderUserAttributesField}
						required={item.required}
					/>
				);
			});
		}
	};



	if (errorForm === 1 && step === 2) {
		errorForm = 0;
		nextstep = 1;
		onStep(1);

	}
	if(step === 1) {
		ReactGA.event({
			category: "User",
			action: "Step_1_Form",
		  });
	} else if(step === 2) {
		ReactGA.event({
			category: "User",
			action: "Step_2_Form",
		  });
	}

	const isRequiredUserAttributes: boolean =
		(settings && settings.requiredUserAttributes && settings.requiredUserAttributes.length > 0) || false;
	const customHeader: string = (props.registerContent && props.registerContent.register) || '';
	let userArributesHeader: string = '';

	if (customHeader) {
		userArributesHeader = customHeader;
	}
	if (submitInProgress && !submittingState) {
		setSubmittingState(true);
	}
	if (submitFailed && submittingState && !submitInProgress) {
		setSubmittingState(false);
	}

	const currencies = getContainer().getSettingsService().getCurrencies();
	const defaultCurrency = getContainer().getSettingsService().getDefaultCurreny();
	let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();

    dialCode = dial_code;
	if (props.loggedIn) {

			return (<RegisterSuccess/>);


		// return <Redirect to={'/'} />;
	} else {

		return (
			<form
				id="registerForm"
				className="form register-form d-flex flex-row flex-wrap"
				onSubmit={props.handleSubmit}
				autoComplete="off"
			>
				<div className="col-12 align-self-center">
					{step === 1 ? (<h3><strong>{t("signUp")}</strong></h3>) : (<h3><strong>{t("registerForm.completeSignup")}</strong></h3>)}
				</div>
				<hr />
				<div className="col-md-12">
					{/* {props.registrationResponse && props.registrationResponse.status === "VALIDATION_FAIL" ? <div className="alert alert-danger">Registration Failed please check all fields.</div> : false} */}

				</div>
				<div className="col-lg-6">
					<MultiStepForm className="steps" activeStep={step}>
						<Step className="labeled" label="Sign Up">

							<div className="col-12">

								<div >
									<p><strong>{t("registerForm.text1")}</strong></p>
									{error ? <div className="alert alert-danger">{error}</div> : false}
								</div>
								<div>
									<div className="formHeaderSection">{props.registerContent && props.registerContent.username}</div>
									{props.registrationResponse &&
										props.registrationResponse.errors.find((x: any) => {
											if (errorForm === 0 && x.field === 'username') {
												errorForm = 1;
											}
											return x.field === 'username';
										}) ? (
										<div className="alert alert-danger">{t("registerForm.userInvalid")}</div>
									) : (
										false
									)}
										{!/^.{6,16}$/.test(username) && username.length > 0 ? (
										<div className="alert alert-danger">{t("registerForm.text2")}</div>
									) : (
										false
									)}
									<Field
										props={{ label: 'Username', position: position, nameOverride: 'username' }}
										name="usr"
										onChange={(e: any) => setUserName(e.target.value)}
										value=""
										onBlur={onVerifyUserName}
										hideHelp={true}
										pristine={true}
										maxLength={16}
										component={renderField}
										type="text"
									/>

								</div>

								



								<div>
									<div className="formHeaderSection">{props.registerContent && props.registerContent.email}</div>

									{props.registrationResponse &&
										props.registrationResponse.errors.find((x: any) => {
											if (errorForm === 0 && x.field === 'email') {
												errorForm = 1;
											}
											return x.field === 'email';
										}) ? (
										<div className="alert alert-danger">{t("registerForm.emailInvalid")}</div>
									) : (
										false
									)}

									{mailIsNotValid && emailtest ? (<div className="alert alert-danger">{"Please enter a valid email address"}</div>) : null}

									{emailtest.slice(-4) === '.con' ? (<div className="alert alert-danger">{".con is not a valid email"}</div>) : null}
									{emailtest.slice(-4) === '.cob' ? (<div className="alert alert-danger">{".cob is not a valid email"}</div>) : null}
									<Field
										props={{ label: 'E-mail', position: position, nameOverride: 'email' }}
										name="eml"
										value=""
										hideHelp={true}
										pristine={true}
										component={renderField}
										onChange={(e: any) => getEmail(e.target.value)}
										type="text"
									/>
								</div>
								<div >
									<div className="formHeaderSection">{props.registerContent && props.registerContent.password}</div>
									{ props.registrationResponse &&
										props.registrationResponse.errors.find((x: any) => {
											if (errorForm === 0 && x.field === 'password') {
												errorForm = 1;
											}
											return x.field === 'password';
										}) ? (
										<div className="alert alert-danger">
											<span className='password-alert'>
											{t("registerForm.text3")}</span></div>
									) : (
										false
									)}


									<Field
										props={{
											label: 'Password',
											autoComplete: 'new-password',
											autoCorrect: 'off',
											position: position,
											nameOverride: 'password'
										}}
										onChange={(e: any) => setNewPassword(e.target.value)}

										name="pwd"
										value=""
										hideHelp={true}
										pristine={true}
										maxLength={16}
										component={renderField}
										type="password"

									/>

								</div>
								<div>
								<p dangerouslySetInnerHTML={
                                	{ __html: t("registerForm.text5", { interpolation: { escapeValue: false } })}}>
                            	</p>
								</div>
								<div>
									<button
										className={`btn btn-cta btn-lg height   ${nextstep === 2 ? "" : "disabled"}`}

										onMouseDown={() => {
											if (props.registrationResponse &&
												props.registrationResponse.errors) {
												errorForm = 2;
											}
											onStep(nextstep);
										}}
									>
										{t("registerForm.agreeAndContinue")}
									</button>


								</div>

							</div>
						</Step>
						<Step className="labeled" label="Complete Sign Up!">

							<div className="col-lg-12">
								<div className='txt-label'>
								<p dangerouslySetInnerHTML={
                                	{ __html: t("registerForm.text6", { interpolation: { escapeValue: false } })}}>
                            	</p>
							<p dangerouslySetInnerHTML={
                                                { __html: t("registerForm.text7", { interpolation: { escapeValue: false } }) }
                                            } />
								</div>

								<div>
									<div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.firstName}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'First Name' }) ? <div className="alert alert-danger">{t("registerForm.userInvalid")}</div> : false}
									<Field props={{ label: "First Name", position: position, nameOverride: "First Name" }}
										name="fname"
										value=""
										hideHelp={true}
										pristine={true}
										component={renderField} type="text" />
								</div>


								<div>
									<div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.lastName}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("registerForm.userInvalid")}</div> : false}
									<Field props={{ label: "Last Name", position: position, nameOverride: "Last Name" }}
										name="lname"
										value=""
										hideHelp={true}
										pristine={true}
										component={renderField} type="text" />
								</div>


								<div >
									<div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.gender}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("registerForm.userInvalid")}</div> : false}
									<Field props={{ label: "Gender", position: position, nameOverride: "Gender" }}
										name="gndr"
										value=""
										hideHelp={true}
										pristine={true}
										withDropdown={true}
										optionsDropdown={["Male", "Female"]}
										component={RenderUserAttributesField} type="text" />
								</div>

								<div >
									<div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.birthDate}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'birthDate' }) ? <div className="alert alert-danger">{t("registerForm.minAge")}</div> : false}

									<div className='row'>

										<div className="col-lg-4 col-md-4 col-4">
											<DayPicker
												defaultValue={'Day'}
												year={year}    // mandatory
												month={month}  // mandatory
												endYearGiven              // mandatory if end={} is given in YearPicker
												required={true}           // default is false

												value={day}    // mandatory
												onChange={(day: any) => {      // mandatory
													onChangeDay(day)
													console.log(day);
												}}
												id={'day'}
												name={'day'}
												classes={'form-control'}
												optionClasses={'option classes'}
											/>
											{/* <div className="description-2">Seclect Day </div> */}
										</div>

										<div className="col-lg-4 col-md-4  col-4">
											<MonthPicker
												defaultValue={'Month'}
												numeric

												endYearGiven              // mandatory if end={} is given in YearPicker
												year={year}    // mandatory
												required={true}           // default is false

												value={month}  // mandatory
												onChange={(month: any) => {    // mandatory
													onChangeMonth(month)
													console.log(month);
												}}
												id={'month'}
												name={'month'}
												classes={'form-control'}
												optionClasses={'option classes'}
											/>
											{/* <div className="description-2">Seclect Month </div> */}
										</div>
										<div className="col-lg-4 col-md-4 col-4">
											<YearPicker
												defaultValue={'Year'}

												reverse                     // default is ASCENDING
												required={true}             // default is false

												value={year}     // mandatory
												onChange={(year: any) => {       // mandatory
													onChangeYear(year)
													console.log(year);
												}}
												id={'year'}
												name={'year'}
												classes={'form-control'}
												optionClasses={'option classes'}
											/>
											{/* <div className="description-2">Seclect Year </div> */}
										</div>
									</div>
								</div>
								{mainLobby == "https://joiabet.com/" ? (<div>
									<div className="formHeaderSection">{t("registerForm.CPFnum")}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("registerForm.userInvalid")}</div> : false}
									<Field props={{ label: "CPF Number", position: position, nameOverride: "CPF Number" }}
										name="cpf"
										value=""
										hideHelp={true}

										pattern="(^(\d{3}.\d{3}.\d{3}-\d{2})|(\d{11})$)"
										pristine={true}
										component={renderPostal} type="number" />
								</div>):null }


								<div>
									<div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.address}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("registerForm.userInvalid")}</div> : false}
									<Field props={{ label: "Address", position: position, nameOverride: "Address" }}
										name="adrs"
										value=""
										hideHelp={true}
										pristine={true}
										component={renderField} type="text" />
								</div>

								<div >
									<div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.city}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("registerForm.userInvalid")}</div> : false}
									<Field props={{ label: "City", position: position, nameOverride: "City" }}
										name="cty"
										value=""
										hideHelp={true}
										pristine={true}
										component={renderField} type="text" />
								</div>

							


								<div>
									<div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.mobileNumber}</div>
									{props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Mobile Number' }) ? <div className="alert alert-danger">{t("registerForm.mobileInvalid")}</div> : false}

									{mobileNumber && mobileNumber.length > 0 && !validMnumber.test(mobileNumber) ? (<div className="alert alert-danger">{t("registerForm.text8")}</div>) : null}
									{/* {error ? <div className="alert alert-danger">{"Mobile Number is invalid"}</div> : false} */}

									<div className="input-group">

										<Field props={{ label: "Mobile Number", dial_code: "+91", position: position, nameOverride: "Mobile Number" }}
											name="mNumber"
											onChange={(e: any) => {getMobileNumber(e.target.value) }}

											value={""}
											hideHelp={true}
											pristine={true}
											pattern="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im"
											max={16}
											component={renderField2} type="number" required />
									</div>

								</div>


								<div className=" mb-2">
									{!submittingState ? (
										<button
											className={`btn btn-cta btn-register btn-lg height  ${buttonClassList} ${availToSend ? "" : "disabled"}`}
											type="submit"
											onMouseDown={(event) =>{ event.preventDefault(); errorForm = 0;}}
										>
											{t("registerForm.complete")}
										</button>
									) : (
										false
									)}
								</div>
								<div className=" mb-2">
									{(customHeader || customHeader != undefined) && isRequiredUserAttributes ? (
										<div className="formSpace" />
									) : null}
									<div className={`buttonContainer ${containerClass}`}>
										{submittingState ? (
											<button className={`btn btn-cta btn-register btn-lg height ${buttonClassList}`}>
												<ButtonSpinner />
											</button>
										) : (
											false
										)}

									</div>

								</div>
								<div className="mb-2">
									<button
										className={`btn btn-cta btn-back btn-lg height  ${step === 2 ? "" : "disabled"}`}

										onMouseDown={() => onStep(1)}
									>
										{t("buttonBack")}
									</button>
								</div>


							</div>
						</Step>

					</MultiStepForm>
				</div>
				<div className="col-lg-6 mt-5 d-lg-block  d-md-none d-sm-none d-none">
					<div className='row'>
						<div className="d-lg-block col-md-12  ">

							<img
								className="img-fluid promo-banner-signup"
								src={'https://content.adminemx.com/maharajafortune/images/promotionpage/1800x660_100-Welcome-Bonus-SB.jpg'}
								alt="promotions"
							/>


						</div>
						<div className="d-lg-block col-md-12  ">

							<img
								className="img-fluid promo-banner-signup"
								src={'https://content.adminemx.com/maharajafortune/images/promotionpage/1800x660_100-Welcome-Bonus-25k.jpg'}
								alt="promotions"
							/>
						</div>
						<div className="d-lg-block col-md-12 ">

							<img
								className="img-fluid"
								src={'https://content.adminemx.com/maharajafortune/images/promotionpage/register-info.png'}
								alt="promotions"
							/>
						</div>

					</div>


				</div>
			</form>
		);
	}
};

const mapStateToProps = ({ settings, user, contentful }: AppState) => {
	return {
		settings: settings && settings.settings,
		isNewUser: user.user && user.user.newUser,
		loggedIn: user.user && user.user.loggedIn,
		registrationResponse: user && user.registration,
		registerContent: contentful && contentful.register,
		completeRegisterContent: contentful && contentful.completeRegistration,
		activeLng: getContainer().getSettingsService().getActiveLocale().code
	};
};

function formatDate(date: Date) {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
}
const form = reduxForm({
	form: 'registerForm',
	validate: validateRegisterForm,
	touchOnBlur: true,
	enableReinitialize: true,

	asyncBlurFields: [],
	onSubmit: (req: RegistrationRequest, dispatch: Dispatch, props: any) => {
		let internal: any = Object.assign({}, req);
		let username = internal.usr;
		let email = internal.eml;
		let password = internal.pwd;
		let currency = internal.crn;
		let firstName = internal.fname;
		let lastName = internal.lname;
		let gender = internal.gndr === "Male" ? "M" : "F";
		let formattedDate = formatDate(bday);
		let birthDate = formattedDate;
		let address = internal.adrs;
		let city = internal.cty;
		let country = internal.cntry;
		let postal = internal.pstl;
        let mobileNumber =  dialCode +  internal.mNumber;
		let securityQuestion = internal.sq;
		let securityAnswer = internal.ans;
		let receiveEmail = internal.rE ? internal.rE : false;
		let attr = {};
		const currencies = getContainer().getSettingsService().getCurrencies();

		country = "IN"
		mobileNumber =  "+55" +  internal.mNumber;
		if(currencies && currencies.length == 1) {
			currency = currencies && currencies[0];
		}
		props.settings && props.settings.requiredUserAttributes && props.settings.requiredUserAttributes.map((item: any) => {
			let attrKey = item.attributeKey
			Object.defineProperty(attr, item.attributeKey, {
				value: internal[attrKey],
				writable: true,
				enumerable: true,
				configurable: true
			});
			delete internal[item.attributeKey];
		})
		internal.attributes = attr;


		internal.username = username;
		internal.email = email;
		internal.password = password;
		internal.currency = currency;
		internal.firstName = firstName;
		internal.lastName = lastName;
		internal.gender = gender;
		internal.birthDate = birthDate;
		internal.address = address;
		internal.city = city;
		internal.country = country;
		internal.postal = postal;

		internal.mobileNumber = mobileNumber;
		internal.securityQuestion = securityQuestion;
		internal.securityAnswer = securityAnswer;
		internal.receiveEmail = receiveEmail;
		return dispatch(registerAccount(internal, props.settings) as any);
	}
})(RegisterForm as any);

export default connect(mapStateToProps)(form);
