import { HttpService } from "../http/HttpService";
import {
    AuthToken,
    BalanceAndBonus,
    ManualLoginRequest,
    MultiFactorAuthProvider,
    MultiFactorAuthResponse,
    RegistrationRequest,
    RegistrationResponse,
    RequestEmailVerification,
    ResetPasswordRequest,
    TokenLoginRequest,
    ForgotPasswordRequest,
    UserInfo
} from "../http/protocol";


export class UserService {

    httpBackend: HttpService;

    constructor(httpBackend: HttpService) {
        this.httpBackend = httpBackend;
    }

    verifyChannelCode(userId: number, code: string): Promise<{}> {
        return this.httpBackend.get(`/user/verify/${userId}/${code}`);
    }

    requestVerificationEmail(email: string): Promise<{}> {
        return this.httpBackend.post<{}, RequestEmailVerification>("/user/request-email-verification", { email: email });
    }

    verifyAccessToken(token: string): Promise<UserInfo> {
        return this.httpBackend.post('/user/authenticate/facebook', {
            facebookToken: token
        });
    }
    logout(): Promise<{}> {
        return this.httpBackend.get(`/ips/logout?sessionKey=${localStorage.getItem("sessionKey")}`);
    }
    updateUser(attributes: { [key: string]: string }): Promise<UserInfo> {
        return this.httpBackend.post<UserInfo, { [key: string]: string }>("/user/update-attributes", attributes);
    }
    fetchUserInfo(sessionKey: string): Promise<UserInfo> {
        return this.httpBackend.get(`/ips/getPlayerInfo?sessionKey=${sessionKey}`);
    }
    getEligibleOptInBonusPlans(sessionKey: string): Promise<UserInfo> {
        return this.httpBackend.get(`/ips/getEligibleOptInBonusPlans?sessionKey=${sessionKey}&partyId=${localStorage.getItem("partyId")}`);
    }
    getEligibleOptInBonusPlansByDate(sessionKey: string,date:string): Promise<UserInfo> {
        return this.httpBackend.get(`/ips/getEligibleOptInBonusPlans?sessionKey=${sessionKey}&partyId=${localStorage.getItem("partyId")}&startDate=${date}`);
    }
    getActiveBonuses(sessionKey: string): Promise<UserInfo> {
        return this.httpBackend.get(`/ips/getBonuses?sessionKey=${sessionKey}&partyId=${localStorage.getItem("partyId")}`);
    }
    getActiveBonusesByDate(sessionKey: string,startDate:string,endDate:string): Promise<UserInfo> {
        return this.httpBackend.get(`/ips/getBonuses?sessionKey=${sessionKey}&partyId=${localStorage.getItem("partyId")}&startDate=${startDate}&endDate=${endDate}`);
    }
    fetchBalances(): Promise<BalanceAndBonus> {
        return this.httpBackend.get<BalanceAndBonus>(`/ips/getBalance?sessionKey=${localStorage.getItem("sessionKey")}`);
    }
    register(req: RegistrationRequest): Promise<RegistrationResponse> {
        let language = req.language === "ja-JP" ? "ja" : "en";
        if (localStorage.getItem("token")) {
            return this.httpBackend.get(`/ips/quickSignup?username=${req.username}&password=${req.password}&currency=${req.currency}&email=${req.email}&receiveEmail=${req.receiveEmail}&langCode=${language}&userTrackingCodes=%7B%22btag%22%3A%22${localStorage.getItem("token")}%22%7D`);
        } else {
            return this.httpBackend.get(`/ips/quickSignup?username=${req.username}&password=${req.password}&currency=${req.currency}&email=${req.email}&receiveEmail=${req.receiveEmail}&langCode=${language}`);
        }

    }

    registerFull(req: RegistrationRequest): Promise<RegistrationResponse> {
      let language = req.language == "pt-BR" ? "pt" : "en";
        if(req.cpfnumber) {
            if (localStorage.getItem("token")) {
            return this.httpBackend.get(`/ips/signup?username=${req.username}&isSecurityQuestionRequired=${false}&password=${req.password}&currency=${req.currency}&email=${req.email}&receiveEmail=${true}&firstName=${req.firstName}&lastName=${req.lastName}&birthDate=${req.birthDate}&country=${req.country}&mobile=${req.mobileNumber}&userTrackingCodes=%7B%22btag%22%3A%22${localStorage.getItem("token")}%22%7D&docType=ID&docNumber=${req.cpfnumber}&langCode=${language}`);
        } else {
            return this.httpBackend.get(`/ips/signup?username=${req.username}&isSecurityQuestionRequired=${false}&password=${req.password}&currency=${req.currency}&email=${req.email}&receiveEmail=${true}&firstName=${req.firstName}&lastName=${req.lastName}&birthDate=${req.birthDate}&country=${req.country}&mobile=${req.mobileNumber}&docType=ID&docNumber=${req.cpfnumber}&langCode=${language}`);

        }} else {
            if (localStorage.getItem("token")) {
                return this.httpBackend.get(`/ips/signup?username=${req.username}&isSecurityQuestionRequired=${false}&password=${req.password}&currency=${req.currency}&email=${req.email}&receiveEmail=${true}&firstName=${req.firstName}&lastName=${req.lastName}&gender=${req.gender}&birthDate=${req.birthDate}&address=${req.address}&country=${req.country}&postalCode=${req.postal}&mobile=${req.mobileNumber}&city=${req.city}&userTrackingCodes=%7B%22btag%22%3A%22${localStorage.getItem("token")}%22%7D`);
            } else {
                return this.httpBackend.get(`/ips/signup?username=${req.username}&isSecurityQuestionRequired=${false}&password=${req.password}&currency=${req.currency}&email=${req.email}&receiveEmail=${true}&firstName=${req.firstName}&lastName=${req.lastName}&gender=${req.gender}&birthDate=${req.birthDate}&address=${req.address}&country=${req.country}&postalCode=${req.postal}&mobile=${req.mobileNumber}&city=${req.city}`);
            }
        }
        
    }


    quickSignupCompletion(req: RegistrationRequest): Promise<RegistrationResponse> {

        return this.httpBackend.get(`/ips/quickSignupCompletion?&quickRegisterCode=${localStorage.getItem("sessionKey")}&securityQuestion=${req.securityQuestion}&securityAnswer=${req.securityAnswer}&firstName=${req.firstName}&lastName=${req.lastName}&gender=${req.gender}&birthDate=${req.birthDate}&address=${req.address}&country=${req.country}&postalCode=${req.postal}&mobile=${req.mobileNumber}&city=${req.city}&sessionKey=${localStorage.getItem("sessionKey")}`);
    }


    login(req: ManualLoginRequest): Promise<UserInfo> {

        return this.httpBackend.get(`/ips/login?username=${req.email}&password=${req.password}`);

    }
    loginWithToken(req: TokenLoginRequest): Promise<UserInfo> {
        return this.httpBackend.post("/user/authenticate/token", req);
    }
    facebookLogin(facebookToken: string): Promise<UserInfo> {
        return this.httpBackend.post("/user/authenticate/facebook", { facebookToken: facebookToken })
    }

    requestResetPassword(email: string): Promise<{}> {
        return this.httpBackend.get("/user/recover-password/" + encodeURIComponent(email));
    }

    forgotPassword(email: string): Promise<{}> {
        return this.httpBackend.get(`/ips/forgotPasswordStep1And2?email=${email}`);
    }

    resetPassword(req: ResetPasswordRequest): Promise<{}> {
        return this.httpBackend.get(`/ips/forgotPasswordStep3?email=${req.email}&confirmationCode=${req.confirmationCode}&newPassword=${req.newPassword}&resetPasswordKey=${req.confirmationCode}`);
    }

    updateUserSettings(key: string, value: string): Promise<{}> {
        console.log("IN updateUserSettings 45", key)
        console.log("IN updateUserSettings 45", value)
        return this.httpBackend.put(`/user/settings/${key}/${value}`);
    }

    getUserSettings(key: string): Promise<string> {
        return this.httpBackend.get(`/user/settings/${key}`);
    }

    getAllUserSettings(): Promise<{ [key: string]: string; }> {
        return this.httpBackend.get(`/user/settings`);
    }

    setKycStarted(): Promise<{}> {
        return this.httpBackend.get(`/user/kyc-started`);
    }

    getUserLoginTokens(): Promise<AuthToken[]> {
        return this.httpBackend.get('/token/all');
    }

    getTokenByRefId(id: number): Promise<AuthToken> {
        return this.httpBackend.get("/token/token/" + id)
    }

    clearUserLoginToken(id: number): Promise<{}> {
        return this.httpBackend.get(`/token/token/delete/${id}`);
    }

    clearAllUserLoginTokens(): Promise<{}> {
        return this.httpBackend.get('/token/all/clear');
    }
    enableMfa(provider: MultiFactorAuthProvider): Promise<MultiFactorAuthResponse> {
        return this.httpBackend.post("/user/mfa/enable", { provider: provider })
    }
    verifyMfa(provider: MultiFactorAuthProvider, code: string): Promise<{}> {
        return this.httpBackend.post("/user/mfa/verify", { provider: provider, verificationCode: code });
    }
}
