import {Dispatch} from "redux";
import {getContainer} from "../ioc/IOCSetup";
import {SettingsService} from "./SettingsService";
import {Settings} from "../http/protocol";


export enum SettingsActionTypes {
    FETCH_SETTINGS_REQUEST = "@settings/fetch/request",
    FETCH_SETTINGS_SUCCESS = "@settings/fetch/response",
    FETCH_SETTINGS_ERROR = "@settings/fetch/error",
    SET_VIEW_SIZE_BR = "@settings/windowsize/set",
    TOGGLE_UPDATE_BALANCE = "@settings/updatebalance/toggle"
}

export type SettingsActions =
    | { type : SettingsActionTypes.FETCH_SETTINGS_REQUEST }
    | { type : SettingsActionTypes.FETCH_SETTINGS_SUCCESS, contentModelName: string, payload: Settings }
    | { type : SettingsActionTypes.FETCH_SETTINGS_ERROR, payload: string }
    | { type : SettingsActionTypes.SET_VIEW_SIZE_BR, windowSize: number }
    | { type : SettingsActionTypes.TOGGLE_UPDATE_BALANCE, payload: boolean};

export const fetchSettings = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type: SettingsActionTypes.FETCH_SETTINGS_REQUEST});
            let settingsService: SettingsService = getContainer().getSettingsService();
            let data;
            if(settingsService.getSettingsData() == undefined) {
                data = await settingsService.getSettings();
            } else {
                data = settingsService.getSettingsData()
            }
            dispatch({type: SettingsActionTypes.FETCH_SETTINGS_SUCCESS, payload: data});
        } catch(e) {
            dispatch({type: SettingsActionTypes.FETCH_SETTINGS_ERROR, error: e});
        }
    }
};

export const setWindowSizeBr = (windowSize : number) => {
    return { type : SettingsActionTypes.SET_VIEW_SIZE_BR, windowSize : windowSize};
};

export const toggleUpdateBalance = (toggle : boolean) => {
    return { type : SettingsActionTypes.TOGGLE_UPDATE_BALANCE, payload : toggle}
};



