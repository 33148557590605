import React, { lazy } from "react";
import "./RegisterView.scss";
import { Settings, Status } from "../../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../../RootReducer";
import { Link, RouteComponentProps } from "react-router-dom";
import { Redirect } from "react-router";
import getLoggedInRedirect from "../../utils/LoggedInRedirect";
import './RegisterSuccess.scss';
import { ContentModelName, GlobalPayload, RegsiterSuccessPayload } from "../../contenful/types";
import { t } from "../../i18n";
import { Dispatch } from "redux";
import { getEligibleOptInBonusPlans, setNewUser, showCashierModal } from "../UserActions";
import { fetchContent } from "../../contenful/ContentfulActions";
import Root from "../../layout/Root";
import Header from "../../layout/Header";
import Page from "../../layout/Page";
import { getContainer } from "../../ioc/IOCSetup";
import ReactGA from "react-ga4";
import { showSignUp } from "../UserActions";
import {Helmet} from "react-helmet";
interface Props extends RouteComponentProps {
    status?: Status;
    regSuccess?: RegsiterSuccessPayload;
    global?: GlobalPayload;
    loggedIn?: boolean;
    setNewUser?: typeof setNewUser;

    fetchContent: typeof fetchContent;
    lng: string;
    optInBonusPlans?: any;
    hide: () => void;
    getEligibleOptInBonusPlans:() => void;
    showCashierModal:()=> void;
}

interface State {
    startPlaying: boolean;
    deposit: boolean;
    goBack: boolean;
    bonusPlanId?: any;
    promotions?:any
}

export class JoiabetRegisterSuccess extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = { startPlaying: false, deposit: false, goBack: false }
    }

    componentDidMount() {
        let promotions = getContainer().getSettingsService().getPromotions();
        let enablePromo = promotions &&  promotions.filter(function (obj: any) {
			return obj.isPromo && obj.isEnabled;
		})




    this.setState({ promotions: enablePromo });

        if(localStorage.getItem("sessionKey")) {

            this.props.getEligibleOptInBonusPlans();
      
                     }
        if (sessionStorage.getItem("bonusPlanId") && !this.state.bonusPlanId) {
            this.setState({ bonusPlanId: sessionStorage.getItem("bonusPlanId") });
        }
        if (this.props.regSuccess === undefined) {
            this.props.fetchContent(ContentModelName.REGISTERSUCCESS, getContainer().getSettingsService().getActiveLocale().code);

        }
    }
    handleBonusPlanId = (id: any) => {
        sessionStorage.setItem("bonusPlanId", id);
        this.setState({ bonusPlanId: id })
    };
    handleUnclaim = () => {
        sessionStorage.removeItem("bonusPlanId");
        this.setState({ bonusPlanId: null })
    };
    onStartPlaying() {
        this.props.setNewUser && this.props.setNewUser(false);
        this.setState({ startPlaying: true });

    }
    componentDidUpdate() {
        if (sessionStorage.getItem("bonusPlanId") && !this.state.bonusPlanId) {
            this.setState({ bonusPlanId: sessionStorage.getItem("bonusPlanId") });
        }
    }
    onDeposit() {
        this.props.setNewUser && this.props.setNewUser(false);
        this.props.showCashierModal();
        this.props.hide && this.props.hide();
    }

	promoThumbnailGenerator = () => {
		let lang = getContainer().getSettingsService().getLocalesData();
		let activeLang = getContainer().getSettingsService().getActiveLocale().code;	
			return (<div className='col-12 d-flex justify-content-center  mb-3 flex-wrap'>
		
				{this.state.promotions && this.state.promotions.filter((arrayEl: any) => {
					return this.props.optInBonusPlans && this.props.optInBonusPlans.some((filterEl: any) => filterEl.bonusPlanId.toString() === arrayEl.bonusPlanId && arrayEl.firstDeposit);

				}).map((e: any) => {
                    if(e.bonusPlanId=== "18") {
                        return null
                    } else {
					return (
						<div className="promotion col-lg-4 col-md-4 col-sm-6 col-12" key={e.bonusPlanId}>
							<div className="promotion-box">
								<div className='promo-product'>{t(`${e.product}`)}</div>
								{e.bonusPlanId && sessionStorage.getItem("bonusPlanId") == e.bonusPlanId ? (
									<img
										className="img-fluid claimed-badge"
										src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Claimed-badge.png'}
										alt={'Welcome Bonus'}
										title=""
									/>
								) : null}

								<img
									className="img-fluid"
									src={e.thumbnailImage}
									alt={'Welcome Bonus'}
									title=""
								/>
								<div className="row promodesc">
									<div className="col-6 col-lg-12">
									{lang && lang.length >= 2 ? (<h5> {e.promotionTCL[activeLang]}</h5>):(		<h5> {e.promotionTCL}</h5>)}
		
										
														<p>{t("depositMethod.min")} {e.minimumDeposit}</p>
									</div>
									<div className="col-6 col-lg-12">
									{this.state.bonusPlanId == e.bonusPlanId ?
															(<a
																className="btn btn-primary  btn-rounded promo-btn"
																onClick={() => { this.handleUnclaim() }}
															>
																{t("unClaim")}
															</a>) : (<a
																className="btn btn-primary  btn-rounded promo-btn"
																onClick={() => { this.handleBonusPlanId(e.bonusPlanId) }}
															>
																{t("claimNow")}
															</a>)}
									</div>
								</div>
							</div>
						</div>
					)
                }
				})}
			</div>)
		
	}
    render() {

        const imgUrl = this.props.regSuccess && this.props.regSuccess.successImg.fields.file.url;
        const depositText = this.props.global && this.props.global.depositButton;
        const startText = this.props.global && this.props.global.startPlaying;

        return (
            <div>

                <div className="registration-success-container">
                    <div className="success-image">
                
                        <div className='row'>
                            <div className="d-lg-block col-md-12 ">
                                <h3 className="title">
                                    {t("registerSuccess.title")}
                                </h3>
                                <h4 className="info">
                                    {t("registerSuccess.title2")}
                                </h4>
                                {this.promoThumbnailGenerator()}
                                <div className="how-to-claim-bonus-container">
                                        <p dangerouslySetInnerHTML={
                                			{ __html: t("registerSuccess.claimBonus", { interpolation: { escapeValue: false } })}}>
                            			</p>
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <div className="row">
                                <button onClick={() => this.onDeposit()} className="btn btn-cta btn-lg">{t("registerSuccess.button1")}</button>
                            </div>
                            <div className="row">
                                <span onClick={() => this.onDeposit()} className="deposit">{t("registerSuccess.button2")}</span>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user, settings, contentful }: AppState) => (
    {
        status: user.registration && user.registration.status,
        settings: settings.settings,
        optInBonusPlans: user.optInBonusPlans,
        regSuccess: contentful.registersuccessMaharajaFortune,
        loggedIn: user.user && user.user.loggedIn,
        lng: getContainer().getSettingsService().getActiveLocale().code,
    }
);


let dispatchToProps = (dispatch: Dispatch) => ({
    hide: () => { dispatch(showSignUp(false)) },
    showCashierModal:()=> dispatch(showCashierModal(true,"deposit")),
    setNewUser: (newUser: boolean) => dispatch(setNewUser(newUser) as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
    getEligibleOptInBonusPlans: ()=>dispatch(getEligibleOptInBonusPlans(localStorage.getItem("sessionKey")) as any),
});

export default connect(mapStateToProps, dispatchToProps)(JoiabetRegisterSuccess as any);