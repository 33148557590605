import {CasinoAction, CasinoActionTypes} from "./CasinoActions";
import {CasinoLobby} from "../http/protocol";
import {UserAction, UserActionTypes} from "../user/UserActions";

export enum ListMode {
    THUMBNAIL, DESCRIPTION
}

export enum CasinoStatus {
    LOADING, DONE, ERROR
}
export interface CasinoState {
   lobby?: CasinoLobby;
   selectedCategory?: number;
   selectedCategoryName?: string;
   status?: CasinoStatus;
   mode?: ListMode;
   searchValue?: string;
   recentlyPlayedGames? : string[];
   favouriteGames? : string[];
   selectedStudio? : string;
}

const initialState : CasinoState = { status: CasinoStatus.LOADING, mode: ListMode.THUMBNAIL, searchValue : "", recentlyPlayedGames : [], favouriteGames : [], selectedCategoryName:""};
function sortLobby(lobby : CasinoLobby) {

    if(lobby.categories) {
        lobby.categories.sort((a,b)=>{
            let oa = typeof(a.LobbyOrder) != "undefined" ? a.LobbyOrder : 0;
            let ob = typeof(b.LobbyOrder) != "undefined" ? b.LobbyOrder : 0;
            
            if(oa >ob) return 1;
            if(ob > oa) return -1;
            return 0;
        });
    }
    // if(lobby.games) {
    //   lobby.games.reduce(function (r, a) {
    //       if(a.SubProductCode){
    //     r[a.SubProductCode] = r[a.SubProductCode] || [];
    //     r[a.SubProductCode].push(a);
    //           }
    //     return r;
    // }, Object.create(null));
    // }
    if(lobby.studios) {
        lobby.studios.sort((a,b)=>{
            let oa = typeof(a.Name) != "undefined" ? a.Name: "";
            let ob = typeof(b.Name) != "undefined" ? b.Name: "";
            return oa.localeCompare(ob);
        });
    }
}
export function casinoReducer(state: CasinoState = initialState, action : CasinoAction|UserAction ) {

    switch (action.type) {
        case UserActionTypes.LOGIN_RESPONSE:
            return {...state, selectedStudio: undefined};
        case CasinoActionTypes.SELECT_STUDIO:
            let studioName = action.studioName;
            if(state.selectedStudio == studioName) {
                studioName = "0";
            }
            return {...state, selectedStudio : studioName  };
        case CasinoActionTypes.SET_FAVOURITE_GAMES:
            return {...state, favouriteGames : action.GameIds};
        case CasinoActionTypes.SET_PLAYED_GAMES:
            return {...state, recentlyPlayedGames : action.GameIds };
        case CasinoActionTypes.GAMES_RESPONSE:
            sortLobby(action.lobby);
            return {...state, lobby : action.lobby, status: CasinoStatus.DONE, selectedCategory : action.lobby.categories ? action.lobby.categories[0].Id : 0, selectedCategoryName:action.lobby.categories ? action.lobby.categories[0].Name : "All Games"};
        case CasinoActionTypes.GAMES_REQUEST:
            return {...state, status : CasinoStatus.LOADING};
        case CasinoActionTypes.GAMES_ERROR:
            return {...state, status : CasinoStatus.ERROR };
        case CasinoActionTypes.SELECT_CATEGORY:
            return {...state, selectedCategory : action.category, searchValue : "" };
            case CasinoActionTypes.SELECT_CATEGORY_NAME:
        return {...state, selectedCategoryName:action.categoryName };
        case CasinoActionTypes.SELECT_LIST_MODE:
            return {...state, mode : action.mode};
        case CasinoActionTypes.UPDATE_SEARCH_VALUE:
            return {...state, searchValue : action.value};
        default:
            return state;
    }
}
