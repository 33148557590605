import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { selectCategory, selectStudio, selectCategoryName } from '../LiveCasinoActions';
import { t } from '../../i18n';
import { AppState } from '../../RootReducer';
import LiveCasinoSearch from './LiveCasinoSearch';
import { LiveCasinoCurrencies, LiveCasinoLobbyCategory, LiveCasinoStudio } from '../../http/protocol';
import './styles/LiveCasinoCategories.scss';
import { Device } from '../../utils/Device';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { toSEOFriendlyString } from '../../user/ui/utils';
import MobileLiveCasinoSearch from './MobileLiveCasinoSearch';
import Translator, { Translation } from '../../Translator';
import './LiveCasinoSearch.scss';
import livecasinoLobby, { imageUrl } from '../../utils/Helpers';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { getContainer } from '../../ioc/IOCSetup';

const categories = [
	'Popular',
	'New Games',
	'Slots',
	'Megaways',
	'Live LiveCasino',
	'Table Games',
	'Jackpots',
	'Virtual Sports',
	'Favourites'
];

const cats = [];

interface ButtonProps {
	category: LiveCasinoLobbyCategory;
	onClick: any;
	active: boolean;
}
interface State {
	selectedCategory: any;
	showSearchBar?: boolean;
}
const CategoryButton = (props: ButtonProps) => {
	if (!props.category || !props.category.Name) {
		return <div />;
	}
	let translations: Translation[] = [];

	if (props.category.translations) {
		Object.keys(props.category.translations).forEach((k) => {
			if (props.category.translations) {
				translations.push({ lng: k, value: props.category.translations[k] });
			}
		});
	}
	let activeLang = getContainer().getSettingsService().getActiveLocale().code;

	return (
		<li className={'nav-item'}>
			<a className={'nav-link ' + (props.active ? 'active' : '')} onClick={props.onClick}>
				<span className={'title'}>
				{activeLang !== "en-US" ? (<Translator defaultTranslation={props.category.Translation ? props.category.Translation:props.category.Name} translations={translations} />):
					(<Translator defaultTranslation={props.category.Name} translations={translations} />) }
					
				</span>
			</a>
		</li>
	);
};

export interface Props extends RouteComponentProps<{ search?: string }> {
	selectedCategory?: number;
	selectedStudio?: string;
	selectCategory?: (cat: number) => void;
	selectCategoryName?: (catName: string) => void;
	selectStudio?: (studioName: string) => void;
	categories?: LiveCasinoLobbyCategory[];
	studios?: LiveCasinoStudio[];
	firstCategory?: boolean;
	searching?: boolean;
	livecasinoCurrencies?: LiveCasinoCurrencies[];
	activeCurrency?: string;
	loggedIn?: boolean;
	selectedCategoryName?: string;
	scrollVal?: any;
}

export function extractCategoryTitle(cat: LiveCasinoLobbyCategory) {
	let title = '';
	if (cat.translationKey && cat.translationKey.length > 0) {
		title = t(cat.translationKey);
	}

	if (!cat.translationKey || cat.translationKey.length == 0) {
		title = cat.Name ? cat.Name : '';
	}
	return title;
}

export class LiveCasinoCategories extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = { selectedCategory: '', showSearchBar: false };
	}

	catIsFeaturedCat = (catName: string) => {
		if (catName.toLowerCase() === 'featured') {
			return true;
		}

		return false;
	};

	handleOnClick = (cat: LiveCasinoLobbyCategory | undefined) => {
		let activeLang = getContainer().getSettingsService().getActiveLocale().code;

		if (cat && cat.Id && cat.Name) {
			this.props.selectCategory && this.props.selectCategory(cat.Id);
			if(activeLang !== "en-US" && cat.Translation ) {
				this.props.selectCategoryName && this.props.selectCategoryName(cat.Translation);
			} else {
				this.props.selectCategoryName && this.props.selectCategoryName(cat.Name);
			}
	
			this.props.history.push(`/livecasino/${cat.Id}/${toSEOFriendlyString(cat.Name)}`);
			this.setState({ selectedCategory: cat && cat.Name });
		}
	};
	gobackToAllGames = (catid: any, catname: any) => {
		if (catid && catname) {
			this.props.selectCategory && this.props.selectCategory(catid);

			this.props.selectCategoryName && this.props.selectCategoryName(catname);
			this.props.history.push(`/livecasino/${catid}/${toSEOFriendlyString(catname)}`);
		}
	};
	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {}

	getStudioClassNames(id?: string, selectedId?: string) {
		let names = 'studio';
		if (selectedId && selectedId !== "0") {
			if (selectedId == id) {
				names = names + ' selected';
			} else {
				names = names + ' disabled';
			}
		}
		return names;
	}
	isCurrencySupportedByStudio(
		s?: LiveCasinoStudio,
		currencies?: LiveCasinoCurrencies[],
		activeCurrency?: string,
		loggedIn?: boolean
	) {
		if (loggedIn === undefined) {
			return true;
		}

		if (!activeCurrency && !loggedIn) {
			return true;
		}
		if (!s || !currencies) {
			return false;
		}
		let livecasinoCurrency = currencies.find((c) => c.integration == s.integration && c.studioName! == s.id!);
		if (livecasinoCurrency && livecasinoCurrency.currencies) {
			let allowed = livecasinoCurrency.currencies.find((currency) => currency == activeCurrency);
			if (allowed) {
				return true;
			}
		}
		return false;
	}
	render() {
		let activeLang = getContainer().getSettingsService().getActiveLocale().code;
		let list;
		if (this.props.categories) {
			list = this.props.categories;
		}
		let studios: LiveCasinoStudio[] = [];
		if (this.props.studios) {
			studios = this.props.studios;
		}

		return (
			<div>
				<div className={'container'}>
					{Device.isMobile() ? (
						<div>
							<div className="mobile-search">
								<MobileLiveCasinoSearch />
							</div>
							{!this.props.searching &&
							studios.length > 0 && (
								<Dropdown>
									<Dropdown.Toggle id="dropdown-livecasinocatergory">
									{this.props.searching == false &&
											this.props.selectedCategory &&
											this.props.selectedCategoryName && activeLang == 'pt-BR' && this.props.selectedCategoryName == 'popular-live games' ? 'jogos ao vivo populares' : this.props.searching == false &&
											this.props.selectedCategory &&
											this.props.selectedCategoryName }
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-content-container">
										{list &&
											list.map((cat: LiveCasinoLobbyCategory) => {
												let active = false;
												if (
													this.props.searching == false &&
													this.props.selectedCategory &&
													cat.Id == this.props.selectedCategory
												) {
													active = true;
												}
												return (
													<Dropdown.Item
														key={cat.Id + 'p'}
														as="button"
														className="livecasinoMenuDropdown"
													>
														<CategoryButton
															key={cat.Id}
															active={active}
															onClick={() => {
																this.handleOnClick(cat);
																if (cat.Id !== 1) {
																	this.props.selectStudio &&
																		this.props.selectStudio(0);
																}
															}}
															category={cat}
														/>
													</Dropdown.Item>
												);
											})}
									</Dropdown.Menu>
								</Dropdown>
							)}
						</div>
					) : (
						<div
							className={
								'categories-container justify-content-center  ' +
								(Device.isMobile() ? `${'mobile'}` : '') +
								(!Device.isMobile() && this.props.scrollVal > 120
									? 'fixed-nav d-flex fast animated slideInDown'
									: '')
							}
						>
							{!this.state.showSearchBar ? (
								<div className={'navbar-nav-container '}>
									<ul
										className={
											'navbar-nav flex-row livecasino-lobby-categories fast animated slideInDown justify-content-center d-flex ' +
											(Device.isMobile() ? `${'row'} ${'mobile'}` : 'row')
										}
									>
										{list &&
											list.map((cat: LiveCasinoLobbyCategory) => {
												let active = false;
												if (
													this.props.searching == false &&
													this.props.selectedCategory &&
													cat.Id == this.props.selectedCategory
												) {
													active = true;
												}
												return (
													<CategoryButton
														key={cat.Id}
														active={active}
														onClick={() => {
															this.handleOnClick(cat);
															if (cat.Id !== 1) {
																this.props.selectStudio && this.props.selectStudio("0");
															}
														}}
														category={cat}
													/>
												);
											})}
									</ul>
								</div>
							) : null}
							{!Device.isMobile() && this.state.showSearchBar ? (
								<LiveCasinoSearch />
							) : null}

                        {this.state.showSearchBar ? (
							<button
                            className="btn fast animated slideInDown "
                            type="button"
                            onClick={() => {
                                if (this.state.showSearchBar) {
                                    this.setState({ showSearchBar: false });
                                } else {
                                    this.setState({ showSearchBar: true });
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faWindowClose} size="1x" color='white' />
                        </button>
							) : (
								<button
									className="btn  fast animated slideInDown"
									type="button"
									onClick={() => {
										if (this.state.showSearchBar) {
											this.setState({ showSearchBar: false });
										} else {
											this.setState({ showSearchBar: true });
										}
									}}
								>
									<FontAwesomeIcon icon={faSearch} size="1x" color='white' />
								</button>
							)}  
						</div>
					)}
				</div>
				{!this.props.searching &&
				studios.length > 0 && (
					<div
						className={
							'studios ' +
							(!Device.isMobile() && this.props.scrollVal > 120
								? 'd-flex fixed-nav-studios animated fast slideInDown'
								: '')
						}
					>
						<div className={'container'}>
							<div className="justify-content-center studios-wrap ">
								{studios.map((s) => (
									<div
										key={s.Id}
										onClick={() => {
											typeof s.Name != 'undefined' &&
												this.props.selectStudio &&
												this.props.selectStudio(s.Name);
											this.gobackToAllGames(1, 'all-games');
										}}
										className={'studio-container'}
									>
										<div
											className={this.getStudioClassNames(s.Name, this.props.selectedStudio)}
											style={{
												backgroundImage: `url(https://content.adminemx.com/game_provider/${s.iconName})`
											}}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export function isStudioAllowed(s?: LiveCasinoStudio, country?: string) {
	if (!country || country.trim().length == 0) {
		//just show everything when not logged in
		return true;
	}
	if (!s) {
		return true;
	}
	if (s.allowedCountries && s.allowedCountries.trim().length > 0) {
		let codes = s.allowedCountries.split(',');
		return codes.some((c) => c.trim().toLowerCase() == country.toLowerCase());
	}
	if (s.blockedCountries && s.blockedCountries.trim().length > 0) {
		let codes = s.blockedCountries.split(',');
		return !codes.some((c) => c.trim().toLowerCase() == country.toLowerCase());
	}
	return true;
}
const mapStateToProps = (app: AppState) => {
	let searching: boolean = false;
	if (app.livecasino.searchValue && app.livecasino.searchValue.length > 0) {
		searching = true;
	}
	let livecasinoCurrencies;
	if (app.livecasino && app.livecasino.lobby) {
		livecasinoCurrencies = app.livecasino.lobby.currencies;
	}
	let activeCurrency = app.user.activeCurrency;
	if (!activeCurrency && app.user.user && app.user.user.currencies && app.user.user.currencies.length > 0) {
		activeCurrency = app.user.user.currencies[0].code;
	}
	let studios: LiveCasinoStudio[] = [];
	// if(app.livecasino.lobby && app.livecasino.lobby.studios) {
	//     studios = app.livecasino.lobby.studios;
	//     if(app.user && app.user.user && app.user.user.country) {
	//         let country = app.user.user.country;
	//         studios = app.livecasino.lobby.studios.filter(s => isStudioAllowed(s, country));
	//     }
	// }
	if (app.livecasino.lobby && app.livecasino.lobby.studios) {
		studios = app.livecasino.lobby.studios;
	}
	return {
		firstCategory:
			app.livecasino &&
			app.livecasino.lobby &&
			app.livecasino.lobby.categories &&
			app.livecasino.selectedCategory == app.livecasino.lobby.categories[0].Id,
		selectedCategory: app.livecasino.selectedCategory,
		selectedCategoryName: app.livecasino.selectedCategoryName,
		categories: app.livecasino.lobby ? app.livecasino.lobby.categories : [],
		studios: studios,
		selectedStudio: app.livecasino.selectedStudio,
		searching: searching,
		livecasinoCurrencies: livecasinoCurrencies,
		activeCurrency: activeCurrency,
		loggedIn: app.user.user && app.user.user.loggedIn,
		scrollVal: app.header !== undefined ? app.header.scrollTop : undefined
	};
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
	selectCategory: (cat: number) => dispatch(selectCategory(cat)),

	selectCategoryName: (catName: string) => dispatch(selectCategoryName(catName)),
	selectStudio: (studioName: string) => dispatch(selectStudio(studioName))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter<Props>(LiveCasinoCategories));
