import React, { Component, lazy } from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ContentModelName,
  GlobalPayload,
  TermsAndConditionPayload,
  PopupManager,
} from "../../contenful/types";
import { AppState } from "../../RootReducer";
import { fetchContent } from "../../contenful/ContentfulActions";
import {
  AccountBalance,
  CurrencyDefinition,
  Settings,
} from "../../http/protocol";
import { LoginStatus } from "../../http/Types";
import { fetchSettings } from "../../settings/SettingsActions";
import { Redirect } from "react-router";
import { History } from "history";
import { getContainer } from "../../ioc/IOCSetup";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Root from "../../layout/Root";
import MetaHelmet from "../../utils/MetaHelmet";
import { Helmet } from "react-helmet";

import { t } from "../../i18n";

type AllProps = PropsFromState & PropsFromDispatch & OtherProps;

interface State {}

export class TermsAndConditionView extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
  }
  componentDidMount(): void {
    // this.props.loggedIn && localStorage.setItem("lastProductUsed", "poker");
    // if(this.props.popupManager == undefined) {
    //     this.props.fetchContent(ContentModelName.POPUPMANAGER, this.props.lng);
    // }else {
    //     this.setState({ hideAfterWentToOtherPage: true })
    // }

   if (this.props.termsAndConditions == undefined) {
      let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
      if (mainLobby == "https://joiabet.com/") {
        this.props.fetchContent(
          ContentModelName.TERMSANDCONDITIONJOIABET,
          this.props.lng
        );
      } else {
        this.props.fetchContent(
          ContentModelName.TERMSANDCONDITION,
          this.props.lng
        );
      }
     
    }

    // if(this.props.global == undefined) {
    //     this.props.fetchContent(ContentModelName.GLOBAL, this.props.lng);
    // }

    // if(this.props.settings == undefined) {
    //     this.props.fetchSettings();
    // }
  }

  render() {

    const title: string = t("meta.termsAndConditionView.title") || "";
    const subtitle: string = t("meta.termsAndConditionView.subtitle") || "";
    const fullTitle: string =  t("meta.termsAndConditionView.fullTitle") || "";
    const description: string = t("meta.termsAndConditionView.description") || "";
    const keywords: string = t("meta.termsAndConditionView.keywords") || "";
    const baserHref: string = t("meta.termsAndConditionView.baserHref") || "";


    return (
      <div>
        <Root footer={true} header={true}>
          {MetaHelmet(title, description, keywords)}
         
          <div className="main-content">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 ">
                <h1>{t("TnCView.TnCViewTitle")}</h1>
									<hr />
                  <div className="promo-text-wrap">
                  {this.props.termsAndConditions
                    ? documentToReactComponents(
                        this.props.termsAndConditions.termsAndConditions as any
                      )
                    : null}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </Root>
      </div>
    );
  }
}

interface OtherProps {
  history: History;
}

interface PropsFromState {
  termsAndConditions: TermsAndConditionPayload;

  loggedIn: boolean;
  settings?: Settings;
  screenNameSet?: boolean;
  global?: GlobalPayload;

  lng: string;
}

interface PropsFromDispatch {
  fetchContent: typeof fetchContent;
  fetchSettings: typeof fetchSettings;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
    fetchSettings: () => dispatch(fetchSettings() as any),
  };
};

const mapStateToProps = (app: AppState) => {
  let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();

  return {
    balances: app.user.balances,
    currencies:
      app.user.user != undefined ? app.user.user.currencies : undefined,
    loggedIn: LoginStatus.LOGGED_IN == app.user.status,
    settings: app.settings.settings ? app.settings.settings : {},
    activeCurrency: app.user.activeCurrency,
    lng: getContainer().getSettingsService().getActiveLocale().code,
    termsAndConditions:mainLobby == "https://joiabet.com/" ?  app.contentful.termsAndConditionsJoiabet: app.contentful.termsAndConditionsMaharajaFortune,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditionView as any);
