import { ProfileActions, ProfileActionType } from "./ProfileActions";

export interface ProfileState {
  error? : Error;
  isFetching: boolean;
  status?: String;
}

const initialState : ProfileState = {isFetching: false, error: undefined, status: ''};

export function profileReducer (state = initialState, action: ProfileActions): ProfileState {
  
  switch (action.type) {
      case ProfileActionType.UPDATE_PROFILE_REQUEST:
          return {...state, isFetching: true}
      case ProfileActionType.UPDATE_PROFILE_SUCCESS:
          return {...state, error: undefined, isFetching: false, status: action.result.status};
      case ProfileActionType.UPDATE_PROFILE_ERROR:
        console.log("UPDATE PROFILE ERROR");
          return {...state, isFetching: false, error: action.error}
      default:
          return state;
  }
}