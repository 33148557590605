import {HttpService} from "../http/HttpService";
import {CountryInfo} from "../http/protocol";


export class GeoIpService   {
    http : HttpService;
    constructor(http : HttpService) {
        this.http = http;
    }

    getCountryInfo() : Promise<any> {
        return this.http.getGeoInfo();
    }

}