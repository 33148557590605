import React from 'react'
import {Field, InjectedFormProps, reduxForm} from 'redux-form'
import {forgotPassword} from "../user/UserActions";
import {Dispatch} from "redux";
import GenericSubmitButton from "../layout/GenericSubmitButton";
import Loader from "../layout/Loader";
import {EMAIL_REGEXP, renderField2} from "../user/ui/form";
import {isValid, matches} from "../user/ui/utils";
import {t} from "../i18n";
import {RegistrationRequest} from "../http/protocol";


interface Props extends InjectedFormProps {
    remoteError?: string;
    text?: string;
}
const requiredText = (key : string) : string | any => {
    return t("forms.required", { label : t(key)});
}
const validateRequestResetPasswordForm= (values : RegistrationRequest)=> {
    let error : any = {};
    if(!isValid(values.email,4,200)) {
        error.email = requiredText("email");
    }
    if(!matches(values.email,EMAIL_REGEXP)) {
        error.email = t("forms.email.invalid");
    }
    return error;
}

export const RequestResetPasswordForm = (props : Props) => (
    <form style={{display: "flex", flexDirection: "column"}} className={"form"} onSubmit={props.handleSubmit}>
            {props.error && (
                <div className={"alert alert-danger"}>
                {props.error}
                </div>
            )}
             { props.submitSucceeded ? (
         <div className={"alert alert-danger"}>
        {t("resetPassewordText")} 
         </div>):null
            } 
           
           <div className={"form-group"}>
            <p>{t("forgotPassword.text1")}</p>
           <div className="formHeaderSection">{t("email")}</div>
                        <Field  className={"form-control"} name="email" component="input" type="text" autoComplete={"email"}/>
                    </div>
   
        {props.submitting && <GenericSubmitButton type="recover_password" hideText={true} ><Loader /></GenericSubmitButton>}
        {!props.submitting && <GenericSubmitButton type="recover_password" />}
        </form>
);


export default reduxForm<any,{email?: string}>({
    form: 'requestResetPassword',
    validate : validateRequestResetPasswordForm,
    touchOnBlur : true,
    onSubmit : (req: {email: string}, dispatch: Dispatch, props: any) => {
        return dispatch(forgotPassword(req) as any)
    }
})(RequestResetPasswordForm)