import React, { Component } from 'react'


import { RouteComponentProps, withRouter } from "react-router";
import { prettyUrl } from "../utils/UrlUtils";
import { CasinoGame, CasinoGameTranslation } from "../http/protocol";
import { Device } from "../utils/Device";
import Translator, { Translation } from "../Translator";
import BackgroundUrlTranslator from "../BackgroundUrlTranslator";
import { t } from "../i18n";
import {
    LikeBtn,
    ShareBtn
} from './Socials'
import { Modal } from 'react-bootstrap'

interface Props extends RouteComponentProps {
    data: any;
    showLogin: () => void;
    loggedIn?: boolean;
    lng?: string;
}
interface State {
    cls: any;
    prevStatus: any;
    show: boolean;
}

function getClassNames(compact?: boolean) {
    if (compact) {
        return "casino-game col-lg-2 col-md-2 col-sm-6 col-6";
    } else {
        return "casino-game col-lg-3 col-md-3 col-sm-6 col-6";
    }
}
function mapTranslations(g?: CasinoGame): Translation[] {
    if (!g || !g.translations) {
        return [];
    }
    return g.translations.map((t: CasinoGameTranslation) => ({ lng: t.language, value: t.imageUrl }));
}

export class ContentSliderComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { cls: '', prevStatus: false, show: false }



    }
    showVideoOnHover() {
        this.setState({ show: true });
    }
    hideVideoOnHover() {
        this.setState({ show: false });
    }
    launchGame(props: Props, playforreal?: boolean) {
        // if (props.updateSearchValue) {
        //     props.updateSearchValue("");
        // }

        if (props.data && props.data.Name && !playforreal) {
            props.history.push(`/casino/playforfun/${props.data.GameLaunchId}/${prettyUrl(props.data.Name)}`)
        } else if (props.data && props.data.Name && playforreal) {
            props.history.push(`/casino/playforreal/${props.data.GameLaunchId}/${prettyUrl(props.data.Name)}`)
        }
    }


    lauchMobileGameView(props: Props) {

        if (props.data && props.data.Name && Device.isMobile()) {
            props.history.push(`/casino/game/${props.data.GameLaunchId}/${prettyUrl(props.data.Name)}`)
        }
    }
    render() {
    
        let screeshots;

        screeshots = this.props.data.ProductCode + '/' + this.props.data.SubProductCode + '/' + this.props.data.GameId + '/' + "screen.png";
        let isScreen = this.props.data.Tags?.includes("isScreen");
        let gameIcon;

        
        gameIcon  = "https://content.adminemx.com/" + this.props.data.ProductCode + '/' + this.props.data.SubProductCode + '/' + this.props.data.GameId  + '/' + "icon.png"; 
        return (

            <div className={'slide-item  casino-game '} >
                <div className={"block-images position-relative"} key={this.props.data.GameId}  >
                    <div className={"game-box"}  >
                        <div className="content" onClick={() => { this.props.loggedIn ? this.launchGame( this.props,true):(this.props.showLogin && this.props.showLogin())}} >

                            <BackgroundUrlTranslator className={"thumbnail thumb-bg"} defaultTranslation={gameIcon} translations={mapTranslations(this.props.data)} >


                                {this.state.show && this.props.data.GameTrailerNameDESKTOP ? (<video
                                    autoPlay
                                    muted
                                    crossOrigin="anonymous"
                                    src={`../../../assets/casinoVideo/${this.props.data.GameTrailerNameDESKTOP}.mp4`}
                                    loop />) : isScreen ? (<div className="ssCasino" style={{ backgroundImage: `url(https://content.adminemx.com/${screeshots})` }}></div>): null}





                                {Device.isMobile() ? null : (<div className={"play-now"} >
                                    <div className={"game-title"}>

                                        {this.props.loggedIn ? (
                                            <button className="btn btn-primary  btn-rounded" onClick={() => this.launchGame(this.props, true)}>{t("playForReal")}</button>
                                        ) : (
                                            <button className="btn btn-primary  btn-rounded" onClick={() => this.props.showLogin && this.props.showLogin()}>{t("playForReal")}</button>
                                        )
                                        }
                                        {this.props.data.Tags?.includes("isDemoEnabled") ? (
                                            <button className="btn btn-primary  btn-rounded play-for-fun" onClick={() => this.launchGame(this.props, false)}>{t("playForReal")}</button>
                                        ) : null}
                                    </div>
                                </div>)}


                            </BackgroundUrlTranslator>
                            {this.props.data.isTopGame  || this.props.data.Tags?.includes("isPopular") ? (<div className="popular">    <i className="ri-star-fill"></i>{this.props.lng === "ja-JP" ? "人気" : "Popular"} </div>) : null}
                            {this.props.data.Tags?.includes("isNewGame") ? (<div className="newgame">{t("new")} </div>) : null}
                        </div>
                        <div className={"game-description"}>

                            <div className={"game-text-container"}>
                                <div className={"content game-name"} >
                                    <div className={"provider"}> {this.props.lng === "ja-JP" ? this.props.data && this.props.data.NameJP : this.props.data && this.props.data.Name}</div>
                                    {/* {props.data && props.data.Name && props.data.translations &&
                  <Translator defaultTranslation={props.data.Name} translations={props.data.translations.map(t=>({lng : t.language, value : t.name})) as any} />} */}
                                </div>
                                <div className={"meta"}>
                                    <div className={"provider"}>{this.props.data && this.props.data.SubProductCode}</div>
                                </div>
                            </div>

                            <div className={"favourite-container"}>
                                {/* <a className={"btn favourite " + (this.props.favourite ? "selected" : "")}><i className={"oi oi-heart"}></i></a> */}
                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter<Props>(ContentSliderComponent as any);