import React, { useEffect, useState, Component } from "react";

import { connect } from "react-redux";
import {Spinner} from "../ui/Spinner";
import { logout, showLogin, showSignUp } from "../user/UserActions";
import {ContentModelName, FooterPayload, GlobalPayload} from "../contenful/types";
import { AppState } from "../RootReducer";
import {fetchContent} from "../contenful/ContentfulActions";
import {getContainer} from "../ioc/IOCSetup";
import { Dispatch } from "redux";
import FooterComponent from "./FooterComponent";
import { sideBarStatus, scrollTop } from "../actions/HeaderActions";
import { hasClass, imageUrl } from '../utils/Helpers'
import FooterComponentJoiabet from "./FooterComponentJoiabet";
interface dispatchProps {
  sidebarStatus: any;
  scrollVal: any;
  isLoggedIn: any;
  showLogin: () => void;
  showSignUp: () => void;
  loggedIn: boolean;
  logout: () => void;
  user: any;
  scrollTop:typeof scrollTop,
  balances: any;
  isOpen: typeof sideBarStatus,
}

interface State {
  isSticky: any;
  sidebarStatus: any;
  path:string;
}
interface PropsFromState {
    isFetching?: boolean;
    footer?: FooterPayload;
    lng: string;
    error?: string;
    fetchContent: typeof fetchContent;
   
}


type AllProps = PropsFromState & dispatchProps ;


export class Footer extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      isSticky: false,
      sidebarStatus: false,
      path:''
    };
  }
  componentDidMount(): void {
    let lang = getContainer().getSettingsService().getLocalesData();

    let path ;
    if(lang && lang.length >= 2) {
         path = window.location.pathname.split("/")[2];
    } else {
       path = window.location.pathname.split("/")[1];
    }
    this.setState({path:path});
    if(!this.props.isFetching) {
   
            if(this.props.footer === undefined) {
                this.props.fetchContent(ContentModelName.FOOTER, getContainer().getSettingsService().getActiveLocale().code);
            }
       
    }

}

toggleSidebar = (event: any) => {

  event.preventDefault();
 
  
  if (!this.props.sidebarStatus && event !== undefined) {
  
      document.body.classList.add('nav-open');
      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('touchstart', this.handleClickOutside);
      this.props.isOpen(true);
  }
  // console.error(this.props.sidebarStatus);

}
handleClickOutside = (event: any) => {
  const target = event.target
  // console.error("outside",this.props.sidebarStatus);
 
  if(this.props.sidebarStatus) {
  if ( !hasClass(target, 'b-sidebar')) {

      this.hideSidebar(event)
   }
  }
}

hideSidebar = (event: any) => {
  event.preventDefault();
       this.props.isOpen(false)
      document.body.classList.remove('nav-open');
      document.removeEventListener('mousedown', this.handleClickOutside);
      document.removeEventListener('touchstart', this.handleClickOutside);

}

backToTop() {
  

  window.scrollTo({top: 0, behavior: 'smooth'});
 
}
  render() {
    let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
    return (
        <React.Fragment>
        {
            this.props.isFetching
                ? <Spinner/>
                :mainLobby === "https://joiabet.com/" && this.props.footer ? (<FooterComponentJoiabet path={this.state.path} openNav={this.toggleSidebar} scrollTop={this.backToTop} balances={this.props.balances} scrollVal={this.props.scrollVal} loggedIn={this.props.loggedIn} user={this.props.user} logInClicked={this.props.showLogin} signUpClicked={this.props.showSignUp} lng={this.props.lng} logOutClicked={this.props.logout} footer={this.props.footer}/>) : this.props.footer ? (<FooterComponent path={this.state.path} openNav={this.toggleSidebar} scrollTop={this.backToTop} balances={this.props.balances} scrollVal={this.props.scrollVal} loggedIn={this.props.loggedIn} user={this.props.user} logInClicked={this.props.showLogin} signUpClicked={this.props.showSignUp} lng={this.props.lng} logOutClicked={this.props.logout} footer={this.props.footer}/>) : null
        }
    </React.Fragment>
       
    );
  }
}
const mapStateToProps = ({contentful, settings, user, header} : AppState) => {
    return {
        isFetching: contentful.isFetching,
        footer: contentful.footerPayload,
        lng: getContainer().getSettingsService().getActiveLocale().code,
        loggedIn : user.user && user.user.loggedIn,
        user: user.user,
        scrollVal:header !== undefined ? header.scrollTop : undefined,
        balances: user.balances,
        sidebarStatus: header !== undefined ? header.sidebarStatus : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showLogin: () => dispatch(showLogin(true)),
  showSignUp: () => dispatch(showSignUp(true)),
  logout: () => dispatch(logout() as any),
  isOpen: (open: boolean) => dispatch(sideBarStatus(open) as any),
  fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer as any);
