import * as React from "react";
import {Fragment} from "react";
import {connect} from "react-redux";
import {AppState} from "./RootReducer";
import {getContainer} from "./ioc/IOCSetup";

export interface Translation { lng? : string; value? : any };
interface Props {
    defaultTranslation? : string;
    selectedLanguage?: string;
    translations? : Translation[]
}

export function getTranslation(selectedLanguage? : string, defaultTranslation? : string, translations? : Translation[]) {
    if (!selectedLanguage) {
        return defaultTranslation;
    }

    if (!translations) {
        return defaultTranslation;
    }
    let match = translations.filter(t => t.lng && t.value).find(t =>  t.value.lng == selectedLanguage);

    if (!match) {
        match = translations
            .map(t => ({lng: t.lng ? t.lng.split("-")[0] : undefined, value: t.value}))
            .filter( t => t.lng && t.value)
            .find(t => t.lng == selectedLanguage);
    }
    if (match) {
        return match.value.value;
    } else {
        return defaultTranslation;
    }
}

export class Translator extends React.Component<Props>{
    getTranslation() {
        let selectedLanguage = this.props.selectedLanguage;
        let translations = this.props.translations;
        let defaultTranslation = this.props.defaultTranslation;
        return getTranslation(selectedLanguage, defaultTranslation, translations);

    }
    render(){
        return (
            <Fragment>
                { this.getTranslation()}
            </Fragment>
        )
    }
}

function mapStateToProps(app: AppState) {
    return {
        selectedLanguage : getContainer().getSettingsService().getActiveLocale().code
    }
}
export default connect(mapStateToProps)(Translator);