import {LiveCasinoLobby, LiveCasinoStudio} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class LiveCasinoService {
    constructor(private http : HttpService) {}

    getLiveCasinoGames() : Promise<LiveCasinoLobby> {
        return this.http.getLiveCasinoLobby();
    }


    getGameLaunchUrl(GameId : string, currency : string, mobile?: boolean, language? : string, isPlayForReal?: boolean, platform?:string, mainLobby?: string) {
        if(!language) {
            language = 'en';
        }
        if(mainLobby === "https://joiabet.com/" && platform === "WEARECASINO" ) {
            if(isPlayForReal) {

                return this.http.getCasinoGame("/game/GameContainer.action?platform="+platform+"_JB"+"&gameId="+GameId+'&playForReal='+isPlayForReal+'&sessionKey='+localStorage.getItem('sessionKey')+'&isMobile='+mobile+'&lobbyUrl='+mainLobby);
        
            } else {
                return this.http.getCasinoGame("/game/GameContainer.action?platform="+platform+"_JB"+"&gameId="+GameId+'&playForReal='+isPlayForReal+'&isMobile='+mobile+'&lobbyUrl='+mainLobby);
    
        
                   }
        }  else {
          if(isPlayForReal) {

            return this.http.getLiveCasinoGame("/game/GameContainer.action?platform="+platform+"&gameId="+GameId+'&playForReal='+isPlayForReal+'&sessionKey='+localStorage.getItem('sessionKey')+'&lang='+language+'&isMobile='+mobile+'&lobbyUrl='+mainLobby);
    
        } else {
            return this.http.getLiveCasinoGame("/game/GameContainer.action?platform="+platform+"&gameId="+GameId+'&playForReal='+isPlayForReal+'&lang='+language+'&isMobile='+mobile+'&lobbyUrl='+mainLobby);

    
               }
            }
    }
}




