import React, { Component, useEffect, useState, useRef } from 'react'
import { RouteComponentProps, withRouter } from "react-router";

import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import  SingleSlide  from './SingleSlide'

import {UserInfo} from "../http/protocol";
import { getContainer } from '../ioc/IOCSetup';
import SingleSlideJoiabet from './SingleSlideJoiabet';

interface Props extends RouteComponentProps{
    data: any;
    loggedIn:boolean;
    showLogin:()=> void;
    lng:string;
    optInBonusPlans?:any;
    user?:UserInfo;
}
interface State {
    listSlides: any;
    visible: boolean;
    mdShow: boolean;
    videoSrc: String;

}


export class Banner extends  React.Component<Props, State> {
    constructor(props: Props) {
      
        super(props);
      
    }
 
    
    render() {

    const defaultProps = {
        autoplay: 1,
        speed: 500,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        dots: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
           
            {
                breakpoint: 576,
                settings: {
                    dots: false,
                }
            }
        ]
    }
    let slides;
    let settings;

    if(this.props.loggedIn && this.props.optInBonusPlans &&  this.props.optInBonusPlans.length === 0  && this.props.user && this.props.user.registrationStatus === "PLAYER") {
        slides = this.props.data.filter(function( obj ) {
        return obj.isDepositTrigger !== true;
      });
      settings = { ...defaultProps, ...slides }

    }  else if(this.props.loggedIn && !this.props.optInBonusPlans  && this.props.user && this.props.user.registrationStatus === "QUICK_REG") {
        slides = this.props.data.filter(function( obj ) {
        return obj.isDepositTrigger !== true;
      });
      settings = { ...defaultProps, ...slides }
   
    }  else if (this.props.loggedIn && this.props.optInBonusPlans &&  this.props.optInBonusPlans.length > 0){
       slides = this.props.data.filter((arrayEl:any) =>{
       return this.props.optInBonusPlans.some((filterEl:any) => filterEl.bonusPlanId.toString() === arrayEl.bonusPlanId) || arrayEl.isDepositTrigger !== true;

    });
       settings = { ...defaultProps, ...slides }
       
    } else {
        slides = this.props.data.filter((arrayEl:any) =>{
            return arrayEl.logInOnly !== true;
        });
        settings = { ...defaultProps, ...slides }
    }


    
	let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();

    return (
        <>
        
            {slides && slides.length > 0   &&
                <>
                    <Slider {...settings} className="main-slider">
                        {slides.map((game: any, idx: any) => {
                            return <SingleSlideJoiabet key={idx} user={this.props.user} data={game} optInBonusPlans={this.props.optInBonusPlans} loggedIn={this.props.loggedIn} showLogin={()=> this.props.showLogin()} lng={this.props.lng}/>
                        })}
                    </Slider>
                
                </>
             }
        </>
    )
        }
}


function NextArrow(props: any) {
    const { onClick } = props;
    return (
        <a onClick={onClick}  className="slick-arrow slick-next">
            <FontAwesomeIcon icon={faChevronRight} color="red" style={{ color: "#d00d0d", width: "15px", height: "25px", marginLeft: "15px"}} size="1x" />
        </a>
    )
}

function PrevArrow(props: any) {
    const { onClick } = props;
    return (
        <a  onClick={onClick} className="slick-arrow slick-prev"> <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#d00d0d", width: "15px", height: "25px",  marginTop: "-15px",   marginLeft: "12px"}} color="red" size="1x" /> </a>
    )
}


export default withRouter<Props>(Banner);
