import {Dispatch} from "redux";
import {getContainer} from "../ioc/IOCSetup";
import {UserService} from "./UserService";
import {
    AccountBalance,
    BalanceAndBonus,
    BonusOffer,
    KycStatus,
    LoginResponseStatus,
    ManualLoginRequest, MultiFactorAuthProvider,ForgotPasswordRequest,
    RegistrationRequest,
    RegistrationResponse,
    ResetPasswordRequest,
    Settings,
    Status,
    TokenLoginRequest,
    UserBonusView,
    UserInfo
} from "../http/protocol";
import {LoginError, LoginType} from "./UserReducer";
import {SubmissionError} from "redux-form";
import {t} from "../i18n";
import {AppState} from "../RootReducer";
import {
    amplitudeLogEvent,
    amplitudeLoginEvent,
    amplitudeLogOutEvent,
    amplitudeRegistrationEvent
} from "../amplitude/AmplitudeActions";
import {AmplitudeEvent} from "../amplitude/AmplitudeEvent";
import {getLoggedInRedirect} from "../utils/LoggedInRedirect";
import {deviceDetect} from 'react-device-detect';
import {selectCurrency} from "../payments/DepositActions";
import {selectWithdrawCurrency} from "../payments/WithdrawActions";
import {BonusActionTypes} from "../bonus/BonusActions";
import {STATUS_CODES} from "http";
import FingerprintJS, {GetResult} from '@fingerprintjs/fingerprintjs';
import ReactGA from "react-ga4";

export enum UserActionTypes {
    LOGIN_REQUEST = "@user/login/request",
    LOGIN_RESPONSE = "@user/login/response",
    MFA_VERIFICATION = "@user/login/mfa_required",
    MFA_VERIFICATION_ERROR  ="@user/login/mfa_error",
    MFA_RESET = "@user/login/mfa_reset",
    LOGIN_ERROR = "@user/login/error",
    FACEBOOK_LOGIN_START ="@user/login/facebook/start",
    FACEBOOK_LOGIN_RESPONSE = "@user/login/facebook/response",
    FACEBOOK_LOGIN_CANCELED = "@user/login/facebook/canceled",
    LOGOUT = "@user/logout",
    SHOW_LOGIN = "@user/show-login",
    SHOW_SIGNUP = "@user/show-signup",
    SHOW_FORGOT_PASSWORD = "@user/show-forgot-password",
    BALANCE_REQUEST = "@user/balance/request",
    BALANCE_RESPONSE = "@user/balance/response",
    BALANCE_ERROR = "@user/balance/error",
    USER_INFO_REQUEST = "@user/info/request",
    USER_INFO_RESPONSE = "@user/info/response",
    USER_INFO_ERROR = "@user/info/error",
    REGISTER_REQUEST = "@user/signup/request",
    REGISTER_RESPONSE = "@user/signup/response",
    REGISTER_ERROR = "@user/signup/error",
    REQUEST_RECOVER_PASSWORD = "@user/requestresetpassword/request",
    REQUEST_RECOVER_PASSWORD_ERROR = "@user/requestresetpassword/error",
    RESET_PASSWORD_REQUEST = "@user/resetpassword/request",
    RESET_PASSWORD_RESPONSE = "@user/resetpassword/response",
    RESET_PASSWORD_ERROR = "@user/resetpassword/error",
    SET_ACTIVE_CURRENCY = "@user/activeCurrency",
    SET_SHOW_BALANCE = "@user/showbalance",
    VERIFY_EMAIL_REQUEST = "@user/verifyEmail/request",
    VERIFY_EMAIL_RESPONSE = "@user/verifyEmail/response",
    VERIFY_EMAIL_ERROR = "@user/verifyEmail/error",
    VERIFY_RESET = "@user/verifyEmail/reset",
    VERIFY_CHANNEL_CODE_REQUEST = "@user/verifyChannelCode/request",
    VERIFY_CHANNEL_CODE_RESPONSE = "@user/verifyChannelCode/response",
    VERIFY_CHANNEL_CODE_ERROR = "@user/verifyChannelCode/error",
    SET_MATI_STATUS = "@user/matistatus/set",
    USERSETTINGS_REQUEST = "@user/updateusersettings/request",
    USERSETTINGS_RESPONSE = "@user/updateusersettings/response",
    USERSETTINGS_ERROR = "@user/updateusersettings/error",
    SET_APP_MOODE= "@user/set/appMode",
    SHOW_PUSH_PROMPT= "@user/show-push-prompt",
    REDIRECT_USER= "@user/redirect",
    SHOW_IOS_INSTALL_GUIDE= "@user/show-ios-install-guide",
    SET_NEW_USER= "@user/set/new-user",
    USER_ELIGABLE_BONUS = "@user/bonus/response",
    USER_ACTIVE_BONUS = "@user/bonusActive/response",
    USER_ACTIVE_BONUS_BY_DATE = "@user/bonusActiveByDate/response",
    REQUEST_ELIGABLE_BONUS = "@user/bonus/request",
    REQUEST_ACTIVE_BONUS = "@user/bonus/request",
    SHOW_BONUS = "@user/bonus/show",
    SHOW_ACCOUNT_VERIFICATION = "@user/verification/show",
    SHOW_SETTINGS = "@user/settings/show",
    SHOW_CASHIER = "@user/cashier/show",
}
export interface BalanceResponse {
    balances : AccountBalance[];
    casinoPlayableBonusBalance?:number;
    sportsbookPlayableBonusBalance?:number;
    activeCurrency? : string;
    bonusBalance?:number;
    bonusOffers : BonusOffer[];
    activeBonuses : UserBonusView[];
}

export type UserAction =
    | { type : UserActionTypes.LOGIN_REQUEST, request : ManualLoginRequest }
    | { type : UserActionTypes.SHOW_LOGIN, show : boolean }
    | { type : UserActionTypes.SHOW_FORGOT_PASSWORD, show : boolean }
    | { type : UserActionTypes.LOGIN_RESPONSE, response : UserInfo}
    | { type : UserActionTypes.LOGIN_ERROR, error: LoginError}
    | { type : UserActionTypes.LOGOUT }
    | { type : UserActionTypes.BALANCE_REQUEST}
    | { type : UserActionTypes.BALANCE_RESPONSE, response : BalanceResponse}
    | { type : UserActionTypes.BALANCE_ERROR, error: Error}
    | { type : UserActionTypes.USER_INFO_REQUEST}
    | { type : UserActionTypes.USER_INFO_RESPONSE, info: UserInfo}
    | { type : UserActionTypes.USER_INFO_ERROR, error: Error}
    | { type : UserActionTypes.REGISTER_REQUEST}
    | { type : UserActionTypes.REGISTER_RESPONSE, response : RegistrationResponse}
    | { type : UserActionTypes.REGISTER_ERROR, error : Error}
    | { type : UserActionTypes.REQUEST_RECOVER_PASSWORD}
    | { type : UserActionTypes.REQUEST_RECOVER_PASSWORD_ERROR, error : Error}
    | { type : UserActionTypes.RESET_PASSWORD_REQUEST, request : ResetPasswordRequest }
    | { type : UserActionTypes.RESET_PASSWORD_RESPONSE, response : any }
    | { type : UserActionTypes.RESET_PASSWORD_ERROR, error : Error }
    | { type : UserActionTypes.SET_ACTIVE_CURRENCY, activeCurrency : string}
    | { type : UserActionTypes.SET_SHOW_BALANCE, show : boolean}
    | { type : UserActionTypes.VERIFY_EMAIL_REQUEST }
    | { type : UserActionTypes.VERIFY_EMAIL_RESPONSE, request : ManualLoginRequest  }
    | { type : UserActionTypes.VERIFY_EMAIL_ERROR }
    | { type : UserActionTypes.VERIFY_RESET}
    | { type : UserActionTypes.VERIFY_CHANNEL_CODE_REQUEST }
    | { type : UserActionTypes.VERIFY_CHANNEL_CODE_RESPONSE }
    | { type : UserActionTypes.VERIFY_CHANNEL_CODE_ERROR, error : Error  }
    | { type : UserActionTypes.FACEBOOK_LOGIN_START }
    | { type : UserActionTypes.FACEBOOK_LOGIN_RESPONSE, response : any}
    | { type : UserActionTypes.FACEBOOK_LOGIN_CANCELED }
    | { type : UserActionTypes.SET_MATI_STATUS, status: KycStatus }
    | { type : UserActionTypes.USERSETTINGS_REQUEST, request: string}
    | { type : UserActionTypes.USERSETTINGS_RESPONSE, response: Settings }
    | { type : UserActionTypes.USERSETTINGS_ERROR, error: Error}
    | { type : UserActionTypes.SET_APP_MOODE, appMode?: boolean}
    | { type : UserActionTypes.SHOW_PUSH_PROMPT, show: boolean}
    | { type : UserActionTypes.REDIRECT_USER, url: string}
    | { type : UserActionTypes.SHOW_IOS_INSTALL_GUIDE, show: boolean}
    | { type : UserActionTypes.SET_NEW_USER, newUser: boolean}
    | { type : UserActionTypes.MFA_VERIFICATION, provider : MultiFactorAuthProvider, username : string, password : string }
    | { type : UserActionTypes.MFA_VERIFICATION_ERROR }
    | { type : UserActionTypes.MFA_RESET }
    | { type : UserActionTypes.SHOW_SIGNUP, show: boolean} 
    | { type : UserActionTypes.SHOW_BONUS, show: boolean}
    | { type : UserActionTypes.SHOW_CASHIER, show: boolean, selectedCashier:string | undefined}
    | { type : UserActionTypes.SHOW_ACCOUNT_VERIFICATION, show: boolean}
    | { type : UserActionTypes.SHOW_SETTINGS, show: boolean}
    | { type : UserActionTypes.USER_ELIGABLE_BONUS, optInBonusPlans: any}
    | { type : UserActionTypes.USER_ACTIVE_BONUS, bonuses: any}
    | { type : UserActionTypes.USER_ACTIVE_BONUS_BY_DATE, bonuses: any}
    

export const setNewUser = (newUser:boolean):UserAction => {
    return {type:UserActionTypes.SET_NEW_USER, newUser:newUser};
}
export const setShowIOSGuide = (show:boolean): UserAction =>  {
    return{ type : UserActionTypes.SHOW_IOS_INSTALL_GUIDE, show:show};
}

export const setMatiStatus = (status: KycStatus) => (
    { type : UserActionTypes.SET_MATI_STATUS, status: status }
);
export const setAppMode = (enabled: boolean) => (
    { type : UserActionTypes.SET_APP_MOODE, appMode: enabled}
);
export const loginWithFacebook = () => {
    return async (dispatch : Dispatch) => {
        let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
        dispatch(amplitudeLogEvent(AmplitudeEvent.LOGIN_REQUEST, {loginType:LoginType.FACEBOOK, email: "facebook login n/a", homescreen:homescreen }) as any);
        dispatch({ type : UserActionTypes.FACEBOOK_LOGIN_START });
        let res = await getContainer().getFacebookApi().login();
        dispatch({ type : UserActionTypes.FACEBOOK_LOGIN_RESPONSE, response : res});
        if(res.status == "connected") {
            try {
                let response = await getContainer().getUserService().facebookLogin(res.authResponse.accessToken);

                if(response.loggedIn === false) {
                    dispatch(loginError(LoginError.FB_EMAIL_ALREADY_EXIST));
                    dispatch(amplitudeLogEvent(AmplitudeEvent.LOGIN_FAILED, {email: "facebook login n/a"}) as any);
                } else {
                    response.loggedIn = true;
                    dispatch(loginResponse(response));
                    dispatch(fetchBalance() as any);
                    dispatch(amplitudeLoginEvent(response.userId) as any);
                }
            } catch (e) {
                console.log("Login Error ", e)
                dispatch(loginError(e));
                dispatch(amplitudeLogEvent(AmplitudeEvent.LOGIN_FAILED, {email: "facebook login n/a"}) as any);
            }

        } else if(res.status == "unknown") {
            dispatch({ type : UserActionTypes.FACEBOOK_LOGIN_CANCELED });
        }

    }
}
export const showLogin = (show : boolean): UserAction => (
    { type : UserActionTypes.SHOW_LOGIN, show : show}
)
export const showSignUp = (show : boolean): UserAction => (
    { type : UserActionTypes.SHOW_SIGNUP, show : show}
)
export const showBonusModal = (show : boolean): UserAction => (
    { type : UserActionTypes.SHOW_BONUS, show : show}
)
export const showCashierModal = (show : boolean, selectedCashier?:string): UserAction => (
    { type : UserActionTypes.SHOW_CASHIER, show : show, selectedCashier:selectedCashier}
)
export const showAccountVerificationModal = (show : boolean): UserAction => (
    { type : UserActionTypes.SHOW_ACCOUNT_VERIFICATION, show : show}
)
export const showSettingsModal = (show : boolean): UserAction => (
    { type : UserActionTypes.SHOW_SETTINGS, show : show}
)
export const showForgotPassword = (show : boolean): UserAction => (
    { type : UserActionTypes.SHOW_FORGOT_PASSWORD, show : show}
)
export const showPushPrompt = (show : boolean): UserAction => (
    { type : UserActionTypes.SHOW_PUSH_PROMPT, show : show}
)
export const loginRequest = (request : ManualLoginRequest) : UserAction => {
    let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
    let updatedReq = request;
    updatedReq.homescreen = homescreen;
    return {type: UserActionTypes.LOGIN_REQUEST, request: updatedReq}
}

export const mfaRequired = (provider : MultiFactorAuthProvider, username : string, password : string) => {
    return { type : UserActionTypes.MFA_VERIFICATION, provider  : provider, username : username, password : password};
}
export const mfaFailed = () => {
    return { type :  UserActionTypes.MFA_VERIFICATION_ERROR };
}
export const resetMfa = () => {
    return { type : UserActionTypes.MFA_RESET }
}
export const loginResponse = (response : UserInfo) : UserAction => {
    if(response.loggedIn ) {
        //set localStorage logged in true
        localStorage.setItem("loggedIn","true");
        let preferredCurrency = response.currency && response.currency;
        preferredCurrency && setActiveCurrency(preferredCurrency)
    } else {
        localStorage.setItem("loggedIn","false");
    }

    return {type: UserActionTypes.LOGIN_RESPONSE, response: response}
}

export const loginError = (error : LoginError) : UserAction => (
    {type: UserActionTypes.LOGIN_ERROR, error: error}
)

export const logout = () => {
    const token = localStorage.getItem("token");
    const salt = localStorage.getItem("salt");
    const refId = localStorage.getItem("tokenRefId");
    const sessionKey = localStorage.getItem("sessionKey");
    const hooyuLink = localStorage.getItem("hooyuLink");
   const bonusPlanId =       sessionStorage.getItem("bonusPlanId");
    return  async (dispatch : Dispatch) => {
        let userService : UserService = getContainer().getUserService();
        if(token && salt && refId) {
            userService.clearUserLoginToken(Number(refId)).then((res) => {
            
                localStorage.removeItem("salt");
                localStorage.removeItem("token");
                localStorage.removeItem("tokenRefId");
            });
      
        }
        if(bonusPlanId) {
            sessionStorage.removeItem("bonusPlanId");
        }
        if(hooyuLink) {
            localStorage.removeItem("hooyuLink");
        }
        ReactGA.event({
            category: "User",
            action: "log_out",
          });
        await userService.logout().then(() => {
            //set localStorage logge in false
            localStorage.setItem("loggedIn","false");
            if(sessionKey) {
                localStorage.removeItem("sessionKey");
                console.error('logout?',localStorage.removeItem("sessionKey"))
            }
            //this prevents you from auto logging in after logout until you chose to store your credentials again.
            // const nav:any = navigator;
            // nav.credentials && nav.credentials.preventSilentAccess();
        });
        dispatch({type: UserActionTypes.LOGOUT });
        dispatch(amplitudeLogOutEvent() as any);
         // eslint-disable-next-line no-restricted-globals
         location.reload();
    }
}

export const fetchAllUserSettings = () => {
    return async (dispatch : Dispatch) => {
        dispatch({ type : UserActionTypes.USERSETTINGS_REQUEST});
        try {
            let userService : UserService = getContainer().getUserService();
            let value = await userService.getAllUserSettings();
            dispatch({ type : UserActionTypes.USERSETTINGS_RESPONSE, response : value });
        } catch (e) {
            dispatch({ type : UserActionTypes.USERSETTINGS_ERROR, error : e});
        }
    }
}

function loadActiveCurrencyFromLocalStorage() {
   try {
        let item = localStorage.getItem(ACTIVE_CURRENCY_KEY);
        if(item) {
            return item;
        } else {
            return undefined;
        }
    } catch (e) {
        return undefined;
    }
}

export const fetchBalance = () => {
    return async (dispatch : Dispatch) => {
        dispatch({ type : UserActionTypes.BALANCE_REQUEST});
        try {
            let userService : UserService = getContainer().getUserService();
            let balances:BalanceAndBonus = await userService.fetchBalances();
     
            dispatch({ type : UserActionTypes.BALANCE_RESPONSE, response : {bonusOffers:balances.activeOfferList, activeBonuses:balances.activeBonusList, balances : balances.totalBalance, activeCurrency : loadActiveCurrencyFromLocalStorage(), casinoPlayableBonusBalance:balances.casinoPlayableBonusBalance, sportsbookPlayableBonusBalance:balances.sportsbookPlayableBonusBalance, bonusBalance:balances.bonusBalance }});
            if(balances.activeBonusList) {
                dispatch({type: BonusActionTypes.ACTIVE_BONUSES_RESPONSE, activeBonuses:balances.activeBonusList})
            }
            if(balances.activeOfferList) {
                dispatch({type: BonusActionTypes.BONUS_OFFERS_RESPONSE, offers:balances.activeOfferList});
            }
        } catch (e) {
            let userService : UserService = getContainer().getUserService();
            await userService.logout();
            dispatch({type : UserActionTypes.LOGOUT});
            dispatch({ type : UserActionTypes.BALANCE_ERROR, error : e})
        }
    }
}

export const fetchUserInfo = (sessionKey: any) => {
    return async (dispatch : Dispatch) => {
        dispatch({type : UserActionTypes.USER_INFO_REQUEST});
        try {
            let userService : UserService = getContainer().getUserService();
            await userService.fetchUserInfo(sessionKey).then((userInfo) => {
                if(userInfo.loggedIn !== false && userInfo.sessionKey) {
                    userInfo.loggedIn = true;
                    let activeCurrency:string = localStorage.getItem("activeCurrency") ||"";
                    if(activeCurrency.length > 0) {
                        setActiveCurrency(activeCurrency);
                        selectCurrency(activeCurrency);
                    } else if(userInfo.currencies && userInfo.currencies[0].code) {
                        activeCurrency = userInfo.currencies[0].code;
                        setActiveCurrency(activeCurrency);
                        selectCurrency(activeCurrency);
                    }
                    dispatch(fetchBalance() as any);
                } else {
                    localStorage.setItem("loggedIn", "false");
                }
                dispatch({type : UserActionTypes.USER_INFO_RESPONSE, info : userInfo});
            });

        } catch (e) {
            dispatch({ type : UserActionTypes.USER_INFO_ERROR, error : e});
        }
    }
}


export const getEligibleOptInBonusPlans = (sessionKey: any) => {
    return async (dispatch : Dispatch) => {
        dispatch({type : UserActionTypes.REQUEST_ELIGABLE_BONUS});
        try {
            let userService : UserService = getContainer().getUserService();
            await userService.getEligibleOptInBonusPlans(sessionKey).then((r) => {
      
                dispatch({ type : UserActionTypes.USER_ELIGABLE_BONUS, optInBonusPlans : r.optInBonusPlans });
            });

        } catch (e) {
            dispatch({ type : UserActionTypes.USER_INFO_ERROR, error : e});
        }
    }
}

export const getActiveBonuses = (sessionKey: any) => {
    return async (dispatch : Dispatch) => {
        dispatch({type : UserActionTypes.REQUEST_ACTIVE_BONUS});
        try {
            let userService : UserService = getContainer().getUserService();
            await userService.getActiveBonuses(sessionKey).then((r) => {

                dispatch({ type : UserActionTypes.USER_ACTIVE_BONUS, bonuses : r.bonuses });
            });

        } catch (e) {
            dispatch({ type : UserActionTypes.USER_INFO_ERROR, error : e});
        }
    }
}

export const getActiveBonusesByDate = (sessionKey: any,startDate:any,endDate:any) => {
    return async (dispatch : Dispatch) => {
        dispatch({type : UserActionTypes.REQUEST_ACTIVE_BONUS});
        try {
            let userService : UserService = getContainer().getUserService();
            await userService.getActiveBonusesByDate(sessionKey,startDate,endDate).then((r) => {

                dispatch({ type : UserActionTypes.USER_ACTIVE_BONUS_BY_DATE, bonuses : r.bonuses });
            });

        } catch (e) {
            dispatch({ type : UserActionTypes.USER_INFO_ERROR, error : e});
        }
    }
}

export const redirectUser = (settings:Settings, newUser:boolean) => {
    return async (dispatch : Dispatch) => {
        try {
            let redirectURL ="";
            if(!settings.skipDepositPageOnRegister && newUser) {
                redirectURL = "/playnow/method";
            } else {
                redirectURL = getLoggedInRedirect(settings);
            }
            dispatch({type : UserActionTypes.REDIRECT_USER, url: redirectURL});

        } catch (e) {

        }
    }
};

export const updateUserDetails = (attributes : {[key:string] : string}) => {
    return async (dispatch : Dispatch) => {
        let userService = getContainer().getUserService();
        // if(!attributes.screenName) {
        //     throw new SubmissionError({_error : "forms.screenName.invalid"})
        // }
        try {
            let resp = await userService.updateUser(attributes);
            dispatch({type : UserActionTypes.USER_INFO_RESPONSE, info : resp})
        } catch (e) {
            dispatch({type: UserActionTypes.USER_INFO_ERROR, error:Error})
            if(e.message === "Request failed with status code 409") {
                throw new SubmissionError({_error : t("forms.ScreenNameAlreadyExist")});
            } else {
                throw new SubmissionError({
                    _error : e.message
                });
            }
        }
    }
}
export const updateUserSettings = (key: string, value: string) => {
    return async (dispatch : Dispatch) => {
        // console.log("updateUserSettings ", value);
        let userService = getContainer().getUserService();
        try {
            // console.log("updateUserSettings 2");
            let resp = await userService.updateUserSettings(key, value);
            // console.log("updateUserSettings 3", resp);
        } catch (e) {
            console.error(e);
        }
    }
}

export const registerAccount = (request: RegistrationRequest, settings: Settings) => {
    return async (dispatch : Dispatch) => {
        if(!request.email || request.email.trim().length == 0){
            throw new SubmissionError({_error :  "INVALID EMAIL"});
        }
        if(!request.password || request.password.trim().length == 0) {
            throw new SubmissionError({_error :   "INVALID PASSWORD"});
        }
        for (const key in request.attributes) {
            if(!request.attributes[key]) {
                throw new SubmissionError({_error :  t("user.invalid-registration-details")});
            }
        }
   
        dispatch(amplitudeLogEvent(AmplitudeEvent.START_CREATE_ACCOUNT, {email: request.email}) as any);
        let userService : UserService = getContainer().getUserService();
        let response  : RegistrationResponse;
        try {
            response = await userService.registerFull(request);
           
        } catch (e) {
         
            console.error("error creating account", e);
            if(e.message === "Request failed with status code 429") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode429")})
            } else {
                throw new SubmissionError({
                    _error: e.message
                });
            }
        }
        if(response.status == Status.VALIDATION_FAIL) { 
            ReactGA.event({
                category: "User",
                action: "registration_error",
                label:response.message
              });
        }
        if(response.status == Status.SUCCESS) { 
            ReactGA.event({
                category: "User",
                action: "full_register",
              });
            //   console.error(response.status);
        }
     
        dispatch({ type : UserActionTypes.REGISTER_RESPONSE, response: response});
        // console.error(response.status,'response.status');
        if(response.status == Status.SUCCESS) {
        dispatch(amplitudeRegistrationEvent(AmplitudeEvent.COMPLETED_CREATE_ACCOUNT, response.userInfo&& response.userInfo.userId) as any);
                let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
                let loginRequest: ManualLoginRequest = {email: request.email, password: request.password, homescreen:homescreen};
                dispatch(setNewUser(true));
                dispatch(login(loginRequest) as any);
            // if(!settings.requireUserChannelVerification) {
            //     dispatch(amplitudeRegistrationEvent(AmplitudeEvent.COMPLETED_CREATE_ACCOUNT, response.userInfo&& response.userInfo.userId) as any);
            //     let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
            //     let loginRequest: ManualLoginRequest = {email: request.email, password: request.password, homescreen:homescreen};
            //     dispatch(setNewUser(true));
            //     dispatch(login(loginRequest) as any);
            // } else {
            //     dispatch(amplitudeRegistrationEvent(AmplitudeEvent.COMPLETED_CREATE_ACCOUNT_REQUIRE_VERIFICATION, response.userInfo&& response.userInfo.userId) as any);
            //     let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
            //     let loginRequest: ManualLoginRequest = {email: request.email, password: request.password, homescreen:homescreen};
            //     dispatch({ type : UserActionTypes.VERIFY_EMAIL_RESPONSE, request: loginRequest });
            // }
        } else if(response.status == Status.SCREEN_NAME_EXIST) {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.screenname-taken")});
        } else if(response.status == Status.EMAIL_EXIST) {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.email-exists")});
        } else if(response.status == Status.BLOCKED_DOMAIN) {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.email-domain-blocked")});
        } else if(response.status == Status.INVALID_CHARACTER){
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.email-invalid-character")});
        } else if(response.status == Status.BLOCKED_IP){
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.invalid-registration-blockedip")});
        } else {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error :  t("user.invalid-registration-details")});
        }

    }
};



export const completeRegistration = (request: RegistrationRequest, settings: Settings) => {
    console.log("completeRegistration",request);
    return async (dispatch : Dispatch) => {
        ReactGA.event({
            category: "User",
            action: "complete_register",
          });
       
        let userService : UserService = getContainer().getUserService();
        let response  : RegistrationResponse;
  
        try {
            response = await userService.quickSignupCompletion(request);
   
          
            
        } catch (e) {
            console.error("error creating account", e);
            if(e.message === "Request failed with status code 429") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode429")})
            } else {
                throw new SubmissionError({
                    _error: e.message
                });
            }
        }
        dispatch({ type : UserActionTypes.REGISTER_RESPONSE, response: response});
        if(response.status == Status.SUCCESS) {
            //Add acquisition Script!
            dispatch(setNewUser(true));
            if(localStorage.getItem("sessionKey")) {
                dispatch(fetchUserInfo(localStorage.getItem("sessionKey")) as any);
                dispatch(getEligibleOptInBonusPlans(localStorage.getItem("sessionKey")) as any);
                dispatch(getActiveBonuses(localStorage.getItem("sessionKey")) as any);
            }
     
        } else if(response.status == Status.SCREEN_NAME_EXIST) {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.screenname-taken")});
        } else if(response.status == Status.EMAIL_EXIST) {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.email-exists")});
        } else if(response.status == Status.BLOCKED_DOMAIN) {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.email-domain-blocked")});
        } else if(response.status == Status.INVALID_CHARACTER){
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.email-invalid-character")});
        } else if(response.status == Status.BLOCKED_IP){
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error : t("user.invalid-registration-blockedip")});
        } else {
            dispatch(amplitudeLogEvent(AmplitudeEvent.CREATE_ACCOUNT_FAILED, {email: request.email}) as any);
            throw new SubmissionError({_error :  t("user.invalid-registration-details")});
        }

    }
};
export const tokenLogin = (request : TokenLoginRequest) => {
    return async (dispatch : Dispatch ) : Promise<any> => {
        let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
        const device = deviceDetect();
        dispatch(amplitudeLogEvent(AmplitudeEvent.LOGIN_REQUEST, {loginType: LoginType.TOKEN, homescreen:homescreen}) as any);
        let userService : UserService = getContainer().getUserService();
        //get fingerprint and add it to amplitude login request;
        let fpID:string = "";
        try {
            await window.fingerprint.get().then((res:GetResult) => {
                fpID = res.visitorId;
            });
        } catch (e) {
            console.log("Failed to get fingerprint id")
        }
        try {
            let updatedReq = request;
            updatedReq.homescreen = homescreen;
            updatedReq.device = device.os || device.osName || undefined;
            updatedReq.deviceId = fpID;
            const tokenRef = localStorage.getItem("tokenRefId");
            await userService.loginWithToken(updatedReq).then((res) => {
                if(res.loggedIn === false) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("salt");
                    localStorage.removeItem("hooyuLink");
                    localStorage.removeItem("tokenRefId");
                    localStorage.setItem("loggedIn", "false");
                    dispatch(loginError(LoginError.INVALID_TOKEN));
                    tokenRef && tokenRef.length > 0 && userService.clearUserLoginToken(Number(tokenRef)).then((re) => {
                        userService.logout();
                    })
                } else {
                    //proceed with login
                    res.loggedIn = true;
                    dispatch(loginResponse(res));
                    if(res.responseStatus === LoginResponseStatus.VERIFICATION_REQUIRED) {
                        dispatch(requestVerifyEmail(request) as any);
                    } else {
                        if(res.sessionKey){
                        dispatch(fetchUserInfo(res.sessionKey) as any);
                        dispatch(getEligibleOptInBonusPlans(res.sessionKey) as any);
                        dispatch(getActiveBonuses(res.sessionKey) as any);
                        }
                    }
                }
            }).catch(e=>console.log("failed login with token: ", e));


        } catch (e) {
            dispatch(loginError(e));
        }

    }
}

export const login = (request : ManualLoginRequest)  => {

    return async (dispatch : Dispatch, getState : () => AppState ) : Promise<any> => {
        let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
        const device = deviceDetect();
        //get fingerprint and add it to amplitude login request;
        let fpID:string = "";
        try {
            await window.fingerprint.get().then((res:GetResult) => {
                fpID = res.visitorId;
            });
        } catch (e) {
            console.log("Failed to get fingerprint id")
        }

        dispatch(amplitudeLogEvent(AmplitudeEvent.LOGIN_REQUEST, {loginType: LoginType.EMAIL, email: request.email, homescreen:homescreen}) as any);
        let userService : UserService = getContainer().getUserService();
        try {
            let updatedReq = request;
            updatedReq.homescreen = homescreen;
            updatedReq.device = device.os || device.osName || undefined;
            updatedReq.deviceId = fpID;
            let response:UserInfo = await userService.login(updatedReq);
            if(response.partyId) {
                localStorage.setItem("partyId", response.partyId.toString());
                   //@ts-ignore
                ReactGA.set({ userId: parseInt(response.partyId) });
            }
            if(response.sessionKey) {
                localStorage.setItem("sessionKey", response.sessionKey);
            }
      
            ReactGA.event({
                category: "User",
                action: "log_in",
              });

        
            if(request.email && request.password && (response.responseStatus == LoginResponseStatus.MULTI_FACTOR_AUTH_REQUIRED)) {
                dispatch(mfaRequired(response.errorMessage as MultiFactorAuthProvider, request.email, request.password));
            } else if(request.email && request.password && response.responseStatus == LoginResponseStatus.MULTI_FACTOR_AUTH_FAILED) {
                dispatch(mfaFailed())
            } else if(response.loggedIn === false) {
                throw new Error(response.errorMessage)
            } else {
                //Check if we should show notifications prompt: TODO: Check if this can be deleted.
                let settings = getState().settings.settings;
                let user = getState().user.user;
                let loginsRequired = settings && settings.loginsRequiredForPushPrompt != undefined ? settings.loginsRequiredForPushPrompt : null;
                let pushEnabled:boolean = !!(settings && settings.pushNotificationsEnabled);
                let noOfLoggins = response.userSettings && response.userSettings["noOfLogin"];
                let optOutPushPrompt = response.userSettings && response.userSettings["optOutPushPrompt"];
                let newUser = noOfLoggins === undefined;

                try {
                    window.fcWidget && window.fcWidget.user.setProperties({
                        email: response && response.email,
                        "User ID": response && response.userId
                      });
                } catch (e) {
                    console.error("FC Fail", e);
                }

                if(pushEnabled && noOfLoggins != null && loginsRequired != null && (optOutPushPrompt == null)) {
                    if((Number(noOfLoggins)+1) >= Number(loginsRequired)) {
                        try {
                            //Check if already subscribed
                            if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller) {
                                await navigator.serviceWorker.ready.then((swRegistration:ServiceWorkerRegistration) => {
                                    if(swRegistration) {
                                        swRegistration.pushManager.getSubscription().then((res) => {
                                            if(res == null) {
                                                swRegistration.pushManager.permissionState({userVisibleOnly:true}).then((res) => {
                                                    if(res == "prompt") {
                                                        //browser is set to prompt for subscription means that the user has not yet subscribed.
                                                        dispatch(showPushPrompt(true));
                                                    }
                                                });
                                            }
                                        }).catch((e) => {
                                            console.log("Failed to get subscription: ", e);
                                        })
                                    }
                                }).catch((e) => {
                                    console.log("Service worker not ready: ", e);
                                })
                            }
                        } catch (e) {
                            console.log("Failed to check if user is subscribed: ", e);
                        }
                    }
                }

                //This is google sso. im leaving it in here in case we want to go back to it.

                //Credential manager - store credentials for auto login using pwa
                // if (window.PasswordCredential && homescreen) {
                //     const nav:any = navigator;
                //     if(request.password && request.email) {
                //         let cred = new window.PasswordCredential({
                //             id: request.email,
                //             password: request.password
                //         });
                //         await nav.credentials.store(cred)
                //             .then(()=> console.log("Done saving credentials!: "));
                //     }
                // } else {
                //     console.log("Failed to store user")
                // }

                // Set token in localstorage, if remember me is checked on login.
                if(response.token && response.secret && response.tokenRefId) {
                    localStorage.setItem("token", response.token);
                    localStorage.setItem("salt", response.secret);
                    localStorage.setItem("tokenRefId", response.tokenRefId.toString());
                    // userService.clearUserLoginToken()
                }

                if(response.responseStatus === LoginResponseStatus.VERIFICATION_REQUIRED) {
                    dispatch(requestVerifyEmail(request) as any);
                } else if(response.status = "FAIL_UN_PW" && response.message){
                    console.error(response.message)
                    throw new Error(response.message)
                }else {
                    //proceed with login
                    response.loggedIn = true;
                    dispatch(loginResponse(response));
                    if(response.sessionKey) {
                    dispatch(fetchUserInfo(response.sessionKey) as any);
                
                        dispatch(getEligibleOptInBonusPlans(response.sessionKey) as any);
                        dispatch(getActiveBonuses(response.sessionKey) as any);
                   }
                    // settings != undefined && newUser != undefined && dispatch(redirectUser(settings, newUser) as any);
                }

                /*
                if(response.userSettings&& response.userSettings["language"]) {
                    dispatch(setLng(response.userSettings["language"]));
                } else {
                    dispatch(updateUserSettings("language", getState().user.lng) as any);
                }*/
                dispatch(amplitudeLoginEvent(response.userId) as any);
            }
        } catch (e) {

            dispatch(loginError(e));
            dispatch(amplitudeLogEvent(AmplitudeEvent.LOGIN_FAILED, {email: request.email}) as any);
            if(e.message === "Request failed with status code 429") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode429")})
            }
            if(e.message === "Request failed with status code 500") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode500")})
            } else {
                throw new SubmissionError({
                    _error: e.message
                });
            }


        }

    }
}
export const forgotPassword = (requst: {email: string}) => {
    return async (dispatch : Dispatch) : Promise<any> => {
        let userService : UserService = getContainer().getUserService();
        try {
            let response = await userService.forgotPassword(requst.email);
            dispatch({ type : UserActionTypes.REQUEST_RECOVER_PASSWORD });
        } catch (e) {
            console.error("Request password error", e);
            dispatch({ type : UserActionTypes.REQUEST_RECOVER_PASSWORD_ERROR, error : Error });
            throw new SubmissionError({
                _error: e.message
            });
        }

    }
}
const requestVerifyEmail = (request : ManualLoginRequest) => {
    return async (dispatch : Dispatch) : Promise<any> => {
        let userService : UserService = getContainer().getUserService();
        try {
            dispatch({ type : UserActionTypes.VERIFY_EMAIL_REQUEST });
            let response = await userService.requestVerificationEmail(request.email != undefined ? request.email : "");
            dispatch({ type : UserActionTypes.VERIFY_EMAIL_RESPONSE, request: request });
        } catch (e) {
            console.error("Request verify email error", e);
            dispatch({ type : UserActionTypes.VERIFY_EMAIL_ERROR, error : Error });

            if(e.message === "Request failed with status code 429") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode429")})
            } else {
                throw new SubmissionError({
                    _error: e.message
                });
            }
        }
    }
}

export const verifyChannelCode = (manualLoginRequest: ManualLoginRequest, userId?: number, code?: string) => {
    return async (dispatch : Dispatch) : Promise<any> => {
        let userService : UserService = getContainer().getUserService();
        try {
            dispatch({ type : UserActionTypes.VERIFY_CHANNEL_CODE_REQUEST });

            let response = await userService.verifyChannelCode(userId != undefined ? userId : -1, code != undefined ? code : "0");

            dispatch({ type : UserActionTypes.VERIFY_CHANNEL_CODE_RESPONSE });
            dispatch(login(manualLoginRequest) as any);
        } catch (e) {
            console.error("Request verify email error", e);
            dispatch({ type : UserActionTypes.VERIFY_CHANNEL_CODE_ERROR, error : Error });

            if(e.message === "Request failed with status code 406") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode406")});
            } else if(e.message === "Request failed with status code 500") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode500")});
            } else if(e.message === "Request failed with status code 429") {
                throw new SubmissionError({_error : t("forms.verifyEmail.errorCode429")})
            }
            throw new SubmissionError({
                _error: e.message
            });
        }
    }
}

export const requestResetPassword = (requst: {email: string}) => {
    return async (dispatch : Dispatch) : Promise<any> => {
        let userService : UserService = getContainer().getUserService();
        try {
            let response = await userService.requestResetPassword(requst.email);
            dispatch({ type : UserActionTypes.REQUEST_RECOVER_PASSWORD });
        } catch (e) {
            console.error("Request password error", e);
            dispatch({ type : UserActionTypes.REQUEST_RECOVER_PASSWORD_ERROR, error : Error });
            throw new SubmissionError({
                _error: e.message
            });
        }

    }
}

export const resetPassword = (requst: ResetPasswordRequest) => {
    return async (dispatch : Dispatch) : Promise<any> => {
        dispatch({type : UserActionTypes.RESET_PASSWORD_REQUEST, request: requst});
        let userService : UserService = getContainer().getUserService();
        try {
            let response = await userService.resetPassword(requst);
            dispatch({type : UserActionTypes.RESET_PASSWORD_RESPONSE, response: response});
        } catch (e) {
            console.error("Request password error", e);
            dispatch({ type : UserActionTypes.RESET_PASSWORD_ERROR, error : Error });
            throw new SubmissionError({
                _error: e.message
            });
        }
    }
}

export const setShowBalance = (show: boolean): UserAction => (
    { type : UserActionTypes.SET_SHOW_BALANCE, show : show}
)

export const ACTIVE_CURRENCY_KEY = "activeCurrency";
export const setActiveCurrency = (currency : string): UserAction => {

    if(localStorage) {
        localStorage.setItem("activeCurrency", currency);
    }

    selectCurrency(currency);
    selectWithdrawCurrency(currency);
    return { type : UserActionTypes.SET_ACTIVE_CURRENCY, activeCurrency : currency};
}

export const resetVerifyProcess = (): UserAction => (
    {type : UserActionTypes.VERIFY_RESET}
)
