export function hasClass (element:any, cls:any)  {
    return element.closest(`.${cls}`)
}

export const imageUrl = (image:any) => {
    if ( !image ) return ''
    return `https://content.adminemx.com/maharajafortune/images/${image}`;
}


export const paymentsImgUrl = (image:any) => {
    if ( !image ) return ''
    return `https://content.adminemx.com/maharajafortune/images/payments/${image}`
}


const footLogoUrl = (image:any) => {
   
    if ( !image ) return ''
    
    return `https://content.adminemx.com/maharajafortune/images/footerlogo/${image}`
}
export default footLogoUrl;

export const casinoLobby = (image:any) => {
    if ( !image ) return ''
    return `https://content.adminemx.com/maharajafortune/images/footerlogo/${image}`
}


export const transactionCheckUrl = (image:any) => {
    if ( !image ) return ''
    return `https://content.adminemx.com/maharajafortune/images/payments/${image}`
}