import React from 'react';
import './PromotionView.scss';
import { Bonuses, Bonus } from '../../http/protocol';
import { connect } from 'react-redux';
import { AppState } from '../../RootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Redirect } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import Root from '../../layout/Root';
import MetaHelmet from '../../utils/MetaHelmet';
import { Helmet } from 'react-helmet';
import SamplePromoImage from 'https://content.adminemx.com/maharajafortune/images/promotion-sample-image.jpg';
import { HTML5_FMT } from 'moment';
import { logout, showLogin, showCashierModal, } from '../../user/UserActions';
import CloseIcon from "../../utils/closeIcon"
import { Accordion, Card } from "react-bootstrap";
import { getContainer } from '../../ioc/IOCSetup';

import { t } from "../../i18n";

let staticPromotion = {
	status: 'SUCCESS',
	bonuses: [
		{
			id: 69,
			bonusPlanName: 'Maharaja Fortune Welcome Bonus Offer',
			status: 'RELEASED',
			expiryDate: '2021-09-21',
			triggerDate: '2021-08-15 18:04:53',
			amount: '10.00',
			wagerRequirement: '100.00',
			amountWagered: '100.00',
			triggerType: 'PLTFRM_BON'
		},
		{
			id: 70,
			bonusPlanName: 'Free Spin',
			status: 'RELEASED',
			expiryDate: '2021-09-21',
			triggerDate: '2021-08-15 18:04:53',
			amount: '10.00',
			wagerRequirement: '100.00',
			amountWagered: '100.00',
			triggerType: 'PLTFRM_BON'
		}
	]
};
interface Props extends RouteComponentProps {
	promotion?: Bonus;
	isloggedIn: boolean;
	showLogin: () => void;
	showCashierModal?:(selectedCashier:string) => void;
	isDeposited: boolean;
}
interface State {
	show: boolean;
	bonusPlanId?: any;
	promotion?:any;
}

export class PromotionTNCsVIew extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = { show: false, bonusPlanId: null as any };
		this.hideModal = this.hideModal.bind(this);

		this.showModal = this.showModal.bind(this);
		this.claimClick = this.claimClick.bind(this);
	}
	componentDidMount(): void {
		let promotions = getContainer().getSettingsService().getPromotions();
		let path = window.location.pathname;
		let lang = getContainer().getSettingsService().getLocalesData();
		let activeLang = getContainer().getSettingsService().getActiveLocale().code;
		if(lang && lang.length >= 2) {
			path = window.location.pathname.replace(`/${activeLang}`, '');
		}


        let promotion = promotions &&  promotions.filter(function( obj:any ) {
			
				return obj.termsAndCondition == path;
			
          
          })

		  this.setState({promotion:promotion[0]});
		if (sessionStorage.getItem("bonusPlanId") && !this.state.bonusPlanId) {
			this.setState({ bonusPlanId: sessionStorage.getItem("bonusPlanId") });
		}

	}
	handleBonusPlanId = (id: any) => {
		sessionStorage.setItem("bonusPlanId", id);
		this.setState({ bonusPlanId: id })
	};
	handleUnclaim = () => {
		sessionStorage.removeItem("bonusPlanId");
		this.setState({ bonusPlanId: null })
	};
	showModal() {
		this.setState({ show: true });
	}
	hideModal() {
		this.setState({ show: false });
	}
	claimClick() {
		if (this.props.isloggedIn) {
			this.handleBonusPlanId(this.state.promotion && this.state.promotion.bonusPlanId);
			this.props.showCashierModal &&  this.props.showCashierModal("deposit");
		} else {
			this.props.showLogin();
		}
	}
	render() {
		if(this.state.promotion && !this.state.promotion.isEnabled) {
			return <Redirect to={"/"}/>
		}
		let activeLang = getContainer().getSettingsService().getActiveLocale().code;
		let lang = getContainer().getSettingsService().getLocalesData();

		const title: string = t("meta.promotionTNCsView.title") || '';
		const subtitle: string = t("meta.promotionTNCsView.subtitle") || '';
		const fullTitle: string = lang.length >= 2 && this.state.promotion ? this.state.promotion.metaTitle[activeLang]: t("meta.promotionTNCsView.fullTitle") ;
		const description: string =  lang.length >= 2 && this.state.promotion   ?  this.state.promotion.metaDescription[activeLang] : t("meta.promotionTNCsView.description") ;
		const keywords: string =  lang.length >= 2 && this.state.promotion  ? this.state.promotion.metaKeyword[activeLang] : t("meta.promotionTNCsView.keywords") ;
		const baserHref: string = t("meta.promotionTNCsView.baserHref") || '';

		
	

		return (
			<Root footer={true} header={true}>
				{MetaHelmet(fullTitle, description, keywords)}
				<Helmet />
				<div className='promotion-wrapper'>
					<Link to="/promotions"><CloseIcon className={`invalid-icon`} animate={false} /> </Link>
					{this.props.isloggedIn && this.state.bonusPlanId == "3" ? (
						<img
							className="img-fluid claimed-badge"
							src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Claimed-badge.png'}
							alt={'Welcome Bonus'}
							title=""
						/>
					) : null}
					<img
						className="img-fluid top-banner"
						src={this.state.promotion && this.state.promotion.TnCBanner }
						alt={'Welcome Bonus'}
						title=""
					/>


					<div className="main-content promotion-content">

						<div className="promoContent">
						{lang && lang.length >= 2 ? (<h3> {this.state.promotion && this.state.promotion.promotionTCL[activeLang]}</h3>):(		<h3> {this.state.promotion && this.state.promotion.promotionTCL}</h3>)}
		
					
							{this.state.promotion && this.state.promotion.TnCBTN ? (<a
									className="btn btn-primary  btn-rounded promo-btn"
									href={this.state.promotion.TnCBTN}
								>
									{t("promotionTNCsView.promoClaim")}
								</a>):(this.props.isloggedIn && this.state.bonusPlanId == this.state.promotion && this.state.promotion && this.state.promotion.bonusPlanId ?
								(<a
									className="btn btn-primary  btn-rounded promo-btn"
									onClick={() => { this.handleUnclaim() }}
								>
									{t("promotionTNCsView.promoUnClaim")}
								</a>) : (<a
									className="btn btn-primary  btn-rounded promo-btn"
									onClick={() => { this.claimClick() }}
								>
									{t("promotionTNCsView.promoClaim")}
								</a>))}

								{lang && lang.length >= 2 ? (<div><div dangerouslySetInnerHTML={ {__html: this.state.promotion && this.state.promotion.TnCPart1[activeLang] } } /></div>):(	<div><div dangerouslySetInnerHTML={ {__html: this.state.promotion && this.state.promotion.TnCPart1 } } /></div>)}
							

							<Accordion >
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="0">

										<h5> {t("promotionTNCsView.promotionTitle")} </h5>

									</Accordion.Toggle>
									<Accordion.Collapse eventKey="0">
										<Card.Body>


											

										{lang && lang.length >= 2 ? (<div><div dangerouslySetInnerHTML={ {__html: this.state.promotion && this.state.promotion.TnCPart2[activeLang] } } /></div>):(	<div><div dangerouslySetInnerHTML={ {__html: this.state.promotion && this.state.promotion.TnCPart2 } } /></div>)}
							

										{this.state.promotion && this.state.promotion.TnCWagerContent ? (
											<React.Fragment>
											<h5 dangerouslySetInnerHTML={
                                				{ __html: t("promotionTNCsView.promotionTitle2", { interpolation: { escapeValue: false } })}}>
                            				</h5>

											<p dangerouslySetInnerHTML={
                                				{ __html: t("promotionTNCsView.promotionText", { interpolation: { escapeValue: false } })}}>
                            				</p>
											</React.Fragment>):null}
										</Card.Body>



									</Accordion.Collapse>
								</Card>

							</Accordion>

						</div>
					</div>
				</div>
			</Root>
		);
	}
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
	showLogin: () => dispatch(showLogin(true)),
	showCashierModal:(selectedCashier:string)=> dispatch(showCashierModal(true,selectedCashier)),
});
const mapStateToProps = (app: AppState) => ({
	isloggedIn: app.user.user && app.user.user.loggedIn,
	isDeposited: app.user.user && app.user.user.madeDeposit
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PromotionTNCsVIew as any));
