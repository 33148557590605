import {Settings} from "../http/protocol";
import {userInfo} from "os";



export const getLoggedInRedirect = (settings:Settings, emailVerified:boolean = false) => {
    let lastProductUsed = localStorage.getItem("lastProductUsed");

    if(settings.requireUserChannelVerification && !emailVerified) {
        return "/playnow/verifyemail";
    }

    if((lastProductUsed === null || lastProductUsed.length === 0) && settings && settings.loginRedirectUrl && settings.loginRedirectUrl.length > 0) {
        if(settings.loginRedirectUrl === "/poker" && settings.pokerEnabled) {
            return "/poker"
        } else if(settings.loginRedirectUrl === "/casino" && settings.casinoEnabled) {
            return "/casino"
        } else if(settings.loginRedirectUrl === "/sportsbook" && settings.sportsbookEnabled) {
            return "/sportsbook"
        } else if(settings.loginRedirectUrl === "/racing" && settings.racingEnabled) {
            return "/racing"
        } else {
            return "/"
        }
    } else {
        if(lastProductUsed === "poker" && settings.pokerEnabled) {
            return "/poker"
        } else if(lastProductUsed === "casino" && settings.casinoEnabled) {
            return "/casino"
        } else if(lastProductUsed === "sportsbook" && settings.sportsbookEnabled) {
            return "/sportsbook"
        } else if(lastProductUsed === "racing" && settings.racingEnabled) {
            return "/racing"
        } else {
            return "/"
        }
    }

}

export default getLoggedInRedirect;