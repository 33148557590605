import React from "react";
import "./RegisterView.scss";
import { Status } from "../../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../../RootReducer";
import { Redirect } from "react-router";
import RegisterForm from "./RegisterForm";
import Root from "../../layout/Root";
import MetaHelmet from "../../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { Dispatch } from "redux";
import { getContainer } from "../../ioc/IOCSetup";
import { ContentModelName, RegisterPayload, CompleteRegistrationPayload } from "../../contenful/types";
import { fetchContent } from "../../contenful/ContentfulActions";
import { COUNTRIES } from "./Countries";
import Modal from "react-bootstrap/Modal"
import { showSignUp } from "../UserActions";
import { hasClass } from "../../utils/Helpers";
import JoiabetRegisterForm from "./JoiabetRegisterForm";
import { t } from '../../i18n';
type Props = {
    status?: Status;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    registerContent?: RegisterPayload;
    completeRegisterContent?: CompleteRegistrationPayload;
    activeLng?: string;
    show?: boolean,
    hide?: () => void;
    loggedIn?:boolean
    madeDeposit?:boolean;
    isNewUser?:boolean;
};
interface State {
    modal2Show: boolean;
    path:string;
}
export class RegisterModal extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            modal2Show: false,
            path:''
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        let lang = getContainer().getSettingsService().getLocalesData();

        let path ;
        if(lang && lang.length >= 2) {
             path = window.location.pathname.split("/")[2];
        } else {
           path = window.location.pathname.split("/")[1];
        }
        this.setState({path:path});
        document.addEventListener('mousedown', this.handleClickOutside);
        if (!this.props.isFetching) {

            if (this.props.registerContent === undefined) {
                this.props.fetchContent(ContentModelName.REGISTER, getContainer().getSettingsService().getActiveLocale().code);
            }
            if (this.props.completeRegisterContent === undefined) {
                this.props.fetchContent(ContentModelName.COMPLETEREGISTRATION, getContainer().getSettingsService().getActiveLocale().code);
            }


        }
        
        getContainer().getSettingsService().getCountryAvailable().then((r: any) => {
            let country = COUNTRIES.filter(e => r.countries && r.countries.includes(e.code));
            getContainer().getSettingsService().setCountryData(country);

        });
        getContainer().getSettingsService().getBrandCurrencies().then((r: any) => {

            getContainer().getSettingsService().setCurrencies(r.currencies);

        });

    }
    closed = (event: any) => {
        event.preventDefault();
        this.props.hide && this.props.hide();
       
            document.removeEventListener('mousedown', this.handleClickOutside);
            document.removeEventListener('touchstart', this.handleClickOutside);

    }
    handleClickOutside = (event: any) => {
        const target = event.target
    
       
        if(this.props.show) {
        if ( !hasClass(target, 'modal-content')) {
            console.error("outside modal");
            if(this.props.loggedIn) {
                this.closed(event)
            } else {
                this.setState({ modal2Show: true })
            }
         }
        }
    }
    render() {
        if (this.props.loggedIn) {
            if (this.props.madeDeposit) {
                this.props.hide && this.props.hide();
            }
   
        }
        let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
        if(!this.props.show && this.state.path === 'signup') {
            return <Redirect to={"/"} />
        }
        return (
            <React.Fragment>
                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.show}
                    onHide={() => this.props.hide && this.props.hide()}
                    className="sign-up-modal"
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => { 
                            if(this.props.loggedIn) {
                                this.props.hide && this.props.hide();
                            } else {
                                this.setState({ modal2Show: true })
                            }
                        
                             }}><span aria-hidden="true">×</span><span className="sr-only">{t("registerModal.close")}</span></button>
                    </Modal.Header>

                    <Modal.Body className="register-form-container">
                        {mainLobby == "https://joiabet.com/" ? (<JoiabetRegisterForm />):(<RegisterForm />)}
                        

                    </Modal.Body>
                </Modal>

                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.modal2Show}
                    onHide={() => this.setState({ modal2Show: false })}
                    className="register-modal-goback"
                >
                    

                    <Modal.Body >
                        <h3> {t("registerModal.title")} </h3>

                        <p> {t("registerModal.text")}</p>

                        <button className="btn btn-cta btn-lg height" onClick={() => { this.setState({ modal2Show: false }) }}>{t("registerModal.back")}</button>
                        <button className="btn" onClick={() => { this.setState({ modal2Show: false }); this.props.hide && this.props.hide(); }}>{t("registerModal.close")}</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>



        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hide: () => { dispatch(showSignUp(false)) },
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
});
const mapStateToProps = (app: AppState) => (
    {
        status: app.user.registration && app.user.registration.status,
        registerContent: app.contentful.register,
        show: app.user.displaySignup,
        completeRegisterContent: app.contentful.completeRegistration,
        loggedIn:app.user.user?.loggedIn,
        madeDeposit: app.user.user && app.user.user.madeDeposit,
        isNewUser: app.user.user && app.user.user.newUser,
        activeLng: getContainer().getSettingsService().getActiveLocale().code
    }
);
export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal as any);