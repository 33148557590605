import { Dispatch } from "redux";
import { DocumentTypeResult, FileUploadRequest, FileUploadResult } from "../http/protocol";
import { getContainer } from "../ioc/IOCSetup";
import { AppState } from "../RootReducer";

export enum FileUploadActionType {
  UPLOAD_FILE_REQUEST = "@user/fileUpload/request",
  UPLOAD_FILE_SUCCESS = "@user/fileUpload/success",
  UPLOAD_FILE_ERROR = "@user/fileUpload/error",
  GET_DOCUMENT_TYPES_SUCCESS = "@user/fileUpload/documentTypes",
  GET_DOCUMENT_TYPES_ERROR = "@user/fileUpload/documentTypes/error"
}

export type FileUploadActions =
    | { type : FileUploadActionType.UPLOAD_FILE_REQUEST}
    | { type : FileUploadActionType.UPLOAD_FILE_SUCCESS, result : FileUploadResult }
    | { type : FileUploadActionType.UPLOAD_FILE_ERROR, error : Error }
    | {type : FileUploadActionType.GET_DOCUMENT_TYPES_SUCCESS, documentTypes: DocumentTypeResult[] }


export function fileUpload(request: FileUploadRequest) {
  console.log('PROCESS File Upload', request)
  return (dispatch : Dispatch, getState :() => AppState) => {
    dispatch({type : FileUploadActionType.UPLOAD_FILE_REQUEST});
    getContainer().getFileUploadService().fileUpload(request)
        .then((r: FileUploadResult) => {
          console.log(r.status, "STATUS");
            if (r.status !== undefined) {
                if (r.status == 'SUCCESS') {
                  dispatch({type : FileUploadActionType.UPLOAD_FILE_SUCCESS, result: r});
                } else {
                  dispatch({ type : FileUploadActionType.UPLOAD_FILE_ERROR, error : r.errors});
                }
            }
    }).catch(err => {
      dispatch({ type : FileUploadActionType.UPLOAD_FILE_ERROR, error : err});
    })
  }
}

export function getDocumentTypes() {
  console.log("getDocumentTypes start --")
  return (dispatch : Dispatch, getState :() => AppState) => {
    console.log('Getting document types');
    getContainer().getFileUploadService().getDocumentTypes().then(r => {
        console.log("RESULT", r)
        dispatch({type : FileUploadActionType.GET_DOCUMENT_TYPES_SUCCESS, documentTypes: r.documentTypes});
    }).catch(err => {
      dispatch({ type : FileUploadActionType.GET_DOCUMENT_TYPES_ERROR, error : err});
    })
  }
}
