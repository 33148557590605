import {BonusConfig, BonusOffer, UserBonusView} from "../http/protocol";
import {BonusActions, BonusActionTypes} from "./BonusActions";
import {UserAction, UserActionTypes} from "../user/UserActions";


export enum RequestState {
    NONE, IN_PROGRESS, OK, ERROR
}


export interface BonusStoreState {
    offers? : BonusOffer[];
    nextBonuses? : BonusConfig[];
    activeBonuses? : UserBonusView[];
    offerState? : RequestState;
    nextBonusesState? : RequestState;
    activeBonusesState? : RequestState;
    claimOfferStatus : RequestState;
}

const initialState : BonusStoreState = {
    offers : [],
    nextBonuses : [],
    activeBonuses: [],
    offerState : RequestState.NONE,
    nextBonusesState : RequestState.NONE,
    activeBonusesState : RequestState.NONE,
    claimOfferStatus : RequestState.NONE
};

export function bonusReducer (state = initialState, action: BonusActions|UserAction) : BonusStoreState {
    switch (action.type) {
        case BonusActionTypes.BONUS_OFFERS_REQUEST:
            return {...state, offerState : RequestState.IN_PROGRESS, offers : []};
        case BonusActionTypes.BONUS_OFFERS_RESPONSE:
            return {...state, offers: action.offers, offerState: RequestState.OK};
        case BonusActionTypes.BONUS_OFFERS_ERROR:
            return {...state, offerState: RequestState.ERROR, offers: []};
        case BonusActionTypes.BONUS_NEXT_REQUEST:
            return {...state, nextBonusesState : RequestState.IN_PROGRESS, offers : []};
        case BonusActionTypes.BONUS_NEXT_RESPONSE:
            return {...state, nextBonuses: action.bonuses, nextBonusesState: RequestState.OK};
        case BonusActionTypes.BONUS_NEXT_ERROR:
            return {...state, nextBonusesState: RequestState.ERROR, offers: []};
        case BonusActionTypes.ACTIVE_BONUSES_REQUEST:
            return {...state, activeBonusesState : RequestState.IN_PROGRESS, activeBonuses : []};
        case BonusActionTypes.ACTIVE_BONUSES_RESPONSE:
            return {...state, activeBonuses: action.activeBonuses, activeBonusesState: RequestState.OK};
        case BonusActionTypes.ACTIVE_BONUSES_ERROR:
            return {...state, activeBonusesState: RequestState.ERROR, activeBonuses: []};
        case BonusActionTypes.CLAIM_BONUS_REQUEST:
            return {...state, claimOfferStatus : RequestState.IN_PROGRESS};
        case BonusActionTypes.CLAIM_BONUS_RESPONSE:
            return {...state,  claimOfferStatus: RequestState.OK, activeBonusesState : RequestState.OK, activeBonuses : [action.userBonus], offers  : []};
        case BonusActionTypes.CLAIM_BONUS_ERROR:
            return {...state, claimOfferStatus: RequestState.ERROR };
        case UserActionTypes.LOGOUT:
            return {...initialState};
        default:
            return state;
    }
}