import React from "react";
import {Settings, UserInfo} from "../http/protocol";
import {AppState} from "../RootReducer";
import {Dispatch} from "redux";
import {fetchUserInfo} from "../user/UserActions";
import {connect} from "react-redux";
// @ts-ignore
import FreshChat from 'react-freshchat';

type Props = PropsFromState & PropsFromDispatch;

export class FreshChatWidget extends React.Component<Props,{}>{
    constructor(props:Props) {
        super(props);
    }

    componentDidUpdate(prevProps : Props, prevState : {}) {
        let prevLoggedIn = prevProps.user && prevProps.user.loggedIn;
        let loggedIn = this.props.user && this.props.user.loggedIn;
        if(prevProps != loggedIn) {
            this.initFreshChat();
        }
    }

    initFreshChat(id?:number) {
        let token = this.props.settings && this.props.settings.freshChatWidgetToken;
        let host = "https://wchat.freshchat.com";
        let email = this.props.email;
        const firstName = this.props.attributes && this.props.attributes["firstName"];
        const lastName = this.props.attributes && this.props.attributes["lastName"];
        if(this.props.settings && this.props.settings.freshChatHost && this.props.settings.freshChatHost.trim().length > 0) {
            host =  this.props.settings.freshChatHost;
        }
        if(token && this.props.user) {
            return (
                <FreshChat
                    token={token}
                    host={host}
                    onInit={(widget:any)=>{
                    }}
                />
            )
        } 
    }

    render() {
        return(
            <div>
                {this.props.user && this.props.settings && this.props.settings.freshChatEnabled && this.initFreshChat(this.props.user.userId)}
            </div>
        )
    }

}




interface PropsFromState {
    user?: UserInfo;
    userId?: number;
    settings?: Settings;
    email?: string;
    attributes?:{ [index: string]: string };
}

interface PropsFromDispatch {
    fetchUserDetails? : () => void;
}

let stateToProps = ({user, settings} : AppState) => {
    return {
        user : user.user,
        userId : user.user && user.user.userId,
        email : user.user && user.user.email,
        settings : settings.settings,
        attributes : user.user && user.user.attributes,
        sessionKey: user.user && user.user.sessionKey,
    }
};

let dispatchToProps = (dispatch : Dispatch) => ({
    fetchUserInfo: (sessionKey:UserInfo)=>dispatch(fetchUserInfo(sessionKey) as any),
});

export default connect(stateToProps, dispatchToProps)(FreshChatWidget);