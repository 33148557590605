import {Dispatch} from "redux";
import {getContainer} from "../ioc/IOCSetup";
import {BundleServicee} from "./BundleService";
import {ListBundlesResponse, PaymentDialog} from "../http/protocol";


export enum StoreActionTypes {
    FETCH_BUNDLE_REQUEST = "@store/fetchbundle/request",
    FETCH_BUNDLE_SUCCESS = "@store/fetchbundle/response",
    FETCH_BUNDLE_ERROR = "@store/fetchbundle/error",
    BUY_BUNDLE_REQUEST = "@store/buyBundle/request",
    BUY_BUNDLE_RESPONSE = "@store/buyBundle/response",
    BUY_BUNDLE_ERROR = "@store/buyBundle/error",
    SELECT_BUNDLE = "@store/selectBundle/select",
    FETCH_BUNDLE_BONUS_REQUEST = "@store/fetchbundlebonus/request",
    FETCH_BUNDLE_BONUS_SUCCESS = "@store/fetchbundlebonus/response",
    FETCH_BUNDLE_BONUS_ERROR = "@store/fetchbundlebonus/error",
    CLAIM_BONUS_BUNDLE = "@store/bonusbundle/claim",
}

export type StoreActions =
    | { type : StoreActionTypes.FETCH_BUNDLE_REQUEST }
    | { type : StoreActionTypes.FETCH_BUNDLE_SUCCESS, bundles: ListBundlesResponse }
    | { type : StoreActionTypes.FETCH_BUNDLE_ERROR, error: Error }
    | { type : StoreActionTypes.BUY_BUNDLE_REQUEST }
    | { type : StoreActionTypes.BUY_BUNDLE_RESPONSE, paymentDialog: PaymentDialog }
    | { type : StoreActionTypes.BUY_BUNDLE_ERROR, error: Error }
    | { type : StoreActionTypes.SELECT_BUNDLE, id: number }
    | { type : StoreActionTypes.FETCH_BUNDLE_BONUS_REQUEST }
    | { type : StoreActionTypes.FETCH_BUNDLE_BONUS_SUCCESS, bundles: ListBundlesResponse }
    | { type : StoreActionTypes.FETCH_BUNDLE_BONUS_ERROR, error: Error }
    | { type : StoreActionTypes.CLAIM_BONUS_BUNDLE, id: number };


export const fetchStoreBundles = () => {
    return async (dispatch : Dispatch) => {
        dispatch({ type : StoreActionTypes.FETCH_BUNDLE_REQUEST})
        try {
            let bundleService : BundleServicee = getContainer().getBundleService();
            let bundles = await bundleService.getAllActiveBundles();
            dispatch({ type : StoreActionTypes.FETCH_BUNDLE_SUCCESS, bundles : bundles });
        } catch (e) {
            dispatch({ type : StoreActionTypes.FETCH_BUNDLE_ERROR, error : e})
        }
    }
}

export const selectBundle = (id? : number) => {
    return { type : StoreActionTypes.SELECT_BUNDLE, id : id};
}

export const fetchStoreBonusBundles = () => {
    return async (dispatch : Dispatch) => {
        dispatch({ type : StoreActionTypes.FETCH_BUNDLE_BONUS_REQUEST})
        try {
            let bundleService : BundleServicee = getContainer().getBundleService();
            let bundles = await bundleService.getAvailableBonusBundlesForUser();
            dispatch({ type : StoreActionTypes.FETCH_BUNDLE_BONUS_SUCCESS, bundles : bundles });
        } catch (e) {
            dispatch({ type : StoreActionTypes.FETCH_BUNDLE_BONUS_ERROR, error : e})
        }
    }
}

export const claimBonusBundle = (id : number) => {
    return async (dispatch : Dispatch) => {
        try {
            console.log("claimBonusBundle request ")
            let bundleService : BundleServicee = getContainer().getBundleService();
            let bundle = await bundleService.claimBonusBundlesForUser(id);
            dispatch(fetchStoreBonusBundles() as any); // maybe
        } catch (e) {
            console.log("claimBonusBundle Did I get here ")
        }
    }
}