

//in witdth and height include the type, ex: px, rem, vb etc..
import React from "react";

export function progressBar(progress:number, width:string, height:string) {

    return(
        <div className="progress progress-bar-color" style={{width:width, height:height, marginTop:"10rem", zIndex:250, position:"fixed"}}>
            <div className="progress-bar progress-color" role="progressbar" style={{width:`${progress}%`, textAlign:"center"}} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}>
            </div>
        </div>
    )

}