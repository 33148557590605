import { Dispatch } from "redux";
import { getContainer } from "../ioc/IOCSetup";
import {
    CustomerInfo,
    DepositResult,
    PaymentDialog,
    PaymentMethod,
    PaymentType,
    PlayerOrder,
    Settings,
    TransferLimit,
    ProcessDepResult,
    PrepareDepositResponse,
} from "../http/protocol";
import { PaymentMehtodsByCurrency } from "../http/Types";
import { AppState } from "../RootReducer";
import { DepositPhase } from "./PaymentsReducer";
import { fetchBalance, UserActionTypes } from "../user/UserActions";
import ReactGA from "react-ga4";
export enum DepositActionType {
    SHOW_DEPOSIT = "@payments/deposit/show",
    SHOW_CASHIER = "@payments/cashier/show",
    SELECT_CURRENCY = "@payments/deposit/selectCurrency",
    INIT_DEPOSIT_REQUEST = "@payments/deposit/init/request",
    INIT_DEPOSIT_RESPONSE = "@payments/deposit/init/response",
    INIT_DEPOSIT_RESULT = "@payments/deposit/init/result",
    INIT_DEPOSIT_ERROR = "@payments/deposit/init/request",
    PROVIDERS_REQUEST = "@payments/providers/request",
    PROVIDERS_RESPONSE = "@payments/providers/response",
    PROVIDERS_ERROR = "@payments/providers/error",
    SELECT_METHOD = "@payments/providers/select",
    UPDATE_AMOUNT = "@payments/deposit/amount/update",
    SHOW_POSSIBLE_DEPOSIT_ERRORS = "@payments/deposit/show/errors",
    RESET = "@payments/deposit/reset",
    SET_USE_AS_STORE = "@payments/useasstore/set",
    SET_SENDING_PAYMENT = "@payments/sendingPayment/set",
    SET_SENDING_PAYMENT_FAILED = "@payments/sendingPayment/failed",
    UPDATE_PHASE = "@payments/deposit/phase/update",

}
export type DepositActions =
    | { type: DepositActionType.SHOW_DEPOSIT, show: boolean }
    | { type: DepositActionType.SHOW_CASHIER, show: boolean }
    | { type: DepositActionType.INIT_DEPOSIT_REQUEST, request: { provider: string, order: PlayerOrder, amount?: string, activeCurrency?: string } }

    | { type: DepositActionType.INIT_DEPOSIT_RESPONSE, continueUrl: string, message?: string }
    | { type: DepositActionType.INIT_DEPOSIT_RESULT, depositResult: DepositResult }
    | { type: DepositActionType.INIT_DEPOSIT_RESULT, depositResult: ProcessDepResult }
    | { type: DepositActionType.INIT_DEPOSIT_ERROR, error: Error }
    | { type: DepositActionType.PROVIDERS_REQUEST }
    | { type: DepositActionType.PROVIDERS_RESPONSE, methods: PaymentMehtodsByCurrency }
    | { type: DepositActionType.PROVIDERS_ERROR, error: Error }
    | { type: DepositActionType.SELECT_METHOD, method: PaymentMethod, phase?: DepositPhase }
    | { type: DepositActionType.SELECT_CURRENCY, currency: string }
    | { type: DepositActionType.UPDATE_AMOUNT, amount: string, limit?: TransferLimit }
    | { type: DepositActionType.RESET, amount: string }
    | { type: DepositActionType.SET_USE_AS_STORE, useAsStore: boolean }
    | { type: DepositActionType.SET_SENDING_PAYMENT, sendingPayment: boolean }
    | { type: DepositActionType.SET_SENDING_PAYMENT_FAILED, sendingPaymentFailed: boolean }
    | { type: DepositActionType.UPDATE_PHASE, phase: DepositPhase }
    | { type: DepositActionType.SHOW_POSSIBLE_DEPOSIT_ERRORS };

export const updateDepositPhase = (phase: DepositPhase) => {
    return { type: DepositActionType.UPDATE_PHASE, phase: phase }
}

export const updateDepositAmount = (amount: string, limit?: TransferLimit) => {
    return { type: DepositActionType.UPDATE_AMOUNT, amount: amount, limit: limit };
}
export const showPossibleDepositErrors = () => {
    return { type: DepositActionType.SHOW_POSSIBLE_DEPOSIT_ERRORS };
}
export const selectPaymentMethod = (m: PaymentMethod, p?: DepositPhase) => {
    return { type: DepositActionType.SELECT_METHOD, method: m, phase: p };
}
export const showDeposit = (show: boolean): DepositActions => (
    { type: DepositActionType.SHOW_DEPOSIT, show: show }
)
export const showCashier = (show: boolean): DepositActions => (
    { type: DepositActionType.SHOW_CASHIER, show: show }
)
export const selectCurrency = (currency: string): DepositActions => {
    return { type: DepositActionType.SELECT_CURRENCY, currency: currency };
}
export const reset = (): DepositActions => {
    return { type: DepositActionType.RESET, amount: "" };
}
export const setUseAsStore = (useAsStore: boolean): DepositActions => {
    return { type: DepositActionType.SET_USE_AS_STORE, useAsStore: useAsStore };
}

export const showDepositResult = (depositResult: DepositResult): DepositActions => {
    return { type: DepositActionType.INIT_DEPOSIT_RESULT, depositResult: depositResult };
}

export const isSendingPayment = (sendingPayment: boolean): DepositActions => {
    return { type: DepositActionType.SET_SENDING_PAYMENT, sendingPayment: sendingPayment };
};

export const isSendingPaymentFailed = (sendingPaymentFailed: boolean): DepositActions => {
    return { type: DepositActionType.SET_SENDING_PAYMENT_FAILED, sendingPaymentFailed };
};

export function fetchProviders() {
    return (dispatch: Dispatch, getState: () => AppState) => {
        if (getState().payments.deposit.methods) {
            dispatch({ type: DepositActionType.RESET });
        } else {
            dispatch({ type: DepositActionType.PROVIDERS_REQUEST });
            getContainer().getPaymentsService().getDepositMethods().then(r => {
                dispatch({ type: DepositActionType.PROVIDERS_RESPONSE, methods: r.depositMethods })
            }).catch(err => {
                dispatch({ type: DepositActionType.PROVIDERS_ERROR, error: err });
            })
        }
    }
}

export function processDeposit(redirect = true) {
    return (dispatch: Dispatch, getState: () => AppState) => {
        let deposit = getState().payments.deposit;
        let conversionId = null as any;
        let depositParams = {};
        dispatch({ type: DepositActionType.INIT_DEPOSIT_REQUEST });

        if (deposit.selectedDepositMethod && deposit.selectedDepositMethod.conversionRequired) {
            getContainer().getPaymentsService().prepareDeposit(deposit.selectedDepositMethod.code!!)
                .then((r: PrepareDepositResponse) => {
                    depositParams = {
                        paymentMethod: deposit.selectedDepositMethod.code,
                        amount: deposit.amount,
                        conversionId: r.conversionId
                    }
                })
                .then(() => {
                    getContainer().getPaymentsService().processDeposit(depositParams)
                        .then((r: ProcessDepResult) => {
                   
                            if (r.continueUrl !== undefined) {
                                console.error(r.continueUrl);
                                // const url = "https://ps.adminemx.com/ps" + r.continueUrl.replace("/ps", "") + "&sessionKey=" + localStorage.getItem('sessionKey');
                                const url = r.continueUrl;
                                ReactGA.event({
                                    category: "User",
                                    action: "deposit",
                                });
                                if (url != null) {
                                    console.log("continue url", url)
                                    getState().payments.deposit.continueUrl = url;
                                    dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, continueUrl: url });
                                }
                            } else if (r.status === 'FAIL') {
                                dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, message: r.message });;
                            } else if (r.status === 'VALIDATION_FAIL') {
                                dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, message: r.status });;
                            }
                        }).catch(err => {
                            dispatch({ type: DepositActionType.INIT_DEPOSIT_ERROR, error: err });
                        })
                });

        } else if (!deposit.selectedDepositMethod.conversionRequired) {
            depositParams = {
                paymentMethod: deposit.selectedDepositMethod.code,
                amount: deposit.amount
            }

            getContainer().getPaymentsService().processDeposit(depositParams)
                .then((r: ProcessDepResult) => {

                    if (r.continueUrl !== undefined) {
                        const url = r.continueUrl;
                        const id = r.continueId;
                        if (url != null) {
                            console.log("continue url", url)
                            console.log("continue id", id)
                            getState().payments.deposit.continueUrl = url;
                            getState().payments.deposit.continueId = id as string;
                            dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, continueUrl: url });
                            ReactGA.event({
                                category: "User",
                                action: "deposit",
                            });
                             //@ts-ignore
                            if (redirect && depositParams && depositParams?.paymentMethod  !== "CASHIP_PIX") {
                                let windowReference =  window.open("about:blank","_blank");

                                if( windowReference) {
                                    windowReference.location.href = url;
                                };
                            }
                        
                        }
                    } else if (r.status === 'FAIL') {
                        dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, message: r.message });;
                    } else if (r.status === 'VALIDATION_FAIL') {
                        dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, message: r.status });;
                    }
                }).catch(err => {
                    dispatch({ type: DepositActionType.INIT_DEPOSIT_ERROR, error: err });
                })
        }
        console.log('action deposit', getState())
        return getState();
    }
}

export function initDeposit() {
    return (dispatch: Dispatch, getState: () => AppState) => {
        let deposit = getState().payments.deposit;
        if (deposit.activeMethod && deposit.activeMethod.provider) {
            let provider = deposit.activeMethod.provider;
            let amount = deposit.amount;
            let activeCurrency = deposit.activeCurrency;
            let order: PlayerOrder = {
                type: PaymentType.DEPOSIT,
                currencyCode: activeCurrency,
                method: deposit.activeMethod.code
            }
            ReactGA.event({
                category: "User",
                action: "view_deposits",
            });
            if (deposit.activeMethod.amountRequired && amount) {
                order = { ...order, amount: parseFloat(amount) }
            } else {
                order = { ...order, amount: 0 }
            }
            dispatch({ type: DepositActionType.INIT_DEPOSIT_REQUEST, request: { provider: provider, order: provider, amount: deposit.amount, activeCurrency: deposit.activeCurrency } });

            getContainer().getPaymentsService().getPaymentDialog(deposit.activeMethod.provider, order).then((d: PaymentDialog) => {
                fetchBalance();
                dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, dialog: d });
            }).catch(err => {
                dispatch({ type: DepositActionType.INIT_DEPOSIT_ERROR, error: err })
            });
        }
    }
}

export function initStorePurchase(bundleId?: number) {
    return (dispatch: Dispatch, getState: () => AppState) => {
        let deposit = getState().payments.deposit;
        if (deposit.activeMethod && deposit.activeMethod.provider && bundleId) {
            let provider = deposit.activeMethod.provider;
            let method = deposit.activeMethod.code;
            dispatch({ type: DepositActionType.INIT_DEPOSIT_REQUEST, request: { provider: provider, order: provider } });

            getContainer().getBundleService().getPaymentDialog(bundleId, { provider: provider, method: method }).then((d: PaymentDialog) => {
                dispatch({ type: DepositActionType.INIT_DEPOSIT_RESPONSE, dialog: d });
            }).catch(err => {
                dispatch({ type: DepositActionType.INIT_DEPOSIT_ERROR, error: err })
            });
        }
    }
}
