import * as React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import {ContentModelName, CompleteRegistrationPayload} from "../../contenful/types";
import { asyncValidate, isPasswordValid, isValid, matches } from "./utils";
import { EMAIL_REGEXP, renderCheckbox, renderField,renderField2, RenderUserAttributesField } from "./form";
import { RegistrationRequest, Settings } from "../../http/protocol";
import { t } from "../../i18n";
import { Dispatch } from "redux";
import { completeRegistration } from "../UserActions";
import { ButtonSpinner } from "../../ui/ButtonSpinner";
import { AppState } from "../../RootReducer";
import { connect } from "react-redux";
import { OnboardingPagePayload } from "../../contenful/types";
import "./styles/RegisterForm.scss"
import { Redirect } from "react-router";
import { useState } from "react";
import {COUNTRIES} from "./Countries";
import { AnyPtrRecord } from "dns";
import { getContainer } from '../../ioc/IOCSetup';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
let dialCode = "";
const requiredText = (key: string): string | any => {
    return `Required ${key}`
}
const illegalChars = (key: string): string | any => {
    return t("forms.illegal", { label: t(key) });
}



export const validateCompleteRegisterForm = (values: RegistrationRequest & { terms: boolean }) => {
    let error: any = {};
    let internal: any = Object.assign({}, values);
    let password = internal.password;

    let attributes = values.attributes;
    console.log("Attributes from validateRegisterForm; ", attributes)
	let phoneRegex = /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    internal.pwd = password;
    let passwordNotIsValid = !phoneRegex.test(internal.mNumber);
    // console.error(phoneRegex.test(internal.mNumber),internal.mNumber);
    if(passwordNotIsValid) {
        error.number = "Mobile Number is Invalid";
    }
    if (!isValid(values.email, 4, 200)) {
        error.email = requiredText("email");
    }
    if (!matches(values.email, EMAIL_REGEXP)) {
        //  error.email = t("forms.email.invalid");
        error.email = "Invalid Email";
    }
    if (!isValid(internal.usr, 8, 50)) {
        error.username = requiredText("username");
    }
    if (!isPasswordValid(internal.pwd)) {
        //   error.password = t("forms.password.length");
        error.password = "password is too short";
    }
    // if(!values.receiveEmail) {
    //    // error.terms = t("forms.terms.invalid");
    //    error.receiveEmail = "check the terms and conditon";
    // }
    return error;
}

interface Props extends InjectedFormProps {
    registration?: OnboardingPagePayload;
    settings: Settings;
    position?: string;
    userAttributesKey?: string;
    userAttributesHelp?: string;
    regex?: string;
    loggedIn?: boolean;
    registrationResponse: any;
    isNewUser:boolean;
    completeRegisterContent?: CompleteRegistrationPayload;
}

interface State {
  
    dial_code:any;
}
let bday = new Date();
export const CompleteRegisterForm = (props: Props) => {
    const [submittingState, setSubmittingState] = useState(false);
    const [value, onChange] = useState(new Date());
     bday = value;
   
    const { handleSubmit, pristine, reset, submitting, submitFailed, asyncValidating, error, valid, position,
        userAttributesKey, userAttributesHelp, settings } = props
        const [dial_code, set_code] = useState("");
    let submitInProgress = submitting;
    let country = getContainer().getSettingsService().getCountryData();
    const [mobileNumber, getMobileNumber] = useState('');
    let phoneRegex = /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    
    let passwordNotIsValid = !phoneRegex.test(mobileNumber);
    const containerClass = position ? position : ''
    const buttonClassList = position === 'landing' ? 'btn-thin' : ''
    const requiredUserAttributesField = () => {
        if (settings && settings.requiredUserAttributes && settings.requiredUserAttributes.length > 0) {
            return settings.requiredUserAttributes.map((item) => {
                const fieldName = item.nameTranslationKey ? item.nameTranslationKey : null;
                const withDropdown = item.options != '' || undefined ? true : false;
                let options;
                if (withDropdown) {
                    options = item.options && item.options.split(',');
                }
                return <Field key={item.id}
                    props={{
                        label: fieldName, position: position, autoCorrect: "off",
                        userAttributesKey: item.nameTranslationKey, userAttributesHelp: item.descriptionTranslationKey,
                        regex: item.regex
                    }}
                    value=""
                    name={item.attributeKey}
                    pristine={true}
                    maxLength={item.maxLength && item.maxLength}
                    minLength={item.minLength && item.minLength}
                    withDropdown={withDropdown}
                    optionsDropdown={options && options}
                    component={RenderUserAttributesField}
                    required={item.required}
                />

            })
        }
    }
    if(props.submitSucceeded) {
           
        return <Redirect to={"/signup/complete"} />
    }

    const isRequiredUserAttributes: boolean = settings && settings.requiredUserAttributes && settings.requiredUserAttributes.length > 0 || false;
    const customHeader: string = props.registration && props.registration.registerCustomAttributesHeader || "";
    let userArributesHeader: string = "";
    if (customHeader) {
        userArributesHeader = customHeader;
    }
    if (submitInProgress && !submittingState) {
        setSubmittingState(true);
    }
    if (submitFailed && submittingState && !submitInProgress) {
        setSubmittingState(false);

    }
    // if(settings === undefined) {
    //     return <div></div>
    // }
  
    //    console.error(dial_code);

       dialCode = dial_code;
    return (
        <form id="registerForm" className="form register-form d-flex flex-row flex-wrap" onSubmit={props.handleSubmit} autoComplete="off">
            {/* {error ? <div className="alert alert-danger">{error}</div> : false} */}
            {/* {props.registrationResponse && props.registrationResponse.status ? <div className="alert alert-danger">{props.registrationResponse.status}</div> : false} */}


   



            <div className="col-md-6">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.firstName}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'First Name' }) ? <div className="alert alert-danger">{t("completeRegisterForm.userInvalid")}</div> : false}
                <Field props={{ label: "First Name", position: position, nameOverride: "First Name" }}
                    name="fname"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    component={renderField} type="text" />
            </div>


            <div className="col-md-6">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.lastName}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("completeRegisterForm.userInvalid")}</div> : false}
                <Field props={{ label: "Last Name", position: position, nameOverride: "Last Name" }}
                    name="lname"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    component={renderField} type="text" />
            </div>


            <div className="col-md-6">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.gender}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("completeRegisterForm.userInvalid")}</div> : false}
                <Field props={{ label: "Gender", position: position, nameOverride: "Gender" }}
                    name="gndr"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    withDropdown={true}
                    optionsDropdown={["Male", "Female"]}
                    component={RenderUserAttributesField} type="text" />
            </div>

            <div className="col-md-6">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.birthDate}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'birthDate' }) ? <div className="alert alert-danger">{t("completeRegisterForm.belowMinAge")}</div> : false}
                {/* <Field props={{ label: "Birth Date", position: position, nameOverride: "Birth Date" }}
                    name="bdate"
                    value={bday}
                    hideHelp={true}
                    pristine={true}
                    component={DatePicker}  type="date" /> */}
                          <DatePicker  name="bdate" onChange={onChange} required={true}  className="form-control"  value={value} />
            </div>



            <div className="col-md-8">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.address}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("completeRegisterForm.userInvalid")}</div> : false}
                <Field props={{ label: "Address", position: position, nameOverride: "Address" }}
                    name="adrs"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    component={renderField} type="text" />
            </div>

            <div className="col-md-4">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.city}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Last Name' }) ? <div className="alert alert-danger">{t("completeRegisterForm.userInvalid")}</div> : false}
                <Field props={{ label: "City", position: position, nameOverride: "City" }}
                    name="cty"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    component={renderField} type="text" />
            </div>


            <div className="col-md-4">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.country}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Country' }) ? <div className="alert alert-danger">{t("completeRegisterForm.country")}</div> : false}
                {/* <Field props={{ label: "Country", position: position, nameOverride: "Country" }}
                    name="cntry"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    withDropdown={true}
                    optionsDropdown={[COUNTRIES.map( c => <option key={c.code} value={c.name}>{c.name}</option>)]}
                    
                    component={RenderUserAttributesField} type="text" /> */}

            <Field   onChange={(e:any) => {getMobileNumber("selectNumber"); set_code(e.target.selectedOptions[0].getAttribute('data-dial-code'))}} onLoad={(e:any) => set_code(e.target.selectedOptions[0].getAttribute('data-dial-code')) } props={{ label: "Country", position: position, }}
       
                           name="cntry"
                           component={"select"} type="text"
                           required={true}
                           className={"form-control"} >
                       <option  ></option>
                     
                        {
                              //@ts-ignore
                        country && country.map( c => <option key={c.code} value={c.code} label={c.name } data-dial-code={c.dial_code}  >{c.name}</option>)}
                  
                    </Field>
            </div>

            <div className="col-md-4">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.postalCode}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Postal Code' }) ? <div className="alert alert-danger">{t("completeRegisterForm.postalInvalid")}</div> : false}
                <Field props={{ label: "Postal Code", position: position, nameOverride: "Postal Code" }}
                    name="pstl"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    component={renderField} type="number" />
            </div>

            <div className="col-md-4">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.mobileNumber}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Mobile Number' }) ? <div className="alert alert-danger">{t("completeRegisterForm.mobileInvalid")}</div> : false} 
                
                { mobileNumber && mobileNumber.length < 8   ? (<div className="alert alert-danger">{"Mobile Number is invalid"}</div>):null}
                {	error? <div className="alert alert-danger">{"Mobile Number is invalid"}</div> : false}
               
                <div className="input-group">

                <Field props={{ label: "Mobile Number", dial_code:dial_code, position: position, nameOverride: "Mobile Number" }}
                    name="mNumber"
                    onChange={(e: any) => getMobileNumber(e.target.value)}
                    value={""}
                    hideHelp={true}
                    pristine={true}
                    pattern="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im"
                    component={renderField2} type="number" required/>
                </div>
                {/* <Field props={{ label: "Mobile Number", position: position, nameOverride: "Mobile Number" }}
                    name="mNumber"
                    value={""}
                    hideHelp={true}
                    pristine={true}
                    component={renderField} type="number" /> */}
            </div>

            <div className="col-md-6">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.securityQuestion}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Security Question' }) ? <div className="alert alert-danger">{t("completeRegisterForm.securityInvalid")}</div> : false}
                <Field props={{ label: "Security Question", position: position, nameOverride: "Security Question" }}
                    name="sq"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    withDropdown={true}
                    optionsDropdown={["What was your childhood nickname?",
                        "In what city did you meet your spouse/significant other?",
                        "What is the name of your favorite childhood friend?",
                        "What street did you live on in third grade?",
                        "What is your oldest sibling’s birthday month and year? (e.g., January 1900)",
                        "What is the middle name of your youngest child?",
                        "What is your oldest sibling's middle name?",
                        "What school did you attend for sixth grade?",
                        "What was your childhood phone number including area code? (e.g., 000-000-0000)",
                        "What is your oldest cousin's first and last name?",
                        "What was the name of your first stuffed animal?",
                        "In what city or town did your mother and father meet?",
                        "Where were you when you had your first kiss?",
                        "What is the first name of the boy or girl that you first kissed?",
                        "What was the last name of your third grade teacher?",
                        "In what city does your nearest sibling live?",
                        "What is your youngest brother’s birthday month and year? (e.g., January 1900)",
                        "What is your maternal grandmother's maiden name?",
                        "In what city or town was your first job?",
                        "What is the name of the place your wedding reception was held?",
                        "What is the name of a college you applied to but didn't attend?",
                        "Where were you when you first heard about 9/11?"]}
                    component={RenderUserAttributesField} type="text" />
            </div>

            <div className="col-md-6">
                <div className="formHeaderSection">{props.completeRegisterContent && props.completeRegisterContent.answer}</div>
                {props.registrationResponse && props.registrationResponse.errors && props.registrationResponse.errors.find((x: any) => { return x.field === 'Security Question' }) ? <div className="alert alert-danger">Security Question is invalid</div> : false}
                <Field props={{ label: "Answer", position: position, nameOverride: "Answer" }}
                    name="ans"
                    value=""
                    hideHelp={true}
                    pristine={true}
                    component={renderField} type="text" />
            </div>



            <div className="col-md-6">
                </div>


            <div className="col-md-6">
                {(customHeader || customHeader != undefined) && isRequiredUserAttributes ? <div className="formSpace" /> : null}
                <div className={`buttonContainer ${containerClass}`}>
                    {submittingState ? <button className={`btn btn-cta btn-register btn-lg height ${buttonClassList}`}><ButtonSpinner /></button> : false}
                    {!submittingState ? <button
                        className={`btn btn-cta btn-register btn-lg height ${buttonClassList}`}
                        type="submit"
                        onMouseDown={event => event.preventDefault()}>{props.completeRegisterContent && props.completeRegisterContent.submit}</button> : false}
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = ({ settings, user, contentful }: AppState) => {
    return {
        settings: settings && settings.settings,
        isNewUser:user.user && user.user.newUser,
        loggedIn: user.user && user.user.loggedIn,
        registrationResponse: user && user.registration,
        completeRegisterContent: contentful.completeRegistration
    }
}

function formatDate(date: Date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    // console.error(date,[year, month, day].join('-'));
    return [year, month, day].join('-');
}
const form = reduxForm({
    form: 'CompleteRegisterForm',
    validate: validateCompleteRegisterForm,
    touchOnBlur: true,
    enableReinitialize: true,

    asyncBlurFields: [],
    onSubmit: (req: RegistrationRequest, dispatch: Dispatch, props: any) => {
        let internal: any = Object.assign({}, req);
        // console.error(internal);
        let firstName = internal.fname;
        let lastName = internal.lname;
        let gender = internal.gndr === "Male" ? "M" : "F";
        let formattedDate =formatDate(bday)
        let birthDate = formattedDate;
        let address = internal.adrs;
        let city = internal.cty;
        let country = internal.cntry;
        let postal = internal.pstl;
        let mobileNumber =  dialCode +  internal.mNumber;
        let securityQuestion = internal.sq;
        let securityAnswer = internal.ans;
  
        let attr = {};
        props.settings && props.settings.requiredUserAttributes && props.settings.requiredUserAttributes.map((item: any) => {
            let attrKey = item.attributeKey
            Object.defineProperty(attr, item.attributeKey, {
                value: internal[attrKey],
                writable: true,
                enumerable: true,
                configurable: true
            });
            delete internal[item.attributeKey];
        })
        internal.attributes = attr;


      
        internal.firstName = firstName;
        internal.lastName = lastName;
        internal.gender = gender;
        internal.birthDate = birthDate;
        internal.address = address;
        internal.city = city;
        internal.country = country;
        internal.postal = postal;

        internal.mobileNumber = mobileNumber;
        internal.securityQuestion = securityQuestion;
        internal.securityAnswer = securityAnswer;
        



        console.log(internal,"COMPLETE REGISTRATION");
      
        delete internal["fname"];
        delete internal["lname"];
        delete internal["gndr"];
        delete internal["bdate"];
        delete internal["adrs"];
        delete internal["cty"];
        delete internal["cntry"];
        delete internal["pstl"];
        delete internal["mNumber"];
        delete internal["sq"];
        delete internal["ans"];
        return dispatch(completeRegistration(internal, props.settings) as any)
    }
})(CompleteRegisterForm as any);

export default connect(mapStateToProps)(form);

