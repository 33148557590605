import {
  CASINO_CATEGORY_FAIL,
  CASINO_CATEGORY_LOADING, 
  CASINO_CATEGORY_SUCCESS, 
  CasinoCategoryDispatchTypes,
  CasinoCategoryType
} from "../actions/CasinoCategoryActions";

interface DefaultStateI {
  loading: boolean,
  casinoCategory?: CasinoCategoryType
}

const defaultState: DefaultStateI = {
  loading: false,
};

const casinoCategoryReducer = (state: DefaultStateI = defaultState, action: CasinoCategoryDispatchTypes) : DefaultStateI => {

  switch (action.type) {
    case CASINO_CATEGORY_FAIL:
   

      return {
        loading: false,
      }
    case CASINO_CATEGORY_LOADING:
   
      return {
        loading: true,
      }
    case CASINO_CATEGORY_SUCCESS:
     
      return {
        loading: false,
        casinoCategory: action.payload
      }
    default:
  
      return state
  }
};


export default casinoCategoryReducer