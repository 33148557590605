
import "./SportsView.scss";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import Root from "../layout/Root";
import {fetchSettings} from "../settings/SettingsActions";
import {AppState} from "../RootReducer";
import {Settings, SportsbookProvider, UserInfo} from "../http/protocol";
import {LoginStatus} from '../http/Types';
import {ContentModelName, PopupManager, SportsPagePayload,SlidersMaharajaPayload} from "../contenful/types";
import {fetchContent} from "../contenful/ContentfulActions";
import {t} from "../i18n";
import MetaHelmet from "../utils/MetaHelmet";
import Song88SportsComponent from "./providers/Song88SportsComponent";
import React, { Component } from "react";
import UltraplaySportsComponent from "./providers/UltraplaySportsComponent";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { getContainer } from "../ioc/IOCSetup";
import Banner from "../ui/Banner"
import { logout, showLogin } from "../user/UserActions";
import {Redirect, RouteComponentProps} from "react-router";
type Props = {
    settings : Settings,
    fetchSettings : typeof fetchSettings,
    loggedIn?: boolean;
    currency?: string;
    popupManager?: PopupManager;
    fetchContent : typeof fetchContent;
    lng: string;
    sportsbookVisible:boolean;
    sportsPage?: SportsPagePayload;
    isFetching?: boolean;
    slidersMaharaja?: SlidersMaharajaPayload;
    showLogin: () => void;
    optInBonusPlans?:any;
    user?: UserInfo;
};

interface State {
    sportsbookUrl?: string;
    iframeHeight?: number;
    loading:boolean;
    hideAfterWentToOtherPage: boolean;
    progress:number;
    manualProgressIncrease:boolean;
    freshChatLoaded:boolean;
    promotionbanner?:any;
}
let staticSporstBanner = {
    "banner": [
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/weekend-bonus",
            "copyline": "/assets/images/HomePromo/welcomebonus/weekend-bonus-copyline.png",
            "mainBanner": "/assets//images/HomePromo/welcomebonus/1920x1080_Weekend-Bonus.jpg",
            "bonusPlanId": "11",
            "termsAndCondition": "/promotions/weekend-bonus"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/second-welcome",
            "copyline": "/assets/images/HomePromo/welcomebonus/second-welcome-copyline.png",
            "mainBanner": "/assets//images/HomePromo/welcomebonus/1920x1080_Second-Welcome.jpg",
            "bonusPlanId": "12",
            "termsAndCondition": "/promotions/second-welcome"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/every-monday-deposit",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/moday-promo-copyline.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/1920x1080_Monday-TopUp.jpg",
            "bonusPlanId": "8",
            "termsAndCondition": "/promotions/every-monday-deposit"
        },
        {
            "isPromo": true,
            "promoType": "maharajaBoost",
            "claimNow": "/promotions/welcomebonus",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/maharaja-boost-copyline.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/1920x1080_Maharaja-Boost.jpg",
            "termsAndCondition": "/promotions/sportsbook-maharaja-boost"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/welcomebonus",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/sportsbook-copy-line.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/1920x1080_100-Welcome-Bonus-SB.jpg",
            "bonusPlanId": "4",
            "termsAndCondition": "/promotions/sportsbook-welcomebonus"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/welcomebonus",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/Copyline.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/Hero-banner-Live-Casino-Promo.jpg",
            "bonusPlanId": "3",
            "termsAndCondition": "/promotions/casino-welcomebonus"
        },
        {
            "gameId": "EVOLUTION-crazytime-CrazyTime0000001",
            "labeled": "New",
            "copyline": "https://content.adminemx.com/maharajafortune/banners/crazy-time-copyline.png",
            "gameName": "Crazy Time",
            "isMobile": false,
            "isDetails": true,
            "labeledJP": "新しい",
            "segmentId": 1,
            "gameNameJP": "アトランティスライジング",
            "productCode": "WEARECASINO",
            "staticBanner": "https://content.adminemx.com/maharajafortune/banners/1920x1080_Crazy-Time.jpg",
            "isDemoEnabled": true,
            "supportMobile": true,
            "freespinEnabled": true,
            "isFreespinEnable": true
        },
        {
            "gameId": "8721",
            "labeled": "New",
            "copyline": "https://content.adminemx.com/maharajafortune/banners/Aviator_Copyline.png",
            "gameName": "AVIATOR",
            "isMobile": false,
            "isDetails": true,
            "labeledJP": "新しい",
            "segmentId": 1,
            "gameNameJP": "アトランティスライジング",
            "productCode": "DASHUR_V2",
            "staticBanner": "https://content.adminemx.com/maharajafortune/banners/1920x1080_Aviator.jpg",
            "isDemoEnabled": true,
            "supportMobile": true,
            "freespinEnabled": true,
            "isFreespinEnable": true
        }
    ]
}
export class SportsView extends Component<Props, State> {
    listener: any;
    constructor(props : Props){
        super(props);
        this.state = {iframeHeight : 1800, loading:true, hideAfterWentToOtherPage:false, progress: 0, manualProgressIncrease:true, freshChatLoaded: false };
    }
    componentDidMount(): void {
        let promotions = getContainer().getSettingsService().getPromotions();
        let sportsbook = promotions &&  promotions.filter(function( obj:any ) {
            return obj.placement.includes("SPORTSBOOK") && obj.isEnabled;
          })
          this.setState({promotionbanner: sportsbook});

        let current_progress:number = 0;
        let step:number = 0.1;
        let progressInterval = setInterval(() => {
            if(this.state.manualProgressIncrease) {
                current_progress += step;
                let progress:number = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 98 * 1000) / 1000;
                if(this.state.progress > 35) {
                    step = 0.4
                }
                this.setState({progress:progress})
            }
        }, 200);

        if (!this.props.isFetching) {

        
            if (this.props.slidersMaharaja === undefined) {
                this.props.fetchContent(ContentModelName.SLIDERSMAHARAJA, getContainer().getSettingsService().getActiveLocale().code);
            }
        }

        if(this.props.loggedIn == false) {
        }

        if (!this.props.currency) {
            return;
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        let fcWidget = window.fcWidget;
        if(fcWidget && fcWidget.isLoaded() && !this.state.freshChatLoaded) {
            if(fcWidget) {
                const direction = this.props.sportsbookVisible ? 'ltr' : 'rtl';
                if(this.props.settings.sportsbookProvider === SportsbookProvider.SONG88) {
                    fcWidget.setConfig({headerProperty:{direction:direction}})
                }
            }
            this.setState({freshChatLoaded:true})
        }
        if(prevProps.sportsbookVisible != this.props.sportsbookVisible) {
            if(fcWidget) {
                const direction = this.props.sportsbookVisible ? 'ltr' : 'rtl';
                if(this.props.settings.sportsbookProvider === SportsbookProvider.SONG88) {
                    fcWidget.setConfig({headerProperty:{direction:direction}})
                }
            }
        }
    }


    render() {
        const title:string = t("meta.sportsView.title") || "";
        const subtitle:string = t("meta.sportsView.subtitle") || "";
        const fullTitle:string = t("meta.sportsView.fullTitle") || "";
        const description:string = t("meta.sportsView.description") || "";
        const keywords:string = t("meta.sportsView.keywords") || "";
        const loadSportsbook:boolean = localStorage.getItem("loadSportsbook") === "true";
        
        if(this.props.loggedIn && this.props.user && this.props.user.registrationStatus === "QUICK_REG") {
            return <Redirect to={"/signup/full"}/>
        }
        return (
               <Root footer={true} header={true}>
                 {MetaHelmet(title, description, keywords)}
                 <section id="home" className="iq-main-slider p-0">
                    <div id="home-slider" className="slider m-0 p-0">
                      
                        {/* {this.props.slidersMaharaja ? <Banner optInBonusPlans={this.props.optInBonusPlans} data={this.props.slidersMaharaja.sportsbookSlider} loggedIn={this.props.loggedIn} showLogin={() => this.props.showLogin()} lng={this.props.lng} /> : null} */}
                        {this.state.promotionbanner ? <Banner data={this.state.promotionbanner} optInBonusPlans={this.props.optInBonusPlans} loggedIn={this.props.loggedIn} showLogin={()=>this.props.showLogin()}  user={this.props.user} lng={this.props.lng} /> : null}
                    </div>
                </section>
            <div >
             
        <Song88SportsComponent lng={this.props.lng}/> 
            </div>
            </Root>
        )
    }
}

const stateToProps = (app : AppState) => {
    return {
        settings: app.settings.settings ? app.settings.settings : {},
        loggedIn : app.user.status == LoginStatus.LOGGED_IN,
        currency: app.user.activeCurrency,
        slidersMaharaja: app.contentful.slidersMaharaja,
        sportsbookVisible: app.navigation.sportsbookVisible,
        user:app.user.user,
        optInBonusPlans:app.user.optInBonusPlans, 

    }
};

const dispatchToProps = (dispatch : Dispatch) => (
    {
        showLogin: () => dispatch(showLogin(true)),
        fetchSettings : () => dispatch(fetchSettings() as any),
        fetchContent : (contentModelName : ContentModelName) => dispatch(fetchContent(contentModelName) as any),

    }
);



export default connect(stateToProps, dispatchToProps)(SportsView as any);