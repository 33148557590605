import React from "react";
import "./AccountVerificationModal.scss";
import { KycStatus, Status, UserInfo } from "../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../RootReducer";
import { Redirect } from "react-router";
import Root from "../layout/Root";
import MetaHelmet from "../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { Dispatch } from "redux";
import { getContainer } from "../ioc/IOCSetup";
import { fetchContent } from "../contenful/ContentfulActions";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Modal from "react-bootstrap/Modal"
import { showBonusModal, showSignUp, getActiveBonuses, showAccountVerificationModal } from "../user/UserActions";
import { hasClass } from "../utils/Helpers";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import DocumentUploadPage from "./DocumentUploadPage";

import { t } from "../i18n";
import DocumentUploadPageJoiabet from "./DocumentUploadPageJoiabet";

type Props = {
    status?: Status;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    activeLng?: string;
    show?: boolean,
    hide?: () => void;
    getActiveBonuses: () => void;
    loggedIn?: boolean
    madeDeposit?: boolean;
    isNewUser?: boolean;
    bonuses?: any;
    kycStatus?:string;
};
var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

interface State {
    modal2Show: boolean;
    path: string;
}
export class AccountVerificationModal extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            modal2Show: false,
            path: ''
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {



        document.addEventListener('mousedown', this.handleClickOutside);



    }
    componentDidUpdate(prevProps: Props) {
  

    }
    closed = (event: any) => {
        event.preventDefault();

        this.props.hide && this.props.hide();

            document.removeEventListener('mousedown', this.handleClickOutside);
            document.removeEventListener('touchstart', this.handleClickOutside);
       

    }
    handleClickOutside = (event: any) => {
        const target = event.target
    
       
        if(this.props.show) {
        if ( !hasClass(target, 'modal-content')) {
            console.error("outside modal");
           
            if(this.props.kycStatus === KycStatus.PASS) {
                this.closed && this.closed(event);
            } else {
                this.closed && this.closed(event);
            }
         }
        }
    }
    
    convertToDate(dateString: any) {
        //  Convert a "dd/MM/yyyy" string into a Date object
        let d = dateString.split("-");
        let dat = new Date(d[2].split(' ')[0] + '/' + d[1] + '/' + d[0] + ' ' + d[2].split(' ')[1]);

        return dat;
    }

    
	handleKYCpass = () => {
		console.log('FROM HANDLE CONTINUE REDIRECT', this.props);
		
			// window.open(this.props.deposit.continueUrl, "_blank");

			return (
				<div >
					<div>
                

                    <p dangerouslySetInnerHTML={
                                			{ __html: t("accountVerificationModal.info", { interpolation: { escapeValue: false } })}}>
                            			</p>




					</div>
					<div className="success-image-sub">
						<img src={'https://content.adminemx.com/maharajafortune/images/payments/ok-64.png'} />
					</div>
					<div>
						<div className="buttons">
							<Link to={'/casino'} className="btn btn-primary btn-block">
                            {t("accountVerificationModal.btn")}
							</Link>
						</div>
					</div>
				</div>
			);
		
    }
    render() {

        let lang = getContainer().getSettingsService().getLocalesData();

        let path ;
        if(lang && lang.length >= 2) {
             path = window.location.pathname.split("/")[2];
        } else {
           path = window.location.pathname.split("/")[1];
        }
        if(path === 'withdraw' && !this.props.show &&  this.props.kycStatus !== KycStatus.PASS) {
            return <Redirect to="/" />
        }
        let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
  
        return (
            <React.Fragment>
                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.show}
                    onHide={() => this.props.hide && this.props.hide()}
                    className="account-verification-modal"
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={(e) => {
                          

                                if(this.props.kycStatus === KycStatus.PASS) {
                                    this.closed && this.closed(e);
                                } else {
                                    this.closed && this.closed(e);
                                }

                        }}><span aria-hidden="true">×</span><span className="sr-only">{t("accountVerificationModal.close")}</span></button>
                    </Modal.Header>

                    <Modal.Body className="account-verification-container">


                        <h3>{t("accountVerificationModal.actVerify")}</h3>

                 
                        <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/account-verification.svg"} className="modal-icon" />
                        {mainLobby == "https://joiabet.com/" ? (<DocumentUploadPageJoiabet />):  this.props.kycStatus && this.props.kycStatus === KycStatus.PASS  ? (this.handleKYCpass()):( <DocumentUploadPage />)}
                      
                   
                    

                    </Modal.Body>
                </Modal>

                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.modal2Show}
                    onHide={() => this.setState({ modal2Show: false })}
                    className="register-modal-goback"
                >
                    

                    <Modal.Body >
                        <h3> {t("accountVerificationModal.closeAcc")}</h3>

                       

                        <button className="btn btn-cta btn-lg height" onClick={() => { this.setState({ modal2Show: false }) }}>{t("accountVerificationModal.no")}</button>
                        <button className="btn" onClick={(e) => { this.setState({ modal2Show: false }); this.closed && this.closed(e); }}>{t("accountVerificationModal.closeAcc")}</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>



        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hide: () => { dispatch(showAccountVerificationModal(false)) },
});
const mapStateToProps = (app: AppState) => (
    {

        show: app.user.displayAccountVerificationModal,
        kycStatus:app.user.user?.kycStatus,
        activeLng: getContainer().getSettingsService().getActiveLocale().code
    }
);
export default connect(mapStateToProps, mapDispatchToProps)(AccountVerificationModal as any);