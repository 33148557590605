import React from "react";
import { Component } from "react";
import { Spinner } from "../ui/Spinner";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { UpdatePasswordRequest, UserInfo } from "../http/protocol";
import { LoginStatus, Status } from "../http/Types";
import { getContainer } from "../ioc/IOCSetup";
import Page from "../layout/Page";
import Root from "../layout/Root";
import { AppState } from "../RootReducer";
import { setNewUser } from "../user/UserActions";
import { updatePassword } from "./PasswordActions";
import { PasswordState } from "./PasswordReducer";
import { Field } from "redux-form";
import { renderField } from "../user/ui/form";
import { ShowHidePassword } from "../user/ui/ShowHidePassword";

import { t } from "../i18n";



interface State {
  oldPassword: string,
  newPassword: string,
  errorMessage: string,
  confirmNewPassword: string
}

type AllProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps &
  OtherProps;

export class UpdatePasswordPage extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      errorMessage: null as any,
      confirmNewPassword: ''
    };
  }

  handleFormSubmit = (e: any) => {
    var oldPassword = this.state.oldPassword;
    var newPassword = this.state.newPassword;
    var confirmNewPassword = this.state.confirmNewPassword;

    if (oldPassword === '' || newPassword == '' || confirmNewPassword === '') {
      this.setState({ errorMessage: t("updatePassword.text2") });
    } else if (oldPassword === newPassword) {
      this.setState({ errorMessage: t("updatePassword.text3") });
    } else if (newPassword !== confirmNewPassword) {
      this.setState({ errorMessage: t("updatePassword.text4") })
    } else {
      this.props.updatePassword({ oldPassword: oldPassword, newPassword: newPassword });
    }

    console.log("AFTER UPDATE PASSWORD", this.props.password)
    if (this.props.password && this.props.password.status && !this.props.password.isFetching &&
      this.props.password.status == "SUCCESS") {
      this.setState({ oldPassword: '', newPassword: '', confirmNewPassword: '' })
    } else if (this.props.password && this.props.password.error) {
      this.setState({
        oldPassword: '', newPassword: '', confirmNewPassword: '',
        errorMessage: t("updatePassword.text5")
      })
    }
  };

  handleOldPassInput = (e: any) => {
    this.setState({ oldPassword: e.target.value, errorMessage: '' });
  }

  handleNewPassInput = (e: any) => {
    this.setState({ newPassword: e.target.value, errorMessage: '' });
  }

  handleConfirmPassInput = (e: any) => {
    this.setState({ confirmNewPassword: e.target.value, errorMessage: '' });
  }



  render() {
    console.log(this.props.password.isFetching, "IS FETCHING")
    if (this.props.password.status == "SUCCESS") {
      return (<div className="mt-5">
        <h4>{t("updatePassword.passChanged")}</h4>
        <p>{t("updatePassword.text1")}</p>
      </div>)
    }
    return (
      <React.Fragment>

        {this.props.password.isFetching ? <Spinner /> :
          <div>

            <small className="text-danger">{this.state.errorMessage ? this.state.errorMessage : ""}</small>
            <form id="form-send-money">
              <div className="form-group">

                <div className="formHeaderSection">{t("updatePassword.oldPass")}</div>
                <ShowHidePassword>
                  <input
                    id="oldPassword"
                    type="password"
                    className="form-control"
                    onChange={this.handleOldPassInput}
                    value={this.state.oldPassword}
                  />
                </ShowHidePassword>


                <div className="formHeaderSection">{t("updatePassword.newPass")}</div>
                <ShowHidePassword>
                  <input
                    id="newPassword"
                    type="password"
                    className="form-control"
                    onChange={this.handleNewPassInput}
                    value={this.state.newPassword}
                  />
                </ShowHidePassword>




                <div className="formHeaderSection">{t("updatePassword.confirmPass")}</div>
                <ShowHidePassword>
                  <input
                    id="confirmNewPassword"
                    type="password"
                    className="form-control"
                    onChange={this.handleConfirmPassInput}
                    value={this.state.confirmNewPassword}
                  />
                </ShowHidePassword>
              </div>

              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={() => this.handleFormSubmit(this.props)}
              >
                {t("updatePassword.changePass")}
              </button>
            </form>
          </div>
        }

      </React.Fragment>
    )
  }



}

interface OtherProps extends RouteComponentProps { }

interface PropsFromState {
  loggedIn: boolean;
  user?: UserInfo;
  lng: string;
  updatePassword: typeof updatePassword
}

interface PropsFromDispatch {
  updatePassword: (request: UpdatePasswordRequest) => any;
  password: PasswordState;
}

const mapStateToProps = ({ user, contentful, password }: AppState) => {
  let status = false;
  if (user.registration && user.registration.status) {
    if (user.registration.status == Status.SUCCESS) {
      status = true;
    }
  }

  return {
    user: user.user,
    newUser: user.newUser,
    lng: getContainer().getSettingsService().getActiveLocale().code,
    loggedIn: LoginStatus.LOGGED_IN == user.status,
    password: password
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNewUser: (newUser: boolean) => dispatch(setNewUser(newUser) as any),
  updatePassword: (request: UpdatePasswordRequest) => dispatch(updatePassword(request) as any),
  //fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage);
