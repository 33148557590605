import React from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {loadLiveCasinoGames, selectCategory} from "../LiveCasinoActions";
import {fetchContent} from "../../contenful/ContentfulActions";
import {ContentModelName, PopupManager, SlidersMaharajaPayload} from "../../contenful/types";
import Root from '../../layout/Root';
import Page from '../../layout/Page';
import {AppState} from "../../RootReducer";
import {fetchSettings} from "../../settings/SettingsActions";
import {LiveCasinoLobbyCategory, Settings} from "../../http/protocol";
import {Route, RouteComponentProps} from "react-router-dom";
import {LiveCasinoStatus} from "../LiveCasinoReducer";
import {LoadingLiveCasinoLobby} from "../LoadingLiveCasinoLobby";
import {LoginStatus} from "../../http/Types";
import {t} from "../../i18n";
import MetaHelmet from "../../utils/MetaHelmet";
import "./styles/LiveCasinoPage.scss";
import LiveCasinoCategoryPage from "./LiveCasinoCategoryPage";
import CarouselComponent from "./CarouselComponent";
import LiveCasinoCategories from "./LiveCasinoCategories";
import Banner from "../../ui/Banner"
import { logout, showLogin } from "../../user/UserActions";
import {UserInfo} from "../../http/protocol";
import { getContainer } from '../../ioc/IOCSetup';

interface Props extends RouteComponentProps  {
    loadLiveCasinoGames? : () => void,
    settings : Settings,
    fetchSettings : typeof fetchSettings,
    selectedCategory?: number;
    categories? : LiveCasinoLobbyCategory[],
    selectCategory? : (cat : number) => void;
    status?: LiveCasinoStatus;
    loadingCarousel?: boolean;
    popupManager?: PopupManager;
    fetchContent : typeof fetchContent;
    lng: string;
    loggedIn : boolean;
    slidersMaharaja?:SlidersMaharajaPayload;
    isloggedIn:boolean;
    user:UserInfo;
    optInBonusPlans?:any;

    showLogin: () => void;
};
interface State {
    hideAfterWentToOtherPage: boolean;
    promotionbanner?:any;
}


let staticSlideContent = {
    "banner": [
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/weekend-bonus",
            "copyline": "/assets/images/HomePromo/welcomebonus/weekend-bonus-copyline.png",
            "mainBanner": "/assets//images/HomePromo/welcomebonus/1920x1080_Weekend-Bonus.jpg",
            "bonusPlanId": "11",
            "termsAndCondition": "/promotions/weekend-bonus"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/second-welcome",
            "copyline": "/assets/images/HomePromo/welcomebonus/second-welcome-copyline.png",
            "mainBanner": "/assets//images/HomePromo/welcomebonus/1920x1080_Second-Welcome.jpg",
            "bonusPlanId": "12",
            "termsAndCondition": "/promotions/second-welcome"
        },
        {
            "isPromo": true,
            "isWelcomeBonus":true,
            "claimNow": "/promotions/every-monday-deposit",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/moday-promo-copyline.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/1920x1080_Monday-TopUp.jpg",
            "bonusPlanId": "8",
            "termsAndCondition": "/promotions/every-monday-deposit"
        },
        {
            "isPromo": true,
            "claimNow": "/promotions/welcomebonus",
            "copyline": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/Copyline.png",
            "mainBanner": "https://content.adminemx.com/maharajafortune/images/HomePromo/welcomebonus/Hero-banner-Live-Casino-Promo.jpg",
            "bonusPlanId": "3",
            "isWelcomeBonus": true,
            "termsAndCondition": "/promotions/casino-welcomebonus"
        },
        {
            "gameId": "EVOLUTION-crazytime-CrazyTime0000001",
            "labeled": "New",
            "copyline": "https://content.adminemx.com/maharajafortune/banners/crazy-time-copyline.png",
            "gameName": "Crazy Time",
            "isMobile": false,
            "isDetails": true,
            "labeledJP": "新しい",
            "segmentId": 1,
            "gameNameJP": "アトランティスライジング",
            "productCode": "WEARECASINO",
            "staticBanner": "https://content.adminemx.com/maharajafortune/banners/1920x1080_Crazy-Time.jpg",
            "isDemoEnabled": true,
            "supportMobile": true,
            "freespinEnabled": true,
            "isFreespinEnable": true
        }
    ]
}


export class LiveCasinoPage extends React.Component<Props, State>{
    constructor(props : Props){
        super(props);
        this.state = {hideAfterWentToOtherPage: false};
    }
    componentDidMount(): void {
        let promotions = getContainer().getSettingsService().getPromotions();
        let livecasino = promotions &&  promotions.filter(function( obj:any ) {
            return obj.placement.includes("LIVECASINO") && obj.isEnabled;
          })
          this.setState({promotionbanner: livecasino});
          
        this.props.loggedIn && localStorage.setItem("lastProductUsed", "livecasino");

        this.props.loadLiveCasinoGames && this.props.loadLiveCasinoGames();
      
        if(this.props.settings == undefined) {
            // this.props.fetchSettings && this.props.fetchSettings();
        }
        if(this.props.slidersMaharaja === undefined) {
            this.props.fetchContent(ContentModelName.SLIDERSMAHARAJA, this.props.lng);
        }
        if(this.props.popupManager == undefined) {
            // this.props.fetchContent(ContentModelName.POPUPMANAGER, this.props.lng);
        } else {
            this.setState({ hideAfterWentToOtherPage: true })
        }
    }

    render() {
        const title:string = t("meta.liveCasinoPage.title") || "";
        const subtitle:string = t("meta.liveCasinoPage.subtitle") || "";
        const fullTitle:string = t("meta.liveCasinoPage.fullTitle") || "";
        const description:string = t("meta.liveCasinoPage.description") || "";
        const keywords:string = t("meta.liveCasinoPage.keywords") || "";

        return (
            <Root footer={true} header={true}>
                {MetaHelmet(title, description, keywords)}

                    <Page>
             
                        <div className={"livecasino-container"} >
                        <section id="home" className="iq-main-slider p-0">
                    <div id="home-slider" className="slider m-0 p-0">
                        {this.state.promotionbanner  ? <Banner data={this.state.promotionbanner } optInBonusPlans={this.props.optInBonusPlans} loggedIn={this.props.isloggedIn} showLogin={()=>this.props.showLogin()}  user={this.props.user} lng={this.props.lng} /> : null}
                        </div>
                        </section>
                            {this.props.status == LiveCasinoStatus.DONE && (
                                <React.Fragment>
                                    <LiveCasinoCategories />
                                    <Route exact path={"/livecasino/:catId?/:name?"} component={LiveCasinoCategoryPage}/>
                                </React.Fragment>
                            )}
                            {this.props.status == LiveCasinoStatus.LOADING && (
                                <LoadingLiveCasinoLobby />
                            )}
                            {this.props.status == LiveCasinoStatus.ERROR && (
                                <div className={"container"}>
                                   <div className={"row"}>
                                       <div className={"col-md-12"}>
                                           <div className={"alert alert-warning"}>
                                                {t("liveCasinoPage.text1")}
                                           </div>
                                       </div>
                                   </div>
                                </div>
                            )}

                        </div>
                    </Page>
               
       
            </Root>
        )
    }
}

const stateToProps = (state : AppState) => {

    return {
  
        status : state.livecasino.status,
        settings: state.settings.settings ? state.settings.settings : {},
        selectedCategory : state.livecasino.selectedCategory,
        categories : state.livecasino.lobby ? state.livecasino.lobby.categories : [],
        isloggedIn: state.user.user && state.user.user.loggedIn,
        loggedIn : LoginStatus.LOGGED_IN == state.user.status,
        slidersMaharaja:state.contentful.slidersMaharaja,
        user:state.user.user,
        optInBonusPlans:state.user.optInBonusPlans, 
     
    }
};

const dispatchToProps = (dispatch : Dispatch) => (
    {
        loadLiveCasinoGames : ()=> dispatch(loadLiveCasinoGames() as any),
        fetchSettings : () => dispatch(fetchSettings() as any),
        selectCategory : (cat : number) => dispatch(selectCategory(cat)),
        fetchContent : (contentModelName : ContentModelName) => dispatch(fetchContent(contentModelName) as any),
        showLogin: () => dispatch(showLogin(true)),
    }
);

export default connect(stateToProps, dispatchToProps)(LiveCasinoPage as any)

