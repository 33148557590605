import React from 'react';
import ReactGA from 'react-ga4';

import { useLocation } from "react-router";



interface GAWrapperProps {
    initialized:boolean;
    children:React.PropsWithChildren<any>;
}

export function GAWrapper(props:GAWrapperProps) {
    const location = useLocation();
    
    React.useEffect(()=> {
        if(props.initialized) {

            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

        }
    },[props.initialized, location])

    return props.children;
}