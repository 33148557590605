import { HttpService } from "../http/HttpService";
import {
    CustomerInfo,
    DepositRequest,
    PaymentDialog,
    PaymentMethod,
    PlayerOrder,
    PrepareDepositResponse,
    ProcessDepResult,
    ProcessWithdrawalResult,
    WithdrawalMethod,
    WithdrawalRequest,
} from "../http/protocol";
import { WithdrawContext } from "./PaymentsReducer";

export class PaymentsService {
    constructor(private http: HttpService) {}

    getPaymentDialog(
        provider: string,
        order: PlayerOrder
    ): Promise<PaymentDialog> {
        return this.http.post("/payments/payment-dialog/" + provider, order);
    }

    performDeposit(
        paymentId: string,
        customerInfo?: CustomerInfo
    ): Promise<any> {
        return this.http.post(
            "/payments/deposit/perform/" + paymentId,
            customerInfo
        );
    }

    performHooyu(partyId: number) {
        return this.http.get("/ips/kyc/hooyu?partyid=" + partyId);
    }

    checkKYCStatus(partyId: number) {
        return this.http.get("/ips/kyc/check?partyid=" + partyId);
    }

    processDeposit(
        depositRequest: DepositRequest
    ): Promise<{ [key: string]: ProcessDepResult }> {
        if (
            depositRequest.paymentMethod === "XP_EPG" &&
            sessionStorage.getItem("bonusPlanId")
        ) {
            let bonusPlanId = sessionStorage.getItem("bonusPlanId");
            sessionStorage.removeItem("bonusPlanId");
            return this.http.getConstumeUrl(
                "https://paymentgateway-h2mdpetmja-uc.a.run.app/api/paymentgateway/processDeposit?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&requestedBonusPlanId=" +
                    bonusPlanId +
                    "&paymentMethod=" +
                    depositRequest.paymentMethod +
                    "&amount=" +
                    depositRequest.amount +
                    (depositRequest.conversionId
                        ? "&conversionId=" + depositRequest.conversionId
                        : "")
            );
        } else if (depositRequest.paymentMethod === "XP_EPG") {
            let bonusPlanId = sessionStorage.getItem("bonusPlanId");
            sessionStorage.removeItem("bonusPlanId");
            return this.http.getConstumeUrl(
                "https://paymentgateway-h2mdpetmja-uc.a.run.app/api/paymentgateway/processDeposit?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&paymentMethod=" +
                    depositRequest.paymentMethod +
                    "&amount=" +
                    depositRequest.amount +
                    (depositRequest.conversionId
                        ? "&conversionId=" + depositRequest.conversionId
                        : "")
            );
        } else if (
            depositRequest.paymentMethod === "APCOPAY" &&
            sessionStorage.getItem("bonusPlanId")
        ) {
            let bonusPlanId = sessionStorage.getItem("bonusPlanId");
            sessionStorage.removeItem("bonusPlanId");
            return this.http.get(
                "/ips/processDeposit?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&requestedBonusPlanId=" +
                    bonusPlanId +
                    "&subPaymentMethod=PAY5" +
                    "&paymentMethod=" +
                    depositRequest.paymentMethod +
                    "&amount=" +
                    depositRequest.amount +
                    (depositRequest.conversionId
                        ? "&conversionId=" + depositRequest.conversionId
                        : "")
            );
        } else if (depositRequest.paymentMethod === "APCOPAY") {
            return this.http.get(
                "/ips/processDeposit?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&subPaymentMethod=PAY5" +
                    "&paymentMethod=" +
                    depositRequest.paymentMethod +
                    "&amount=" +
                    depositRequest.amount +
                    (depositRequest.conversionId
                        ? "&conversionId=" + depositRequest.conversionId
                        : "")
            );
        } else if (sessionStorage.getItem("bonusPlanId")) {
            let bonusPlanId = sessionStorage.getItem("bonusPlanId");
            sessionStorage.removeItem("bonusPlanId");
            return this.http.get(
                "/ips/processDeposit?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&requestedBonusPlanId=" +
                    bonusPlanId +
                    "&paymentMethod=" +
                    depositRequest.paymentMethod +
                    "&amount=" +
                    depositRequest.amount +
                    (depositRequest.conversionId
                        ? "&conversionId=" + depositRequest.conversionId
                        : "")
            );
        } else {
            return this.http.get(
                "/ips/processDeposit?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&paymentMethod=" +
                    depositRequest.paymentMethod +
                    "&amount=" +
                    depositRequest.amount +
                    (depositRequest.conversionId
                        ? "&conversionId=" + depositRequest.conversionId
                        : "")
            );
        }
    }
    checkPaymentStatus(paymentId: string) {
        return this.http.get(
            "/ips/checkPaymentStatus?sessionKey=" +
                localStorage.getItem("sessionKey") +
                "&paymentId=" +
                paymentId
        );
    }
    prepareDeposit(depositMethod: String): Promise<PrepareDepositResponse> {
        return this.http.get(
            "/ips/prepareDeposit?sessionKey=" +
                localStorage.getItem("sessionKey") +
                "&paymentMethod=" +
                depositMethod +
                "&action=GET_EXCHANGE_INFO"
        );
    }

    processWithdraw(
        withdrawalRequest: WithdrawalRequest
    ): Promise<{ [key: string]: ProcessWithdrawalResult }> {
        console.log("api call", withdrawalRequest);
        if (withdrawalRequest.paymentMethod === "CASHIP_PIX") {
            return this.http.get(
                "/ips/processWithdrawal?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&paymentMethod=" +
                    withdrawalRequest.paymentMethod +
                    "&amount=" +
                    withdrawalRequest.amount
            );
        } else if (
            withdrawalRequest.paymentMethod === "DELUXEPAY365_IMPS" ||
            withdrawalRequest.paymentMethod === "DELUXEPAY365_NETBANKING" ||
            withdrawalRequest.paymentMethod === "DELUXEPAY365_BANKTRANSFER"
        ) {
            return this.http.get(
                "/ips/processWithdrawal?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&paymentMethod=" +
                    withdrawalRequest.paymentMethod +
                    "&amount=" +
                    withdrawalRequest.amount +
                    "&accountId=" +
                    withdrawalRequest.accountNumber +
                    "&ifscCode=" +
                    withdrawalRequest.bankCode +
                    "&accountHolderName=" +
                    withdrawalRequest.accountHolderName +
                    "&mobileNumber=" +
                    withdrawalRequest.mobileNumber
            );
        } else {
            return this.http.get(
                "/ips/processWithdrawal?sessionKey=" +
                    localStorage.getItem("sessionKey") +
                    "&paymentMethod=" +
                    withdrawalRequest.paymentMethod +
                    "&amount=" +
                    withdrawalRequest.amount +
                    "&bankCode=" +
                    withdrawalRequest.bankCode +
                    "&accountNumber=" +
                    withdrawalRequest.accountNumber +
                    "&accountHolderName=" +
                    withdrawalRequest.accountHolderName +
                    "&mobileNumber=" +
                    withdrawalRequest.mobileNumber
            );
        }
    }
    getPendingWithdrawal(): Promise<{ [key: string]: string }> {
        return this.http.get(
            "/ips/getPendingWithdrawals?" +
                "&sessionKey=" +
                localStorage.getItem("sessionKey")
        );
    }
    prepareWithdrawal(): Promise<{ [key: string]: string }> {
        return this.http.get(
            "/ips/prepareWithdrawal?paymentMethod=CASHIP_PIX&action=GET_EXCHANGE_INFO&" +
                "&sessionKey=" +
                localStorage.getItem("sessionKey")
        );
    }
    cancelWithdrawal(paymentId: any): Promise<{ [key: string]: string }> {
        return this.http.get(
            "/ips/cancelWithdrawal?" +
                "&sessionKey=" +
                localStorage.getItem("sessionKey") +
                "&paymentId=" +
                paymentId
        );
    }

    processDepositContinueUrl(
        continueUrl: string
    ): Promise<{ [key: string]: string }> {
        return this.http.get(
            continueUrl + "&sessionKey=" + localStorage.getItem("sessionKey")
        );
    }

    getPaymentMethods(): Promise<{ [key: string]: PaymentMethod[] }> {
        return this.http.get("/payments/methods");
    }

    getDepositMethods(): Promise<{ [key: string]: PaymentMethod[] }> {
        return this.http.get(
            "/ips/getDepositMethods?sessionKey=" +
                localStorage.getItem("sessionKey")
        );
    }

    getWithdrawalMethods(): Promise<{ [key: string]: WithdrawalMethod[] }> {
        return this.http.get(
            "/ips/getWithdrawalMethods?sessionKey=" +
                localStorage.getItem("sessionKey")
        );
    }

    requestWithdraw(
        provider: string,
        order: PlayerOrder
    ): Promise<PaymentDialog> {
        return this.http.post("/payments/withdraw/" + provider, order);
    }
    getWithdrawContext(provider: string): Promise<WithdrawContext> {
        return this.http.get("/payments/withdraw/context/" + provider);
    }
}
