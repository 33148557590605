import React from "react";
import asdfgh from 'asdfgh';
import "./styles/StrengthMeter.scss";

interface Props {
    value : string;
}

interface State {
    score: any;
}

export class StrengthPassword extends React.Component<Props, State>{
    constructor(props : Props) {
        super(props);
        this.state = {
          score: null
        }
    }

  async componentDidUpdate(prevProps : any) {
      if(prevProps.value != this.props.value) {
        try {
          const result = await asdfgh(this.props.value);
          this.setState({ score: result.score })
        } catch (err) {
          console.error(err);
        }
      }
  }

  renderPasswordFeedback(score : any) {
    let passwordStrength;
    switch(score) {
      case 0:
          passwordStrength='Too weak'
          break;
      case 1:
          passwordStrength='Very weak'
          break;
      case 2:
          passwordStrength='Weak'
          break;
      case 3:
          passwordStrength='Good'
          break;
      case 4:
          passwordStrength='Very good'
          break;
    }
    return passwordStrength;
  }

  render() {
    const { score } = this.state;
    const scorePassword = this.props.value == "" ? null : score;
    return (
      <React.Fragment>
          {scorePassword != null && (
              <div className="strength-meter">
                  <div data-strength={scorePassword} className="strength-meter-fill" />
              </div>
          )}
        <div data-strength={scorePassword} className="password-feedback">{this.renderPasswordFeedback(scorePassword)}</div>
      </React.Fragment>
    );
  }
}

export default StrengthPassword;
