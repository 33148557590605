import {HttpService} from "../http/HttpService";
import {
    BuyBundleRequest,
    ListBundlesResponse, PaymentDialog
} from "../http/protocol";


export class BundleServicee {
    constructor(private http : HttpService) {}

    getAllActiveBundles() : Promise<ListBundlesResponse> {
        return this.http.get("/store/bundle/all");
    }

    getPaymentDialog(bundleId: number, providerRequest : BuyBundleRequest) : Promise<PaymentDialog> {
        return this.http.post(`/store/bundle/buy/${bundleId}`, providerRequest);
    }

    getAvailableBonusBundlesForUser(): Promise<ListBundlesResponse> {
        return this.http.get("/store/bundle/bonus");
    }

    claimBonusBundlesForUser(bundleId: number): Promise<{}>  {
        return this.http.get(`/store/bundle/bonus/claim/${bundleId}`);
    }
}

