import React from "react";
import "./BonusModal.scss";
import { Status, UserInfo } from "../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../RootReducer";
import { Redirect } from "react-router";
import Root from "../layout/Root";
import MetaHelmet from "../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { Dispatch } from "redux";
import { getContainer } from "../ioc/IOCSetup";
import { fetchContent } from "../contenful/ContentfulActions";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Modal from "react-bootstrap/Modal"
import { showBonusModal, showSignUp, getActiveBonuses, getActiveBonusesByDate } from "../user/UserActions";
import { hasClass } from "../utils/Helpers";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import getSymbolFromCurrency from 'currency-symbol-map'

import { t } from "../i18n";

type Props = {
    status?: Status;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    activeLng?: string;
    show?: boolean,
    hide?: () => void;
    getActiveBonuses: () => void;
    getActiveBonusesByDate?: (startDate: any, endDate: any) => void;
    loggedIn?: boolean
    madeDeposit?: boolean;
    isNewUser?: boolean;
    bonuses?: any;
    bonusesByDate?: any
};
var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

interface State {
    modal2Show: boolean;
    path: string;
    startDate?: any;
    endDate?: any;
}
export class BonusModal extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            modal2Show: false,
            path: '',
            startDate: '',
            endDate: ''
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {

        if (this.props.show === true) {
            this.props.getActiveBonuses();

        }

        document.addEventListener('mousedown', this.handleClickOutside);



    }
    componentDidUpdate(prevProps: Props) {
        if (this.props.show !== prevProps.show && this.props.show === true) {
            this.props.getActiveBonuses();
            this.props.getActiveBonusesByDate && this.props.getActiveBonusesByDate(this.state.startDate, this.state.endDate);
        
        }

    }
    closed = (event: any) => {
        event.preventDefault();
        this.props.hide && this.props.hide();

        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);

    }
    handleClickOutside = (event: any) => {
        const target = event.target


        if (this.props.show) {
            if (!hasClass(target, 'modal-content')) {
                console.error("outside modal");
                if (this.props.loggedIn) {
                    this.closed(event)
                } else {
                    this.setState({ modal2Show: true })
                }
            }
        }
    }
    convertToDate(dateString: any) {
        //  Convert a "dd/MM/yyyy" string into a Date object
        let d = dateString.split("-");
        let dat = new Date(d[2].split(' ')[0] + '/' + d[1] + '/' + d[0] + ' ' + d[2].split(' ')[1]);

        return dat;
    }

    showExpiredBonuses() {
        
        return (
            <div className="games-history">
                <div className="row">
                    <div className="col-lg-5 col-6">
                    <span className="d-block">    {t("bonusModal.start1")}</span> 
                        <input type="date" id="dob" onChange={(e) => { this.setState({ startDate: e.target.value }) }} placeholder="mm/dd/yyyy" className={`form-control transaction-date `} />
                    </div>
                    <div className="col-lg-5 col-6">
                    <span className="d-block">    {t("bonusModal.end1")}</span> 
                        <input type="date" id="dob" onChange={(e) => { this.setState({ endDate: e.target.value }) }} placeholder="mm/dd/yyyy" className={`form-control transaction-date `} />
                    </div>
                    <div className="col-lg-2 col-4"><br/>
                    <button onClick={()=>this.getActiveBonusesByDate()}       className={`btn btn-cta btn-back float-right`}
> 
                    {t("bonusModal.search")}
                    </button>
                    </div>
                    
                </div>
                <Table id="history" className="table">
                    <Thead>
                        <Tr>
                            <Th>{t("bonusModal.id")}</Th>
                            <Th>{t("bonusModal.name")}</Th>
                            <Th>{t("bonusModal.wagered")}</Th>
                            <Th>{t("bonusModal.wagering")}</Th>
                            <Th>{t("bonusModal.start2")}</Th>
                            <Th>{t("bonusModal.end2")}</Th>
                            <Th>{t("bonusModal.amount")}</Th>
                            <Th>{t("bonusModal.status")}</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {this.props.bonusesByDate && this.props.bonusesByDate.length > 0 ? (
                            this.props.bonusesByDate.map((e: any, index: any) => {
                                let triggerDate = new Date(this.convertToDate(e.triggerDate));
                                let triggreDateFormat = new Date(triggerDate.setHours(triggerDate.getHours() - 3)).toLocaleString('en-GB');
                                let expiryDate = new Date(this.convertToDate(e.expiryDate));
                                let expiryDateFormat = new Date(expiryDate.setHours(expiryDate.getHours() - 3)).toLocaleString('en-GB');
                                let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
                                return [
                                    <Tr key={index} key={index}>
                                        <Td>{e.id}</Td>
                                        <Td>{e.bonusPlanName}</Td>
                                        <Td>{e.amountWagered}</Td>
                                        <Td>{e.wagerRequirement}</Td>
                                        <Td> {mainLobby == "https://joiabet.com/" ? triggreDateFormat:this.convertToDate(e.triggerDate).toLocaleDateString("En-US", options)} {new Date(this.convertToDate(e.triggerDate)).toLocaleTimeString([], { timeStyle: "short" })}</Td>
                                        <Td> {mainLobby == "https://joiabet.com/" ? expiryDateFormat:this.convertToDate(e.expiryDate).toLocaleDateString("En-US", options)} {new Date(this.convertToDate(e.expiryDate)).toLocaleTimeString([], { timeStyle: "short" })}</Td>
                                        <Td>{e.amount}</Td>
                                        <Td>{e.status}</Td>

                                    </Tr>,
                                ]
                            })
                        ) : (

                            <Tr>
                                <td colSpan={5}>{t("bonusModal.noRecords")}</td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </div>
        );


    }

    showBonuses() {
        let activeCurrecy = localStorage.getItem("activeCurrency") || "";
        let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
        return this.props.bonuses.filter(function (e: any) {
            return e.status === "ACTIVE" || e.status === "QUEUED";
        }) ? (
            <React.Fragment>
                {this.props.bonuses.filter(function (e: any) {
                    return e.status === "ACTIVE" || e.status === "QUEUED";
                }).map((e: any) => {
                    let widthProgress = (e.amountWageredNumber / e.wagerRequirementNumber) * 100;
                    if (e.status === "ACTIVE" || e.status === "QUEUED") {
                        let triggerData = new Date(e.triggerDate);
                        let expiryDate = new Date(e.expiryDate);

                        return (

                            <div className="bonus-banner" key={e.id}>




                                {e.status === "ACTIVE" ? (
                                    <div className="active-status" >
                                        <div className="bubble">
                                            <span className="bubble-outer-dot">
                                                <span className="bubble-inner-dot"></span>
                                            </span>
                                        </div>
                                        <label>{e.status}</label>
                                    </div>

                                ) : (<div className="active-status" >
                                    <div className="bubble-queued">

                                    </div>
                                    <label className="queued-label">{e.status}</label>
                                </div>)}
                                <img
                                    className="img-fluid bonuses-banner"
                                    src={`${mainLobby === "https://joiabet.com/" ? `https://content.adminemx.com/joiabet/bonus/${e.bonusPlanId}.jpg`:`https://content.adminemx.com/maharajafortune/bonus/${e.id}.jpg` }`}
                                    alt="promotions"
                                />
                                <label className="promoPeriod">{t("bonusModal.expireDate")}{this.convertToDate(e.expiryDate).toLocaleDateString("En-US", options)} {new Date(this.convertToDate(e.expiryDate)).toLocaleTimeString([], { timeStyle: "short" })}</label>
                                <label className="wagerAmountLabel">{t("bonusModal.amountWagered")}
                                </label>
                                <label className="wagerRequiredAmountLabel">{t("bonusModal.wagerRequired")}
                                </label>
                                <div className="progress">

                                    <label className="wagerAmount">  {getSymbolFromCurrency(activeCurrecy)} {e.amountWagered}</label>
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${widthProgress + "%"}` }} aria-valuenow={widthProgress} aria-valuemin="0" aria-valuemax="100"></div>
                                    <label className="wagerRequiredAmount">{getSymbolFromCurrency(activeCurrecy)} {e.wagerRequirement}</label>
                                </div>

                            </div>
                        );
                    }
                })}
            </React.Fragment>
        ) : (<p>{t("bonusModal.text")}</p>)
    }
    getActiveBonusesByDate() {
        this.props.getActiveBonusesByDate && this.props.getActiveBonusesByDate(this.state.startDate, this.state.endDate);


    }

    render() {

        if (!this.props.show && this.state.path === 'signup') {
            return <Redirect to={"/"} />
        }
        return (
            <React.Fragment>
                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.show}
                    onHide={() => this.props.hide && this.props.hide()}
                    className="bonus-modal"
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => {
                            if (this.props.loggedIn) {
                                this.props.hide && this.props.hide();
                            } else {
                                this.setState({ modal2Show: true })
                            }

                        }}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    </Modal.Header>

                    <Modal.Body className="bonuses-modal-container">


                        <h3>{t("bonusModal.info")}</h3>
             
                        <Tabs
                            defaultActiveKey="active"
                            id="tab-active-bonus"
                            className="mb-3 mt-3 d-flex align-items-center justify-content-center"
                        >
                            <Tab eventKey="active" title={t("bonusModal.active")}>
                                {this.showBonuses()}

                                <Link to="/promotions" onClick={() => { this.props.hide && this.props.hide(); }} className={`btn btn-primary  justify-content-sm-start btn-rounded`}

                                >
                                    {t("bonusModal.infoText")}
                                </Link>
                                <div className="bonus-desc">
                                <p dangerouslySetInnerHTML={
                                			{ __html: t("bonusModal.bonusDesc", { interpolation: { escapeValue: false } })}}>
                            			</p>

                                    <Accordion >
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                            {t("bonusModal.readMore")}
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                {t("bonusModal.readMoreText")}

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>


                                    </Accordion>
                                </div>
                            </Tab>
                            <Tab eventKey="history" title={t("bonusModal.history")}>

                                {this.showExpiredBonuses()}

                            </Tab>

                        </Tabs>

                    </Modal.Body>
                </Modal>

                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.modal2Show}
                    onHide={() => this.setState({ modal2Show: false })}
                    className="register-modal-goback"
                >


                    <Modal.Body >
                        <h3> {t("registerModal.title")} </h3>

                        <p> {t("registerModal.text")}</p>

                        <button className="btn btn-cta btn-lg height" onClick={() => { this.setState({ modal2Show: false }) }}>{t("registerModal.back")}</button>
                        <button className="btn" onClick={() => { this.setState({ modal2Show: false }); this.props.hide && this.props.hide(); }}>{t("registerModal.close")}</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>



        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hide: () => { dispatch(showBonusModal(false)) },
    getActiveBonuses: () => dispatch(getActiveBonuses(localStorage.getItem("sessionKey")) as any),
    getActiveBonusesByDate: (startDate: any, endDAte: any) => dispatch(getActiveBonusesByDate(localStorage.getItem("sessionKey"), startDate, endDAte) as any),

});
const mapStateToProps = (app: AppState) => (
    {
        status: app.user.registration && app.user.registration.status,
        registerContent: app.contentful.register,
        show: app.user.displayBonusModal,
        completeRegisterContent: app.contentful.completeRegistration,
        bonuses: app.user.bonuses,
        bonusesByDate: app.user.bonusesByDate,
        loggedIn: app.user.user?.loggedIn,
        madeDeposit: app.user.user && app.user.user.madeDeposit,
        isNewUser: app.user.user && app.user.user.newUser,
        activeLng: getContainer().getSettingsService().getActiveLocale().code
    }
);
export default connect(mapStateToProps, mapDispatchToProps)(BonusModal as any);