import React from "react";

import { PaymentMethod, Status, UserInfo } from "../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../RootReducer";
import { Redirect } from "react-router";
import Root from "../layout/Root";
import MetaHelmet from "../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { Dispatch } from "redux";
import { getContainer } from "../ioc/IOCSetup";
import { fetchContent } from "../contenful/ContentfulActions";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Modal from "react-bootstrap/Modal"
import { getActiveBonuses, getActiveBonusesByDate, showAccountVerificationModal, showCashierModal } from "../user/UserActions";
import { hasClass } from "../utils/Helpers";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import DepositMethod from "./deposit/DepositMethod";
import { fetchProviders } from "../payments/DepositActions";
import { DepositState } from "../payments/PaymentsReducer";
import StorePageNoFooter from "../storepage/StorePageNoFooter";
import "./CashierModal.scss";
import WithdrawalPageNoFooter from "../withdraw/WithdrawalPageNoFooter";
import PendingWithdrawalPage from "../withdraw/PendingWithdrawalPage";

import { t } from "../i18n";
import DocumentUploadPageJoiabet from "../kyc/DocumentUploadPageJoiabet";

type Props = {
    status?: Status;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    activeLng?: string;
    show?: boolean,
    hide?: () => void;
    fetchProviders?: () => void;
    showAccountVerificationModal: (show: boolean) => void;
    deposit: DepositState;
    loggedIn?: boolean
    madeDeposit?: boolean;
    isNewUser?: boolean;
    bonuses?: any;
    bonusesByDate?: any;
    selected?: string;
};
var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

interface State {
    modal2Show: boolean;
    path: string;
    startDate?: any;
    endDate?: any;
    selectedTab: strin;
}
export class CashierModal extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            modal2Show: false,
            path: '',
            startDate: '',
            endDate: '',
            selectedTab: '',
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {

        if (this.props.deposit !== undefined) {
            console.log("this.props.deposit !== undefined")
            this.props.fetchProviders && this.props.fetchProviders();
        }


        document.addEventListener('mousedown', this.handleClickOutside);



    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.selected !== this.props.selected && this.props.show) {
            this.setState({ selectedTab: this.props.selected });
        }

    }
    closed = (event: any) => {
        event.preventDefault();
        this.props.hide && this.props.hide();

        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);

    }
    handleClickOutside = (event: any) => {
        const target = event.target


        if (this.props.show) {
            if (!hasClass(target, 'modal-content')) {
                console.error("outside modal");
                if (this.props.loggedIn) {
                    this.closed(event)
                } else {
                    this.setState({ modal2Show: true })
                }
            }
        }
    }


    render() {
        let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
        if (!this.props.show && this.state.path === 'signup') {
            return <Redirect to={"/"} />
        }
        return (
            <React.Fragment>
                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.show}
                    onHide={() => this.props.hide && this.props.hide()}
                    className="cashier-modal"
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => {
                            if (this.props.loggedIn) {
                                this.props.hide && this.props.hide();
                            } else {
                                this.setState({ modal2Show: true })
                            }

                        }}><span aria-hidden="true">×</span><span className="sr-only">{t("cashierModal.close")}</span></button>
                    </Modal.Header>

                    <Modal.Body className="cashier-modal-container">


                        <h3>{t("cashierModal.title")}</h3>

                        <Tabs
                            defaultActiveKey={this.props.selected}
                            id="tab-deposit-bonus"
                            className="mb-3 mt-3 d-flex align-items-center"
                            onSelect={(key) => this.setState({ selectedTab: key })}
                        >
                            <Tab eventKey="deposit" title={t("cashierModal.deposit")}>

                                {this.props.show ? (<StorePageNoFooter />) : null}

                            </Tab>
                            <Tab eventKey="withdraw" title={t("cashierModal.withdraw")}>

                                {this.props.show ? (<WithdrawalPageNoFooter />) : null}

                            </Tab>
                            <Tab eventKey="pendingWithdraw" title={t("cashierModal.pendingWidthdrawal")}>
                                {this.state.selectedTab === "pendingWithdraw" ? (<PendingWithdrawalPage />) : null}

                            </Tab>
                            {mainLobby == "https://joiabet.com/" && this.state.selectedTab === "kycVerification" ? (
                                <Tab eventKey="kycVerification" title={t("accountVerificationModal.actVerify")}>


                                    <div className="account-verification-container ">

                                        <div className="deposit-wrapper">


                                            <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/account-verification.svg"} className="modal-icon" />
                                            <DocumentUploadPageJoiabet />
                                        </div>
                                    </div>

                                </Tab>
                            ) : null}
                        </Tabs>

                    </Modal.Body>
                </Modal>

                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.modal2Show}
                    onHide={() => this.setState({ modal2Show: false })}
                    className="register-modal-goback"
                >


                    <Modal.Body >
                        <h3> {t("registerModal.title")} </h3>

                        <p> {t("registerModal.text")}</p>

                        <button className="btn btn-cta btn-lg height" onClick={() => { this.setState({ modal2Show: false }) }}>{t("buttonBack")}</button>
                        <button className="btn" onClick={() => { this.setState({ modal2Show: false }); this.props.hide && this.props.hide(); }}>{t("registerModal.close")}</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>



        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hide: () => { dispatch(showCashierModal(false)) },
    fetchDepositProviders: () => dispatch(fetchProviders() as any),
    showAccountVerificationModal: (show: boolean) => dispatch(showAccountVerificationModal(show)),

});
const mapStateToProps = (app: AppState) => (
    {
        status: app.user.registration && app.user.registration.status,
        selected: app.user.cashierSelected,
        show: app.user.displayCashierModal,
        deposit: app.payments.deposit,
        loggedIn: app.user.user?.loggedIn,
        madeDeposit: app.user.user && app.user.user.madeDeposit,
        isNewUser: app.user.user && app.user.user.newUser,
        activeLng: getContainer().getSettingsService().getActiveLocale().code
    }
);
export default connect(mapStateToProps, mapDispatchToProps)(CashierModal as any);