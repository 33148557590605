import React, { Component } from 'react';

import '../casino/ui/CasinoGameThumbnail.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { prettyUrl } from '../utils/UrlUtils';
import { CasinoGame, CasinoGameTranslation } from '../http/protocol';
import { Link } from 'react-router-dom';
import Translator, { Translation } from '../Translator';
import BackgroundUrlTranslator from '../BackgroundUrlTranslator';
import './Banner.scss';

import { UserInfo } from "../http/protocol";

import { t } from "../i18n";

interface Props extends RouteComponentProps {
	data: any;
	loggedIn: boolean;
	showLogin: () => void;
	lng: string;
	optInBonusPlans?: any;
	user?: UserInfo;
}
interface State {
	listSlides: any;
	visible: boolean;
	mdShow: boolean;
	videoSrc: String;
	bonusPlanId?: any;

}

export class SingleSlideJoiabet extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { bonusPlanId: null as any };
	}
	componentDidMount(): void {
		if (sessionStorage.getItem("bonusPlanId") && !this.state.bonusPlanId) {
			this.setState({ bonusPlanId: sessionStorage.getItem("bonusPlanId") });
		}
	}

	handleBonusPlanId = (id: any) => {
		sessionStorage.setItem("bonusPlanId", id);
		this.setState({ bonusPlanId: id })
	};

	launchGame(props: Props, playforreal?: boolean) {
		// if (props.updateSearchValue) {
		//     props.updateSearchValue("");
		// }
		// console.error(props.data);
		if (props.data && props.data.gameName && !playforreal) {
			props.history.push(`/casino/playforfun/${props.data.gameId}/${prettyUrl(props.data.gameName)}`);
		} else if (props.data && props.data.gameName && playforreal) {
			props.history.push(`/casino/playforreal/${props.data.gameId}/${prettyUrl(props.data.gameName)}`);
		}
	}

	viewGameDetails() {
		return console.log('handleMyGameClick');
	}

	render() {
		const gamesInfo = this.props.data;
		let bonusPlansIsValid;
		if (this.props.optInBonusPlans) {
			bonusPlansIsValid = this.props.optInBonusPlans.filter(b => { if (b.bonusPlanId.toString() === gamesInfo.bonusPlanId) { return true; } });
			// console.error(bonusPlansIsValid);
		}

		return this.props.user && this.props.user.registrationStatus === "PLAYER" && this.props.loggedIn && bonusPlansIsValid && bonusPlansIsValid.length > 0 && gamesInfo.isDepositTrigger ? (
			<div
				className={`slide slick-bg s-bg-1  promo-banner`}
				style={{ backgroundImage: `url(${gamesInfo.mainBanner})` }}
			>
				<video crossOrigin="anonymous" className="videoTag" autoPlay loop muted playsInline>
					<source src={gamesInfo.mainBanner} type="video/mp4" />
				</video>
				<div className="container-fluid position-absolute h-100">
					<div className="slider-inner h-100">
						<div className="row align-items-center  h-100">
							<div className="col-xl-6 col-lg-6 col-md-6  col-xs-6 slider-desc">
								<img
									src={gamesInfo.copyline}
									className="img-fluid copyline-img fadeInLeft"
									data-animation-in="fadeInLeft"
								/>
								<h1
									className="slider-text b-big-title title fadeInLeft"
									data-animation-in="fadeInLeft"
									data-delay-in="1.6"
								>
									{this.props.lng === 'ja-JP' ? gamesInfo.gameNameJP : gamesInfo.gameName}
								</h1>
								<div data-animation-in="fadeInUp" data-delay-in=".3" className="sub-desc fadeInUp" />

								<div
									className="d-flex flex-column  align-items-lg-center align-items-sm-start fadeInLeft  link-desktop"
									data-animation-in="fadeInLeft"
									data-delay-in="2.2"
								>
									{this.state.bonusPlanId == gamesInfo.bonusPlanId ? (

										<a className="btn btn-primary  justify-content-sm-start btn-rounded link-desktop">
												{t("claimed")}
										</a>
									) : this.props.loggedIn ? (<Link to={gamesInfo.termsAndCondition} className="btn btn-primary  justify-content-sm-start btn-rounded link-desktop">
											{t("joinNow")}
									</Link>) : (<Link to="/signup" className="btn btn-primary  justify-content-sm-start btn-rounded link-desktop">
									{t("joinNow")}
									</Link>)}
									{gamesInfo.termsAndCondition ? (
										<Link to={gamesInfo.termsAndCondition}>
											{t("termsAndCondition")}
										</Link>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : gamesInfo.promoType === "maharajaBoost" ? (
			<div
				className={`slide slick-bg s-bg-1  promo-banner`}
				style={{ backgroundImage: `url(${gamesInfo.mainBanner})`, backgroundSize: 'cover', backgroundPositionY: 0 }}
			>
				<video crossOrigin="anonymous" className="videoTag" autoPlay loop muted playsInline>
					<source src={gamesInfo.mainBanner} type="video/mp4" />
				</video>
				<div className="container-fluid position-absolute h-100 boost-promo">
					<div className="slider-inner h-100">
						<div className="row align-items-center  h-100">
							<div className="col-xl-12 col-lg-6 col-md-12  col-xs-6 ">
								<img
									src={gamesInfo.copyline}
									className=" fadeInLeft boost-copyline"
									data-animation-in="fadeInLeft"

								/>
								<h1
									className="slider-text b-big-title title fadeInLeft"
									data-animation-in="fadeInLeft"
									data-delay-in="1.6"
								>
									{this.props.lng === 'ja-JP' ? gamesInfo.gameNameJP : gamesInfo.gameName}
								</h1>
								<div data-animation-in="fadeInUp" data-delay-in=".3" className="sub-desc fadeInUp" />

								<div
									className="d-flex flex-column  align-items-lg-center align-items-sm-start fadeInLeft  link-desktop"
									data-animation-in="fadeInLeft"
									data-delay-in="2.2"
								>
									{this.props.loggedIn ? (<Link to={gamesInfo.termsAndCondition} className="btn btn-primary btn-center justify-content-sm-start btn-rounded">
										{this.props.lng === 'ja-JP' ? '本番をプレーする' : 'VIEW MAHARAJA BOOST'}
									</Link>) : (<Link to="/signup" className="btn btn-primary btn-center justify-content-sm-start btn-rounded">
										{this.props.lng === 'ja-JP' ? '本番をプレーする' : 'JOIN NOW'}
									</Link>)}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : gamesInfo.promoType === "pngSquare" ? (
		<div
				className={`slide slick-bg s-bg-1  promo-banner`}
				style={{ backgroundImage: `url(${gamesInfo.mainBanner})` }}
			>		<a href={gamesInfo.termsAndCondition} target="_blank" style={{ width:"100%", height: "100%", zIndex:99, position:"absolute", cursor: "pointer" }}>
				<video crossOrigin="anonymous" className="videoTag" autoPlay loop muted playsInline>
					<source src={gamesInfo.mainBanner} type="video/mp4" />
				</video>
				</a>
				<div className="container-fluid position-absolute h-100 boost-promo">
				<a href={gamesInfo.termsAndCondition} target="_blank">
						<div className='link-for-mobile'></div>
						</a>
					<div className="slider-inner h-100">
						<div className="row align-items-center  h-100">
					
						</div>
					</div>
				</div>
				
			</div>
		) : !this.props.loggedIn && gamesInfo.isPromo ? (
			<div
				className={`slide slick-bg s-bg-1  promo-banner`}
				style={{ backgroundImage: `url(${gamesInfo.mainBanner})` }}
			>
				<video crossOrigin="anonymous" className="videoTag" autoPlay loop muted playsInline>
					<source src={gamesInfo.mainBanner} type="video/mp4" />
				</video>
				<div className="container-fluid position-absolute h-100">
				{this.props.loggedIn ? (<Link to={gamesInfo.termsAndCondition} >
							<div className='link-for-mobile'></div>
										</Link>) : (<Link to="/signup" >
							<div className='link-for-mobile'></div>
										</Link>)}

					<div className="slider-inner h-100">
						<div className="row align-items-center  h-100">
							<div className="col-xl-6 col-lg-6 col-md-6  col-xs-6  slider-desc">
								<img
									src={gamesInfo.copyline}
									className="img-fluid copyline-img fadeInLeft"
									data-animation-in="fadeInLeft"
								/>
								<h1
									className="slider-text b-big-title title fadeInLeft"
									data-animation-in="fadeInLeft"
									data-delay-in="1.6"
								>
									{this.props.lng === 'ja-JP' ? gamesInfo.gameNameJP : gamesInfo.gameName}
								</h1>
								<div data-animation-in="fadeInUp" data-delay-in=".3" className="sub-desc fadeInUp" />

								<div
									className="d-flex flex-column  align-items-lg-center align-items-sm-start fadeInLeft  link-desktop"
									data-animation-in="fadeInLeft"
									data-delay-in="2.2"
								>
									{this.state.bonusPlanId == gamesInfo.bonusPlanId ? (

										<a className="btn btn-primary  justify-content-sm-start link-desktop btn-rounded">
												{t("claimed")}
										</a>
									) : this.props.loggedIn ? (<Link to={gamesInfo.termsAndCondition} className="btn btn-primary link-desktop justify-content-sm-start btn-rounded">
								{t("joinNow")}
									</Link>) : (<Link to="/signup" className="btn btn-primary  link-desktop justify-content-sm-start btn-rounded">
									{t("joinUs")}
									</Link>)}
									{gamesInfo.termsAndCondition ? (
										<Link to={gamesInfo.termsAndCondition} className='link-desktop'>
											{t("termsAndCondition")}
										</Link>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : gamesInfo.gameId ? (
			<div
				className={`slide slick-bg s-bg-1  game-details promo-banner`}
				style={{ backgroundImage: `url(${gamesInfo.staticBanner})` }}
			>
				<video crossOrigin="anonymous" className="videoTag" autoPlay loop muted playsInline>
					<source src={gamesInfo.mainBanner} type="video/mp4" />
				</video>
				<div className="container-fluid position-absolute h-100">
					<div className="slider-inner h-100">
						<div className="row align-items-center  h-100">
							<div className="col-xl-7 col-lg-12 col-md-12 slider-desc">
								<img
									src={gamesInfo.copyline}
									className="img-fluid copyline-img fadeInLeft"
									data-animation-in="fadeInLeft"
								/>
								{/* <span className="badge-new">
									{this.props.lng === 'ja-JP' ? gamesInfo.labeledJP : gamesInfo.labeled}
								</span> */}
								{/* <h1
									className="slider-text b-big-title title fadeInLeft"
									data-animation-in="fadeInLeft"
									data-delay-in="1.6"
								>
									{this.props.lng === 'ja-JP' ? gamesInfo.gameNameJP : gamesInfo.gameName}
								</h1> */}
								<div data-animation-in="fadeInUp" data-delay-in=".3" className="sub-desc fadeInUp" />

								<div
									className="d-flex flex-column  align-items-lg-center align-items-sm-start fadeInLeft  link-desktop"
									data-animation-in="fadeInLeft"
									data-delay-in="2.2"
								>
									{this.props.loggedIn ? (
										<button
											className="btn btn-primary  justify-content-sm-start btn-rounded link-desktop"
											onPointerDown={() => this.launchGame(this.props, true)}
										>
											{t("playNow")}
										</button>
									) : (
										<button
											className="btn btn-primary  justify-content-sm-start btn-rounded link-desktop"
											onPointerDown={() => this.props.showLogin && this.props.showLogin()}
										>
											{t("playNow")}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		): gamesInfo.pageLink ? (
			<div
				className={`slide slick-bg s-bg-1  game-details promo-banner`}
				style={{ backgroundImage: `url(${gamesInfo.staticBanner})` }}
			>
				<video crossOrigin="anonymous" className="videoTag" autoPlay loop muted playsInline>
					<source src={gamesInfo.mainBanner} type="video/mp4" />
				</video>
				<div className="container-fluid position-absolute h-100">
				<Link to={gamesInfo.pageLink} >
							<div className='link-for-mobile'></div>
										</Link>
					<div className="slider-inner h-100">
						<div className="row align-items-center  h-100">
							<div className="col-xl-7 col-lg-12 col-md-12 mt-3 slider-desc">
								<img
									src={gamesInfo.copyline}
									className="img-fluid copyline-img fadeInLeft"
									data-animation-in="fadeInLeft"
								/>
								<br/>
								<div data-animation-in="fadeInUp" data-delay-in=".3" className="sub-desc fadeInUp" />

								<div
									className="d-flex flex-column  align-items-lg-center align-items-sm-start fadeInLeft  link-desktop"
									data-animation-in="fadeInLeft"
									data-delay-in="2.2"
								>
								
										<Link to={gamesInfo.pageLink} className="btn btn-primary link-desktop btn-center justify-content-sm-start btn-rounded">
											{t("playNow")}
										</Link>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : null;
	}
}

export default withRouter<Props>(SingleSlideJoiabet);
