import React, { Component } from 'react';
import "./casino/LandingCasinoView.scss";

import { getContainer } from "../ioc/IOCSetup";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { scrollTop } from "../actions/HeaderActions";
import { AppState } from "../RootReducer";
import { Link, Redirect } from "react-router-dom";
import MetaHelmet from '../utils/MetaHelmet';

import { t } from "../i18n";

interface dispatchProps {


    scrollVal: any,
    isLoggedIn: any,
    user: any,
    balances: any,
    lng: string,
    location: Location
}
type Props = dispatchProps;

interface State {
    isSticky: any;
    landingPage: any;
}
export class LandingPageView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isSticky: false,
            landingPage: {}
        };

    }

    componentDidMount() {
        const landing = getContainer().getSettingsService().getLandingPages();
    

        let lang = getContainer().getSettingsService().getLocalesData();

        let path ;
        if(lang && lang.length >= 2) {
             path = window.location.pathname.split("/")[3];
        } else {
           path = window.location.pathname.split("/")[2];
        }
        this.setState({ landingPage: landing[path] });
        window.addEventListener('scroll', this.listenToScroll);
        if (this.props.scrollVal > 100) {
            this.setState({ isSticky: true });
        } else {
            this.setState({ isSticky: false });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    }
    componentDidUpdate(prevProps: Props) {
        const locationChanged = this.props.location.pathname !== prevProps.location.pathname;
        const fc_frame = document.getElementById('fc_widget');
        if (fc_frame) {
            fc_frame.style.display = 'none';
            fc_frame.style.height = '0px';
        }

        if (locationChanged) {

            document.body.classList.remove('nav-open');
        }
    }
    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height


        if (this.props.scrollVal > 100) {
            this.setState({ isSticky: true });
        } else {
            this.setState({ isSticky: false });
        }
    }



    render() {

        if (!this.state.landingPage) {
            return <Redirect to={"/"} />
        }

        const keywords: string = t("meta.landingPageView.keywords") || "Maharaja Fortune";

        return (
            <React.Fragment>
                {MetaHelmet(this.state.landingPage && this.state.landingPage.title, this.state.landingPage && this.state.landingPage.description, keywords)}
                <header
                    id="main-header"
                    className={this.state.isSticky ? "menu-sticky animated slideInDown" : ""}
                >
                    <div className="main-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <nav className="navbar navbar-light p-0">
                                        <div className="logo-side">


                                            <span className="navbar-brand">
                                                <img
                                                    className="img-fluid logo"
                                                    src={"https://content.adminemx.com/maharajafortune/images/maharaja-logo.png"}
                                                    alt="Logo"
                                                />
                                            </span>


                                        </div>


                                    </nav>
                                    <div className="nav-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className={`landing-main ${this.state.landingPage && this.state.landingPage.logo ? 'has-logo' : ''} `} style={{ backgroundImage: `url('${this.state.landingPage && this.state.landingPage.backgroundImage}')`, backgroundSize: 'cover' }}>
                    <div className="container-fluid ">
                        {this.state.landingPage && this.state.landingPage.stepsEnable ? null : (<div><br /><br /><br /></div>)}
                        <div className={`slider-inner h-100 ${this.state.landingPage && this.state.landingPage.showRightBgOnMobile ? 'show-right-bg-onmobile' : ''} `}>
                            <div className="row align-items-center" >

                                <div className="col-xl-6 col-lg-6 col-md-6   col-xs-6  d-lg-none d-md-none" style={{ marginTop: "-50px" }}>
                                    {this.state.landingPage && this.state.landingPage.logo ? (<img
                                        src={`${this.state.landingPage && this.state.landingPage.logo}`}
                                        className="img-fluid  fadeInLeft"
                                        data-animation-in="fadeInLeft"
                                    />) : null}
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-xs-6 mt-3 slider-desc">
                                    <img
                                        src={`${this.state.landingPage && this.state.landingPage.copyline}`}
                                        className="img-fluid  fadeInLeft"
                                        data-animation-in="fadeInLeft"
                                    />

                                    <div data-animation-in="fadeInUp" data-delay-in=".3" className="sub-desc fadeInUp" />

                                    <div
                                        className="d-flex flex-column  align-items-lg-center align-items-sm-start fadeInLeft "
                                        data-animation-in="fadeInLeft"
                                        data-delay-in="2.2"
                                    >
                                        <p>{t("landingPageView.18+Promo")}</p>
                                        <Link to="/signup" className="btn btn-primary  justify-content-sm-start btn-rounded">
                                            {t("landingPageView.bonus")}
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6   col-xs-6 mt-3 d-lg-block col-md-6 d-md-block d-sm-none d-none">
                                    {this.state.landingPage && this.state.landingPage.logo ? (<img
                                        src={`${this.state.landingPage && this.state.landingPage.logo}`}
                                        className="img-fluid  fadeInLeft"
                                        data-animation-in="fadeInLeft"
                                    />) : null}

                                </div>

                            </div>

                        </div>
                        {this.state.landingPage && this.state.landingPage.stepsEnable ? null : (<div><br /><br /><br /></div>)}
                    </div>

                    <div className="container-fluid steps-container">
                        {this.state.landingPage && this.state.landingPage.stepsEnable ? (<div className="row align-items-center">
                            <div className="col-xl-4 col-lg-4 col-md-4  col-xs-4 col-4 mt-3">
                                <img
                                    src={"https://content.adminemx.com/maharajafortune/images/landing_page/casino/step-1.png"}
                                    className="img-fluid  fadeInLeft"
                                    data-animation-in="fadeInLeft"
                                />
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4  col-xs-4 col-4 mt-3">
                                <img
                                    src={"https://content.adminemx.com/maharajafortune/images/landing_page/casino/step-2.png"}
                                    className="img-fluid  fadeInLeft"
                                    data-animation-in="fadeInLeft"
                                />
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4  col-xs-4 col-4 mt-3">
                                <img
                                    src={"https://content.adminemx.com/maharajafortune/images/landing_page/casino/step-3.png"}
                                    className="img-fluid  fadeInLeft"
                                    data-animation-in="fadeInLeft"
                                />
                            </div>
                        </div>) : null}

                    </div>
                </div>
                {this.state.landingPage && this.state.landingPage.crossSellEnable ? (
                    <div>
                <div className='payment-second-section'>
                    <div className="col-xl-12">
                        <img
                            src={"https://content.adminemx.com/maharajafortune/landing/payment-title.png"}
                            className="img-fluid  payment-title-img"
                            data-animation-in="fadeInLeft"
                        />
                        <p>{t("landingPageView.payment2ndMethodText")}</p>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-center flex-wrap bank-logo">
                        <img className="img-fluid align-items-baseline"
                            src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-Paytm.png"}
                            alt="Maharaja Fortune"
                            title="Maharaja Fortune"
                        />
                        <img className="img-fluid align-items-baseline"
                            src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/upi_c.png"}
                            alt="Maharaja Fortune"
                            title="Maharaja Fortune"
                        />
                        <img className="img-fluid align-items-baseline"
                            src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-PhonePe.png"}
                            alt="Maharaja Fortune"
                            title="Maharaja Fortune"
                        />
                        <img className="img-fluid align-items-baseline"
                            src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/netbanking_c.png"}
                            alt="Maharaja Fortune"
                            title="Maharaja Fortune"
                        />
                    </div>
                </div>
                <div className='cross-sell-section container'>
                <div className='row'>
                    <div className="col-xl-12">
                        <img
                            src={"https://content.adminemx.com/maharajafortune/landing/cross-sell-title.png"}
                            className="img-fluid  cross-title-img"
                            data-animation-in="fadeInLeft"
                        />

                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-6">
                    <img
                                    src={"https://content.adminemx.com/maharajafortune/landing/cross-sell-banner-1.png"}
                                    className="cross-sell-image"
                                    data-animation-in="fadeInLeft"
                                />
                                            <div className="stepsContainer" >
                                        
                                  
                                                <div className="stepContent">
                                                    
                                                    <p>{t("landingPageView.stepContent1")}</p>
                                                    <Link to="/signup" className="btn btn-secondary btn-sm btn-thin red-bg">
                                                        {t("signUp")}
                                                    </Link>
                                                </div>
                                            </div>
                       </div>
                       <div className="col-sm-12 col-lg-4 col-md-6">
                       <img
                                    src={"https://content.adminemx.com/maharajafortune/landing/cross-sell-banner-2.png"}
                                    className="cross-sell-image-2"
                                    data-animation-in="fadeInLeft"
                                />
                                            <div className="stepsContainer" >
                                    
                                                <div className="stepContent">
                                                    
                                                    <p>{t("landingPageView.stepContent2")}</p>
                                                    <Link to="/signup" className="btn btn-secondary btn-sm btn-thin red-bg">
                                                    {t("signUp")}
                                                    </Link>
                                                </div>
                                            </div>
                       </div>
                       <div className="col-sm-12 col-lg-4 col-md-6">
                       <img
                                    src={"https://content.adminemx.com/maharajafortune/landing/cross-sell-banner-3.png"}
                                    className="cross-sell-image-3"
                                    data-animation-in="fadeInLeft"
                                />
                                            <div className="stepsContainer" >
                                             
                                                <div className="stepContent">
                                      
                                                    <p>{t("landingPageView.stepContent3")}</p>
                                                    <Link to="/signup" className="btn btn-secondary btn-sm btn-thin red-bg">
                                                    {t("signUp")}
                                                    </Link>
                                                </div>
                                            </div>
                       </div>
                       </div>
                </div>
                </div>):null }
                
          
                    <section id="landingPageFooter">
                        <div className="container ">
                            <div className="row d-flex flex-column justify-content-center">

                                <h3>{t("landingPageView.footer")}</h3>
                                <div className="col-sm-12 d-flex justify-content-center flex-wrap bank-logo">
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-Paytm.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/axisbank-1.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-GPay.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-PhonePe.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-BHIM.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/2560px-ICICI_Bank_Logo.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/netbanking_c.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/upi_c.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/HomeInfo/yesbank-1.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                    <img className="img-fluid align-items-baseline"
                                        src={"https://content.adminemx.com/maharajafortune/images/payment_provider/pp-Bank-Transfer.png"}
                                        alt="Maharaja Fortune"
                                        title="Maharaja Fortune"
                                    />
                                </div>
                            </div>
                        </div>

                    </section>
             
            </React.Fragment>
        )
    }


}

const mapDispatchToProps = (dispatch: Dispatch) => ({

    scrollTop: (position: any = 0) => dispatch(scrollTop(position) as any)
});



const mapStateToProps = (app: AppState) => {



    return {

        user: app.user.user,
        isLoggedIn: app.user.user !== undefined ? app.user.user.loggedIn : undefined,
        balances: app.user.balances,
        lng: getContainer().getSettingsService().getActiveLocale().code,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LandingPageView as any));