import React from 'react';
import "./ShowHidePassword.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
    children: any;
    type?: string;
}

export class ShowHidePassword extends React.Component<Props>{
    state = { clickEye: false };

    handleClick = () => {
        this.setState({ clickEye: !this.state.clickEye });
    }

   render() {
    const { clickEye } = this.state;
    const type = clickEye ? "text" : "password";
    const childrenWithProps = React.Children.map(this.props.children, child =>
        React.cloneElement(child, {type})
    );

       return (
        <div className={"form-group password"}>
            <div className={"input-password-wrapper"}>
                {
                    childrenWithProps
                }
            </div>
            <span className={"eye-password"} id="eyePassword" onClick={this.handleClick} >
                {clickEye ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye-slash" />}
            </span>
        </div>
        )
   }
}

