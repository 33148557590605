import React, {lazy} from "react";
import "./RegisterView.scss";
import {Settings, Status} from "../../http/protocol";
import {connect} from "react-redux";
import {AppState} from "../../RootReducer";
import {RouteComponentProps} from "react-router-dom";
import {Redirect} from "react-router";
import getLoggedInRedirect from "../../utils/LoggedInRedirect";
import './RegisterSuccess.scss';
import {ContentModelName, GlobalPayload, RegsiterSuccessPayload} from "../../contenful/types";
import {t} from "../../i18n";
import {Dispatch} from "redux";
import {setNewUser} from "../UserActions";
import {fetchContent} from "../../contenful/ContentfulActions";
import Root from "../../layout/Root";
import Header from "../../layout/Header";
import Page from "../../layout/Page";
import {getContainer} from "../../ioc/IOCSetup";

interface Props extends RouteComponentProps {
    status?: Status;
    regSuccess?:RegsiterSuccessPayload;
    global?:GlobalPayload;
    loggedIn?: boolean;
    setNewUser?: typeof setNewUser;
    fetchContent: typeof fetchContent;
    lng:string;
}

interface State {
    startPlaying:boolean;
    deposit:boolean;
    goBack:boolean;
}

export class CompleteRegisterSuccess extends React.Component<Props, State>{
    constructor(props:Props) {
        super(props);

        this.state = {startPlaying:false, deposit:false, goBack:false}
    }

    componentDidMount() {
        // if(this.props.global === undefined) {
        //     this.props.fetchContent(ContentModelName.GLOBAL);
        // }
        if(this.props.regSuccess === undefined) {
            this.props.fetchContent(ContentModelName.REGISTERSUCCESS,  getContainer().getSettingsService().getActiveLocale().code);
          
        }
    }

    onStartPlaying() {
        this.props.setNewUser && this.props.setNewUser(false);
        this.setState({startPlaying:true});
       
    }
    componentDidUpdate(){

    }
    onDeposit() {
        this.setState({deposit:true});
    }

    render() {
        // if(this.state.goBack) {
        //     return <Redirect to={"/"} />
        // }
        if(this.state.startPlaying) {
            return <Redirect to={"/"} />
        }
        if(this.state.deposit) {
            return <Redirect to={"/deposit"} />
        }
        // if(!this.props.loggedIn) {
        //     return <Redirect to={"/"} />
        // }
        const imgUrl = this.props.regSuccess && this.props.regSuccess.successImg.fields.file.url;
        const depositText = this.props.global && this.props.global.depositButton;
        const startText = this.props.global && this.props.global.startPlaying;
 
        return (
            <Root footer={true} header={true}>
                
                <Page noPaddingTop={true}>
                    <div className="registration-success-container">
                        <div className="success-image">
                            <img src={imgUrl} alt={"Success-image"}/>
                        </div>
                        <h1 className="title">
                            {this.props.regSuccess && this.props.regSuccess.welcomeText}
                        </h1>
                        <h5 className="info">
                        {this.props.regSuccess && this.props.regSuccess.descriptText}
                        </h5>
                        <div className="buttons">
                            <div className="row">
                                <button onClick={() => this.onDeposit()} className="btn btn-cta btn-lg">{this.props.lng === "ja-JP" ? "預り金" :"Deposit"}</button>
                            </div>
                            <div className="row">
                                <span onClick={() => this.onStartPlaying()} className="deposit">{this.props.lng === "ja-JP" ? "ロビーに戻る" :"Go back to lobby"} </span>
                            </div>
                        </div>
                    </div>
                </Page>
            </Root>
        );
    }
}

const mapStateToProps = ({user,settings, contentful} : AppState) => (
    {
        status : user.registration && user.registration.status,
        settings: settings.settings,
        regSuccess: contentful.registersuccessMaharajaFortune,
        loggedIn: user.user && user.user.loggedIn,
        lng: getContainer().getSettingsService().getActiveLocale().code,
    }
);


let dispatchToProps = (dispatch : Dispatch) => ({
    setNewUser : (newUser:boolean)=>dispatch(setNewUser(newUser) as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
});

export default connect(mapStateToProps, dispatchToProps)(CompleteRegisterSuccess);