import { combineReducers } from 'redux';
import {userReducer, UserState} from "./user/UserReducer";
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {casinoReducer, CasinoState} from "./casino/CasinoReducer";
import {livecasinoReducer, LiveCasinoState} from "./livecasino/LiveCasinoReducer";
import {navigationReducer, NavigationState} from "./navigation/NavigationReducer";
import {PaymentsState} from "./payments/PaymentsReducer";
import {paymentsReducer} from "./payments/PaymentsReducer";

import {contentfulReducer, ContentfulState} from "./contenful/ContentfulReducer";
import {settingsReducer, SettingsState} from "./settings/SettingsReducer";
import {storeReducer, StoreState} from "./store/StoreReducer";
import {bonusReducer, BonusStoreState} from "./bonus/BonusReducer";
import {CasinoCategoryType} from "./actions/CasinoCategoryActions";
import {HeaderType} from "./actions/HeaderActions";
import casinoCategoryReducer from "./reducers/CasinoCategoryReducer";
import {headerReducer} from "./reducers/HeaderReducer";
import {composeWithDevTools} from "redux-devtools-extension";
import { passwordReducer, PasswordState } from './settings/PasswordReducer';
import { fileUploadReducer, FileUploadState } from './kyc/FileUploadReducer';
import { transactionHistoryReducer, TransactionHistoryState } from './transactions/TransactionReducer';
import { profileReducer, ProfileState } from './profilepage/ProfileReducer';

export interface AppState {
    user : UserState;
    casino: CasinoState;
    livecasino: LiveCasinoState;
    navigation : NavigationState;
    payments : PaymentsState,
    casinoCat:CasinoCategoryType,
    header:HeaderType,
    settings: SettingsState,
    store: StoreState,
    bonus : BonusStoreState,
    contentful: ContentfulState,
    password: PasswordState,
    fileUpload: FileUploadState,
    transactionHistory: TransactionHistoryState,
    profileState: ProfileState
}

const rootReducer = combineReducers({
    user: userReducer as any,
    casino: casinoReducer as any,
    livecasino: livecasinoReducer as any,
    navigation : navigationReducer as any,
    payments : paymentsReducer as any,
    form: formReducer,
    casinoCat: casinoCategoryReducer as any,
    header: headerReducer as any,
    settings: settingsReducer,
    store: storeReducer,
    bonus : bonusReducer,
    contentful: contentfulReducer,
    password: passwordReducer,
    fileUpload: fileUploadReducer,
    transactionHistory: transactionHistoryReducer,
    profileState: profileReducer
});

export function configureStore() {
    return createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(thunk))
    );
}