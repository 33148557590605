import {LaunchUrl} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class RacingService {
    constructor(private http : HttpService) {}

    getGameLaunchUrl(currency:string, locale : string) : Promise<LaunchUrl>{
        return this.http.get("/racebook/latrobet/launch/"+currency+"/" + locale);
    }

}



