export interface Content {
    content: any;
    data: any;
    nodeType: string;
}

export interface LandingPagePayload {
    title: string;
    subtitle: string;
    buttonTitle: string,
    buttonSubtitle: string;
    content1: string;
    content2: string;
    content3: string;
    backgroundImage: ImageObject;
    promotionAreaTitle?: string;
    promotionArea?: ImageObject[];
    bottomPromotion?: string;
    dynamicContent?: Array<DynamicContentPayload>;
    bannerContentPosition?: string[] | undefined;
    bannerSignupForm?: boolean | undefined;
    sliderPromotionArea?: boolean | undefined;
    sliderPromotionAreaTiming?: string[] | undefined;
}
export interface CasinoBannerPayload {
    title?: string;
    backgroundImage: ImageObject;
    smallImage? : ImageObject;
    content? : string;
    gameId? : number;
}

export interface LiveCasinoBannerPayload {
    title?: string;
    backgroundImage: ImageObject;
    smallImage? : ImageObject;
    content? : string;
    gameId? : number;
}
// add types here that belongs to dynamicContent example PageSection | Leaderboard
export type DynamicContentType = PageSection | SectionBoxes | InfoPageAccordion;

export interface DynamicContentPayload {
    fields: DynamicContentType;
    sys: {contentType: {sys: {id: string}}}
}

export interface PageSection {
    image?: ImageObject;
    text: any;
    textColor: string;
    imageLeftSide: boolean;
    backgroundColor: string;
    borderColor: string;
    name?: string;
}


export interface ImageObject {
    fields: {file: {url: string, details: any, fileName: string, contentType: string}, title: string, description: string}
}

export interface SlidersMaharajaPayload {
    slider1?:SliderObject;
    casinoSlider?:SliderObject;
    sportsbookSlider?:SliderObject;
    livecasinoSlider?:SliderObject;
}
export interface SliderObject {
    banner: {file: {gameId: string, gameName: string, gameNameJP: string, isMobile: string, isFreespinEnable: string, isDemoEnabled: string, supportMobile: string, mainBanner: string}}
}

export interface CasinoGamesPayload {
    casinoGames?:GameObject;
}
export interface LiveCasinoGamesPayload {
    livecasinoGames?:GameObject;
}
export interface GameObject {
    gamesInfo: [ {id?: number;
        gameId?:string;
        name?: string;
        gameName?:string;
        gameNameJP?:string;
        gameLaunchId?:string;
        gameCategoryId?:number;
        gameCategoryName?:string;
        segmentId?:string;
        segmentName?:string;
        productId?:string;
        enabled?: boolean;
        productCode?:string;
        subProductName?:string;
        subProductId?:string;
        subProductCode?:string;
        isMobile?:boolean;
        isFreespinEnable?:boolean;
        mobile?: boolean;
        desktop?: boolean;
        playForFun?: boolean;
        isDemoEnabled?:boolean;
        imageUrl?: string;
     
        GameTrailerNameMOBILE?: string;
        GameTrailerNameDESKTOP?: string;
        InGameScreenshot?: string;
        GameIconName?: string;
        updated?: Date;
        externalId?: string;
        mobileExternalId?: string;
     
        removed?: boolean;
        attributes?: { [index: string]: string };
        created?: Date;
      
        backgroundImageUrl?: string;
        GameBannerName?:string;
        mobileOnly?:boolean;
        isTopGame?:boolean;
        isNewGame?:boolean;
        isJackpots?:boolean;
        isMobileDesktop?:boolean;
        isDesktopOnly?:boolean;
        isMobileOnly?:boolean;
        homeTopGames?: boolean;
        homeMostPopular?:boolean;
        homeRecommended?:boolean;
        isPopularGame?:boolean;}]
}

export interface OnboardingPagePayload {
    title: string;
    subtitle: string;
    terms: string;
    registerSuccessLogo: ImageObject;
    loginButton: string;
    loginText: string;
    maxDecimalsWarning: string;
    recoverPassword: string;
    recoverPasswordTitle: string;
    recoverPasswordText: string
    resetPassword: string;
    resetPasswordTitle: string;
    resetPasswordText: string;
    infoText: string;
    registerCustomAttributesHeader: string;
}

export interface WithdrawPagePayloadV2 {
    pageTitle: string;
    pageDescription: string;
    progressBar: Array<string>;
    minLabel: string;
    maxLabel: string;
    inputAmountTitle: string;
    amountField: string;
    buttonMyAccount: string;
    buttonBack: string;
    buttonContinue: string;
}

export interface DepositPagePayload {
    pageTitle: string;
    pageDescription: string;
    progressBar: Array<string>;
    minLabel: string;
    maxLabel: string;
    inputAmountTitle: string;
    amountField: string;
    buttonMyAccount: string;
    buttonBack: string;
    buttonContinue: string;
}

export interface WithdrawPagePayload {
    withdrawTitle: string;
    addressPhaseTitle: string;
    addressPhaseLabel: string;
    amountPhaseTitle: string;
    amountPhaseText1: string;
    verifyPhaseTitle: string;
    verifyPhaseText1: string;
    verifyPhaseText2: string;
    verifyPhaseText3: string;
    summaryPhaseTitle: string;
    summaryPhaseText1: string;
    summaryPhaseTitleRejected: string;
    summaryPhaseTextRejected1: string;
    summaryPhaseTitlePending: string;
    summaryPhaseTextPending1: string;
    summaryPhaseRequestAlreadyOpenTitle: string;
    summaryPhaseRequestAlreadyOpenText: string;
    withdrawButtonText1: string;
    withdrawButtonText2: string;
    maxAmountButtonText: string;
    kycVerificationHeader: string;
    kycVerificationText: string;
    kycVerificationInProgressText: string;
}

export enum ContentModelName {
    LANDINGPAGE = "landingpage",
    ONBOARDINGPAGE = "onboardingpage",
    REGISTERSUCCESS = "registersuccessMaharajaFortune",
    WITHDRAWPAGE  = "withdrawPage",
    DEPOSITPAGE = "depositPage",
    GLOBAL = "global",
    POKERPAGE = "pokerPage",
    PROFILEPAGE = "profilePage",
    INFOPAGE = "infoPage",
    CASINO_BANNER ="casinoBanner",
    LIVE_CASINO_BANNER ="livecasinoBanner",
    FOOTER = "footerPayload",
    PAGESECTION = "pageSection",
    SECTIONBOXES = "sectionBoxes",
    POPUPAPPINSTALL = "popupAppInstall",
    POPUPNOTIFICATION = "popupNotification",
    POPUPMANAGER = "popupManager",
    SPORTSPAGE = "sportsPage",
    INFOPAGEACCORDION = "infoPageAccordion",
    SQUARESPAGE = "squaresPage",
    TERMSANDCONDITION = "termsAndConditionsMaharajaFortune",
    TERMSANDCONDITIONJOIABET = "termsAndConditionsJoiabet",
    HOME = "home",
    FAQ = "FAQ",
    SLIDERSMAHARAJA = "slidersMaharaja",
    REGISTER = "register",
    COMPLETEREGISTRATION="completeRegistration",
    CASINOGAMES ="casinoGames",
    LIVECASINOGAMES ="livecasinoGames"
}


export interface RegsiterSuccessPayload {
    successImg: ImageObject;
    welcomeText?: string;
    descriptText?:string;
}

export interface GlobalPayload {
    brandName:string;
    logo: ImageObject;
    favicon: ImageObject;
    depositButton: string;
    withdrawButton: string;
    goToLobby: string;
    startPlaying: string;
    addressLoadingText: string;
}

export interface PokerPagePayload {
    mainTitle: string;
    subtitle: string;
    banner: ImageObject;
    playButtonText: string;
    promotionType: string;
    promotion: string;
    promotionTime: string;
    dynamicContent?: Array<DynamicContentPayload>;
}
export interface TermsAndConditionPayload {
    termsAndConditions: RichText;
    responsibleGambling: RichText;
    amlPolicy: RichText;
    kycPolicy: RichText;
    bonusTerms: RichText;
    privacyPolicy: RichText;
    cookiePolicy: RichText;
}
export interface FAQPayload {
    faq: string;
    registrationAccount: string;

    iForgotMyPasswordWhatShouldIDoNow:RichText;
    canIChangeMyPassword:RichText;
    canIChangeMyAccountInformation:RichText;
    whyDoINeedToRegisterMyPersonalInformation:RichText;
}

export interface ProfilePagePayload {
    title?: string;
    submitButton?: string;
    titleAccountSetting?:string;
    titleManageFunds?:string;
    manageFundsContent?:string[] ;
}

export interface FooterPayload {
    home?:string;
    recommended?:string;
    myFavorites?:string;
    popular?:string;
    latest?:string;
    upcoming?:string;
    myAccount?:string;
    notification?:string;
    settings?:string;
    termsAndConditions?:string;
    promotions?:string;
    faq?:string;
    logIn?:string;
    signUp?:string;
    weCreatePossibilitiesForTheConnectedWorld?:string;
    gameProviders?:string;
    paymentProviders?:string;
    legal?:string;
    responsibleGaming?:string;
    kycPolicy?:string;
    amlPolicy?:string;
    footerTextLast?:RichText;
    newGames?:string;
    liveCasino?:string;
    casino?:string;
    other?:string;
    helpCenter?:string;
    bonusTerms?:string;
    cookiePolicy?:string;
    privacyPolicy?:string;
    logOut?:string;
}

export interface RegisterPayload {
    register?:string;
    username?:string;
    password?:string;
    email?:string;
    currency?:string;
    privacyPolicy?:RichText;
    yearsOfAge?:RichText;
    submit?:string;
}

export interface CompleteRegistrationPayload {
    completeRegistration?:string;
    completeRegistrationLabel?:string;
    firstName?:string;
    lastName?:string;
    gender?:string;
    birthDate?:string;
    address?:string;
    city?:string;
    country?:string;
    postalCode?:string;
    mobileNumber?:string;
    securityQuestion?:string;
    answer?:string;
    submit?:string;
}
export interface InfoPage {
    body: any;
    highlight: boolean;
    order: number;
    id: string;
    dynamicContent?: Array<DynamicContentPayload>;
}

export interface InfoPagePayload {
    fields: InfoPage;
}

export interface Locale {
    code: string;
    name: string;
    defaultLocale?: boolean;
    fallback: string | null;
}

export interface SectionBoxes {
    name: string;
    title1?: string;
    title2?: string;
    title3?: string;
    title4?: string;
    text1?: any;
    text2?: any;
    text3?: any;
    text4?: any;
    icon1?: ImageObject;
    icon2?: ImageObject;
    icon3?: ImageObject;
    icon4?: ImageObject;
    textColor?: string;
    backgroundColorBox1?: string;
    backgroundColorBox2?: string;
    backgroundColorBox3?: string;
    backgroundColorBox4?: string;
    backgroundColorSection?: string;
    backgroundGradientSection?: string;
    backgroundImage?: ImageObject;
    borderColor?: string;
    iconLeft?: boolean;
    circleBox?: boolean;
    borderColorBox?: string;
}

export interface PopupAppInstall {
    image?: ImageObject;
    title?: string;
    description?: string;
    buttonText?: string;
}

export type PopupType = Popup;

export interface PopupPayload {
    fields: PopupType;
    sys: {contentType: {sys: {id: string}}};
}
export interface Popup {
    id: string;
    header?: string;
    text?: any;
    image?: ImageObject;
    showOnlyOnce?: boolean;
}

export interface PopupManager {
    id: string;
    login?: Popup;
    visit?: Popup;
}

export interface HomePayload {
    viewMore?: string;
    topGames?: string;
    newGames?: string;
    playAgain?: string;
    mostPopular?: string;
}

export interface PopupNotification {
    title?:string;
    message?:string;
    approveButtonText?:string;
    declineButtonText?:string;
}

export interface SportsPagePayload {
    dynamicContent?: Array<DynamicContentPayload>;
}

export interface InfoPageAccordion {
    title?: string;
    text?: string;
    id: any;
    index?: string;
}
export interface RichText {
    
    content?: Content;
    data: any;
    nodeType?: Document;
}