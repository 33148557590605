import {HttpService} from "../http/HttpService";
import {UserService} from "../user/UserService";
import {SettingsUrl} from "../http/Types";
import {CasinoService} from "../casino/CasinoService";
import {LiveCasinoService} from "../livecasino/LiveCasinoService";
import {PokerService} from "../casino/PokerService";
import {PaymentsService} from "../payments/PaymentsService";
import {TransactionService} from "../transactions/TransactionService";
import ContentfulClientService from "../contenful/ContentfulClientService";
import {BundleServicee} from "../store/BundleService";
import {SettingsService} from "../settings/SettingsService";
import {FacebookApi} from "../FacebookApi";
import { SportsbookService } from "../sports/SportsbookService";
import {AmplitudeService} from "../amplitude/AmplitudeService";
import {NotificationsService} from "../notifications/NotificationsService";
import {RacingService} from "../racing/RacingService";
import {GeoIpService} from "../user/GeoIpService";
import {UserBonusService} from "../bonus/UserBonusService";
import { PasswordService } from "../settings/PasswordService";
import { FileUploadService } from "../kyc/FileUploadService";
import {ProfileService} from "../profilepage/ProfileService";



interface AppContext {
    getUserService() : UserService;
    getHttpService() : HttpService;
    getCasinoService() : CasinoService;
    getLiveCasinoService() : LiveCasinoService;
    getPokerService() : PokerService;
    getSportsbookService() : SportsbookService;
    getRacingService() : RacingService;
    getPaymentsService() : PaymentsService;
    getTransactionService() : TransactionService;
    getContentfulClientService(): ContentfulClientService;
    getSettingsService(): SettingsService;
    getFacebookApi() : FacebookApi;
    getBundleService(): BundleServicee;
    getAmplitudeService(): AmplitudeService | null;
    getNotificationsService(): NotificationsService;
    getGeoIpService() : GeoIpService;
    getUserBonusService() : UserBonusService;
    getPasswordService(): PasswordService;
    getFileUploadService(): FileUploadService;
    getProfileService(): ProfileService;
}
export function wireApplication(settings : SettingsUrl, spaceId:string, accessToken:string, fb : FacebookApi, amplitudeApiKey: string | undefined, useAmplitude: boolean | undefined) {
    let httpService = new HttpService(settings.baseUrl,settings.casinoUrl,settings.liveCasinoUrl,settings.brandId);
    let userService = new UserService(httpService);
    let casinoService = new CasinoService(httpService);
    let livecasinoService = new LiveCasinoService(httpService);
    let pokerService = new PokerService(httpService);
    let sportsbookService = new SportsbookService(httpService);
    let racingService = new RacingService(httpService);
    let paymentsService = new PaymentsService(httpService);
    let transactionService = new TransactionService(httpService);
    let contentfulClientService = new ContentfulClientService(spaceId, accessToken, false);
    let settingsService = SettingsService.getInstance(httpService);
    let bundleService = new BundleServicee(httpService);
    let amplitudeService = AmplitudeService.getInstance(amplitudeApiKey, useAmplitude);
    let notificationsService = new NotificationsService(httpService);
    let geoIpService = new GeoIpService(httpService);
    let userBonusService = new UserBonusService(httpService);
    let passwordService = new PasswordService(httpService);
    let fileUploadService = new FileUploadService(httpService);
    let profileService = new ProfileService(httpService);
    container = {
        getHttpService: () => httpService,
        getUserService: () => userService,
        getCasinoService: () => casinoService,
        getLiveCasinoService: () => livecasinoService,
        getPokerService : () => pokerService,
        getSportsbookService: () => sportsbookService,
        getRacingService: () => racingService,
        getPaymentsService : () => paymentsService,
        getTransactionService : () => transactionService,
        getContentfulClientService: () => contentfulClientService,
        getSettingsService : () => settingsService,
        getFacebookApi  :() => fb,
        getBundleService: () => bundleService,
        getAmplitudeService: () => amplitudeService,
        getNotificationsService: () => notificationsService,
        getGeoIpService: ()=> geoIpService,
        getUserBonusService : () => userBonusService,
        getPasswordService : () => passwordService,
        getFileUploadService : () => fileUploadService,
        getProfileService : () => profileService
    };
}
export function getContainer() : AppContext {
    return container;
}var container : AppContext;