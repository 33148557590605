import {Dispatch} from "redux";
import axios from "axios";

export const CASINO_CATEGORY_LOADING = "CASINO_CATEGORY_LOADING";
export const CASINO_CATEGORY_FAIL = "CASINO_CATEGORY_FAIL";
export const CASINO_CATEGORY_SUCCESS = "CASINO_CATEGORY_SUCCESS";


export type TopGames = {
  imageUrl: string,
  title: string,
  year: string,
  age: string,
  shortDesc: string

}


export type PlayAgainGames = {
  imageUrl: string,
  title: string,
  year: string,
  age: string,
  shortDesc: string

}

export type NewGames = {
  imageUrl: string,
  title: string,
  year: string,
  age: string,
  shortDesc: string

}
export type MostPopularGames = {
  imageUrl: string,
  title: string,
  year: string,
  age: string,
  shortDesc: string

}
export type CasinoGameCategory = {
  imageUrl: string,
  title: string,
}
export type casinoCategory = {
  topGames: TopGames[],
  playAgainGames: PlayAgainGames[],
  newGames: NewGames[],
  mostPopularGames:MostPopularGames[],
  category: CasinoGameCategory[],
  banner:any
  }

  
export interface CasinoCategoryType {
  casinoCategory:casinoCategory
}



export interface CasinoCategoryLoading {
  type: typeof CASINO_CATEGORY_LOADING
}

export interface CasinoCategoryFail {
  type: typeof CASINO_CATEGORY_FAIL
}

export interface CasinoCategorySuccess {
  type: typeof CASINO_CATEGORY_SUCCESS,
  payload: CasinoCategoryType
}

export type CasinoCategoryDispatchTypes = CasinoCategoryLoading | CasinoCategoryFail | CasinoCategorySuccess
export const getCasinoCategory = () => async (dispatch: Dispatch<CasinoCategoryDispatchTypes>) => {
  try {
    dispatch({
      type: CASINO_CATEGORY_LOADING
    })

    const res = await axios.get(`/data/StaticAPI.json`);
    dispatch({
      type: CASINO_CATEGORY_SUCCESS,
      payload: res.data
      
    })

  } catch(e) {
    console.error(e)
    dispatch({
      type: CASINO_CATEGORY_FAIL
     
    })
 
  }
};