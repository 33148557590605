import { Dispatch } from "redux";
import { UpdatePasswordRequest, UpdatePasswordResult } from "../http/protocol";
import { getContainer } from "../ioc/IOCSetup";
import { AppState } from "../RootReducer";

export enum PasswordActionType {
  UPDATE_PASSWORD_REQUEST = "@settings/updatePassword/request",
  UPDATE_PASSWORD_SUCCESS = "@settings/updatePassword/success",
  UPDATE_PASSWORD_ERROR = "@settings/updatePassword/error"
}

export type PasswordActions =
    | { type : PasswordActionType.UPDATE_PASSWORD_REQUEST}
    | { type : PasswordActionType.UPDATE_PASSWORD_SUCCESS, result : UpdatePasswordResult }
    | { type : PasswordActionType.UPDATE_PASSWORD_ERROR, error : Error }


export function updatePassword(request: UpdatePasswordRequest) {
  console.log('PROCESS UPDATE PASSWORD', request)
  return (dispatch : Dispatch, getState :() => AppState) => {
    let passwordState = getState().password;
    dispatch({type : PasswordActionType.UPDATE_PASSWORD_REQUEST});
    getContainer().getPasswordService().updatePassword(request)
        .then((r: UpdatePasswordResult) => {
          console.log(r.status, "STATUS");
            if (r.status !== undefined) {
                if (r.status == 'SUCCESS') {
                  passwordState.status = r.status;
                  dispatch({type : PasswordActionType.UPDATE_PASSWORD_SUCCESS, result: r});
                } else {
                  dispatch({ type : PasswordActionType.UPDATE_PASSWORD_ERROR, error : r.errors});
                }
            }
    }).catch(err => {
      dispatch({ type : PasswordActionType.UPDATE_PASSWORD_ERROR, error : err});
    })
  }
}