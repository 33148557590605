import React from "react";
import "./styles/LanguageMenu.scss";
import {Locale} from "../../contenful/types";
import locales from '../../locales.json';
import {FlagIcon} from "react-flag-kit";
import {getCountryByLocale} from "../../i18n";

const Item = (props : {locale : Locale, onClick : (language?: string) => void }) => {
    let country, language = "";
    // @ts-ignore
    let primaryDialect:any = locales["primary-dialects"][props.locale.code]  || "";
    // @ts-ignore
    let languageName = locales["language-names"][primaryDialect] && locales["language-names"][primaryDialect][0] || "";

    let langCountry = props.locale.code.split("-");
    if(langCountry.length == 2) {
        // console.log("country found!")
        language = langCountry[0]
        country = langCountry[1]
        // @ts-ignore
        languageName = locales["language-names"][props.locale.code] && locales["language-names"][props.locale.code][0] || "";
    }

    // console.log(language," ",  country)

    return (
        <div onClick={()=>{props.onClick(props.locale.code)}} className={"dropdownItem"}>
            {props.locale &&
                <div className={"item"}>
                    <span style={{paddingLeft:"10px"}}>{country && country.length > 0 ? <FlagIcon code={getCountryByLocale(props.locale.code) as any} size={29} /> : <span style={{width:"29px", display:"inline-block"}}>{props.locale.code.toUpperCase()}</span> } {languageName}</span>
                </div>
            }
        </div>
    )
}



export default Item;