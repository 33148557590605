import React, {Fragment} from "react";
import {connect} from "react-redux";
import "./LiveCasinoGames.scss";
import {AppState} from "../../RootReducer";
import LiveCasinoGameThumbnail from "./LiveCasinoGameThumbnail";
import {LiveCasinoCurrencies, LiveCasinoGame, LiveCasinoLobbyCategory} from "../../http/protocol";
import {LoginStatus} from "../../http/Types";
import {extractGamesByCategory, toggleFavouriteGame, updateSearchValue} from "../LiveCasinoActions";
import {extractCategoryTitle, isStudioAllowed} from "./LiveCasinoCategories";
import {Dispatch} from "redux";
import InfiniteScroll from "react-infinite-scroller";
import {Device} from "../../utils/Device";
import {t} from "./../../i18n";
import {LiveCasinoStatus} from "../LiveCasinoReducer";
import { logout, showLogin } from "../../user/UserActions";
import LiveCasinoSearchResults from "./LiveCasinoSearchResults";
import {getContainer} from "../../ioc/IOCSetup";
interface Props {
    games: LiveCasinoGame[];
    loggedIn?: boolean;
    recentlyPlayed?: LiveCasinoGame[];
    currentCategory? : LiveCasinoLobbyCategory;
    updateSearchValue? : (str : string) => void;
    favourites? : LiveCasinoGame[];
    toggleFavourite : (id : string) => void;
    displayFavourites? : boolean;
    status? : LiveCasinoStatus;
    showLogin?: () => void;
    searchValue?: string;
    lng: string;
}
interface State {
    page : number;
    start : number;
    stop : number;
}
const PAGE_SIZE = 36;
export class LiveCasinoGames extends React.Component<Props, State> {
    constructor(props : Props) {
        super(props);
        this.state = { page : 0, start : 0, stop : PAGE_SIZE };
    }
    load(page : number) {
        this.setState({ page : page, start : 0, stop : page * PAGE_SIZE});
        // console.error(page);
    }
    hasMore() {
        return this.state.stop < this.props.games.length;
    }

    render() {
        let props = this.props;
        let activeLang = getContainer().getSettingsService().getActiveLocale().code;
        return (
            <div className={"main-container"}>
                <div className={"container container-fluid"}>
                    <div className={"livecasino-games-container"}>
                        {/* { props.displayFavourites && props.favourites && props.favourites.length > 0 && (
                            <Fragment>
                                <div className={"row games-list title"}>
                                    <h5>{"Favourites"}</h5>
                                </div>
                                <div className={"row games-list"}>
                                    {
                                        props.favourites.map((g : LiveCasinoGame) => <LiveCasinoGameThumbnail
                                            compact={true}
                                            favourite={props.favourites && props.favourites.some(f => f.id == g.id)}
                                            toggleFavourite={(id)=>props.toggleFavourite(id)}
                                            updateSearchValue={(str)=> props.updateSearchValue && props.updateSearchValue(str)}
                                            key={"favourites" + g.GameId}
                                            loggedIn={props.loggedIn} game={g}/>)
                                    }
                                </div>
                            </Fragment>
                        )} */}
                        {props.games.length === 0 && props.searchValue && props.searchValue.length > 1 && (
                            <div>{"GAME NOT FOUND" + ": " + props.searchValue}</div>
                          
                       
                        )}
                        { props.recentlyPlayed && props.recentlyPlayed.length > 0 && (
                            <Fragment>
                                <div className={"row games-list title"}>
                                <h5>{t("recentlyPlayed")}</h5>
                                </div>
                                <div className={"row games-list"}>
                                    {
                                        props.recentlyPlayed.map((g : LiveCasinoGame) => <LiveCasinoGameThumbnail
                                            compact={true}
                                            favourite={props.favourites && props.favourites.some(f => f.GameId == g.GameId)}
                                            toggleFavourite={(id)=>props.toggleFavourite(id)}
                                            updateSearchValue={(str)=> props.updateSearchValue && props.updateSearchValue(str)}
                                            key={"recently" + g.GameId}

                                            loggedIn={props.loggedIn} game={g} showLogin={props.showLogin}  activeLng={props.lng}/>)
                 }
                                </div>
                            </Fragment>
                        )}
                         {   props.currentCategory && (
                            <div className={"row games-list title"}>
                                {activeLang !== 'en-US' && props.currentCategory.Translation ? ( <><h5>{props.currentCategory.Translation}</h5></> )
                                :(<><h5>{props.currentCategory.Name}</h5></>)
                                    }

                            </div>
                       
                        ) }
                        <div>
                            <InfiniteScroll
                                className={"row games-list"}
                                pageStart={0}
                                loadMore={(page) => this.load(page)}
                                hasMore={this.hasMore()}
                                loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                {
                                    props.games && props.games.slice(this.state.start, this.state.stop).map((g : LiveCasinoGame) =>
                                        <LiveCasinoGameThumbnail
                                        updateSearchValue={(str)=> props.updateSearchValue && props.updateSearchValue(str)}
                                        favourite={props.favourites && props.favourites.some(f => f.GameId == g.GameId)}
                                        toggleFavourite={(id)=>props.toggleFavourite(id)}
                                        key={g.GameId} loggedIn={props.loggedIn} game={g} showLogin={props.showLogin} activeLng={props.lng}/>
                                    )
                                }
                            </InfiniteScroll>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export const isSupportedCurrency = (g?: LiveCasinoGame, livecasinoCurrencies? : LiveCasinoCurrencies[], activeCurrency?:string, loggedIn?:boolean) => {

    if(!livecasinoCurrencies || !g) {
        return false;
    }

    if(!activeCurrency && !loggedIn) {
        return true;
    }

    if(loggedIn === undefined) {
        return true;
    }

    let c : LiveCasinoCurrencies | undefined = livecasinoCurrencies.find( c => c.integration! == g.integration! && c.studioName! == g.studio!.id);

    if(c && c.currencies) {
        let allowed = c.currencies.find(currency => currency == activeCurrency);
        if(allowed) {
            return true;
        }

    }
    return false;
}
export const isSupportedPlatform = (g? : LiveCasinoGame) => {
    if(g) {
        if(Device.isMobile() && g.mobileExternalId) {
            return true;
        } else if(!Device.isMobile() && g.externalId) {
            return true;
        }
        return false;
    }
    return false;
}

const mapStateToProps = (app : AppState) => {
    let games : LiveCasinoGame[] = [];
    let livecasino = app.livecasino;

    if(!livecasino.lobby || !livecasino.lobby.games || !livecasino.lobby.categories) {
            return { games : games, mode : livecasino.mode}
     }
    let recentlyPlayed : LiveCasinoGame[]  = [];
    let favourites : LiveCasinoGame[] = [];
    let displayFavourites = false;
    let category;
    let allGames = livecasino.lobby && livecasino.lobby.games ?livecasino.lobby.games : games ;
    let currencies = livecasino.lobby.currencies;
    let activeCurrency = app.user.activeCurrency;
    if(!activeCurrency && app.user.user && app.user.user.currencies && app.user.user.currencies.length > 0) {
        activeCurrency = app.user.user.currencies[0].code;
    }
    let country = app && app.user && app.user.user ? app.user.user.country : undefined;
    let loggedIn = app.user.user && app.user.user.loggedIn;

    // allGames = allGames.filter((g : LiveCasinoGame) => isSupportedPlatform(g) && isSupportedCurrency(g,currencies , activeCurrency, loggedIn) && isStudioAllowed(g.studio, country));
   

    if(livecasino.searchValue && livecasino.searchValue.length > 1 && livecasino.lobby) {
        let search = livecasino.searchValue;
     
        if (Device.isMobile() == false) {
            games = allGames.filter(g =>     g.SubProductCode  && g.SubProductCode.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||  g.SubProductCode  && g.SubProductCode.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||  g.nameJP  && g.nameJP.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||    g.nameJP  && g.nameJP.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||   g.Name  && g.Name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||    g.Name  && g.Name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1  );
            // games = allGames;
            } else if ( Device.isMobile() == true ) {
                games = allGames.filter(g =>  g.SubProductCode  && g.SubProductCode.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||  g.SubProductCode  && g.SubProductCode.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||  g.nameJP  && g.nameJP.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||   g.isMobileOnly  && g.nameJP  && g.nameJP.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||  g.Name  && g.Name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 ||   g.isMobileOnly  && g.Name  && g.Name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1 );
            }
       
       

    } else {

        let selectedCategory = livecasino.selectedCategory;

        let firstCategory = selectedCategory == livecasino.lobby.categories[0].Id;

        if( livecasino.selectedStudio && livecasino.selectedStudio !== "0") {
               //@ts-ignore
            allGames = allGames.filter(g => g.SubProductCode.toLowerCase() === livecasino.selectedStudio.toLowerCase());

             
        games = allGames;
        
        }
     
        if(livecasino && firstCategory && livecasino.recentlyPlayedGames && livecasino.favouriteGames) {
            let recently = livecasino.recentlyPlayedGames;
            let favouriteGames = livecasino.favouriteGames;
            displayFavourites = true;
            allGames.filter(g => {
                if(recently.some(GameId => GameId === g.GameId)){
                    recentlyPlayed.push(g);
                }
                if(favouriteGames.some(GameId => GameId === g.GameId)) {
                    favourites.push(g);
                }
                
            })
       
        } else if(livecasino.favouriteGames){
            let favouriteGames = livecasino.favouriteGames;
            allGames.filter(g => {
                if(favouriteGames.some(GameId => GameId == g.GameId)) {
                    favourites.push(g);
                }
            });
            
        }

      
        category = livecasino.lobby.categories.find(c => c.Id == selectedCategory);
    

        // if(category && category.id === 11) {
        //     games = allGames;
        // } 
        // else 

        // console.error(category);
        // @@@@@@ ------- uncomment if categories on API FIXED 
        if(category && category.Name === "Favourites" && livecasino.favouriteGames) {
         
            games   =    favourites
        } else  if(category && category.Id && app.livecasino.lobby) {
            games = extractGamesByCategory(category, allGames, Device.isMobile());
        }
        // 

    }
  
    return { livecasinoStatus : app.livecasino.status, games : games, loggedIn : LoginStatus.LOGGED_IN == app.user.status, recentlyPlayed : recentlyPlayed, currentCategory : category, favourites : favourites, displayFavourites : displayFavourites, searchValue: livecasino.searchValue,  mode : livecasino.mode,  lng: getContainer().getSettingsService().getActiveLocale().code,};
};
const mapDispatchToProps = (dispatch : Dispatch) => {
    return {
        updateSearchValue : (str : string) => dispatch(updateSearchValue(str)),
        toggleFavourite : (id : string) => dispatch(toggleFavouriteGame(id) as any),
        showLogin: () => dispatch(showLogin(true)),
       
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveCasinoGames as any);