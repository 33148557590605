import React, { Component } from 'react'


import { RouteComponentProps, withRouter } from "react-router";
import { prettyUrl } from "../utils/UrlUtils";
import { CasinoGame, CasinoGameTranslation } from "../http/protocol";
import { Device } from "../utils/Device";
import Translator, { Translation } from "../Translator";
import BackgroundUrlTranslator from "../BackgroundUrlTranslator";
import {
    LikeBtn,
    ShareBtn
} from './Socials'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';

interface Props extends RouteComponentProps {
    data: any;
    showLogin: () => void;
    loggedIn?: boolean;
    lng?: string;
}
interface State {
    cls: any;
    prevStatus: any;
    show: boolean;
}

function getClassNames(compact?: boolean) {
    if (compact) {
        return "casino-game col-lg-2 col-md-2 col-sm-6 col-6";
    } else {
        return "casino-game col-lg-3 col-md-3 col-sm-6 col-6";
    }
}
function mapTranslations(g?: CasinoGame): Translation[] {
    if (!g || !g.translations) {
        return [];
    }
    return g.translations.map((t: CasinoGameTranslation) => ({ lng: t.language, value: t.imageUrl }));
}

export class HomeAdsSliderComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { cls: '', prevStatus: false, show: false }



    }
    showVideoOnHover() {
        this.setState({ show: true });
    }
    hideVideoOnHover() {
        this.setState({ show: false });
    }
    launchGame(props: Props, playforreal?: boolean) {
        // if (props.updateSearchValue) {
        //     props.updateSearchValue("");
        // }

        if (props.data && props.data.Name && !playforreal) {
            props.history.push(`/casino/playforfun/${props.data.GameLaunchId}/${prettyUrl(props.data.Name)}`)
        } else if (props.data && props.data.Name && playforreal) {
            props.history.push(`/casino/playforreal/${props.data.GameLaunchId}/${prettyUrl(props.data.Name)}`)
        }
    }


    lauchMobileGameView(props: Props) {

        if (props.data && props.data.Name && Device.isMobile()) {
            props.history.push(`/casino/game/${props.data.GameLaunchId}/${prettyUrl(props.data.Name)}`)
        }
    }
    render() {
     
        return (

            <div className={'slide-item'} >
                {this.props.data.link ? (<Link to={this.props.data.link}>
                <img src={this.props.data.image} className='img-fluid' />
                </Link>):this.props.data.linkOutside ? (<a target='_blank' href={this.props.data.linkOutside}>
                <img src={this.props.data.image} className='img-fluid' />
                </a>):null}
             
            </div>
        )
    }
}

export default withRouter<Props>(HomeAdsSliderComponent as any);