import {LaunchUrl} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class SportsbookService {
    constructor(private http : HttpService) {}

    getSong88GameLaunchUrl(currency:string, locale : string) : Promise<LaunchUrl>{
        let language = locale.substring(0,2);
        return this.http.get( "/ips/openGameSession?sessionKey="+localStorage.getItem('sessionKey')+"&productCode=SONG88&gameId=3746&languageCode="+language);
    }

    getSong88AnonymousGameLaunchUrl(currency:string, locale : string) : Promise<LaunchUrl>{
        let language = locale.substring(0,2);
        return  this.http.getSong88Lobby( "https://preprod-platformweb.song88.com/?clientId=AFF8273B-5252-4B2A-C152-08DA4F718D24&currencyCode=EUR&languageCode="+language);
    }
    getUltraplayGameLaunchUrl(currency:string, locale : string) : Promise<LaunchUrl>{
        return this.http.get("/sportsbook/ultraplay/launch/"+currency+"/" + locale);
    }

    getUltraplayAnonymousGameLaunchUrl(currency:string, locale : string) : Promise<LaunchUrl>{
        return this.http.get("/sportsbook/ultraplay/launch-anonymous/"+currency+"/" + locale);
    }

}



