

import amplitude from "amplitude-js"
import {AmplitudeEvent} from "./AmplitudeEvent";

export class AmplitudeService {
    private static instance: AmplitudeService;

    private constructor(apiKey: string) {
        amplitude.getInstance().init(apiKey);
    }

    static getInstance(apiKey: string | undefined, useAmplitude: boolean | undefined) {
        if(useAmplitude && apiKey) {
            if(!AmplitudeService.instance) {
                AmplitudeService.instance = new AmplitudeService(apiKey);
            }
            return AmplitudeService.instance;
        } else {
            return null;
        }
    }

    public setUserId(userId: string | null) {
        amplitude.getInstance().setUserId(userId);
    }

    public logEvent(event: AmplitudeEvent, eventData?: object) {
        amplitude.getInstance().logEvent(event, eventData);
    }

}

