import React, { useEffect, useState, Component } from 'react'
import HeaderComponent from "./HeaderComponent";
import { throws } from 'assert';
import {login, showCashierModal, showLogin, showSignUp} from "../user/UserActions";
import {getContainer} from "../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import { hasClass, imageUrl } from '../utils/Helpers'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { sideBarStatus, scrollTop } from "../actions/HeaderActions";
import {AppState} from "../RootReducer";
import CurrencyFormatter from '../utils/CurrencyFormatter';

import Big from "big.js";



interface dispatchProps {
    isOpen: typeof sideBarStatus,
    scrollTop:typeof scrollTop,
    showLogin:()=>void,
    showSignUp:()=>void,
    showCashierModal:()=>void,
    sidebarStatus: any,
    scrollVal:any,
    isLoggedIn:any,
    user: any,
    balances:any,
    currencies:any,
    lng:string,
    location : Location
}
type Props = dispatchProps;

interface State {
    isSticky: any;
    sidebarStatus: any;
    path:string;
}
export class HeaderView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isSticky: false,
            sidebarStatus: false,
            path:''
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    componentDidMount() {
        let lang = getContainer().getSettingsService().getLocalesData();

        let path ;
        if(lang && lang.length >= 2) {
             path = window.location.pathname.split("/")[2];
        } else {
           path = window.location.pathname.split("/")[1];
        }
        this.setState({path:path});
        window.addEventListener('scroll', this.listenToScroll);
        if(this.props.scrollVal > 100 ){
            this.setState({isSticky:true});
        } else {
            this.setState({isSticky:false});
        }
    }

    getFormattedAmount() {
         
        if(this.props.currencies && this.props.currencies[this.props.user.currency]) {
           console.error(this.props.currencies[this.props.user.currency].symbolCode+";" +  this.props.balances)
            return <span>{this.props.currencies[this.props.user.currency].symbolCode}  {this.props.balances};</span>
        } else {
            return "Amount unformatted."
        }
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.listenToScroll);
    }
    componentDidUpdate (prevProps : Props) {
        const locationChanged = this.props.location.pathname !== prevProps.location.pathname;

        if(locationChanged) {
            this.props.isOpen(false)
            document.body.classList.remove('nav-open');
        }
    }
    listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.props.scrollTop(window.pageYOffset);
        if(this.props.scrollVal > 100 ){
            this.setState({isSticky:true});
        } else {
            this.setState({isSticky:false});
        }
      }


    toggleSidebar = (event: any) => {

        event.preventDefault();
       
        
        if (!this.props.sidebarStatus && event !== undefined) {
        
            document.body.classList.add('nav-open');
            document.addEventListener('mousedown', this.handleClickOutside);
            document.addEventListener('touchstart', this.handleClickOutside);
            this.props.isOpen(true);
        }
        // console.error(this.props.sidebarStatus);

    }

    hideSidebar = (event: any) => {
        event.preventDefault();
             this.props.isOpen(false)
            document.body.classList.remove('nav-open');
            document.removeEventListener('mousedown', this.handleClickOutside);
            document.removeEventListener('touchstart', this.handleClickOutside);

    }
    handleClickOutside = (event: any) => {
        const target = event.target
        // console.error("outside",this.props.sidebarStatus);
       
        if(this.props.sidebarStatus) {
        if ( !hasClass(target, 'b-sidebar')) {
  
            this.hideSidebar(event)
         }
        }
    }

    render() {
    
        return (
       
            <HeaderComponent isOpen={this.toggleSidebar} path={this.state.path} hideSidebar={this.handleClickOutside} sidebarIsOpen={this.props.sidebarStatus} logInClicked={this.props.showLogin}  signUpClicked={this.props.showSignUp} isSticky={this.state.isSticky} isLoggedIn={this.props.isLoggedIn} user={this.props.user} balances={this.props.balances} depositClicked={this.props.showCashierModal} lng={this.props.lng}/>
       
            
        )
    }


}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    isOpen: (open: boolean) => dispatch(sideBarStatus(open) as any),
    scrollTop: (position: any = 0) => dispatch(scrollTop(position) as any),
    showLogin : ()=> dispatch(showLogin(true)),
    showSignUp : ()=> dispatch(showSignUp(true)),
    showCashierModal:()=> dispatch(showCashierModal(true,"deposit")),
});



const mapStateToProps = (app: AppState) => {



    return {
        sidebarStatus: app.header !== undefined ? app.header.sidebarStatus : undefined,
        scrollVal:app.header !== undefined ? app.header.scrollTop : undefined,
        user: app.user,
        currencies:app.user.user?.currencies,
        isLoggedIn: app.user.user !== undefined ? app.user.user.loggedIn: undefined,
        balances : app.user.balances,
        lng: getContainer().getSettingsService().getActiveLocale().code,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderView as any));