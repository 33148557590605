import  "./ui/styles/CasinoCategories.scss";
import React from "react";
import {Device} from "../utils/Device";
import "./ui/styles/CasinoCategoryPage.scss";
import "./LoadingCasinoLobby.scss";

export const LoadingCasinoLobby = (props : {}) => (
    <div className={"casino-container"}>
        <div className={"container"}>
            <div className={"container categories-container"}>
                <ul className={"navbar-nav flex-row casino-lobby-categories " + (Device.isMobile() ? `${"row"} ${"mobile"}` : "row")}>
                    {[1,2,3,4].map(i => (
                        <li key={i} className={"nav-item"}>
                            <a className={ "nav-link"}>
                                <span className={"title"}>
                                    <span className={"loadingCategory"}/>
                                </span>
                            </a>
                        </li>
                    ))}

                </ul>
            </div>
        </div>
        <div className={"casino-"}>
            <div className={"main-container"}>
                <div className={"casino-games-container container-fluid"}>

                        <div className={"container"}>
                            <div className={"row games-list"}>
                                {[1,2,3,4,5,6,7,8].map(i => (
                                    <div key={i} className={Device.isMobile() ?  "casino-game mobile" : "casino-game col-lg-3 col-md-3 col-sm-6 col-6"}>
                                        <div className={"game-box"} >
                                            <div className="content loading">
                                                <div className={"content-overlay"}/>
                                                <div  className={"thumbnail"} >
                                                    <div className={"play-now"}>
                                                        <div className={"game-title"}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"game-description"}>

                                                <div className={"game-text-container"}>
                                                    <div className={"content game-name"}>
                                                        <div className={"loading-text"}></div>
                                                    </div>
                                                    <div className={"meta"}>
                                                        <div className={"provider"}>
                                                            <div className={"loading-text"}></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"favourite-container"}>
                                                    <a className={"btn favourite"}><i className={"oi oi-heart"}></i></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
)