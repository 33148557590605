import React, { useEffect, useState, Component } from "react";
import { Link } from "react-router-dom";
import { imageUrl } from "../utils/Helpers";
import CasinoSearch from "../casino/ui/CasinoSearch";
import MobileCasinoSearch from "../casino/ui/MobileCasinoSearch";
import CasinoSearchResults from "../casino/ui/CasinoSearchResults";
import "./HeaderComponent.scss";
import DEFAULT_AVATAR from "https://content.adminemx.com/maharajafortune/images/user.png";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getSymbolFromCurrency from 'currency-symbol-map'

import { faIdCard, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import {
  faPiggyBank,
  faCashRegister,
  faHistory,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import LanguageMenu from "../ui/language/LanguageMenu";
import { t } from "../i18n";
import { getContainer } from "../ioc/IOCSetup";
interface Props {
  isOpen: (event: any) => void;
  hideSidebar: (event: any) => void;
  logInClicked: (event: any) => void;
  signUpClicked: (event: any) => void;
  depositClicked: (event: any) => void;
  isLoggedIn: any;
  isSticky: any;
  user: any;
  balances: any;
  lng: string;
  sidebarIsOpen: any;
  path?: string;
}
declare global {
  interface Window {
    checkin: any;
  }
}
export const generateHeaderMenu = (menu: any[])=> {
  let activeLang = getContainer().getSettingsService().getActiveLocale().code;
  return (menu.map((menu : any) => {
    return (<Link to={menu.link} key={menu.translation[activeLang]}>
    {menu.translation[activeLang]}
    </Link>)
}))
}
const HeaderComponent: React.FC<Props> = ({
  isOpen,
  hideSidebar,
  isSticky,
  isLoggedIn,
  logInClicked,
  signUpClicked,
  depositClicked,
  sidebarIsOpen,
  user,
  balances,
  lng,
  path
}) => {
  let brandLogo = getContainer().getSettingsService().getBrandLogo();
  let headerMenu = getContainer().getSettingsService().getHeaderMenu();
  let activeCurrecy = localStorage.getItem("activeCurrency") || "";

  return (
    <header
      id="main-header"
      className={isSticky ? "menu-sticky animated slideInDown" : ""}
    >
      <div className="main-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <nav className="navbar navbar-light p-0">
                
                <div className="logo-side">
                <button onClick={isOpen} className="navbar-toggler c-toggler" role="button" disabled={sidebarIsOpen ? true : false}>
                        <div className="navbar-toggler-icon" data-toggle="collapse">
                          <img
                            className="img-fluid"
                            src={imageUrl("2020/sort-24-px-3@3x.png")}
                            alt="pic"
                          />
                        </div>
                      </button>
                  <Link to={"/"}>
                    <span className="navbar-brand">
                      {brandLogo ? ( <img
                        className="img-fluid logo"
                        src={brandLogo}
                        alt="Logo"
                      />):( <img
                        className="img-fluid logo"
                        src={"https://content.adminemx.com/maharajafortune/images/maharaja-logo.png"}
                        alt="Logo"
                      />)}
                     
                    </span>
                  </Link>

                </div>

                  {headerMenu ? (  <div className="menu-left" >{generateHeaderMenu(headerMenu)}</div>):(
                  <div className="menu-left" >
                  <Link to={"/casino"}>
                  {t("casino")}
                  </Link>
                  <Link to={"/livecasino/23/popular-live%20games"}>
                  {t("liveCasino")}
                  </Link>
                  {/* <Link to={"/sports"}>
                  {t("sports")}
                  </Link>
                  <Link to={"/sports/live"}>
                  {t("liveBetting")}
                  </Link> */}
                  <Link to={"/promotions"}>
                  {t("homePageTexts.promotions")}
                  </Link>
                </div>)}
                

                <div className="d-flex">
                  <div className="navbar-right menu-right">
                    <ul className="d-flex align-items-center list-inline m-0">
                    <LanguageMenu inNavMenu={true}/>
                      {isLoggedIn ? (
                        <React.Fragment>
                    <a  onClick={depositClicked} className="deposit-btn">
                    {t("deposit")}
                       </a>
                          <li className="nav-item nav-icon">
                            <Dropdown>
                              <Dropdown.Toggle

                                id="dropdown-user"
                              >
                                <div
                                  className="iq-user-dropdown header search-toggle p-0 d-flex align-items-center"
                                  data-toggle="iq-user-dropdown"
                                >


                                <span className="balance-label">     {t("balance")}:</span>


                                
                                    <span className="balance"> {getSymbolFromCurrency(activeCurrecy)}
                                      {balances ? balances : null}</span>
                                   <FontAwesomeIcon icon={faAngleDown} color="#f6d96c" className="down" style={{ margin: "10px auto" }}  />
                                  {/* <img src={imageUrl('down.png')} className="down" alt="user" /> */}
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>

                                <Dropdown.Item className="bonus-balance">

                              {t("balance")}: {getSymbolFromCurrency(activeCurrecy)} {user && user.balances ? (user && user.balances
                                  ) : "0.00"}
                                </Dropdown.Item>


                                <Dropdown.Item className="bonus-balance">

                                {t("bonus")}: {getSymbolFromCurrency(activeCurrecy)} {user && user.bonusBalance ? (user && user.bonusBalance
                                  ) : "0.00"}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </React.Fragment>
                      ) : (<div className="right-nav">
                        <div className="right-link" onClick={logInClicked}>

                        {t("logIn")}
                        </div>
                        {path === 'signup' ? (null) : (<div className="right-link-signup btn" onClick={signUpClicked}>

                        {t("signUp")}
                        </div>)}

              
                      </div>
                      )}
                    
                    </ul>

                  </div>
                </div>
              </nav>
              <div className="nav-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
