import {
  USER_PROFILE,
  SIDEBAR_STATUS_IS_OPEN,
  SCROLL_TOP,
  SCROLL_TO_TOP,
  SET_LANGUAGE,
  SET_LOGGIN,
  HeaderType,
  HeaderDispatchTypes,
  UserInfo,
} from "../actions/HeaderActions";

interface DefaultHeaderState {
  userProfile?: UserInfo;
  sidebarStatus: Boolean;
  scrollTop: any;
  language: String;
  isLoggedIn:Boolean
}

const initialState: DefaultHeaderState = {
  sidebarStatus: false,
  scrollTop: 0,
  isLoggedIn:false,
  language: "en",
};

export function headerReducer(
  state: DefaultHeaderState = initialState,
  action: HeaderDispatchTypes
):  any {
  switch (action.type) {
    case USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile,
      };

    case SIDEBAR_STATUS_IS_OPEN:
      return {
        ...state,
        sidebarStatus: action.sidebarStatus,
      };

    case SCROLL_TOP:
      return {
        ...state,
        scrollTop: action.scrollTop,
      };

    case SCROLL_TO_TOP:
      return {
        ...state,
        scrollTop:  state.scrollTop + 1,
      };

    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

      case SET_LOGGIN:
        return {
          ...state,
          isLoggedIn: action.isLoggedIn,
        };

    default:
     
      return state;
  }
}
