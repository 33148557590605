import { HttpService } from "../http/HttpService";
import { DocumentTypeResult, FileUploadRequest, FileUploadResult } from "../http/protocol";


export class FileUploadService {
  constructor(private http : HttpService) {}


  fileUpload(request: FileUploadRequest) : Promise<FileUploadResult> {
    let fileName1 = request.file && request.file.name;
    let fileName2 = request.file2 && request.file2.name;
    const fileMap = {fileName1:"test",fileName2:"test",};
    const formData = new FormData();
    if(Array.isArray(request.file) && request.file.length >= 1) {


      request.file.map((item:any, index:any) => {
        return formData.append('_file_'+index,item,"item"+index);
          
      })


    } else  if(request.file && request.file.length > 0) {
    formData.append('_file_0',request.file,"front");
  }
  if(request.file2) {
    formData.append('_file_1',request.file2,"back");
  }
    formData.append('expiryDate', request.expiryDate);
    formData.append('documentNumber', request.documentNumber);
    formData.append('documentType', "Proof of address");

   
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
  
    return this.http.post("/ips/uploadMultiUserDocument?sessionKey=" + localStorage.getItem('sessionKey'),formData)
  }

  getDocumentTypes() : Promise<{[key:string]: DocumentTypeResult[]}> {
    console.log("API CALL DOCUMENTS");
    return this.http.getDoctype("/ips/getDocumentTypes");
  }

}