


 import {Dispatch} from "redux";

export const USER_PROFILE = "USER_PROFILE";
export const SIDEBAR_STATUS_IS_OPEN = "SIDEBAR_STATUS_IS_OPEN";
export const SCROLL_TOP = "SCROLL_TOP";
export const SCROLL_TO_TOP = "SCROLL_TO_TOP";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LOGGIN = "SET_LOGGIN";


export interface UserInfo  {
  loggedIn?: boolean;
  errorMessage?: string;
  userId?: number;
  blocked?: boolean;
  emailVerified?: boolean;
  newUser?: boolean;
  username?: string;
  attributes?: { [index: string]: string };
  userSettings?: { [index: string]: string };
  email?: string;
  phoneNumber?: string;
  kycVerified?: boolean;
  country?: string;
  token?: string;
  secret?: string;
  tokenRefId?: number;

}
export interface HeaderType  {
  userProfile?:UserInfo;
  sidebarStatus:Boolean;
  scrollTop:Number;
  language:String;
}


export interface SetLogIn {
  type: typeof SET_LOGGIN,
  isLoggedIn:Boolean
}


export interface UserProfile {
  type: typeof USER_PROFILE,
  userProfile: UserInfo
}

export interface SidebarStatus {
  type: typeof SIDEBAR_STATUS_IS_OPEN,
  sidebarStatus:Boolean
}

export interface ScrollTop {
  type: typeof SCROLL_TOP,
  scrollTop:any
}

export interface ScrollToTop {
  type: typeof SCROLL_TO_TOP,
  scrollTop:HeaderType
}

export interface SetLanguage {
  type: typeof SET_LANGUAGE,
  language:String
} 
export type HeaderDispatchTypes = UserProfile | SidebarStatus | ScrollToTop | ScrollTop | SetLanguage | SetLogIn


export const sideBarStatus = (sidebarStatus : Boolean) : HeaderDispatchTypes => (
  {type: SIDEBAR_STATUS_IS_OPEN, sidebarStatus: sidebarStatus}
)

export const userProfile = (userProfile : UserInfo) : HeaderDispatchTypes => (
  {type: USER_PROFILE, userProfile: userProfile}
)

export const scrollTop = (scrollTop : any) : HeaderDispatchTypes => (
  {type: SCROLL_TOP, scrollTop: scrollTop}
)

export const setLogIn = (isLoggedIn : Boolean) : HeaderDispatchTypes => (
  {type: SET_LOGGIN, isLoggedIn: isLoggedIn}
)
