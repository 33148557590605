export enum NavigationActionTypes {
    SHOW_USER_NAVIGATION = "@navigation/user/show",
    SET_ACTIVE_TAB = "@navigation/user/tab",
    SHOW_SPORTSBOOK ="@navigation/sportsbook/show"
}

export type NavigationActions =
    | { type : NavigationActionTypes.SHOW_USER_NAVIGATION, show : boolean}
    | { type : NavigationActionTypes.SET_ACTIVE_TAB, tab : number}
    | { type : NavigationActionTypes.SHOW_SPORTSBOOK, show : boolean};

export const toggleShowUserNavigation = (show : boolean) => (
    { type : NavigationActionTypes.SHOW_USER_NAVIGATION, show : show }
);

export const setActiveTab = (tab : number) => (
    { type : NavigationActionTypes.SET_ACTIVE_TAB, tab : tab }
);

export const toggleShowSportsbook = (show : boolean) => {
    return {type : NavigationActionTypes.SHOW_SPORTSBOOK, show : show}
}