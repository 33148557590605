import React from "react";
import  "./styles/DropdownArrow.scss";


interface Props {
    className: string;
    showDropdown: boolean;
    marginLeft?: string;
}

const DropdownArrow = (props: Props) => {
    const show = props.showDropdown ? 'show' : '';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={`arrow-dropdown ${props.className} ${show}`} style={{ marginLeft: props.marginLeft }}
        width="648.5px" height="398px" viewBox="-36.5 91.5 648.5 398" enableBackground="new -36.5 91.5 648.5 398">
            <path fill="#FFFFFF" d="M599.01,143.18l-36.22-36.37l-13.33-13.39l-12.73,13.96L287.76,380.57L38.77,107.39L26.04,93.42l-13.33,13.4
	l-36.22,36.4l-12.09,12.15l11.55,12.67l279.1,306.24l0.27,0.29l0.28,0.28c8.11,8.15,19.03,12.64,30.75,12.64
	c0.46,0,0.93-0.01,1.4-0.02c0.47,0.01,0.94,0.02,1.4,0.02c11.72,0,22.64-4.49,30.74-12.64l0.28-0.28l0.26-0.29l279.13-306.27
	l11.55-12.68L599.01,143.18z"/>
        </svg>
    )

}

export default DropdownArrow;
