import React from "react";
import "./RegisterView.scss";
import {Status} from "../../http/protocol";
import {connect} from "react-redux";
import {AppState} from "../../RootReducer";
import {Redirect} from "react-router";
import RegisterForm from "./RegisterForm";
import Root from "../../layout/Root";
import MetaHelmet from "../../utils/MetaHelmet";
import {Helmet} from "react-helmet";
import { Dispatch } from "redux";
import {getContainer} from "../../ioc/IOCSetup";
import {ContentModelName, RegisterPayload,CompleteRegistrationPayload} from "../../contenful/types";
import {fetchContent} from "../../contenful/ContentfulActions";
import {COUNTRIES} from "./Countries";
import { showSignUp } from "../UserActions";

import { t } from "../../i18n";

type Props = { 
    status?: Status;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    registerContent?:RegisterPayload;
    completeRegisterContent?: CompleteRegistrationPayload;
    activeLng?:string;
    showSignUp?:()=> void;
    show?: boolean,
 };

export class RegisterView extends React.Component<Props>{
    componentDidMount() {

        this.props.showSignUp && this.props.showSignUp();
        if(!this.props.isFetching) {
   
            if(this.props.registerContent === undefined) {
                this.props.fetchContent(ContentModelName.REGISTER, getContainer().getSettingsService().getActiveLocale().code);
            }
            if (this.props.completeRegisterContent === undefined) {
                this.props.fetchContent(ContentModelName.COMPLETEREGISTRATION, getContainer().getSettingsService().getActiveLocale().code);
            }

            if(!this.props.show) {
                this.props.history.push("/");
            }
    }
    getContainer().getSettingsService().getCountryAvailable().then((r: any) => { 
        let country = COUNTRIES.filter(e =>r.countries && r.countries.includes(e.code));
        getContainer().getSettingsService().setCountryData(country);
      
        });
    getContainer().getSettingsService().getBrandCurrencies().then((r: any) => { 
       
        getContainer().getSettingsService().setCurrencies(r.currencies);
      
        });
    // if(true){
    //     try {

    //         getContainer().getGeoIpService().getCountryInfo().then((info) => {
    //             let countries = getContainer().getSettingsService().getCountryData();
    //             let lang = getContainer().getSettingsService().getActiveLocale().code;
    //             console.error(countries && !countries.some(i => i.code.includes(info.country)));
    //             if (countries && !countries.some(i => i.code.includes(info.country))) {



    //                 if (window.location.pathname !== `/${lang}/blocked/${info.country_name}`) {
                   
    //                     // eslint-disable-next-line no-restricted-globals
    //                     history.replaceState(null, "", `/${lang}/blocked/${info.country_name}`);
    //                     // eslint-disable-next-line no-restricted-globals
    //                     history.go()
    //                 }

    //             }
    //             console.error(info)
    //         });

    //     } catch (e) {
    //         console.log("Error fetching geo ip", e);
    //     }


    // }
    }
    render() {
        const title:string = t("meta.registerView.title") || "";
        const subtitle:string = t("meta.registerView.subtitle") || "";
        const fullTitle:string = t("meta.registerView.fullTitle") || "";
        const description:string = t("meta.registerView.description") || "";
        const keywords:string = t("meta.registerView.keywords") || "";
        const baserHref:string = t("meta.registerView.baserHref") || "";

    
        return (
            <Root footer={true} header={true}>
               
            {MetaHelmet(fullTitle, title ,  description, keywords)}
            <Helmet>
          
            </Helmet>
            <div className="main-content register-bg">
                <div className={"register-container"} >

                    <header className={"top-container gradient"}>
                        {/* <h3>{this.props.registerContent && this.props.registerContent.register}</h3>
                        <hr/> */}
                       <div className={"register-form-container"}>
                           {/* <RegisterForm /> */}
                       </div>

                    </header>

                
                </div>
            </div>
            </Root>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showSignUp : ()=> dispatch(showSignUp(true)),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName,locale) as any),
  });
const mapStateToProps = (app : AppState) => (
    { status : app.user.registration && app.user.registration.status,
        registerContent: app.contentful.register,
        completeRegisterContent: app.contentful.completeRegistration,
        show: app.user.displaySignup,
        activeLng: getContainer().getSettingsService().getActiveLocale().code }
);
export default connect(mapStateToProps, mapDispatchToProps)(RegisterView as any);