import React, { Component, lazy } from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ContentModelName,
  GlobalPayload,
  FAQPayload,
  PopupManager,
} from "../../contenful/types";
import { AppState } from "../../RootReducer";
import { fetchContent } from "../../contenful/ContentfulActions";
import {
  AccountBalance,
  CurrencyDefinition,
  Settings,
} from "../../http/protocol";
import { LoginStatus } from "../../http/Types";
import { fetchSettings } from "../../settings/SettingsActions";
import { Redirect } from "react-router";
import { History } from "history";
import { getContainer } from "../../ioc/IOCSetup";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import Root from "../../layout/Root";
import MetaHelmet from "../../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { Accordion, Card } from "react-bootstrap";

import "./FAQView.scss";

import { t } from "../../i18n";

type AllProps = PropsFromState & PropsFromDispatch & OtherProps;

interface State { }
let para = 0;
let h4 = 0;
export class FAQView extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
  }
  componentDidMount(): void {
    // this.props.loggedIn && localStorage.setItem("lastProductUsed", "poker");
    // if(this.props.popupManager == undefined) {
    //     this.props.fetchContent(ContentModelName.POPUPMANAGER, this.props.lng);
    // }else {
    //     this.setState({ hideAfterWentToOtherPage: true })
    // }

    if (this.props.FAQ == undefined) {
      this.props.fetchContent(ContentModelName.FAQ, this.props.lng);
    }

    // if(this.props.global == undefined) {
    //     this.props.fetchContent(ContentModelName.GLOBAL, this.props.lng);
    // }

    // if(this.props.settings == undefined) {
    //     this.props.fetchSettings();
    // }
  }
  textEdit(children: String) {
    return (
      <Accordion.Collapse eventKey={`${h4}`}>{children}</Accordion.Collapse>
    );
  }

  headingEdit(children: String) {
    h4++;
    return (
      <Accordion.Toggle as={Card.Header} eventKey={`${h4}`}>
        {children}{" "}
      </Accordion.Toggle>
    );
  }
  render() {

    const title: string = t("meta.FAQView.title") || "";
    const subtitle: string = t("meta.FAQView.subtitle") || "";
    const fullTitle: string = t("meta.FAQView.fullTitle") || "";
    const description: string = t("meta.FAQView.description") || "";
    const keywords: string = t("meta.FAQView.keywords") || "";
    const baserHref: string = t("meta.FAQView.baserHref") || "";
    let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
    const options: Options = {
      renderNode: {
        // [BLOCKS.PARAGRAPH]: (node, children) => this.textEdit(children as string),
        // [BLOCKS.HEADING_4]: (node, children) => this.headingEdit(children as string),
      },
    };

    return (
      <div>
        <Root footer={true} header={true}>
          {MetaHelmet(fullTitle, description, keywords)}

          <div className="main-content">
            <div className="container faq-container">
              <div className="row">
                <div className="col-sm-12 ">

                  <h1>{t("faqText.title1")}</h1>
                  <hr />
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="big-box-2">
                        <i className="ri-chat-1-fill"></i>
                        <h5>{t("faqText.liveChat")}</h5>
                        <hr />
                        <p>{t("faqText.liveChatText1")}<br />
                        {t("faqText.liveChatText2")}</p>

                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="big-box-2">
                        <i className="ri-message-2-fill"></i>
                        <h5>{t("faqText.emailUs")}</h5>
                        <hr />
                        <p>{t("faqText.emailUsText1")}</p>
                    {mainLobby === "https://joiabet.com/" ? (<><a className="btn btn-primary  btn-rounded play-for-fun" href="mailto:support@joiabet.com" >{t("faqText.emailUsBtn")}</a></>):(<><a className="btn btn-primary  btn-rounded play-for-fun" href="mailto:support@maharajafortune.com" >{t("faqText.emailUsBtn")}</a></>)}
                        
                      </div>
                    </div>
                  </div>
                  <Accordion >
                    {/* {registrationAccount} */}
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">

                        <h3> {t("faqText.faq1.faq1Title")} </h3>

                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Accordion >
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                <h4>{t("faqText.faq1.q1")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq1.a1")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h4>
                                {t("faqText.faq1.q2")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq1.a2")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                        <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                <h4>{t("faqText.faq1.q3")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq1.a3")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                      

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                <h4>{t("faqText.faq1.q4")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq1.a4")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                <h4>
                                {t("faqText.faq1.q5")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq1.a5")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* {registrationAccount} */}

                    {/* {Free spins (registration free spins, daily deposit spins)} */}
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <h3>
                        {t("faqText.faq2.faq2Title")}
                        </h3>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <Accordion defaultActiveKey="0">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                <h4>{t("faqText.faq2.q1")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq2.a1", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h4>
                                {t("faqText.faq2.q2")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq2.a2", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            {mainLobby == "https://joiabet.com/" ? null:
                           (
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                <h4>
                                {t("faqText.faq2.q3")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq2.a3", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>)}

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                <h4>
                                {t("faqText.faq2.q4")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq2.a4")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                <h4>{t("faqText.faq2.q5")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                  <p>{t("faqText.faq2.a5")}
                                  </p>  </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="5">
                                <h4>
                                {t("faqText.faq2.q6")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                {t("faqText.faq2.a6")}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            {mainLobby == "https://joiabet.com/" ? null:
                           (
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="6">
                                <h4>
                                {t("faqText.faq2.q7")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq2.a7", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>)}

                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* {Free spins (registration free spins, daily deposit spins)} */}


                    {/* {Deposit} */}
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        <h3>{t("faqText.faq3.faq3Title")}</h3>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <Accordion defaultActiveKey="0">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                <h4>{t("faqText.faq3.q1")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq3.a1", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h4>{t("faqText.faq3.q2")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq3.a2")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            {mainLobby == "https://joiabet.com/" ? null:
                           (
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                <h4>
                                {t("faqText.faq3.q3")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq3.a3", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>)
  }

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                <h4>
                                {t("faqText.faq3.q4")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq3.a4")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                <h4>
                                {t("faqText.faq3.q5")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq3.a5", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* {Deposit} */}

                    {/* {Withdrawal} */}
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="4">
                        <h3>{t("faqText.faq4.faq4Title")}</h3>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <Accordion defaultActiveKey="0">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                <h4>{t("faqText.faq4.q1")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq4.a1", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h4>{t("faqText.faq4.q2")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq4.a2", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                <h4>{t("faqText.faq4.q3")}</h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                {t("faqText.faq4.a3")}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                <h4>
                                {t("faqText.faq4.q4")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq4.a4", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                <h4>
                                {t("faqText.faq4.q5")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                {t("faqText.faq4.a5")}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* {Withdrawal} */}

                    {/* {Account Verification} */}
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="5">
                        <h3>{t("faqText.faq5.faq5Title")}</h3>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <Accordion defaultActiveKey="0">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                <h4>
                                {t("faqText.faq5.q1")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq5.a1", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h4>
                                  {t("faqText.faq5.q2")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq5.a2", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                <h4>
                                {t("faqText.faq5.q3")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq5.a3", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            {mainLobby == "https://joiabet.com/" ? null:
                           (
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                <h4>
                                {t("faqText.faq5.q4")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                  <p dangerouslySetInnerHTML={
                                    { __html: t("faqText.faq5.a4", { interpolation: { escapeValue: false } })}}>
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>)}
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                <h4>
                                {t("faqText.faq5.q5")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>

                                  <p>
                                  {t("faqText.faq5.a5")}
                                  </p>

                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>


                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* {Account Verification} */}
                    {/* {Responsible Gaming} */}
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="6">
                        <h3>{t("faqText.faq6.faq5Title")}</h3>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <Accordion defaultActiveKey="0">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                <h4>
                                {t("faqText.faq6.q1")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq6.a1")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h4>
                                {t("faqText.faq6.q2")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq6.a2")}
                                  </p>

                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                <h4>
                                {t("faqText.faq6.q3")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <p>
                                  {t("faqText.faq6.a3")}
                                  </p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                <h4>
                                {t("faqText.faq6.q4")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>

                                  <p>
                                  {t("faqText.faq6.a4")}
                                  </p>


                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>


                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                <h4>
                                {t("faqText.faq6.q5")}
                                </h4>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>

                                  <p>
                                  {t("faqText.faq6.a5")}
                                  </p>


                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* {Responsible Gaming} */}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </Root>
      </div>
    );
  }
}

interface OtherProps {
  history: History;
}

interface PropsFromState {
  FAQ: FAQPayload;

  loggedIn: boolean;
  settings?: Settings;
  screenNameSet?: boolean;
  global?: GlobalPayload;

  lng: string;
}

interface PropsFromDispatch {
  fetchContent: typeof fetchContent;
  fetchSettings: typeof fetchSettings;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchContent: (contentModelName: ContentModelName, locale?: string) =>
      dispatch(fetchContent(contentModelName, locale) as any),
    fetchSettings: () => dispatch(fetchSettings() as any),
  };
};

const mapStateToProps = (app: AppState) => {
  return {
    balances: app.user.balances,
    currencies:
      app.user.user != undefined ? app.user.user.currencies : undefined,
    loggedIn: LoginStatus.LOGGED_IN == app.user.status,
    settings: app.settings.settings ? app.settings.settings : {},
    activeCurrency: app.user.activeCurrency,
    lng: getContainer().getSettingsService().getActiveLocale().code,
    FAQ: app.contentful.FAQ,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FAQView as any);
