import { LiveCasinoStatus, ListMode } from "./LiveCasinoReducer";
import { Dispatch } from "redux";
import { getContainer } from "../ioc/IOCSetup";
import { AppState } from "../RootReducer";
import { LiveCasinoGame, LiveCasinoLobby, LiveCasinoLobbyCategory } from "../http/protocol";
import { Container } from "react-bootstrap";

export enum LiveCasinoActionTypes {
    SELECT_CATEGORY = "@livecasino/category/select",
    GAMES_REQUEST = "@livecasino/games/request",
    GAMES_RESPONSE = "@livecasino/games/response",
    GAMES_ERROR = "@livecasino/games/error",
    SELECT_LIST_MODE = "@livecasino/list/mode",
    UPDATE_SEARCH_VALUE = "@livecasino/update/search",
    SET_PLAYED_GAMES = "@livecasino/set/games",
    SET_FAVOURITE_GAMES = "@livecasino/set/favourite",
    SELECT_STUDIO = "@livecasino/selec/studio",
    SELECT_CATEGORY_NAME = "@livecasino/selected/categories",
}
export type LiveCasinoAction =
    | { type: LiveCasinoActionTypes.GAMES_RESPONSE, lobby: LiveCasinoLobby }
    | { type: LiveCasinoActionTypes.SELECT_CATEGORY, category: number }
    | { type: LiveCasinoActionTypes.GAMES_ERROR, error: Error }
    | { type: LiveCasinoActionTypes.GAMES_REQUEST }
    | { type: LiveCasinoActionTypes.SELECT_LIST_MODE, mode: ListMode }
    | { type: LiveCasinoActionTypes.UPDATE_SEARCH_VALUE, value: string }
    | { type: LiveCasinoActionTypes.SET_PLAYED_GAMES, GameIds: number[] }
    | { type: LiveCasinoActionTypes.SET_FAVOURITE_GAMES, GameIds: number[] }
    | { type: LiveCasinoActionTypes.SELECT_STUDIO, studioName: string }
    | { type: LiveCasinoActionTypes.SELECT_CATEGORY_NAME, categoryName: string }


export const selectStudio = (studioName: string) => (
    { type: LiveCasinoActionTypes.SELECT_STUDIO, studioName: studioName }
)
export const selectCategory = (category: number) => (
    { type: LiveCasinoActionTypes.SELECT_CATEGORY, category: category }
)
export const selectCategoryName = (category: string) => (

    { type: LiveCasinoActionTypes.SELECT_CATEGORY_NAME, categoryName: category }
)
const RECENTLY_PLAYED_GAMES_KEY = "recentlyPlayedGames";
const FAVOURITE_GAMES_KEY = "favouriteGames";

export const addPlayedGame = (GameIdStr: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        let GameId = GameIdStr;
        let games = getState().livecasino.recentlyPlayedGames;
        console.log("add played games " + GameId, games);
        if (games) {
            let update = games.filter(g => g != GameId).slice(0, Math.min(5, games.length));
            games = Array.of(GameId).concat(update);
            dispatch({ type: LiveCasinoActionTypes.SET_PLAYED_GAMES, GameIds: games });
            localStorage.setItem(RECENTLY_PLAYED_GAMES_KEY, JSON.stringify(games));
        }

    }
}
export const toggleFavouriteGame = (GameId: string) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        let games = getState().livecasino.favouriteGames;
        console.log("toggle favourite = ", GameId, games);
        if (games) {
            let update = games.filter(g => g != GameId);
            if (games.length == update.length) {
                update = update.concat([GameId]);
            }
            dispatch({ type: LiveCasinoActionTypes.SET_FAVOURITE_GAMES, GameIds: update });
            localStorage.setItem(FAVOURITE_GAMES_KEY, JSON.stringify(update));
        }

    }
}
export const loadRecentlyPlayedGames = () => {

    try {
        let games = localStorage.getItem(RECENTLY_PLAYED_GAMES_KEY);
        if (games && games.length > 0) {
            let GameIds: number[] = JSON.parse(games);
            return { type: LiveCasinoActionTypes.SET_PLAYED_GAMES, GameIds: GameIds };
        }
    } catch (e) {
        console.log("Unable to parse recently played games");
    }

    return { type: LiveCasinoActionTypes.SET_PLAYED_GAMES, GameIds: [] };
}
export const loadFavouriteGames = () => {
    try {
        let favourites = localStorage.getItem(FAVOURITE_GAMES_KEY);
        if (favourites && favourites.length > 0) {
            let GameIds: number[] = JSON.parse(favourites);
            return { type: LiveCasinoActionTypes.SET_FAVOURITE_GAMES, GameIds: GameIds };
        }
    } catch (e) {
        console.log("Unable to parse recently played games");
    }

    return { type: LiveCasinoActionTypes.SET_FAVOURITE_GAMES, GameIds: [] };

}
export const requestGames = () => (
    { type: LiveCasinoActionTypes.GAMES_REQUEST }
)
export const gamesResponse = (lobby: LiveCasinoLobby) => (
    { type: LiveCasinoActionTypes.GAMES_RESPONSE, lobby: lobby }
)
export const gamesError = (error: Error) => (
    { type: LiveCasinoActionTypes.GAMES_ERROR, error: error }
)
export const selectListMode = (mode: ListMode) => (
    { type: LiveCasinoActionTypes.SELECT_LIST_MODE, mode: mode }
);
export const updateSearchValue = (value: string) => (
    { type: LiveCasinoActionTypes.UPDATE_SEARCH_VALUE, value: value }
);


export const loadLiveCasinoGames = () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        setTimeout(() => dispatch(loadRecentlyPlayedGames()));
        setTimeout(() => dispatch(loadFavouriteGames()));
        if (getState().livecasino.status != LiveCasinoStatus.DONE) {
            dispatch(requestGames());
            try {
                let games = await getContainer().getLiveCasinoService().getLiveCasinoGames();
                dispatch(gamesResponse(games));
            } catch (e) {
                dispatch(gamesError(e));
            }
        }


    }
}

function hasId(id?: number, ids?: number[]) {
    if (!id || !ids) {
        return false;
    }
    return ids.some(i => i == id);
}

export function extractGamesByCategory(cat: LiveCasinoLobbyCategory, games: LiveCasinoGame[], mobile: boolean) {

    let favourites = localStorage.getItem(FAVOURITE_GAMES_KEY);

    let filteredGames = games.filter(g => {
        
        // if(mobile == true) {
        //     if(g.isMobile) {
        //         return true;
        //     }
        // } else if(mobile == false) {
        //     if(!g.isMobile && mobile == false) {
        //         return true;
        //     }
        // }
        //DESKTOP MODE CASINO GAMES
 let catID = cat.Id &&cat.Id.toString();
        if(cat.Name === "All Games") {
            return true
              //@ts-ignore
        }else if(cat.Id && g.Categories && g.Categories.includes(catID)) {
            return true;
        } else {
            return false;
        }
      
        
    });
    return filteredGames;
}