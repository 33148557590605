import React, {useState} from "react";
import {t} from "../../i18n";
import {COUNTRIES} from "./Countries";
import {ShowHidePassword} from './ShowHidePassword';
import {StrengthPassword} from './StrengthPassword';
import "./styles/form.scss";
import DOWN_ARROW from "../../assets/down_arrow.svg";


export const EMAIL_REGEXP = /^$|^(([^+<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const renderField = ({input,disabled, text, label, invalid, nameOverride, type, help,autoComplete="off", maxLength, hideLabel, hideHelp, tabIndex, position, valueText, autoFocus,
    meta: { touched, error, warning , asyncValidating} } : any) => {
    let name = input.name;
    let placeholder = name;
    if(nameOverride) {
        placeholder = nameOverride;
    }
    let displayError = touched && error;
    let labelText = valueText != undefined ? valueText :null;
    const positionClass = position ? position : '';

    return (
        <div className={"form-group " +  (displayError ? "has-error" : "")}>
            <div className={text != undefined ? "form-container" : ""}>
                {
                    type == "password" ?
                    <ShowHidePassword>
                        <label htmlFor={labelText} hidden={true}>{labelText}</label>
                        <input id={labelText} autoComplete={"new-password"} maxLength={maxLength} className={`form-control ${positionClass}`} placeholder={labelText} type={type} tabIndex={tabIndex ? tabIndex : 0}  {...input} disabled={disabled}/>
                    </ShowHidePassword> :
                        <div>
                            <label htmlFor={labelText}  hidden={true}>{labelText}</label>
                            <input id={labelText}  autoComplete={"new-password"} className={`form-control ${positionClass}`}
                            placeholder={labelText} maxLength={maxLength} type={type} tabIndex={tabIndex ? tabIndex : 0}  {...input} disabled={disabled} autoFocus={autoFocus} required/>
                        </div>
                }
                {labelText === 'Password' && <StrengthPassword value={input.value} />}
                {!hideHelp && !displayError &&  <span className="help-block">{}</span>}
                {displayError && !hideHelp && <span className="help-block">{error}</span> }
            </div>
        </div>
    )
};



export const renderPostal = ({input,disabled, text, label, invalid, nameOverride, type, help,autoComplete="off", maxLength, hideLabel, hideHelp, tabIndex, position, valueText, autoFocus,
    meta: { touched, error, warning , asyncValidating} } : any) => {
    let name = input.name;
    let placeholder = name;
    if(nameOverride) {
        placeholder = nameOverride;
    }
    let displayError = touched && error;
    let labelText = valueText != undefined ? valueText : "";
    const positionClass = position ? position : '';
	const handleOnInput = (e:any) => {
		let maxNum = maxLength;
		if (e.target.value.length > maxNum) {
		  e.target.value = e.target.value.slice(0, maxNum);
		}
	  };


    return (
        <div className={"form-group " +  (displayError ? "has-error" : "")}>
            <div className={text != undefined ? "form-container" : ""}>
                {
                    type == "password" ?
                    <ShowHidePassword>
                        <label htmlFor={labelText} hidden={true}>{labelText}</label>
                        <input id={labelText} autoComplete={"new-password"} maxLength={maxLength} className={`form-control ${positionClass}`} placeholder={labelText} type={type} tabIndex={tabIndex ? tabIndex : 0}  {...input} disabled={disabled}/>
                    </ShowHidePassword> :
                        <div>
                            <label htmlFor={labelText}  hidden={true}>{labelText}</label>
                            <input id={labelText}  autoComplete={"new-password"} onInput={(e:any)=> handleOnInput(e)}  className={`form-control ${positionClass}`}
                            placeholder={labelText} maxLength={maxLength} type={type} tabIndex={tabIndex ? tabIndex : 0}  {...input} disabled={disabled} autoFocus={autoFocus} required/>
                        </div>
                }
                {labelText === 'Password' && <StrengthPassword value={input.value} />}
                {!hideHelp && !displayError &&  <span className="help-block">{t("forms." + placeholder + ".help")}</span>}
                {displayError && !hideHelp && <span className="help-block">{error}</span> }
            </div>
        </div>
    )
};



export const renderField2 = ({input,disabled, text, dial_code, max, label, invalid, nameOverride, type, help,autoComplete="off", hideLabel, hideHelp, tabIndex, position, valueText, autoFocus,
    meta: { touched, error, warning , asyncValidating} } : any) => {
    let name = input.name;
    let placeholder = name;
    if(nameOverride) {
        placeholder = nameOverride;
    }
    let displayError = touched && error;
    let labelText = valueText != undefined ? valueText : "";
    const positionClass = position ? position : '';
	const handleOnInput = (e:any) => {
		let maxNum = max;
		if (e.target.value.length > maxNum) {
		  e.target.value = e.target.value.slice(0, maxNum);
		}
	  };

    return (
        <div className={"form-group " +  (displayError ? "has-error" : "")}>
            <div className={text != undefined ? "form-container" : ""}>

                {
                    type == "password" ?
                    <ShowHidePassword>
                        <label htmlFor={labelText} hidden={true}>{labelText}</label>
                        <input id={labelText} autoComplete={"new-password"} className={`form-control ${positionClass}`} placeholder={labelText} type={type} tabIndex={tabIndex ? tabIndex : 0}  {...input} disabled={disabled}/>
                    </ShowHidePassword> :
                        <div className="input-group">
                            
                            <label htmlFor={labelText}  hidden={true}>{labelText}</label>
                            <div className="input-group-prepend">
                                        
                                        <span className="input-group-text">
                                            {"(" + dial_code + ")"}
                                        </span>
                                    </div>
                            <input id={labelText}  	onInput={(e:any)=> handleOnInput(e)}  value={dial_code + input.value}  autoComplete={"new-password"} className={`form-control ${positionClass}`}
                            placeholder={labelText} type={type} tabIndex={tabIndex ? tabIndex : 0}  {...input} disabled={disabled} autoFocus={autoFocus} required/>
                        </div>
                }
               
                {labelText === 'Password' && <StrengthPassword value={input.value} />}
                {!hideHelp && !displayError &&  <span className="help-block">{t("forms." + placeholder + ".help")}</span>}
                {displayError && !hideHelp && <span className="help-block">{error}</span> }
            </div>
        </div>
    )
};

export const
    renderFormField = ({required, input, label, type, help,autoComplete="off", hideLabel, hideHelp, tabIndex, meta: { touched, error, warning , asyncValidating} } : any) => {
    let name = input.name;
    let displayError = touched && error;
    let labelText = t("forms." + name + ".label");
    return (
        <div className={"form-group " +  (displayError ? "has-error" : "")}>
            <div>
                <label className="deposit-form label"> {labelText}</label>
                <input id={name} autoComplete={"on"} className="deposit-form input-area" required={required} type={type} tabIndex={tabIndex ? tabIndex : 0}  {...input}/>
                {!hideHelp && !displayError &&  <span className="deposit-form helper-text">{t("forms." + name + ".help")}</span>}
                {displayError &&  <span className="deposit-form helper-text">{error}</span> }
            </div>
            { asyncValidating && <span>Async in progress</span>}
        </div>
    )
};

export const renderSelect = ({input, values, meta: { touched, error} } : any) => {
    let name = input.name;
    let displayError = touched && error;
    let labelText = t("forms." + name + ".label");
    return (
        <select native {...input} placeholder={labelText}>
            { values.map( (v : { key : any, value : any}) => {
                let key = typeof v.key != "undefined" ? v.key : v;
                return <option key={key} value={key}>{typeof v.value != "undefined" ? v.value : v}</option>
            })}
        </select>
    )
};



export const renderCountries = ( { input, hideLabel, meta : { touched, error, warning }} : any ) => {
    let name = input.name;
    let displayError = touched && error;
    console.log("country error = " + error + " country touched " + touched);
    let labelText = t("forms." + name + ".label");
    return (

        <div style={{marginTop: "45px"}}>
            <div className={"form-control"} >
                {!hideLabel && <label className={"select-label"}>{labelText}</label>}
                <select classes={{formControl : "mui-select"}} autoWidth={false}  {...input}>
                    {COUNTRIES.map( c => <option key={c.code} value={c.name}>{c.name}</option>)}
                </select>
                {displayError &&  <span className="help-block">{error}</span> }
            </div>
        </div>

    );
}

export const renderCheckbox = ({input, text, label,defaultChecked, meta: { touched, error, warning } } : any) => {
    let displayError = touched && error;
    let name = input.name;
    return (
        <div className={"form-group " +  (displayError ? "has-error" : "")}>
            <div className={"form-container"}>
                <label className={"wrapper"}>
                    <input className={"checkbox"}  {...input} type="checkbox" />
                    <span className={"checkmark"}></span>
                    {label}
                </label>
            </div>
        </div>
    )
};

export const RenderUserAttributesField = ({input, text, tabIndex, position, dropdownSelect,
    userAttributesKey, userAttributesHelp, regex, maxLength, minLength, withDropdown, name, optionsDropdown, showHelpAlways = false, validRegex, meta: { touched, error } } : any) => {
    let displayError = touched && error;
    const value = input.value;
    const validDigit = input.value.length >= minLength && minLength && input.value.length <= maxLength && maxLength ? true : false;
    let labelText = userAttributesKey && t(userAttributesKey);
    let helpText = userAttributesHelp && t(userAttributesHelp);
    const positionClass = position ? position : '';
    const [click, setClick] = useState(false)
    const [leave, setLeave] = useState(false)
    const isClick = () => {
        setClick(true)
        setLeave(false)
    }
    const isLeave = () => {
        setLeave(true)
        setClick(false)
    }
    const dropdownSelectClass = dropdownSelect != undefined ? dropdownSelect : '';
    const dropdownArrowBg = dropdownSelect != undefined ? `url(${DOWN_ARROW})` : '';
   
    return (
        <div className={"form-group " +  (displayError ? "has-error" : "")}>
            {!withDropdown ?
            <div className={text != undefined ? "form-container" : ""}>
                <input className={`form-control ${positionClass}`}
                       placeholder={labelText}
                       tabIndex={tabIndex ? tabIndex : 0}
                       maxLength={maxLength}
                       size={maxLength}
                       minLength={minLength}
                       {...input}
                       onBlur={isLeave}
                       onFocus={isClick}
                       style={{ backgroundImage: `${DOWN_ARROW}` }}
                       required/>
            </div> :
            <div className={text != undefined ? `${"form-container"} ${text}` : ""}>
                <select name={name}
                className={`form-control ${dropdownSelectClass} ${positionClass}`}
                style={{ backgroundImage: `${DOWN_ARROW}` }}
                {...input}
                required>
                    <option value="" className="optionSelect">{labelText}</option>
                    {optionsDropdown && optionsDropdown.map((option:any) =>
                    <option value={option} key={option} className="optionSelect">{option}</option>)}
                </select>
            </div>
            }
            {(!showHelpAlways && click && !leave) ?
                <span className="help-block">{helpText}</span> : null
            }
            {showHelpAlways ?
                <span className="help-block">{helpText}</span> : null
            }

        </div>
    )
};
