import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Settings, UserInfo, WithdrawalMethod, LaunchUrl, KycStatus, kycCheck } from "../http/protocol";
import { Spinner } from "../ui/Spinner";

import { Dispatch } from "redux";
import { AppState } from "../RootReducer";
import { connect } from "react-redux";
import {
  LoginStatus,

} from "../http/Types";


import {
  fetchPendingWithdrawal,

} from "../payments/WithdrawActions";
import "../ui/deposit/styles/Deposit.scss";
import "../ui/deposit/styles/Method.scss";
import { fetchContent } from "../contenful/ContentfulActions";
import { getContainer } from "../ioc/IOCSetup";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import "../storepage/styles/PaymentFlow.scss"
import { fetchBalance } from "../user/UserActions";

import { t } from "../i18n";

type AllProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps &
  OtherProps;




interface State {

  pendingWithdrawal: [];


}

var options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

export class PendingWithdrawalPage extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
 
      pendingWithdrawal: [],

    };

  


  }


  componentDidMount(): void {
  
    this.props.fetchPendingWithdrawal();




  
  }


  componentWillUnmount() {
 
  }
  componentDidUpdate(prevProps: AllProps) {
   
  
  if(this.props.pendingWithdrawal >= prevProps.pendingWithdrawal ){
 
  }

 

  }

  cancelWithdrawal(paymentId:any) {
    getContainer().getPaymentsService().cancelWithdrawal(paymentId).then(()=>{
      this.props.fetchPendingWithdrawal();
      this.props.fetchBalance();
    this.setState({pendingWithdrawal:this.props.pendingWithdrawal});
  }).catch(err => {
     console.error(err)
  });
   

  }

  convertToDate(dateString: any) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let d = dateString.split("-");
    let t = dateString.split(":");
    let dat = new Date(d[2].split(' ')[0] + '/' + d[1] + '/' + d[0] + ' ' + d[2].split(' ')[1]);

    return dat;
}

showPendingWithdrawals() {
  let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
    return (

            <Table id="pending" className="table">
                <Thead>
                    <Tr>
                        <Th>{t("pendingWidthdrawal.status")}</Th>
                        <Th>{t("pendingWidthdrawal.amount")}</Th>
                        <Th>{t("pendingWidthdrawal.paymentID")}</Th>
                        <Th>{t("pendingWidthdrawal.requestDate")}</Th>
                        <Th>{t("pendingWidthdrawal.action")}</Th>
                  
                    </Tr>
                </Thead>

                <Tbody>
                    {this.props.pendingWithdrawal && this.props.pendingWithdrawal.length > 0 ? (
                        this.props.pendingWithdrawal.map((e: any, index: any) => {
                          let brTimeZon = new Date(this.convertToDate(e.requestDate));
                          let brNewtime = new Date(brTimeZon.setHours(brTimeZon.getHours() - 3)).toLocaleString('en-GB');
          
                            return [
                                <Tr key={index} key={index}>
                                    <Td>{t(`${e.status}`)}</Td>
                                    <Td>{e.amount}</Td>
                                    <Td>{e.paymentId}</Td>
                    
                                    <Td> {mainLobby == "https://joiabet.com/" ?  brNewtime:e.requestDate} </Td>
               
                                    <Td> <a className="btn btn-cancel" onClick={()=>this.cancelWithdrawal(e.paymentId)}>{t("cancel")}</a> </Td>
                                </Tr>,
                            ]
                        })
                    ) : (

                        <Tr>
                            <td colSpan={5}>{t("pendingWidthdrawal.noRecords")}</td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
       
    );


}


  render() {

  



    return (
  
 
          <React.Fragment>



            <div className="deposit-wrapper">

              <div className={"pf-deposit-container"}>

                <div id="content">

                  <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                  {this.props.pendingWithdrawal ? (this.showPendingWithdrawals()):("No Pending Withdrawals")}

                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
     
    
    );
  }
}

interface OtherProps extends RouteComponentProps { }

interface PropsFromState {
  pendingWithdrawal: any;
  isFetching: boolean;
  settings?: Settings;
  loggedIn: boolean;
  user?: UserInfo;
  selectedBundleId?: number;
  show?: boolean;
  fetchContent: typeof fetchContent;
  lng: string;
  withdrawPage: any;
  partyId: number;
  kycStatus?: string;
  selected?:string
}

interface PropsFromDispatch {

  fetchPendingWithdrawal: () => any;
  fetchBalance: () => any;
}

const mapStateToProps = ({ payments, settings, user, contentful }: AppState) => {

  return {
    pendingWithdrawal: payments.withdraw.pendingWithdrawal,
    settings: settings.settings,
    loggedIn: LoginStatus.LOGGED_IN == user.status,
    user: user.user,
     selected:user.cashierSelected,
    newUser: user.newUser,
    lng: getContainer().getSettingsService().getActiveLocale().code,


  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPendingWithdrawal: () => dispatch(fetchPendingWithdrawal() as any),
  fetchBalance: () => dispatch(fetchBalance() as any),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingWithdrawalPage);
