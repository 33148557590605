import { Dispatch } from "redux";
import { TransactionResult } from "../http/protocol";
import { getContainer } from "../ioc/IOCSetup";
import { AppState } from "../RootReducer";

export enum TransactionActionType {
  GET_TRANSACTIONS_REQUEST = "@profile/transactions/request",
  GET_TRANSACTIONS_SUCCESS = "@profile/transactions/success",
  GET_TRANSACTIONS_ERROR = "@profile/transactions/error"
}

export type TransactionActions =
    | { type : TransactionActionType.GET_TRANSACTIONS_REQUEST}
    | { type : TransactionActionType.GET_TRANSACTIONS_SUCCESS, transactions : TransactionResult[] }
    | { type : TransactionActionType.GET_TRANSACTIONS_ERROR, error : Error }

export function getTransactionHistory(date:any) {
  return (dispatch : Dispatch, getState :() => AppState) => {
    let passwordState = getState().password;
    dispatch({type : TransactionActionType.GET_TRANSACTIONS_REQUEST});
    getContainer().getTransactionService().getTransactionHistory(date)
        .then(r => {
          console.log("CALL HISTORY", r)
          // alert(r.transactions);
          dispatch({type : TransactionActionType.GET_TRANSACTIONS_SUCCESS, transactions: r.transactions.sort((a, b) => {
            return b.timestamp.valueOf() - a.timestamp.valueOf()
          }).reverse()});
      }).catch(err => {
      dispatch({ type : TransactionActionType.GET_TRANSACTIONS_ERROR, error : err});
    })
  }
}