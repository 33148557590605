import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Settings, UserInfo, WithdrawalMethod, LaunchUrl, KycStatus, kycCheck } from "../http/protocol";
import { Spinner } from "../ui/Spinner";
import getLoggedInRedirect from "../utils/LoggedInRedirect";
import Root from "../layout/Root";
import Page from "../layout/Page";
import { Dispatch } from "redux";
import { AppState } from "../RootReducer";
import { connect } from "react-redux";
import { fetchUserInfo, showAccountVerificationModal, showCashierModal } from "../user/UserActions";
import {
  LoginStatus,
  Status,
  WithdrawalMethodsByCurrency
} from "../http/Types";

import {
  fetchBalance,
  setActiveCurrency,
  setNewUser
} from "../user/UserActions";
import {
  fetchProviders,
  prepareWithdrawal,
  processWithdrawal,
  reset
} from "../payments/WithdrawActions";
import { WithdrawPhase, WithdrawState } from "../payments/PaymentsReducer";
import { paymentsImgUrl } from "../utils/Helpers";
import "../ui/deposit/styles/Deposit.scss";
import "../ui/deposit/styles/Method.scss";
import { ContentModelName, WithdrawPagePayloadV2 } from "../contenful/types";
import { fetchContent } from "../contenful/ContentfulActions";
import { getContainer } from "../ioc/IOCSetup";
import Modal from 'react-bootstrap/Modal';

import MetaHelmet from "../utils/MetaHelmet";
import { Helmet } from "react-helmet";


import "../storepage/styles/PaymentFlow.scss"

import { t } from "../i18n";

type AllProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps &
  OtherProps;

let staticWithdraw = [
  {
    code: 'CASHIERPAY',
    conversionRequired: false,
    feeRate: '0.00',
    minimumWithdrawal: "1,000.00",
    maximumWithdrawal: "100,000.00",
    method: 'UPI',
    methods: [],
    paymentMethod: 'upi_c',
    popupRequired: false
  },

  {
    code: 'CASHIERPAY',
    conversionRequired: false,
    feeRate: '0.00',
    minimumWithdrawal: "1,000.00",
    maximumWithdrawal: "100,000.00",
    method: 'Bank Transfer',
    methods: [],
    paymentMethod: 'banktransfer_nz',
    popupRequired: false
  }
];


interface State {
  startPlaying: boolean;
  showMethods: boolean;
  showWithdrawRedirect: boolean;
  withdrawalMethods: WithdrawalMethod[];
  selectedWithdrawalMethod: WithdrawalMethod;
  errorMessage: string;
  withdrawAmount: number;
  accountHolderName: string;
  bankCode: string;
  mobileNumber: string;
  accountNumber: string;
  continueUrl: string;
  iframeLoaded?: boolean;
  hooyuLink?: string;
  ipsKycCheckStatus?: string;
  cashierPayMaximum?: string;
  cashierPayMinimum?: string;
  staticWithdrawalCode?: string;
  methodList?: WithdrawalMethod[];
}


export class WithdrawalPageNoFooter extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      startPlaying: false,
      showMethods: true,
      withdrawalMethods: [],
      selectedWithdrawalMethod: null as any,
      errorMessage: null as any,
      withdrawAmount: 0,
      continueUrl: null as any,
      accountHolderName: '',
      bankCode: '',
      mobileNumber: '',
      accountNumber: '',
      showWithdrawRedirect: false,
      iframeLoaded: false,
      cashierPayMinimum: '0',
      cashierPayMaximum: '0'
    };

    if (this.props.user === undefined) {
      this.props.fetchUserDetails(localStorage.getItem("sessionKey"));
    }


  }


  componentDidMount(): void {



    this.props.fetchProviders && this.props.fetchProviders();
    const kycEnabled = getContainer().getSettingsService().getKYCenabled();
    const kycTriggers = getContainer().getSettingsService().getKYCtriggers();
    const kycProvider = getContainer().getSettingsService().getKYCProvider();
    let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
    if(mainLobby == "https://joiabet.com/") {
    this.props.prepareWithdrawal &&  this.props.prepareWithdrawal();
  }
    if (this.props.partyId && kycEnabled && kycTriggers?.withdraw && kycProvider === "hooyu") {
      getContainer().getPaymentsService().checkKYCStatus(this.props.partyId).then((r: kycCheck) => {
        this.setState({ ipsKycCheckStatus: r.ipsKycCheck && r.ipsKycCheck.kycStatus });
        if (r.ipsKycCheck && r.ipsKycCheck.kycStatus === null && this.props.partyId && !localStorage.getItem("hooyuLink")) {
          getContainer().getPaymentsService().performHooyu(this.props.partyId).then((r: LaunchUrl) => {
            this.setState({ hooyuLink: r.link });
            // console.error(r.link);
          });
        }
      });
    } else if (kycEnabled && kycTriggers?.withdraw && this.props.kycStatus !== KycStatus.PASS && kycProvider === "omegasys" || kycEnabled && kycTriggers?.withdraw && this.props.withdrawalStatus == "INVALID_KYC" && kycProvider === "omegasys") {
      this.props.showAccountVerificationModal();
    }




    this.props.fetchUserDetails(localStorage.getItem("sessionKey"));
    this.setState({ methodList: this.props.withdraw.methods });

    if (this.props.withdraw && this.props.withdraw.methods) {
      let cashierPay = this.props.withdraw.methods.find(el => el.code === "CASHIERPAY" || el.code === "DELUXEPAY365_IMPS");

      // console.error(cashierPay, "methodList");
      this.setState({
        cashierPayMinimum: cashierPay?.minimumWithdrawal,
        cashierPayMaximum: cashierPay?.maximumWithdrawal,
        staticWithdrawalCode: cashierPay?.code
      });
    }

    console.log("IS FETCHING", this.props.isFetching);

    if (this.props.user === undefined) {
      this.props.fetchUserDetails(localStorage.getItem("sessionKey"));
    }

    if (!this.props.isFetching) {
      console.log("this.props.withdrawpage ", this.props.withdrawPage)
      if (this.props.withdrawPage === undefined) {
        this.props.fetchContent(ContentModelName.WITHDRAWPAGE, getContainer().getSettingsService().getActiveLocale().code);
        console.log("CONTENTFUL : ", this.props);
      }
    }
    if (this.props.user && this.props.user.firstName && this.props.user.lastName && this.props.user.mobilePhone) {
      this.setState({ accountHolderName: this.props.user.firstName + this.props.user.lastName, mobileNumber: this.props.user.mobilePhone });
    }

  }
  componentWillUnmount() {
    this.props.setNewUser && this.props.setNewUser(false);
  }
  componentDidUpdate(prevProps: AllProps) {

    if (this.props.withdraw.methods && prevProps.withdraw.methods !== this.props.withdraw.methods) {
      let cashierPay = this.props.withdraw.methods.find(el => el.code === "CASHIERPAY" || el.code === "DELUXEPAY365_IMPS");

      // console.error(cashierPay, "methodList");
      this.setState({
        cashierPayMinimum: cashierPay?.minimumWithdrawal,
        cashierPayMaximum: cashierPay?.maximumWithdrawal,
        staticWithdrawalCode: cashierPay?.code
      });
    }

    if (prevProps.partyId !== this.props.partyId) {
      getContainer().getPaymentsService().checkKYCStatus(this.props.partyId).then((r: kycCheck) => {
        this.setState({ ipsKycCheckStatus: r.ipsKycCheck && r.ipsKycCheck.kycStatus });
        if (r.ipsKycCheck && r.ipsKycCheck.kycStatus === null && this.props.partyId && !localStorage.getItem("hooyuLink")) {
          getContainer().getPaymentsService().performHooyu(this.props.partyId).then((r: LaunchUrl) => {
            this.setState({ hooyuLink: r.link });
          });
        }
      });

    }

  }

  showWithdrawalMethods = () => {
    let methods = this.props.withdraw.methods as WithdrawalMethod[];
    //let methods = staticWithdraw as unknown as WithdrawalMethod[];
    return this.state.showMethods && methods ? (
      <div className="row ">
        {methods.map(e => {
          return (
            <div
              key={e.code}
              className=" col-lg-12 no-padding-2"
              onClick={() => this.handleClickWithdrawMethod(e)}
            >
              <div className="depMethodsBG ">
                <div className="depoTitleHolder">
                  <img
                    className="img-fluid"
                    src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + e.paymentMethod + '.svg'}
                    alt={e.method}
                    title={e.method}
                  />

                  {/* <span className="method-title">
                    {e.code === "DELUXEPAY365_BANKTRANSFER" ? ("BANK TRANSFER") :
                      e.code === "DELUXEPAY365_NETBANKING" ? ("NET BANKING") : (e.code && e.code.replaceAll("DELUXEPAY365_", ""))}
                  </span> */}
                </div>

                <div className="text-method">
                  <span className="subtext-deposit-method">
                   {t("depositMethod.min")} {e.minimumWithdrawal}
                  </span>

                  <span className="subtext-deposit-method">
                   {t("depositMethod.max")} {e.maximumWithdrawal}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : null;
  };
  handleClickWithdrawMethod = (method: WithdrawalMethod) => {
    this.setState({ selectedWithdrawalMethod: method, showMethods: false });
  };
  handleHooyuBtn = () => {
    if (this.state.hooyuLink) {
      localStorage.setItem("hooyuLink", this.state.hooyuLink);
    }
  }


  handleBackClicked = () => {
    this.setState({ selectedWithdrawalMethod: null as any, showMethods: true, showWithdrawRedirect: false });
  };

  handleAmountInput = (e: any) => {
    this.setState({ withdrawAmount: e.target.value });
    console.log(this.state);
  };


  handleAccountNumberInput = (e: any) => {
    this.setState({ accountNumber: e.target.value });
    console.log(this.state);
  };
  handleBankCodeInput = (e: any) => {
    this.setState({ bankCode: e.target.value });
    console.log(this.state);
  };

  showWithdrawForm = (selectedWithdrawalMethod: WithdrawalMethod) => {
    let ifsc_code_validator = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    let bank_number_validator = new RegExp(/^[0-9]{9,18}$/);
    return (
      <div className="deposit-details-group m-auto">




        <div className="logoTitleContainer d-flex">
          <div
            onClick={() => {
              this.handleBackClicked();
            }}
            className="back-btn align-self-center"
          >
                    {t("buttonBack")}
          </div>
          <span className="method-title align-self-center">                {selectedWithdrawalMethod.code === "DELUXEPAY365_BANKTRANSFER" ? ("BANK TRANSFER") :
            selectedWithdrawalMethod.code === "DELUXEPAY365_NETBANKING" ? ("NET BANKING") : (selectedWithdrawalMethod.code && selectedWithdrawalMethod.code.replaceAll("DELUXEPAY365_", ""))}</span>

          <img
            className="img-fluid"
            src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + selectedWithdrawalMethod.paymentMethod + '.svg'}
            alt={selectedWithdrawalMethod.code}
            title=""
          />


        </div>
        <div className="row">

          <div className="col-12">
            {/* <h2>{this.state.errorMessage ? this.state.errorMessage : ""}</h2> */}

            <form id="form-send-money">
              <div className="form-group">
                <label htmlFor="youSend">
                  <b> {t("pendingWidthdrawal.amount")}</b>
                </label>
                {this.state.errorMessage ? (
                  <div className="alert alert-danger">
                    {this.state.errorMessage}
                  </div>
                ) : (
                  false
                )}
                         <div className={"form-group col-12"}>
                <div className="input-group">
       
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      {localStorage.getItem("activeCurrency")}
                    </span>{" "}
                  </div>
                  <input
                    type="number"
                    id="youSend"
                    onChange={this.handleAmountInput}
                    className="form-control"
                    value={this.state.withdrawAmount}
                    data-bv-field="youSend"
                  />
                </div>    
                 {selectedWithdrawalMethod.code === "CASHIP_PIX" ? null : (
                <div className={"form-group "}>
                  <div className="d-flex row">
                    <div className={"form-group col-md-6 col-xs-12"}>
                      {this.state.staticWithdrawalCode === "DELUXEPAY365_IMPS" ? (<label htmlFor="youSend">
                        IFSC
                      </label>) : (<label htmlFor="youSend">
                        Bank Code
                      </label>)}
                      {this.state.staticWithdrawalCode === "DELUXEPAY365_IMPS" && this.state.bankCode && this.state.bankCode.length && !ifsc_code_validator.test(this.state.bankCode.trim()) ? (<div className="alert alert-danger">ISFC is invalid</div>) : null}
                      <input type="text" id="bankCode" title="Bank Code" onChange={this.handleBankCodeInput} className="form-control" value={this.state.bankCode.trim()} data-bv-field="youSend" />
                    </div>

                    <div className={"form-group col-md-6 col-xs-12"}>
                      {this.state.staticWithdrawalCode === "DELUXEPAY365_IMPS" ? (<label htmlFor="youSend">
                        Account ID
                      </label>) : (<label htmlFor="youSend">
                        Account Number
                      </label>)}
                      {this.state.staticWithdrawalCode === "DELUXEPAY365_IMPS" && this.state.accountNumber && this.state.accountNumber.length && !bank_number_validator.test(this.state.accountNumber) ? (<div className="alert alert-danger">Account Number is invalid</div>) : null}
                      <input
                        type="number"
                        id="accountNumber"
                        title="Acount Number"
                        onChange={this.handleAccountNumberInput.bind(this)}
                        className="form-control"
                        value={this.state.accountNumber}
                        data-bv-field="youSend"
                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      />  </div>
                  </div>
                </div>)
                }
                </div>
              </div>
              <div className="col-lg-12">
                <div className="payment-info">

                  <div>       {t("depositMethod.max")}: <span>{selectedWithdrawalMethod.maximumWithdrawal}</span> </div>

                  <div>   {t("depositMethod.min")}: <span>{selectedWithdrawalMethod.minimumWithdrawal}</span> </div>


                </div>
               {selectedWithdrawalMethod.code === "CASHIP_PIX"  ? (<p>Antes de solicitar seu saque, por favor verifique sua conta com nosso time de KYC, anexando os documentos solicitados</p>):null } 
              </div>
              <button
                type="button"
                className={`btn  btn-depo ${this.state.bankCode && this.state.bankCode.length && ifsc_code_validator.test(this.state.bankCode) && this.state.accountNumber && this.state.accountNumber.length && bank_number_validator.test(this.state.accountNumber)||selectedWithdrawalMethod.code === "CASHIP_PIX"  ? "" : "disabled"}`}
                onClick={() => this.handleFormSubmit(this.props.withdraw)}
              >
                {t("continue")}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  handleFormSubmit = (withdrawFromProps: WithdrawState) => {
    const minWithdraw =
      this.state.selectedWithdrawalMethod &&
      this.state.selectedWithdrawalMethod.minimumWithdrawal &&
      this.state.selectedWithdrawalMethod.minimumWithdrawal.replace(
        /[^0-9\.]+/g,
        ""
      );
    const maxWithdraw =
      this.state.selectedWithdrawalMethod &&
      this.state.selectedWithdrawalMethod.maximumWithdrawal &&
      this.state.selectedWithdrawalMethod.maximumWithdrawal.replace(
        /[^0-9\.]+/g,
        ""
      );

    //   const minWithdraw =
    //   this.state.cashierPayMinimum &&
    //   this.state.cashierPayMinimum &&
    //   this.state.cashierPayMinimum.replace(
    //     /[^0-9\.]+/g,
    //     ""
    //   );
    // const maxWithdraw =
    //   this.state.cashierPayMaximum &&
    //   this.state.cashierPayMaximum &&
    //   this.state.cashierPayMaximum.replace(
    //     /[^0-9\.]+/g,
    //     ""
    //   );


    let withdraw = this.props.withdraw;

    if (
      this.state.withdrawAmount >= parseInt(minWithdraw as any) &&
      this.state.withdrawAmount <= parseInt(maxWithdraw as any)
    ) {
      console.log("AMOUNT TO PASS", this.state.withdrawAmount);
      console.log("CODE TO PASS", this.state.selectedWithdrawalMethod.code);
      // this.state.selectedWithdrawalMethod.code = this.state.staticWithdrawalCode;
      withdrawFromProps.selectedWithdrawMethod = this.state.selectedWithdrawalMethod;
      withdrawFromProps.amount = this.state.withdrawAmount.toString();
      withdrawFromProps.accountNumber = this.state.accountNumber;
      withdrawFromProps.bankCode = this.state.bankCode;
      if (this.state.staticWithdrawalCode === "DELUXEPAY365_IMPS" || this.state.staticWithdrawalCode === "DELUXEPAY365_BANKTRANSFER" || this.state.staticWithdrawalCode === "DELUXEPAY365_NETBANKING") {
        withdrawFromProps.accountId = this.state.accountNumber;
        withdrawFromProps.ifscCode = this.state.bankCode;
      }
      withdrawFromProps.accountHolderName = this.state.accountHolderName;
      withdrawFromProps.mobileNumber = this.state.mobileNumber;
      this.props.processWithdrawal();
      console.log("HANDLE SUBMIT", withdrawFromProps)
      withdraw.continueUrl = withdrawFromProps.continueUrl;
      this.setState({
        continueUrl: withdrawFromProps.continueUrl,
        showWithdrawRedirect: true,
      });

    } else {
      this.validateWithdrawAmount(maxWithdraw);
    }
  };

  validateWithdrawAmount(maxWithdraw?: string) {
    if (
      maxWithdraw &&
      this.state.withdrawAmount > parseInt(maxWithdraw as any)
    ) {
      this.setState({
        errorMessage: t("depositMethod.err1 ")
      });
    } else {
      this.setState({ errorMessage: t("depositMethod.err2") });
    }
  }

  handleWithdrawRedirect = () => {
    console.log("FROM HANDLE CONTINUE REDIRECT", this.props)
    if (this.props.withdraw.continueUrl == "SUCCESS") {
      // window.open(this.props.withdraw.continueUrl, "_blank")
      return (
        <div className="deposit-success-container">
             <div className="title-2">
        <p dangerouslySetInnerHTML={
              { __html: t("withdrawalFooter.accountVerificationText", { interpolation: { escapeValue: false } })}}>
          </p>
          </div>
          <div className="title">
            
          <p dangerouslySetInnerHTML={
              { __html: t("withdrawalFooter.text1", { interpolation: { escapeValue: false } })}}>
          </p>

          </div>
          <div className="success-image-sub">
            <img
              className="img-fluid align-items-end"
              src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + this.state.selectedWithdrawalMethod.paymentMethod + '.svg'}
              alt={"selected"}
              title=""
            />
          </div>
          <div>
            {t("withdrawalFooter.text2")}
            <div className="buttons">
              <a onClick={() => this.props.hide()}>
                <div className="btn btn-white">{t("cancel")}</div>
              </a>
              <a onClick={() => this.handleBackClicked()}>
                <div className="btn white-text">{t("registerModal.back")}</div>
              </a>


            </div>

          </div>

        </div>
      );
    } else if (this.props.withdraw.continueUrl == "INSUFFICIENT_FUND") {
      return (
        <div className="deposit-success-container">
                 <div className="title-2">
        <p dangerouslySetInnerHTML={
              { __html: t("withdrawalFooter.accountVerificationText", { interpolation: { escapeValue: false } })}}>
          </p>
          </div>
          <p className="text-center">{t("withdrawalFooter.text3")}</p>
          <div className="title">
          {t("withdrawalFooter.err3")}

          </div>
          <div className="success-image-sub">
            <img
              className="img-fluid align-items-end"
              src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + this.state.selectedWithdrawalMethod.paymentMethod + '.svg'}
              alt={"selected"}
              title=""
            />
          </div>
          <div>

          <div className="buttons">
              <a onClick={() => this.props.hide()}>
                <div className="btn btn-white">{t("cancel")}</div>
              </a>
              <a onClick={() => this.handleBackClicked()}>
                <div className="btn white-text">{t("registerModal.back")}</div>
              </a>


            </div>

          </div>

        </div>

      );
    } else if (this.props.withdraw.continueUrl == "VALIDATION_FAIL" && !this.state.showMethods) {
      return (
        <div className="deposit-success-container">
                 <div className="title-2">
        <p dangerouslySetInnerHTML={
              { __html: t("withdrawalFooter.accountVerificationText", { interpolation: { escapeValue: false } })}}>
          </p>
          </div>
          <h3 className="text-center">{t("withdrawalFooter.err4")}</h3>
          <div className="title">
          {t("withdrawalFooter.text4")}

          </div>
          <div className="success-image-sub">
            <img
              className="img-fluid align-items-end"
              src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + this.state.selectedWithdrawalMethod.paymentMethod + '.svg'}
              alt={"selected"}
              title=""
            />
          </div>
          <div>

            <div className="buttons">
              <a onClick={() => this.props.hide()}>
                <div className="btn btn-white">{t("cancel")}</div>
              </a>
              <a onClick={() => this.handleBackClicked()}>
                <div className="btn white-text">{t("registerModal.back")}</div>
              </a>


            </div>

          </div>

        </div>

      );
    } else if (!this.state.showMethods) {
      return (
        <div className="deposit-success-container">
          <h3 className="text-center">{t("withdrawalFooter.err5")}</h3>
          <div className="title">
          <p dangerouslySetInnerHTML={
                                                { __html: t("withdrawalFooter.text5", { interpolation: { escapeValue: false } }) }
                                            } />
          </div>
          <div className="success-image-sub">
            <img
              className="img-fluid align-items-end"
              src={'https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/' + this.state.selectedWithdrawalMethod.paymentMethod + '.svg'}
              alt={"selected"}
              title=""
            />
          </div>
          <div>

            <div className="buttons">

              <div className="buttons">
                <a onClick={() => this.props.hide()}>
                  <div className="btn btn-white">{t("cancel")}</div>
                </a>
                <a onClick={() => this.handleBackClicked()}>
                  <div className="btn white-text">{t("back")}</div>
                </a>


              </div>


            </div>

          </div>

        </div>

      );
    }

  }
  kycContent() {
    return (<div className="deposit-wrapper">

      <div className={"pf-deposit-container"}>

        <div id="content">

          <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
            <h4>{t("withdrawalPage.verifyKYC")}</h4>
            <p>{t("withdrawalPage.hi")} {this.props.user && this.props.user.firstName}, {t("withdrawalPage.text1")}
            </p>
            <a className='btn btn-primary  btn-rounded' onClick={() => this.props.showAccountVerificationModal(true)}>{t("withdrawalPage.proceedKYC")}</a>

          </div>
        </div>
      </div>
    </div>)
  }
  render() {
    const { isFetching, loggedIn, settings, user, withdraw, kycStatus } = this.props;
    const kycEnabled = getContainer().getSettingsService().getKYCenabled();
    const kycTriggers = getContainer().getSettingsService().getKYCtriggers();
    const kycProvider = getContainer().getSettingsService().getKYCProvider();
     if (kycEnabled && kycTriggers?.withdraw && this.props.kycStatus !== KycStatus.PASS && kycProvider === "omegasys" || kycEnabled && kycTriggers?.withdraw && this.props.withdrawalStatus == "INVALID_KYC" && kycProvider === "omegasys") {
     
      return (this.kycContent());
    }

    if (user !== undefined) {


      if (user && user.registrationStatus === "QUICK_REG") {
        return <Redirect to={"/signup/full"} />
      }



    }
    if (!user) {
      return <Redirect to={'/'} />;
    }
    {
      console.log(this.state.continueUrl);
    }
    {
      // console.log(this.state, "RENDER");
    }
    return (
      <div>
        {isFetching ? (
          <Spinner />
        ) : (
          <React.Fragment>





            <div className="deposit-wrapper">

              <div className={"pf-deposit-container"}>
                {
                  <div id="content">

                    <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">

                      {this.showWithdrawalMethods()}
                      {this.state.selectedWithdrawalMethod &&
                        !this.state.showWithdrawRedirect
                        ? this.showWithdrawForm(
                          this.state.selectedWithdrawalMethod
                        )
                        : null}
                      {this.state.showWithdrawRedirect ?
                        this.handleWithdrawRedirect() : null}

                    </div>
                  </div>
                }
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

interface OtherProps extends RouteComponentProps { }

interface PropsFromState {
  withdraw: WithdrawState;
  isFetching: boolean;
  settings?: Settings;
  loggedIn: boolean;
  user?: UserInfo;
  selectedBundleId?: number;
  show?: boolean;
  fetchContent: typeof fetchContent;
  lng: string;
  withdrawPage: any;
  partyId: number;
  kycStatus?: string;

}

interface PropsFromDispatch {
  fetchProviders?: () => void;
  update?: (amount: string) => void;
  selectCurrency?: (currencyCode: string) => void;
  setUseAsStore?: (setUseAsStore: boolean) => void;
  hide: () => void;
  processWithdrawal: () => any;
  prepareWithdrawal:()=> any;
  setNewUser?: typeof setNewUser;
  continueUrl?: string;
  withdrawalStatus?:string;
  fetchUserDetails: typeof fetchUserInfo;
  showAccountVerificationModal: (show: boolean) => void;
}

const mapStateToProps = ({ payments, settings, user, contentful }: AppState) => {
  let status = false;
  if (user.registration && user.registration.status) {
    if (user.registration.status == Status.SUCCESS) {
      status = true;
    }
  }

  return {
    withdraw: payments.withdraw,
    settings: settings.settings,
    loggedIn: LoginStatus.LOGGED_IN == user.status,
    user: user.user,
    activeCurrency: user.activeCurrency,
    newUser: user.newUser,
    lng: getContainer().getSettingsService().getActiveLocale().code,
    isFetching: contentful.isFetching,
    withdrawPage: contentful.withdrawPage,
    partyId: user.user?.partyId,
    kycStatus: user.user?.kycStatus,
    withdrawalStatus: payments.withdraw.prepareWithdrawal
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserDetails: (sessionKey: UserInfo) => dispatch(fetchUserInfo(sessionKey) as any),
  fetchProviders: () => dispatch(fetchProviders() as any),
  fetchBalance: () => dispatch(fetchBalance() as any),
  setNewUser: (newUser: boolean) => dispatch(setNewUser(newUser) as any),
  showAccountVerificationModal: (show: boolean) => dispatch(showAccountVerificationModal(show)),
  hide: () => dispatch(showCashierModal(false)),
  reset: () => dispatch(reset() as any),
  processWithdrawal: () => dispatch(processWithdrawal() as any),
  prepareWithdrawal:() => dispatch(prepareWithdrawal() as any),
  fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalPageNoFooter);
