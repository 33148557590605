import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { getContainer, wireApplication } from "./ioc/IOCSetup";
import { getActiveLocale, getLocaleFromPath, getTargetLanguagePath, setupI18n } from "./i18n";
import { LandingPages, SettingsUrl } from "./http/Types";
import axios from "axios";
import { Settings } from "./http/protocol";
import { HttpService } from "./http/HttpService";
import { SettingsService } from "./settings/SettingsService";

import { FacebookApi } from "./FacebookApi";
import { Locale } from "./contenful/types";
import "./assets/scss/main.scss"
import { AmplitudeEvent } from "./amplitude/AmplitudeEvent";
import { AmplitudeService } from "./amplitude/AmplitudeService";
import Cookies from "js-cookie";
import FingerprintJS, { Agent } from "@fingerprintjs/fingerprintjs";
import "./index.scss"
import ReactGA from 'react-ga4';
import {COUNTRIES} from "./user/ui/Countries";
import TagManager from 'react-gtm-module'
declare global {
    interface Window {
        deferredPrompt: any,
        installedUsingNativePrompt: string
        PasswordCredential: any
        FederatedCredential: any
        PublicKeyCredential: any
        fcWidget: any
        fingerprint: Agent;
    }
}



const ACTIVE_LOCALE_COOKIE = "activeLocale";

if ('serviceWorker' in navigator) {
    //starting up serivce worker and adding eventlistener for it.
    window.installedUsingNativePrompt = 'native';

    serviceWorker.register();

    window.addEventListener('beforeinstallprompt', (e) => {
        console.log("beforeinstallprompt event called!");
        window.deferredPrompt = e;
    });

    window.addEventListener('appinstalled', (evt) => {
        let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
        if (amplitudeService) {
            amplitudeService.logEvent(AmplitudeEvent.APP_INSTALL_DONE, { prompt: window.installedUsingNativePrompt });
        }
        localStorage.setItem('appinstalled', 'true');
    });

    window.addEventListener('push', () => {
        let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
        if (amplitudeService) {
            amplitudeService.logEvent(AmplitudeEvent.PUSH_RECEIVED, {});
        }
    });

    window.addEventListener('notificationsclick', () => {
        let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
        if (amplitudeService) {
            amplitudeService.logEvent(AmplitudeEvent.PUSH_CLICKED, {});
        }
    })
}
function setTrackingCookies(domain: string) {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('ref')) {
        let trackerValue = urlParams.get('ref');
        document.cookie = `refcode=${trackerValue};max-age=345600;${domain};samesite=lax`;
    }
    if (urlParams.has('ca')) {
        let campaignValue = urlParams.get('ca');
        document.cookie = `campaign=${campaignValue};max-age=345600;${domain};samesite=lax`;
    }
    if (urlParams.has('ch')) {
        let channelValue = urlParams.get('ch');
        document.cookie = `mchannel=${channelValue};max-age=345600;${domain};samesite=lax`;
    }
}
addStylesheet("/assets/css/typography.css?id="+generateUUID());
addStylesheet("/assets/css/style.css?id="+generateUUID());
addStylesheet("/assets/css/b-font-icon.css?id="+generateUUID());
addStylesheet("/assets/css/b-styles.css?id="+generateUUID());
addStylesheet("/assets/css/responsive.css?id="+generateUUID());
function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x7|0x8)).toString(16);
    });
      return uuid;
    };
function addStylesheet(href: string) {
    let css: HTMLLinkElement = document.createElement("link");
    css.href = href;
    css.rel = "stylesheet";
    css.media = "all";
    try {
        if (css.href.length > 0) { }
        document.head.appendChild(css);
    } catch (e) {

    }
}
let loader = document.getElementById("jsLoader");
if (loader) {
    console.log("setting loader to hidden")
    loader.style.display = "none";
}
(async function () {
    let config;
    let config_setting;
    let land_page_config;
    let promotions_config;
    let manifest;
    let settings: Settings;
    let spaceId;
    let accessToken;
    let landing_page_data;
    let GAcode;
    let GTMcode;
    try {
        config = await getConfig();
        land_page_config = await getLandingPageConfig();
        promotions_config =await getPromotionsConfig();
        let hostname = document.location.hostname;
        //@ts-ignore
    
        config_setting = config[hostname];
        landing_page_data = land_page_config;
  
        //@ts-ignore
        if (config_setting && config["global"]) {
            config_setting = {
                baseUrl: config_setting.baseUrl ? config_setting.baseUrl : config["global"].baseUrl,
                casinoUrl: config_setting.casinoUrl ? config_setting.casinoUrl : config["global"].casinoUrl,
                liveCasinoUrl: config_setting.liveCasinoUrl ? config_setting.liveCasinoUrl : config["global"].liveCasinoUrl,
                brandId: config_setting.brandId ? config_setting.brandId : config["global"].brandId,
                song88Url: config_setting.song88Url ? config_setting.song88Url : config["global"].song88Url,
                googleAnalytic: config_setting.googleAnalytic,
                defaultCurrency:config_setting.defaultCurrency ? config_setting.defaultCurrency : config["global"].defaultCurrency,
                kycEnabled:config_setting.kycEnabled ? config_setting.kycEnabled : config["global"].kycEnabled,
                kycTrigger:config_setting.kycTrigger ? config_setting.kycTrigger : config["global"].kycTrigger,
                kycProvider:config_setting.kycProvider ? config_setting.kycProvider : config["global"].kycProvider,
                lang:config_setting.lang ?  config_setting.lang : config["global"].lang,
                googleTagManager: config_setting.googleTagManager,
                themeCSS: config_setting.themeCSS,
                brandLogo:config_setting.brandLogo,
                themeTemplate:config_setting.themeTemplate,
                headerMenu:config_setting.headerMenu,
                mainLobby:config_setting.mainLobby,
                maintenance:config_setting.maintenance
            };
            // console.log(config_setting);
               //@ts-ignore
               GAcode = config_setting.googleAnalytic;
                 //@ts-ignore
               GTMcode = config_setting.googleTagManager;
        } else {

            config_setting = { baseUrl: "https://ps.adminemx.com/ps", casinoUrl: "../../public/data/CasinoGames.json", liveCasinoUrl: "../../public/data/LiveCasinoGames.json", brandId: 1, song88Url: "https://preprod-platformweb.song88.com/?clientId=AFF8273B-5252-4B2A-C152-08DA4F718D24",  googleAnalytic:"G-0254HQRKRB", defaultCurrency:["INR"],    
            lang:[ {"code": "", "name": "English", "defaultLocale": true, "fallback" : null}],        kycProvider:"omegasys", kycEnabled:false,googleTagManager:"GTM-PVGTG45",


            kycTrigger:{
                profile: false,
                withdraw:false
            }};
            
            GAcode = config_setting.googleAnalytic;
              //@ts-ignore
            GTMcode = config_setting.googleTagManager;
    
        }


    } catch (e) {



        config_setting = { baseUrl: "https://ps.adminemx.com/ps", casinoUrl: "../../public/data/CasinoGames.json", liveCasinoUrl: "../../public/data/LiveCasinoGames.json", brandId: 1,  googleAnalytic:"G-0254HQRKRB", defaultCurrency:["INR"],     kycEnabled:false,
        lang:[ {"code": "", "name": "English", "defaultLocale": true, "fallback" : null}], googleTagManager:"GTM-PVGTG45",

        kycTrigger:{
            profile: false,
            withdraw:false
        }};
        GAcode = config_setting.googleAnalytic;
        GTMcode = config_setting.googleTagManager;


    }
//    console.error(GTMcode,"GTMcode");
    if(GTMcode) {
        const tagManagerArgs = {
            gtmId: GTMcode
        }
        
        TagManager.initialize(tagManagerArgs)
    }
    if(GAcode) {
     
        ReactGA.initialize(GAcode);
        }

     if(config_setting.themeCSS) {
        addStylesheet(config_setting.themeCSS);
     }   
    let domain = ((document as any).domain.match(/[^\.]*\.[^.]*\.[^.]*$/) != null ? (document as any).domain.match(/[^\.]*\.[^.]*\.[^.]*$/)[0] : "");
    if (domain.length == 0) {
        domain = (document as any).domain;
    }
    //cookie monster
    const domainValue = "domain=" + domain + ";";

    let searchParams = new URLSearchParams(window.location.search);
    let token = searchParams.get('token');
    if (token) {
        localStorage.setItem("token", token);
        console.log("token", searchParams.get('token'));
    }

    let httpService = new HttpService(config_setting.baseUrl, config_setting.casinoUrl, config_setting.liveCasinoUrl, config_setting.brandId);
    let settingsService = SettingsService.getInstance(httpService);
    settings = await settingsService.getSettings();
    settingsService.setSettingsData();

    settingsService.setLandingPages(landing_page_data);
    settingsService.setPromotions(promotions_config && promotions_config[config_setting.brandId]);
    settingsService.setSong88Url(config_setting.song88Url);
    settingsService.setDefaultCurreny(config_setting.defaultCurrency);
    settingsService.setKYCenabled(config_setting.kycEnabled);
    settingsService.setKYCProvider(config_setting.kycProvider);
    settingsService.setKYCtriggers(config_setting.kycTrigger);
    settingsService.setLocalesData(config_setting.lang);
    settingsService.setBrandLogo(config_setting.brandLogo);
    settingsService.setThemeTemplate(config_setting.themeTemplate);
    settingsService.setHeaderMenu(config_setting.headerMenu);
    settingsService.setMainLobbyUrl(config_setting.mainLobby);
    settingsService.setMaintenance(config_setting.maintenance);
    setTrackingCookies(domainValue);
    let cookieLocale = Cookies.get(ACTIVE_LOCALE_COOKIE);
    let localeFromPath = getLocaleFromPath();
   
    let activeLocale = getActiveLocale(settingsService, localeFromPath, cookieLocale);
    let targetLocale = activeLocale.code;
    await setupI18n( targetLocale,settingsService,config_setting.mainLobby);
    if(!localeFromPath || localeFromPath != targetLocale  && config_setting.lang.length > 1) {
    
        if(localeFromPath && config_setting.lang.length > 1) {
            let path = getTargetLanguagePath(localeFromPath, targetLocale);
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(null,"", path);
        } else if (document.location.search && config_setting.lang.length > 1) {
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(null, "", `/${targetLocale}${document.location.pathname}${document.location.search}`)
        } else if (config_setting.lang.length > 1){
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(null, "", `/${targetLocale}${document.location.pathname}`)
        }
        if(config_setting.lang.length > 1) {
            localeFromPath = targetLocale;
        }

    }
    settingsService.setActiveLocale(activeLocale);



 

    console.log("Setting " + ACTIVE_LOCALE_COOKIE + " cookie to " + activeLocale.code + " with domain " + domain);
    Cookies.set(ACTIVE_LOCALE_COOKIE, activeLocale.code, { expires: 365, domain: domain, path: "/" });


    let fb;

    wireApplication(config_setting, "6y9dy1bxzywa", "WjE6LZKc0xjqErN-VZOCSsgJUDxQbH8f-EA4GrUoVpA", fb = new FacebookApi(undefined), undefined, true);



    ReactDOM.render(<App locale={localeFromPath} />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register();
})();

async function getConfig(): Promise<SettingsUrl> {
    let http = axios.create({
        headers: {
            "Content-type": "application/json"
        }
    });
    return http.get("/config/config.json").then(r => r.data);

}


async function getLandingPageConfig(): Promise<LandingPages> {
    let http = axios.create({
        headers: {
            "Content-type": "application/json"
        }
    });
    return http.get("/config/landing_page_config.json").then(r => r.data);

}


async function getPromotionsConfig(): Promise<any> {
    let http = axios.create({
        headers: {
            "Content-type": "application/json"
        }
    });
    return http.get("/config/promotion.json").then(r => r.data);

}