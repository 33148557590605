
import {StoreActions, StoreActionTypes} from "./StoreActions";
import {ListBundlesResponse} from "../http/protocol";

export enum StoreStateStatus {
    NONE,
    STORE_INITIATED,
    STORE_INITIALIZED,
    STORE_ERROR_INITIALIZING,
    STORE_ERROR_BUYING,
}

export interface StoreState {
    isFetching: boolean;
    isFetchingBonus: boolean;
    loading: boolean;
    claimedBonus: boolean;
    status?: StoreStateStatus;
    bundles?: ListBundlesResponse;
    bonusBundles?: ListBundlesResponse;
    selectedBundleId?: number;
}

const initialState : StoreState = {isFetching: false, isFetchingBonus: false, loading: false, claimedBonus: false};

export function storeReducer (state = initialState, action: StoreActions) {
    switch (action.type) {
        case StoreActionTypes.FETCH_BUNDLE_REQUEST:
            return {...state, selectedBundleId: undefined, isFetching: true}
        case StoreActionTypes.FETCH_BUNDLE_SUCCESS:
            return {...state, isFetching: false, bundles: action.bundles}
        case StoreActionTypes.FETCH_BUNDLE_ERROR:
            return {...state, isFetching: false, status: StoreStateStatus.STORE_ERROR_INITIALIZING}
        case StoreActionTypes.BUY_BUNDLE_REQUEST:
            return {...state, loading: true}
        case StoreActionTypes.BUY_BUNDLE_RESPONSE:
            return {...state, loading: false, paymentDialog: action.paymentDialog}
        case StoreActionTypes.BUY_BUNDLE_ERROR:
            return {...state, loading: false, status: StoreStateStatus.STORE_ERROR_BUYING}
        case StoreActionTypes.SELECT_BUNDLE:
            return {...state, selectedBundleId: action.id}
        case StoreActionTypes.FETCH_BUNDLE_BONUS_REQUEST:
            return {...state, isFetchingBonus: true}
        case StoreActionTypes.FETCH_BUNDLE_BONUS_SUCCESS:
            return {...state, isFetchingBonus: false, bonusBundles: action.bundles}
        case StoreActionTypes.FETCH_BUNDLE_BONUS_ERROR:
            return {...state, isFetchingBonus: false}
        default:
            return state;
    }
}