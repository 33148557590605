import { UserAction, UserActionTypes } from "./UserActions";
import {
    AccountBalance,
    BonusOffer,
    KycStatus,
    LoginResponseStatus,
    ManualLoginRequest, MultiFactorAuthProvider,
    RegistrationResponse,
    UserBonusView,
    UserInfo
} from "../http/protocol";
import { LoginStatus, ResetPasswordStatus, VerifyEmailStatus } from "../http/Types";
import { DepositActions, DepositActionType } from "../payments/DepositActions";

export enum LoginError {
    INVALID_USER_OR_PASSWORD,
    USER_BLOCKED,
    TOO_MANY_LOGIN_ATTEMPTS,
    FB_EMAIL_ALREADY_EXIST,
    INVALID_TOKEN

}
export enum FacebookStatus {
    NONE, IN_PROGRESS
}

export enum LoginType {
    EMAIL,
    FACEBOOK,
    TOKEN
}
export interface MFAState {
    username: string;
    password: string;
    email?: string;
    provider: MultiFactorAuthProvider;
}
export interface UserState {
    status?: LoginStatus;
    facebookStatus?: FacebookStatus
    loginError?: LoginError;
    user?: UserInfo;
    displayLogin?: boolean;
    displaySignup?: boolean;
    displayBonusModal?: boolean;
    displayAccountVerificationModal?: boolean;
    displaySettingsModal?: boolean;
    showForgotPassword?: boolean;
    initialBalanceFetched?: boolean;
    balances?: AccountBalance[];
    casinoPlayableBonusBalance?: number;
    sportsbookPlayableBonusBalance?: number;
    bonusBalance?: number;
    balanceError?: Error;
    registration?: RegistrationResponse;
    resetPasswordStatus?: ResetPasswordStatus;
    resetPasswordResponse?:any;
    verifyEmailStatus?: VerifyEmailStatus;
    manualLoginRequest?: ManualLoginRequest;
    activeCurrency?: string;
    showBalance?: boolean;
    matiStatus: KycStatus;
    appMode?: boolean;
    showPushPrompt?: boolean;
    showAppInstallGuide?: boolean;
    newUser: boolean;
    bonusOffers?: BonusOffer[];
    activeBonuses?: UserBonusView[];
    mfa?: MFAState;
    optInBonusPlans?: any;
    displayCashierModal?: boolean;
    bonuses?: UserBonusView[];
    bonusesByDate?: UserBonusView[];
    cashierSelected?:string;
}




function userSettingsReducer(state: { [index: string]: string } = {}, action: UserAction) {
    switch (action.type) {
        case UserActionTypes.USERSETTINGS_RESPONSE:
            return { ...state, ...action.response }
        default:
            return state;
    }
}


const initialState: UserState = { initialBalanceFetched: false, status: LoginStatus.LOGGED_OUT, displayLogin: false, displaySignup: false, displayBonusModal: false, showBalance: true, facebookStatus: FacebookStatus.NONE, matiStatus: KycStatus.NOT_STARTED, appMode: false, showPushPrompt: false, showAppInstallGuide: false, newUser: false, optInBonusPlans: [], sportsbookPlayableBonusBalance: 0, casinoPlayableBonusBalance: 0, bonusBalance: 0, displayAccountVerificationModal: false, displaySettingsModal: false, bonuses: [], bonusesByDate: [], displayCashierModal: false,cashierSelected:'', resetPasswordResponse:'' };

export function userReducer(state: UserState = initialState, action: UserAction | DepositActions) {
    switch (action.type) {
        case UserActionTypes.USER_ACTIVE_BONUS_BY_DATE:
            return { ...state, bonusesByDate: action.bonuses };
        case UserActionTypes.USER_ACTIVE_BONUS:
            return { ...state, bonuses: action.bonuses };
        case UserActionTypes.SHOW_LOGIN:
            return { ...state, displayLogin: action.show }
        case UserActionTypes.SHOW_SIGNUP:
            return { ...state, displaySignup: action.show }
        case UserActionTypes.SHOW_BONUS:
            return { ...state, displayBonusModal: action.show }
        case UserActionTypes.SHOW_CASHIER:
            return { ...state, displayCashierModal: action.show, cashierSelected:action.selectedCashier }
        case UserActionTypes.SHOW_ACCOUNT_VERIFICATION:
            return { ...state, displayAccountVerificationModal: action.show }
        case UserActionTypes.SHOW_SETTINGS:
            return { ...state, displaySettingsModal: action.show }
        case UserActionTypes.SHOW_FORGOT_PASSWORD:
            return { ...state, showForgotPassword: action.show }
        case UserActionTypes.LOGIN_REQUEST:
            return { ...state, status: LoginStatus.IN_PROGRESS };
        case UserActionTypes.MFA_VERIFICATION:
            return { ...state, status: LoginStatus.MFA_VERIFICATION_REQUIRED, mfa: { provider: action.provider, username: action.username, password: action.password } }
        case UserActionTypes.MFA_VERIFICATION_ERROR:
            return { ...state, status: LoginStatus.MFA_VERIFICATION_ERROR };
        case UserActionTypes.MFA_RESET:
            return { ...state, status: LoginStatus.LOGGED_OUT, mfa: undefined }
        case UserActionTypes.LOGIN_RESPONSE:
            let loginStatus = LoginStatus.LOGGED_IN;
            if (action.response.responseStatus === LoginResponseStatus.VERIFICATION_REQUIRED) {
                loginStatus = LoginStatus.VERIFICATION_REQUIRED;
            }
            return { ...state, status: loginStatus, user: action.response, displayLogin: false, facebookStatus: FacebookStatus.NONE, mfa: undefined };
        case UserActionTypes.LOGIN_ERROR:
            return { ...state, status: LoginStatus.LOGIN_ERROR, loginError: action.error, facebookStatus: FacebookStatus.NONE };
        case UserActionTypes.LOGOUT:

            return { ...state, status: LoginStatus.LOGGED_OUT, user: undefined, registration: undefined, verifyEmailStatus: VerifyEmailStatus.NOT_USED, matiStatus: KycStatus.NOT_STARTED };
        case UserActionTypes.BALANCE_RESPONSE:
            let activeCurrency = state.activeCurrency;
            if (!activeCurrency && action.response.activeCurrency) {
                activeCurrency = action.response.activeCurrency;
            }
            if (!activeCurrency && action.response.balances && action.response.balances.length > 0 && action.response.balances[0].balance) {
                activeCurrency = action.response.balances[0].balance.currencyCode;
            }
            return { ...state, initialBalanceFetched: true, bonusOffers: action.response.bonusOffers, activeBonuses: action.response.activeBonuses, balances: action.response.balances, balanceError: undefined, activeCurrency: activeCurrency, casinoPlayableBonusBalance: action.response.casinoPlayableBonusBalance, sportsbookPlayableBonusBalance: action.response.sportsbookPlayableBonusBalance, bonusBalance: action.response.bonusBalance };
        case UserActionTypes.BALANCE_ERROR:
            return { ...state, balanceError: action.error }
        case UserActionTypes.USER_INFO_RESPONSE:
            let status = action.info.loggedIn == true ? LoginStatus.LOGGED_IN : LoginStatus.LOGGED_OUT;
            return { ...state, user: action.info, status: status, displayLogin: false };
        case UserActionTypes.REGISTER_RESPONSE:
            return { ...state, registration: action.response };
        case UserActionTypes.REQUEST_RECOVER_PASSWORD:
            return { ...state, resetPasswordStatus: ResetPasswordStatus.IN_PROGRESS };
        case UserActionTypes.REQUEST_RECOVER_PASSWORD_ERROR:
            return { ...state, resetPasswordStatus: ResetPasswordStatus.ERROR };
        case UserActionTypes.RESET_PASSWORD_REQUEST:
            return { ...state, resetPasswordStatus: ResetPasswordStatus.IN_PROGRESS };
        case UserActionTypes.RESET_PASSWORD_RESPONSE:
            return { ...state, resetPasswordStatus: ResetPasswordStatus.SENT, resetPasswordResponse: action.response};
        case UserActionTypes.RESET_PASSWORD_ERROR:
            return { ...state, resetPasswordStatus: ResetPasswordStatus.ERROR };
        case UserActionTypes.SET_ACTIVE_CURRENCY:
            return { ...state, activeCurrency: action.activeCurrency };
        case UserActionTypes.SET_SHOW_BALANCE:
            return { ...state, showBalance: action.show };
        case UserActionTypes.VERIFY_EMAIL_REQUEST:
            return { ...state, verifyEmailStatus: VerifyEmailStatus.IN_PROGRESS };
        case UserActionTypes.VERIFY_EMAIL_RESPONSE:
            return { ...state, verifyEmailStatus: VerifyEmailStatus.IN_PROGRESS, manualLoginRequest: action.request };
        case UserActionTypes.VERIFY_EMAIL_ERROR:
            return { ...state, verifyEmailStatus: VerifyEmailStatus.ERROR };
        case UserActionTypes.VERIFY_CHANNEL_CODE_REQUEST:
            return { ...state, verifyEmailStatus: VerifyEmailStatus.IN_PROGRESS };
        case UserActionTypes.VERIFY_CHANNEL_CODE_RESPONSE:
            return { ...state, verifyEmailStatus: VerifyEmailStatus.COMPLETE, manualLoginRequest: undefined };
        case UserActionTypes.VERIFY_CHANNEL_CODE_ERROR:
            return { ...state, verifyEmailStatus: VerifyEmailStatus.ERROR };
        case UserActionTypes.VERIFY_RESET:
            return { ...state, verifyEmailStatus: VerifyEmailStatus.NOT_USED };
        case UserActionTypes.FACEBOOK_LOGIN_START:
            return { ...state, facebookStatus: FacebookStatus.IN_PROGRESS };
        case UserActionTypes.FACEBOOK_LOGIN_CANCELED:
            return { ...state, facebookStatus: FacebookStatus.NONE };
        case UserActionTypes.FACEBOOK_LOGIN_RESPONSE:
            return { ...state, facebookStatus: FacebookStatus.IN_PROGRESS };
        case UserActionTypes.SET_MATI_STATUS:
            return { ...state, matiStatus: action.status };
        case UserActionTypes.SET_APP_MOODE:
            console.log("Setting app mode = ", action.appMode);
            return { ...state, appMode: action.appMode };
        case UserActionTypes.USERSETTINGS_RESPONSE:
            if (state.user) {
                return userSettingsReducer(state.user && state.user.userSettings, action);
            } else return;
        case DepositActionType.INIT_DEPOSIT_REQUEST:
            if (state.user && state.user.userSettings) {
                let req = (action as any).request;
                if (req && req.amount && req.activeCurrency) {
                    let userSettings = { ...state.user.userSettings };
                    userSettings["last_deposit_amount_" + req.activeCurrency] = req.amount;
                    return { ...state, user: { ...state.user, userSettings: userSettings } }
                }
            }
            return state;
        case UserActionTypes.SHOW_IOS_INSTALL_GUIDE:
            return { ...state, showAppInstallGuide: action.show }
        case UserActionTypes.SHOW_PUSH_PROMPT:
            return { ...state, showPushPrompt: action.show };
        case UserActionTypes.REDIRECT_USER:
            // history.pushState({},"",action.url);
            // history.go();
            return;
        case UserActionTypes.SET_NEW_USER:
            if (action.newUser === true) {
                return { ...state, newUser: action.newUser };
            } else {
                return { ...state, newUser: action.newUser };
            }
        case UserActionTypes.USER_ELIGABLE_BONUS:
            return { ...state, optInBonusPlans: action.optInBonusPlans };

        default:
            return state;
    }
}
