import React, { Component, useEffect, useState, useRef } from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import  ContentSliderComponent  from './ContentSliderComponent'
import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
    LikeBtn,
    ShareBtn
} from './Socials'
import { Modal } from 'react-bootstrap'

interface Props extends RouteComponentProps{
    slides: any;
    loggedIn:boolean;
    showLogin:()=> void;
    lng:string;
    themev2?:boolean;
}
interface State {
    listSlides: any;
    visible: boolean;
    mdShow: boolean;
    videoSrc: String;

}





export class ContentSlider extends  React.Component<Props, State> {
    constructor(props: Props) {
      
        super(props);
      
    }


    render() {
        
    
        let settings
        

            settings =  {
                className: "content-slider",
                dots: false,
                arrows: true,
                infinite: false,
                speed: 200,
                autoplay:false,
                autoTransform:false,
                centerMode:false,
                adaptiveHeight: false,
                pauseOnHover: true,
                slidesToShow: 6,
                slidesToScroll: 3,
                rows: 2,
                cssEase: 'linear',
                nextArrow: <NextArrow />,
                prevArrow: <PrevArrow />,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 2,
                            infinite: false,
                            dots: true,
                            autoplay:false,
                        }
                    },
                     {
                        breakpoint: 1176,
                        settings: {
                            centerMode:false,
                            speed:80,
                            infinite: true,
                            slidesToShow: 5,
                            slidesToScroll: 3,
                            variableWidth: false,
                            
                        }
                    },
                 
                    {
                        breakpoint: 676,
                        settings: {
                            centerMode:false,
                            speed:80,
                            infinite: true,
                            slidesToShow: 4,
                            slidesToScroll: 3,
                            variableWidth: false,
                            
                        }
                    }
                ]
            }
      
       

        return (


            <div className="single-slide">
                <div className="slider-wrapper" >

                    <Slider {...settings}>
                        {this.props.slides.map((slide: any, i: any) => {
                            return <ContentSliderComponent key={i} data={slide} loggedIn={this.props.loggedIn} showLogin={()=> this.props.showLogin()} lng={this.props.lng}/>
                        })}
                    </Slider>


                </div>

            </div>



        )
    }

};

function NextArrow(props: any) {
    const { onClick } = props;
    return (
        <a onClick={onClick}  className="slick-arrow slick-next">
            <FontAwesomeIcon icon={faChevronRight} color="red" style={{ color: "#d00d0d", width: "15px", height: "25px", marginLeft: "28px"}} size="1x" />
        </a>
    )
}

function PrevArrow(props: any) {
    const { onClick } = props;
    return (
        <a  onClick={onClick} className="slick-arrow slick-prev"> <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#d00d0d", width: "15px", height: "25px",  marginTop: "-15px",   marginLeft: "12px"}} color="red" size="1x" /> </a>
    )
}



export default withRouter<Props>(ContentSlider);