import React, { Component } from 'react';
import "./Maintenance.scss";

import { getContainer } from "../ioc/IOCSetup";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { scrollTop } from "../actions/HeaderActions";
import { AppState } from "../RootReducer";
import { Link, Redirect } from "react-router-dom";
import MetaHelmet from '../utils/MetaHelmet';

import { t } from "../i18n";

interface dispatchProps {


    scrollVal: any,
    isLoggedIn: any,
    user: any,
    balances: any,
    lng: string,
    location: Location
}
type Props = dispatchProps;

interface State {
    isSticky: any;
    landingPage: any;
}
export class MaintenancePageView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isSticky: false,
            landingPage: {}
        };

    }

    componentDidMount() {

        window.addEventListener('scroll', this.listenToScroll);
        if (this.props.scrollVal > 100) {
            this.setState({ isSticky: true });
        } else {
            this.setState({ isSticky: false });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    }
    componentDidUpdate(prevProps: Props) {
        const locationChanged = this.props.location.pathname !== prevProps.location.pathname;
        const fc_frame = document.getElementById('fc_widget');
        if (fc_frame) {
            fc_frame.style.display = 'none';
            fc_frame.style.height = '0px';
        }

        if (locationChanged) {

            document.body.classList.remove('nav-open');
        }
    }
    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height


        if (this.props.scrollVal > 100) {
            this.setState({ isSticky: true });
        } else {
            this.setState({ isSticky: false });
        }
    }



    render() {

        if (!this.state.landingPage) {
            return <Redirect to={"/"} />
        }

        const keywords: string = t("meta.landingPageView.keywords") || "Maharaja Fortune";

        return (
            <React.Fragment>
                {MetaHelmet(this.state.landingPage && this.state.landingPage.title, this.state.landingPage && this.state.landingPage.description, keywords)}
                <header
                    id="main-header"
                    className={this.state.isSticky ? "menu-sticky animated slideInDown" : ""}
                >
                    <div className="main-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <nav className="navbar navbar-light p-0">
                                        <div className="logo-side">


                                            <span className="navbar-brand">
                                                <img
                                                    className="img-fluid logo"
                                                    src={"https://content.adminemx.com/maharajafortune/images/maharaja-logo.png"}
                                                    alt="Logo"
                                                />
                                            </span>


                                        </div>


                                    </nav>
                                    <div className="nav-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className={`landing-main ${this.state.landingPage && this.state.landingPage.logo ? 'has-logo' : ''} `} style={{ backgroundImage: `url('${this.state.landingPage && this.state.landingPage.backgroundImage}')`, backgroundSize: 'cover' }}>
                    <div className="container-fluid ">
                        <div className='maintenance-container d-flex justify-content-center align-items-center'>
                            <img
                                className="img-fluid logo"
                                src={"https://content.adminemx.com/maharajafortune/images/maharaja-logo.png"}
                                alt="Logo"
                            />
                            <h3>Maharaja Fortune is currently ongoing maintenance.</h3>
                            <h4>We are soon back!</h4>
                        </div>
                    </div>


                </div>



             

            </React.Fragment>
        )
    }


}

const mapDispatchToProps = (dispatch: Dispatch) => ({

    scrollTop: (position: any = 0) => dispatch(scrollTop(position) as any)
});



const mapStateToProps = (app: AppState) => {



    return {

        user: app.user.user,
        isLoggedIn: app.user.user !== undefined ? app.user.user.loggedIn : undefined,
        balances: app.user.balances,
        lng: getContainer().getSettingsService().getActiveLocale().code,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MaintenancePageView as any));