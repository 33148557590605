import React, {Component} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {AppState} from "../../RootReducer";
import {Settings} from "../../http/protocol";
import "./styles/LanguageMenu.scss";
import ActiveItem from "./ActiveItem";
import Item from "./Item";
import {getContainer} from "../../ioc/IOCSetup";
import {Locale} from "../../contenful/types";
import {UserService} from "../../user/UserService";
import {changeLng} from "../../contenful/ContentfulActions";
import {LoginStatus} from "../../http/Types";
import {changeLocaleDirectory} from "../../i18n";


interface State {
    clicked: boolean;
}

interface Props {
    settings?: Settings;
    changeLng: typeof changeLng;
    inNavMenu: boolean;
    scrolled?: boolean;
    loggedIn: boolean;
}

export class LanguageMenu extends Component<Props, State>{
    locales: Array<Locale> | undefined;
    defaultLocale: Locale;
    userService: UserService | undefined;
    constructor(props: Props){
        super(props);

        this.locales = getContainer().getSettingsService().getLocalesData();
        this.defaultLocale = getContainer().getSettingsService().getDefaultLocale();
        this.userService = getContainer().getUserService();

        this.state = {
            clicked: false,
        }
    }

    handleClick = () => {
        this.setState({clicked: !this.state.clicked});
    }

    handleClickLanguage = (lng?: string) => {
        console.log("handle click change language : ", lng);
        if(lng) {
            changeLocaleDirectory(lng);
        }
        i18n.changeLanguage(lng ? lng : "en-US", (err, t) => {
            if (err) {
                return console.log('something went wrong loading', err);
            }
            t('key'); // -> same as i18next.t
        });
        this.setState({clicked: !this.state.clicked});
        if(lng && this.userService) {
            if(lng !== this.props.lng) {
                this.props.changeLng(); // sets every contentful page to be undefined
                this.props.setLng(lng); // sets the user's state to the picked language
            }

            if(this.props.loggedIn) {
                this.userService.updateUserSettings("language", lng).then(() => {
                    // console.log("User settings language update!");
                }).catch(e => console.log(e));
            }
        }
    }

    activeLocale = () => {
        if(this.locales) {
            let locale = this.locales.find((locale) => locale.code === getContainer().getSettingsService().getActiveLocale().code);
            if(locale === undefined) {
                locale = this.defaultLocale;
            }

            return locale;
        }

    }

    render() {
        let scrolled = this.props.scrolled !== undefined ? this.props.scrolled : true;
        let dropDownContent = !this.props.inNavMenu
            ? `dropdown-content footer`
            : scrolled ? `dropdown-content scrolled` : "dropdown-content";
        let dropdown = !this.props.inNavMenu ? `dropdown footer` : "dropdown";
        return (
            <React.Fragment>
            {
                this.locales !== undefined && this.locales.length > 1
                    ? (
                        <div className={"language-menu-container"}>
                            <div className={dropdown}>
                                <div onClick={this.handleClick} className={this.state.clicked ? `dropbtn` : "dropbtn"}>
                                    {
                                        this.locales && (
                                            <ActiveItem locale={this.activeLocale()} clicked={this.state.clicked} onClick={this.handleClick} animateArrow={true} />
                                        )
                                    }
                                </div>
                                <div className={this.state.clicked ? dropDownContent + ` clicked` : "dropdown-content"}>
                                    {
                                        this.locales && this.locales.map((locale: Locale, index: number) => {
                                            return <Item key={index} locale={locale} onClick={this.handleClickLanguage}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    : null
            }
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({settings, user} : AppState) => {
    return {
        settings: settings.settings,
        loggedIn : LoginStatus.LOGGED_IN == user.status,
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeLng: () => dispatch(changeLng())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageMenu);