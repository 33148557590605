import React from "react"
import "./closeIcon.scss";



const CloseIcon = (props: {className: string, animate: boolean}) => {
    const animateClass = props.animate ? 'animateIcon' : ''
    return (
        <svg version="1.1" id="closeCircleIcon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" className={`icon ${animateClass} ${props.className}`}>
            <circle fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" cx="256" cy="256" r="247.81"/>
            <line className="line1" fill="none" stroke="#000000" strokeWidth="18" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="137.87" y1="368.72" x2="374.13" y2="143.28"/>
            <line className="line2" fill="none" stroke="#000000" strokeWidth="18" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="374.13" y1="368.72" x2="137.87" y2="143.28"/>
        </svg>
    )
}

export default CloseIcon
