import {HttpService} from "../http/HttpService";
import {Menu, MenuItemType, Settings} from "../http/protocol";
import {Locale} from "../contenful/types";
import { getCurrencies } from "../payments/PaymentsReducer";
import {KycTrigger,LandingPages} from "../http/Types";


export const EN : Locale = {code: "en-US", name: "English", defaultLocale: true, fallback : null};

export class SettingsService  {
    private static instance: SettingsService;
    httpBackend : HttpService;
    settingsData?: any;
    locales: Array<Locale>;
    activeLocale? : Locale;
    song88Url?: string;
    mainLobbyUrl?: string;
    countries?: Array<object>;
    currencies?: Array<object>;
    brandLogo?:string;
    defaultCurrency?:Array<object>;
    headerMenu?:Array<object>;
    kycEnabled?:boolean;
    kycProvider?:string;
    kycTrigger?:KycTrigger;
    landingPages?: LandingPages;
    promotions?:any;
    themeTemplate?:any;
    maintenance?:boolean;
    private constructor(httpBackend : HttpService) {
        this.httpBackend = httpBackend;
        this.settingsData = undefined;
        this.locales = [];
        this.song88Url = undefined;
        this.countries=[];
        this.defaultCurrency=[];
        this.brandLogo = undefined;
        this.headerMenu = undefined;
        this.themeTemplate = undefined;
    }

    static getInstance(httpBackend : HttpService) {
        if(!SettingsService.instance) {
            SettingsService.instance = new SettingsService(httpBackend);
        }
        return SettingsService.instance;
    }

    public getSettings() {
        // return this.httpBackend.getCasinoLobby();
    }
    public getCountryAvailable(): Promise<Settings> {
        return this.httpBackend.getCountryAvailable();
    }
    public getBrandCurrencies(): Promise<Settings> {
        return this.httpBackend.getBrandCurrencies();
    }
    public setSettingsData() {
        this.settingsData = "data";
    }
    public setCurrencies(currencies: object[] ) {
        this.currencies = currencies;
        // console.error(this.countries)
    }
    public setCountryData(countries: object[] ) {
        this.countries = countries;
        // console.error(this.countries)
    }
    public getCurrencies(): any[] | undefined {
        return this.currencies;
    }
    public getCountryData(): any[] | undefined {
        return this.countries;
    }
    public getSettingsData(): Settings | undefined {
        return this.settingsData;
    }
    public setActiveLocale(locale : Locale) {
        this.activeLocale = locale;
    }

    public setSong88Url(url : string) {
        this.song88Url = url;
    }
    public setMainLobbyUrl(url : string) {
        this.mainLobbyUrl = url;
    }
    public setMaintenance(maintenance : boolean) {
        this.maintenance = maintenance;
    }
    public setHeaderMenu(menu : any[] | undefined) {
        this.headerMenu = menu;
    }
    public setThemeTemplate(template : any) {
        this.themeTemplate = template;
    }

    public setBrandLogo(url : string) {
        this.brandLogo = url;
    }
  
    public getBrandLogo(): string | undefined {
        return this.brandLogo;
    }
    public getThemeTemplate(): any | undefined {
        return this.themeTemplate;
    }
    public getSong88Url(): string | undefined {
        return this.song88Url;
    }
    public getMainLobbyUrl(): string | undefined {
        return this.mainLobbyUrl;
    }
    public getHeaderMenu(): any[] | undefined {
        return this.headerMenu;
    }
    public setDefaultCurreny(defaultCurrency : Array<object>) {
        this.defaultCurrency = defaultCurrency;
    }
  
    public getDefaultCurreny(): Array<object> | undefined {
        return this.defaultCurrency;
    }
  
    public setKYCenabled(kyc_enabled : boolean) {
        this.kycEnabled = kyc_enabled;
    }
    public getKYCenabled(): boolean | undefined {
        return this.kycEnabled;
    }

    public setKYCProvider(kycProvider : string) {
        this.kycProvider = kycProvider;
    }
    public getKYCProvider(): string | undefined {
        return this.kycProvider;
    }
    public getMaintenance(): boolean | undefined {
        return this.maintenance;
    }

    public setKYCtriggers(kycTrigger : KycTrigger) {
        this.kycTrigger = kycTrigger;
    }
    public getKYCtriggers(): KycTrigger | undefined {
        return this.kycTrigger;
    }

    public getActiveLocale() {
        if(this.activeLocale) {
            return this.activeLocale;
        } else {
            return this.getDefaultLocale();
        }
    }
    public setLocalesData(data: Array<Locale>) {
        this.locales = data;
    }
    public getDefaultLocale() {
        let defaultLocale = EN;
        if(this.locales) {
            let locale = this.locales.find(l => !!l.defaultLocale);
            if(locale) {
                defaultLocale = locale;
            }
        }
        return defaultLocale;
    }
    
    public getMenu() : Menu {
        if(!this.settingsData || !this.settingsData.menu) {
            return { items : [{ type : MenuItemType.POKER}, { type : MenuItemType.CASINO }, { type : MenuItemType.SPORTSBOOK},{ type : MenuItemType.RACING}]};
        }
        return this.settingsData.menu;
    }

    public getLocalesData() {

        // return [ {code: "en-US", name: "English", defaultLocale: true, fallback : null},{code: "ja-JP", name: "Japanese", defaultLocale: false, fallback : null}];

        return this.locales;

    }

    public getLandingPages(): LandingPages | undefined {
        return this.landingPages;
    }
    
    public setLandingPages(landingPages : LandingPages | undefined) {
       
        this.landingPages = landingPages;
    }

    public setPromotions(promotions : any | undefined) {
       
        this.promotions = promotions;
    }
    public getPromotions(): any | undefined {
        return this.promotions;
    }
}
