import {EMAIL_REGEXP} from "./form";
import asdfgh from 'asdfgh';

export function toSEOFriendlyString(str? : string) {
    if(!str) {
        return "";
    }
    return str.toLocaleLowerCase().replace(" ", "-");
}
export const isValid = function (value : string | undefined, min : number = 0, max = Number.MAX_VALUE) : boolean {
    if(min == 0 && !value) {
        return true;
    } else if(!value || value.trim().length < min || value.trim().length > max) {
        return false;
    } else {
        return true;
    }
}

export const isPasswordValid = function (value : string | undefined) : boolean {
    if(!value) return false;
    if(!isValid(value,6,16)) {
        return false;
    }
    let lower = /[a-z]/.test(value);
    let upper = /[A-Z]/.test(value)
    let number = /[0-9]/.test(value);
    let email = matches(value,EMAIL_REGEXP);

  //  console.log("lower = " + lower + ", upper = " + upper + ", number = " + number + ", email = " + email);

    return lower && upper && number && !email;
}
export const isPasswordHasAHash = function (value : string | undefined) : boolean {
    if(!value) return false;
    if(!isValid(value,6,16)) {
        return false;
    }
    let hash = /#/.test(value);


    console.log(hash);

    return hash;
}

export const hasIllegalCharacters = (value : string | undefined) => {
    return matches(value, /[$;!"#€%&/()?<>\\+´']/);
}
export const matches = (value : string | undefined, exp : RegExp) : boolean => {
    if(!value) return false;
    return exp.test(value);
}

const checkPassphrase = (value : string | undefined) => new Promise((resolve, reject) => {
    let result = asdfgh(value)
    if(result) {
        resolve(result)
    }
    reject(new Error('Something went wrong'))
})

export const asyncValidate = (value : string | undefined) => {
  return checkPassphrase(value).then((res : any) => {
      if(res.score < 1) {
        throw { password : "Password is too weak" }
      }
  })
}

export const number = (value?: string) => value && isNaN(Number(value)) ? false : true;
