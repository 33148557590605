import * as React from "react";
import {connect} from "react-redux";
import {AppState} from "./RootReducer";
import {getTranslation, Translation} from "./Translator";
import {getContainer} from "./ioc/IOCSetup";

interface Props {
    className?:string;
    defaultTranslation? : string;
    selectedLanguage?: string;
    translations? : Translation[]
}


export class BackgroundUrlTranslator extends React.Component<Props>{
    getTranslation() {
        let selectedLanguage = this.props.selectedLanguage;
        let translations = this.props.translations;
        let defaultTranslation = this.props.defaultTranslation;
        return getTranslation(selectedLanguage, defaultTranslation, translations);

    }
    render(){
        return (
            <div className={this.props.className} style={{backgroundImage : `url(${this.getTranslation()})`}}>
                { this.props.children }
            </div>
        )
    }
}

function mapStateToProps(app: AppState) {
    return {
        selectedLanguage : getContainer().getSettingsService().getActiveLocale().code
    }
}
export default connect(mapStateToProps)(BackgroundUrlTranslator);