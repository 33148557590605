import React from "react";
import "./styles/Page.scss";

interface Props {
    children: any;
    noPaddingTop?: boolean;
    style?: object;
}

const Page: React.FC<Props> = (props: Props) => {
    const pageStyles = props.noPaddingTop ? "page noPaddingTop" : "page paddingTop";

    return (
        <div className={pageStyles} style={props.style}>
            {
                props.children
            }
        </div>
    )
}

export default Page;