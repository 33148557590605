import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../RootReducer";
import {Dispatch} from "redux";
import {ListBundlesResponse, PaymentMethod, Settings, UserInfo} from "../http/protocol";
import {StoreStateStatus} from "../store/StoreReducer";
import "./styles/PaymentFlow.scss";
import {DepositPhase, DepositState} from "../payments/PaymentsReducer";
import {
    fetchProviders, reset, selectPaymentMethod,
} from "../payments/DepositActions";
import {ChooseMethod} from "../ui/deposit/ChooseMethod";
import DepositMethod from "../ui/deposit/DepositMethod";
import DepositProviderContent from "../ui/deposit/DepositProviderContent";
import DepositResultView from "../ui/deposit/DepositResultView";
import PaymentProviderButtonDeposit from "../ui/deposit/preferred/PaymentProviderButtonDeposit";
import Page from "../layout/Page";
import { Redirect } from "react-router";
import { LoginStatus, Status } from "../http/Types";
type AllProps = PropsFromState & PropsFromDispatch;

export class PaymentFlow extends Component<AllProps> {
    constructor(props: AllProps) {
        super(props);
    }

    componentDidMount(): void {
        if(this.props.deposit !== undefined) {
            console.log("this.props.deposit !== undefined")
            this.props.fetchProviders && this.props.fetchProviders();
        }
    }

    componentWillUnmount(): void {
        this.props.reset();
    }

    render() {
        const {deposit, loggedIn} = this.props;


        if(!deposit || !deposit.activeCurrency || !deposit.methods || !deposit.currencies ) {
            return <span/>
        }

        let phase = deposit.phase;
        let isStore = this.props.settings && this.props.settings.useStoreInsteadOfDeposit;
        console.log("PAYMENT FLOW = ", deposit);
        return (



                    <div className={"pf-deposit-container row"}>
                        <div className={"payment-content col-12"}>
                            <DepositMethod />


                        </div>
                    </div>

        )
    }

}


interface PropsFromState {
    status?: StoreStateStatus;
    deposit: DepositState;
    bundles?: ListBundlesResponse;
    selectedBundleId?: number;
    user?: UserInfo;
    settings?: Settings;
    loggedIn?:boolean;
}

interface PropsFromDispatch {
    fetchProviders? : () => void;
    choosePaymentMethod? : (method : PaymentMethod) => void;
    reset: typeof reset;
}


const mapStateToProps = ({store, payments, user, settings} : AppState) => {
    return {
        status: store.status,
        bundles: store.bundles,
        selectedBundleId: store.selectedBundleId,
        deposit : payments.deposit,
        user: user.user,
        settings: settings.settings,
        loggedIn: LoginStatus.LOGGED_IN == user.status,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchProviders : () => dispatch(fetchProviders() as any),
        choosePaymentMethod : (method : PaymentMethod) => dispatch(selectPaymentMethod(method)),
        reset: () => dispatch(reset())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentFlow);
