import React from "react";
import {connect} from "react-redux";
import {fetchBalance, fetchUserInfo, fetchAllUserSettings,getEligibleOptInBonusPlans, getActiveBonuses} from "../user/UserActions";
import {Dispatch} from "redux";
import {AppState} from "../RootReducer";
import {LoginStatus} from "../http/Types";
import {getContainer, wireApplication} from "../ioc/IOCSetup";
import {KycStatus, Settings, UserInfo} from "../http/protocol";
// import { UserInfo } from "os";

interface Props {
    fetchBalance : () => void;
    loggedIn: boolean;
    error?: Error;
    updateBalance?: boolean;
    getEligibleOptInBonusPlans: () => void;
    getActiveBonuses:()=> void;
    fetchUserInfo: () => void;
    optInBonusPlans?:any;
}

export class BalanceUpdater extends React.Component<Props>{
    private interval: any;
    componentDidMount(): void {
     
        document.addEventListener("visibilitychange", () => {
        
            if (document.hidden){
            } else {
                let time = getContainer().getHttpService().getTimeSinceLastRequest();
                if(time > 2*60*1000) {
                    if(this.props.loggedIn && this.props.error == undefined && this.props.updateBalance){
                        this.props.fetchBalance();
                        if(sessionStorage.getItem("bonusPlanId") && this.props.optInBonusPlans && this.props.optInBonusPlans.length <= 0) {
                            this.props.getEligibleOptInBonusPlans();
                            sessionStorage.removeItem("bonusPlanId");
                        }
                    }
                }

            }
        });
        this.interval = setInterval(() => {
            if(this.props.loggedIn && this.props.error == undefined && this.props.updateBalance){
                // this.props.fetchUserInfo(localStorage.getItem("sessionKey"));
                if(sessionStorage.getItem("bonusPlanId") && this.props.optInBonusPlans && this.props.optInBonusPlans.length <= 0) {
                    this.props.getEligibleOptInBonusPlans();
                    sessionStorage.removeItem("bonusPlanId");
                }
                this.props.fetchBalance();
            }
        }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return <div></div>
    }
}

const mapStateToProps = ({user, settings} : AppState) => {
    return {
        loggedIn : LoginStatus.LOGGED_IN == user.status,
        error: user.balanceError,
        updateBalance : settings.updateBalance,
        sessionKey: user.user && user.user.sessionKey,
        optInBonusPlans:user.optInBonusPlans,

    }
}

let dispatchToProps = (dispatch : Dispatch) => (
    {
        fetchBalance : ()=>dispatch(fetchBalance() as any),
        fetchUserInfo: (sessionKey:UserInfo)=>dispatch(fetchUserInfo(sessionKey) as any),
        // fetchUserSetting: (key: string) => dispatch(fetchUserSetting(key) as any)
        getEligibleOptInBonusPlans: (sessionKey:UserInfo)=>dispatch(getEligibleOptInBonusPlans(localStorage.getItem("sessionKey")) as any),
        getActiveBonuses: (sessionKey:UserInfo)=>dispatch(getActiveBonuses(localStorage.getItem("sessionKey")) as any),
    }
    );

export default connect(mapStateToProps, dispatchToProps)(BalanceUpdater as any);