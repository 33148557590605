import {Dispatch} from "redux";
import {getContainer} from "../ioc/IOCSetup";
import {PaymentDialog, PaymentMethod, PaymentStatus, PaymentType, PendingWithdrawal, PlayerOrder, ProcessWithdrawalResult, TransferLimit, WithdrawalMethod} from "../http/protocol";
import {PaymentMehtodsByCurrency} from "../http/Types";
import {AppState} from "../RootReducer";
import {MethodAndContext, WithdrawContext, WithdrawDialog} from "./PaymentsReducer";
import {UserService} from "../user/UserService";
import ReactGA from "react-ga4";
export enum WithdrawActionType {
    SHOW_WITHDRAW = "@payments/withdraw/show",
    SELECT_CURRENCY = "@payments/withdraw/selectCurrency",
    INIT_WITHDRAW_REQUEST = "@payments/withdraw/init/request",
    INIT_WITHDRAW_RESPONSE = "@payments/withdraw/init/response",
    INIT_WITHDRAW_ERROR = "@payments/withdraw/init/error",
    PROVIDER_REQUEST = "@payments/provider/request",
    PROVIDER_RESPONSE = "@payments/provider/response",
    PROVIDER_ERROR = "@payments/provider/error",
    UPDATE_WITHDRAW_ADDRESS= "@payments/withdraw/recipientAddress/update",
    SET_AMOUNT= "@payments/withdraw/amount/select",
    UPDATE_AMOUNT = "@payments/withdraw/amount/update",
    RESET = "@payments/withdraw/reset",
    CONFIRM_WITHDRAW = "@payments/withdraw/confirm",
    SELECT_METHOD_WITH_CONTEXT = "@payments/withdraw/confirm-with-context",
    SELECT_METHOD = "@payments/withdraw/select",
    WITHDRAW_CONTEXT_REQUEST = "@payments/withdraw/context/request",
    WITHDRAW_CONTEXT_RESPONSE = "@payments/withdraw/context/response",
    WITHDRAW_CONTEXT_ERROR = "@payments/withdraw/context/error",
    SHOW_POSSIBLE_WITHDRAW_ERRORS = "@payments/withdraw/show/errors",
    PENDING_WITHDRAWAL =  "@payments/withdraw/pending",
    PENDING_WITHDRAWAL_ERRORS =  "@payments/withdraw/pending/errors",
    PREPARE_WITHDRAWAL =  "@payments/withdraw/prepare"
}
export type WithdrawActions =
    | { type : WithdrawActionType.SHOW_WITHDRAW, show : boolean }
    | { type : WithdrawActionType.INIT_WITHDRAW_REQUEST, request : { provider : string, order : PlayerOrder }}
    | { type : WithdrawActionType.INIT_WITHDRAW_RESPONSE, dialog : WithdrawDialog, continueUrl: string }
    | { type : WithdrawActionType.INIT_WITHDRAW_ERROR, error : Error }
    | { type : WithdrawActionType.PROVIDER_REQUEST }
    | { type : WithdrawActionType.PROVIDER_RESPONSE, methods : WithdrawalMethod[] }
    | { type : WithdrawActionType.PROVIDER_ERROR, error : Error }
    | { type : WithdrawActionType.UPDATE_WITHDRAW_ADDRESS, recipientAddress : string }
    | { type : WithdrawActionType.SET_AMOUNT }
    | { type : WithdrawActionType.SELECT_CURRENCY, currency : string }
    | { type : WithdrawActionType.UPDATE_AMOUNT, amount : string, limit?:TransferLimit }
    | { type : WithdrawActionType.RESET, amount : string }
    | { type : WithdrawActionType.CONFIRM_WITHDRAW, method : PaymentMethod}
    | { type : WithdrawActionType.SELECT_METHOD, method : PaymentMethod }
    | { type : WithdrawActionType.WITHDRAW_CONTEXT_REQUEST}
    | { type : WithdrawActionType.WITHDRAW_CONTEXT_ERROR }
    | { type : WithdrawActionType.SELECT_METHOD_WITH_CONTEXT, payload : MethodAndContext }
    | { type : WithdrawActionType.PENDING_WITHDRAWAL, withdrawals : any }
    | { type : WithdrawActionType.PREPARE_WITHDRAWAL, status : any }
    | { type : WithdrawActionType.SHOW_POSSIBLE_WITHDRAW_ERRORS }

export const selectPaymentMethod = (method : PaymentMethod) => {
    return (dispatch : Dispatch, getState : () => AppState) => {

        if(method.achBankTransfer == true && method.provider) {
            console.log("check withdraw context")
            dispatch({type : WithdrawActionType.WITHDRAW_CONTEXT_REQUEST});
            getContainer().getPaymentsService().getWithdrawContext(method.provider).then((res : any)=>{
                dispatch({ type : WithdrawActionType.SELECT_METHOD_WITH_CONTEXT, payload : { context : res, method : method} });
            }).catch(err => {
                dispatch({ type : WithdrawActionType.WITHDRAW_CONTEXT_ERROR })
            });
        } else {
            return dispatch( { type : WithdrawActionType.SELECT_METHOD, method : method});
        }
    }

};

export const reset = () : WithdrawActions => {
    return { type : WithdrawActionType.RESET, amount: "" };
}

export const showPossibleWithdrawErrors = () => {
    return { type : WithdrawActionType.SHOW_POSSIBLE_WITHDRAW_ERRORS};
}
export const updateWithdrawAmount = (amount : string, limit?:TransferLimit) => {
    return { type : WithdrawActionType.UPDATE_AMOUNT, amount : amount, limit:limit};
};
export const updateWithdrawAddress = (recipientAddress : string) => {
    return { type : WithdrawActionType.UPDATE_WITHDRAW_ADDRESS, recipientAddress : recipientAddress};
};

export const setWithdrawAmount = () => {
    return { type : WithdrawActionType.SET_AMOUNT}
};

export const confirmWithdraw = () => {
    return  { type : WithdrawActionType.CONFIRM_WITHDRAW}

};
export const showWithdraw = (show : boolean): WithdrawActions => (
    { type : WithdrawActionType.SHOW_WITHDRAW, show : show }
);
export const selectWithdrawCurrency = (currency : string) : WithdrawActions => {
    return { type : WithdrawActionType.SELECT_CURRENCY, currency : currency};
};
export function fetchProviders() {
    return (dispatch : Dispatch, getState :() => AppState) => {
        if(getState().payments.withdraw.methods) {
            dispatch({ type : WithdrawActionType.RESET });
        } else {
            dispatch({type : WithdrawActionType.PROVIDER_REQUEST});
            getContainer().getPaymentsService().getWithdrawalMethods().then( r => {
                console.log("get providers withdraw", r.withdrawalMethods);
                dispatch({type : WithdrawActionType.PROVIDER_RESPONSE, methods : r.withdrawalMethods})
            }).catch(err => {
                dispatch({ type : WithdrawActionType.PROVIDER_ERROR, error : err});
            })
        }
    }
}
export function fetchPendingWithdrawal() {
    return (dispatch : Dispatch, getState :() => AppState) => {
        getContainer().getPaymentsService().getPendingWithdrawal().then((r : PendingWithdrawal)=>{
            dispatch({ type : WithdrawActionType.PENDING_WITHDRAWAL, withdrawals : r.withdrawals });
        }).catch(err => {
            dispatch({ type : WithdrawActionType.PENDING_WITHDRAWAL_ERRORS, error : err })
        });
    }
}

export function cancelendingWithdrawal(paymentId:any) {
    return (dispatch : Dispatch, getState :() => AppState) => {
        getContainer().getPaymentsService().cancelWithdrawal(paymentId).then((r : PendingWithdrawal)=>{
            dispatch({ type : WithdrawActionType.PENDING_WITHDRAWAL, withdrawals : r.withdrawals });
        }).catch(err => {
            dispatch({ type : WithdrawActionType.PENDING_WITHDRAWAL_ERRORS, error : err })
        });
    }
}

export function prepareWithdrawal() {
    return (dispatch : Dispatch, getState :() => AppState) => {
        getContainer().getPaymentsService().prepareWithdrawal().then((r : PendingWithdrawal)=>{
            dispatch({ type : WithdrawActionType.PREPARE_WITHDRAWAL, status : r.status });
        }).catch(err => {
            dispatch({ type : WithdrawActionType.PENDING_WITHDRAWAL_ERRORS, error : err })
        });
    }
}

export function initWithdraw() {
    return (dispatch : Dispatch, getState : () => AppState) => {
        let withdraw = getState().payments.withdraw;
        if(withdraw.activeMethod &&  withdraw.activeMethod.provider) {
            let provider = withdraw.activeMethod.provider;
            let amount = withdraw.amount;
            let order : PlayerOrder  = {
                type : PaymentType.WITHDRAWAL,
                currencyCode : withdraw.activeCurrency,
                method : withdraw.activeMethod.code,
                recipientAddress: withdraw.recipientAddress
            };
            ReactGA.event({
                category: "User",
                action: "view_withdraw",
              });
            if(amount) {
                order = {...order,  amount : parseFloat(amount)}
            } else {
                order = {...order,  amount : 0}
            }
            dispatch({type : WithdrawActionType.INIT_WITHDRAW_REQUEST, request : { provider : provider, order : provider}});

            let methodKey = `${order.method}-${order.currencyCode}-withdrawAddress`;

            if(withdraw.activeMethod.achBankTransfer == false) {
                let userSettingsValue = order.recipientAddress ? order.recipientAddress : "";
                getContainer().getUserService().updateUserSettings(methodKey, userSettingsValue).then(() => {
                    console.log("User settings update!");
                }).catch(e => console.log(e));
            }


            getContainer().getPaymentsService().requestWithdraw(withdraw.activeMethod.provider, order).then((d : PaymentDialog)=>{
                dispatch({ type : WithdrawActionType.INIT_WITHDRAW_RESPONSE, dialog : d });
            }).catch(err => {
                dispatch({ type : WithdrawActionType.INIT_WITHDRAW_ERROR, error : err })
            });
        } else {
            console.error("Withdraw state is not valid to initiate a withdrawal", withdraw);
        }


    }
}

export function processWithdrawal() {
    // let windowReference =  window.open("about:blank","_blank");
    return (dispatch : Dispatch, getState :() => AppState) => {
            let withdraw = getState().payments.withdraw;
            dispatch({type : WithdrawActionType.INIT_WITHDRAW_REQUEST});
            let userService : UserService = getContainer().getUserService();
            getContainer().getPaymentsService().processWithdraw({paymentMethod: withdraw.selectedWithdrawMethod.code, amount: withdraw.amount, accountNumber: withdraw.accountNumber, bankCode: withdraw.bankCode, accountHolderName: withdraw.accountHolderName, mobileNumber: withdraw.mobileNumber})
                .then((r: ProcessWithdrawalResult) => {
                    if (r.status == "SUCCESS") {
                        dispatch({type : WithdrawActionType.INIT_WITHDRAW_RESPONSE, continueUrl: r.status});
                      
                    }
                    getContainer().getUserService().fetchBalances();
                    fetchPendingWithdrawal();
                    if (r.continueUrl !== undefined) {
                        const url = r.continueUrl;
                        // if (url != null) {
                        //     console.log("continue url", url)
                        //     withdraw.continueUrl = url;
                        //     dispatch({type : WithdrawActionType.INIT_WITHDRAW_RESPONSE, continueUrl: url});
                        //     // if( windowReference) {
                        //     // windowReference.location.href = url;
                        //     // }
                        // } 
                        ReactGA.event({
                            category: "User",
                            action: "withdraw",
                          });
                    } else if (r.status === 'INSUFFICIENT_FUND') {
                        dispatch({type : WithdrawActionType.INIT_WITHDRAW_RESPONSE, continueUrl: r.status});;
                    } else {
                        dispatch({type : WithdrawActionType.INIT_WITHDRAW_RESPONSE, continueUrl: r.status});;
                    }
            }).catch(err => {
                dispatch({ type : WithdrawActionType.INIT_WITHDRAW_ERROR, error : err.message});
            })
            console.log('action withdraw', getState())
    }
}