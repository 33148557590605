
declare const FB: any;

export class FacebookApi {
    private appId?: string
    private callback :  (res : any )=>void = ()=>{};

    constructor(appId?: string) {
        this.appId = appId

    }

    login() : Promise<any> {
        if(this.isEnabled()) {
            return new Promise((res,rej)=>{
                FB.login((auth : any)=>{
                    res(auth);
                },{scope: 'email,public_profile'});
            });
        } else {
            return Promise.resolve();
        }


    }

    isEnabled() {
        return this.appId && this.appId != ""
    }

    init(statusCallback : (status : any)=>void) : Promise<any> {
        if(!this.isEnabled()) {
            return Promise.resolve("FB disabled");
        }
        this.callback = statusCallback;
        return new Promise<any>((resolve,reject)=>{
            try {
                let js,
                    id = 'facebook-jssdk',
                    ref = document.getElementsByTagName('script')[0];

                if (document.getElementById(id)) {
                    return;
                }

                js = document.createElement('script');
                js.id = id;
                js.async = true;
                js.src = "https://connect.facebook.net/en_US/sdk.js";

                (ref as any).parentNode.insertBefore(js, ref);

                js.onload = results => {
                    try {
                        this.initSDK()
                        resolve(results);
                    } catch (e) {
                        this.appId = undefined;
                        resolve();

                    }

                }
            } catch (e) {
                this.appId = undefined;
                resolve();
                console.log("FB is blocked")
            }

        })

    }

    initSDK() {
        FB.init({
            appId: this.appId,
            xfbml: true,
            version: 'v4.0'
        });
        this.setCallback();
    }

    setCallback() {
        FB.getLoginStatus((response : any) => {
            this.callback(response);
        });
    }
}