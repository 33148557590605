import { PasswordActions, PasswordActionType } from "./PasswordActions";

export interface PasswordState {
  error? : Error;
  isFetching: boolean;
  status?: string;
}

const initialState : PasswordState = {isFetching: false, error: undefined};

export function passwordReducer (state = initialState, action: PasswordActions): PasswordState {
  
  switch (action.type) {
      case PasswordActionType.UPDATE_PASSWORD_REQUEST:
          return {...state, isFetching: true}
      case PasswordActionType.UPDATE_PASSWORD_SUCCESS:
          return {...state, error: undefined, isFetching: false, status: action.result.status};
      case PasswordActionType.UPDATE_PASSWORD_ERROR:
        console.log("UPDATE PASSWORD ERROR");
          return {...state, isFetching: false, error: action.error}
      default:
          return state;
  }
}