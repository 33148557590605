import {t} from "../../i18n";
import React, {createRef} from "react";
import {connect} from "react-redux";
import {AppState} from "../../RootReducer";
import {Dispatch} from "redux";
import {updateSearchValue} from "../CasinoActions";
import "./CasinoSearch.scss";
import {Device} from "../../utils/Device";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
interface Props {
    searchString?: string;
    updateSearch?: (value : string) => void;
    onSearchEvent? : (event : SearchEvent) => void;
}

interface State {
    selectedIndex: number;
    focus? : boolean;
}
export enum SearchEvent {
    UP, DOWN, SELECT
}
export class CasinoSearch extends React.Component<Props, State> {
    searchRef  = createRef<HTMLDivElement>();
    constructor(props : Props) {
        super(props);
        this.state = { selectedIndex : 0}
    }
    handleInput(e : any) {
        this.props.updateSearch && this.props.updateSearch(e.target.value);

    }
    postEvent(e : SearchEvent) {
        window && window.postMessage && postMessage({ type : "search", value : e}, "*");
    }
    handleDown(e : any) {
        if(e.keyCode == 27) {
            this.props.updateSearch && this.props.updateSearch("");
        } else if(e.keyCode == 40){
            this.postEvent(SearchEvent.DOWN)
        } else if(e.keyCode == 38) {
            this.postEvent(SearchEvent.UP);
        } else if(e.keyCode == 13) {
            this.postEvent(SearchEvent.SELECT);
        }
    }

    render() {
        let props = this.props;
        return (
            <div className={"search-container float-right fast animated slideInRight" + (this.state.focus ? " focused" : "")}>
                <div className="input-group" ref={this.searchRef}>

                    <input className={"form-control"}
                           onKeyDown={(e)=>this.handleDown(e)}
                           onChange={(e)=>this.handleInput(e)}
                           value={props.searchString}
                           placeholder={t("searchGames")}/>
                    <span className="input-group-append">
                    {(!props.searchString || props.searchString.length == 0) && (
                        <button className="btn" type="button">
                       <FontAwesomeIcon
                      icon={faSearch}
                 
                      size="1x"
                    />
                        </button>
                    )}
                        {props.searchString && props.searchString.length > 0 && (
                            <button className="btn" type="button" onClick={()=> props.updateSearch && props.updateSearch("")}>
                                      <FontAwesomeIcon
                      icon={faTrash}
                 
                      size="1x"
                    />
                            </button>
                        )}
                </span>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (app : AppState, props : Props) => {
    return { ...props, searchString : app.casino.searchValue };
}
const mapDispatchToProps = (dispatch : Dispatch) => {
    return { updateSearch : (value : string) => dispatch(updateSearchValue(value))}
}
export default connect(mapStateToProps, mapDispatchToProps)(CasinoSearch);