import React from "react";
import { Component } from "react";
import { Spinner } from "../ui/Spinner";
import { connect } from "react-redux";
import { Link, RouteComponentProps, Redirect } from "react-router-dom";
import { Dispatch } from "redux";
import {
  DocumentTypeResult,
  FileUploadRequest,
  FileUploadResult,
  UpdatePasswordRequest,
  UserInfo,
} from "../http/protocol";
import { LoginStatus, Status } from "../http/Types";
import { getContainer } from "../ioc/IOCSetup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { AppState } from "../RootReducer";

import { debug } from "console";
import { fileUpload, getDocumentTypes } from "./FileUploadActions";
import "./FileUpload.scss";
import { showAccountVerificationModal, showCashierModal } from "../user/UserActions";
import Compressor from 'compressorjs';
import { t } from "../i18n";

interface State {
  file1: File;
  file2: File;
  expiryDate: string;
  documentNumber: string;
  errorMessage: string;
  selectedDocType: DocumentTypeResult;
  showDocumentNumber: boolean;
  showExpiryDate: boolean;
  file2hasFile: boolean;
  file1hasFile: boolean;
  multiFiles: any;
}

type AllProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps &
  OtherProps;

export class DocumentUploadPageJoiabet extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      file1: null as any,
      file2: null as any,
      expiryDate: "",
      documentNumber: "",
      errorMessage: null as any,
      selectedDocType: null as any,
      showDocumentNumber: false,
      showExpiryDate: false,
      file1hasFile: false,
      file2hasFile: false,
      multiFiles: []
    };
    this.uploadSingleFile = this.uploadSingleFile.bind(this)
    this.props.getDocumentTypes && this.props.getDocumentTypes();
  }

  handleFormSubmit = (e: any) => {
    const file = this.state.file1;
    const file2 = this.state.file2;
    var expiryDate = this.state.expiryDate;
    var documentNumber = this.state.documentNumber;
    var documentType = this.state.selectedDocType;

    if (this.state.multiFiles === null || this.state.multiFiles.length == 0) {
      this.setState({ errorMessage: t("docuUpload.err3") });
    } else if (this.state.multiFiles) {

      this.props.fileUpload({
        file: this.state.multiFiles,
        expiryDate: expiryDate,
        documentNumber: documentNumber,
        documentType: "Proof of billing",
      });

      // if (this.props.upload.uploadSuccess) {
      //   this.setState({
      //     expiryDate: "",
      //     documentNumber: "",
      //     file1: null as any,
      //     file2: null as any,
      //     selectedDocType: null as any,
      //   });
      // }
    } else {
      //Error message
    }
  };

  handleUpload = (e: any) => {
    if (Array.from(e.target.files).length > 3) {
      e.preventDefault();
      alert(`Cannot upload files more than ${3}`);
      return;
    }
    this.setState({ file1: e.target.files[0] as any, errorMessage: "", file1hasFile: true });
  };

  handleUpload2 = (e: any) => {

    console.log(e.target.files[0]);
    this.setState({ file2: e.target.files[0] as any, errorMessage: "", file2hasFile: true });
  };

  handleExpiryDateInput = (e: any) => {
    this.setState({ expiryDate: e.target.value });
  };

  handleDocumentNumberInput = (e: any) => {
    this.setState({ documentNumber: e.target.value });
  };

  handleDocTypeSelect = (e: any) => {
    if (e.target.value != "") {
      var selectedDocType = this.props.documentTypes[e.target.value];
      this.setState({
        selectedDocType: selectedDocType,
        showDocumentNumber: selectedDocType.documentNumberRequired,
        showExpiryDate: selectedDocType.expiryDateRequired,
      });
    }
  };
  uploadSingleFile(e: any) {
    if (this.state.multiFiles.length > 4 || Array.from(e.target.files).length > 4) {
      e.preventDefault();
      alert(`Cannot upload files more than ${4}`);
      return;
    }
    Array.from(e.target.files).forEach((item) => {
      console.error(item);
      new Compressor(item, {
        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        maxHeight:1000,
        maxWidth:1500,
        success: (compressedResult) => {
          
          this.setState({ multiFiles: [...this.state.multiFiles, ...[compressedResult]], file1hasFile: true });
        },
      });

 
      
  });
  }

compressFiles(props:any){

  this.state.multiFiles.forEach((item, index, array) => {
      new Compressor(item, {
        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        maxHeight:1000,
        maxWidth:1500,
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.   
          console.error(compressedResult); 
          //@ts-ignore
          this.setState({ multiFiles: [...compressedResult]});
        },
      });
    
     
      
  })
 
}

  deleteFile(e: any) {
    const s = this.state.multiFiles.filter((item, index) => index !== e);
    this.setState({ multiFiles: s });
    console.log(s);
  }

  render() {
    let activeLang = getContainer().getSettingsService().getActiveLocale().code;
    const docTypes = this.props.documentTypes;
    var selectedType = this.state.selectedDocType;
    if (this.props.fileUploadResult && this.props.fileUploadResult.status === "SUCCESS") {
      return (

        <p>
          <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/account-verification.svg"} className="img-fluid d-block" style={{ width: "200px", display: "block", margin: "10px auto", fill: "#fff", filter: "invert(1)" }} />
          <p dangerouslySetInnerHTML={
            { __html: t("docuUpload.text1", { interpolation: { escapeValue: false } }) }}>
          </p>
          <button className="btn btn-cta btn-lg mt-4" onClick={() => { this.props.hide && this.props.hide(); }}>{t("docuUpload.close")}</button>
        </p>
      );
    }

    return (
      <React.Fragment>
        {this.props.upload.isFetching ? (
          <Spinner />
        ) : (
          <div className="kycForm">
            {/* <h1>KYC Upload Document</h1> */}
            <p dangerouslySetInnerHTML={
              { __html: t("docuUpload.KYCform", { interpolation: { escapeValue: false } }) }}>
            </p>
            {this.state.errorMessage ?
              (<div className="alert alert-danger mt-4">
                {this.state.errorMessage}
              </div>) : null}
            <form id="form-upload-doc">
              <div className="form-group d-flex">
                <div className="row d-flex justify-content-center">


                  <div className="col-lg-6 col-12">
                    <div className="upload-container">
                      <label htmlFor="file1">
                        <p dangerouslySetInnerHTML={
                          { __html: t("docuUpload.IDproof", { interpolation: { escapeValue: false } }) }}>
                        </p>
                      </label>
                      {this.state.file1hasFile ? (<FontAwesomeIcon icon={faUpload} color="#ff9c00" style={{ margin: "10px auto" }} size="3x" />) : (<FontAwesomeIcon icon={faUpload} color="#4b4b4b" style={{ margin: "10px auto" }} size="3x" />)}
                      <input
                        id="file1"
                        type="file"
                        className={`custom-file-input ${activeLang} ${this.state.file1hasFile ? 'hasFile' : ''}`}
                        onChange={this.uploadSingleFile}
                        multiple
                      />
                      <div className="d-flex justify-content-center">
                        {this.state.multiFiles && this.state.multiFiles.length > 0 &&
                          this.state.multiFiles.map((item, index) => {
                            return (
                              <div className="img-preview" key={index}>
                                <button type="button" className="close" onClick={() => this.deleteFile(index)}>
                                  <span aria-hidden="true">×</span>
                                </button>

                                <img src={window.URL.createObjectURL(new File([item],item.name, {type: item.type}))} alt="" />

                              </div>
                            );
                          })}

                      </div>
                      {this.state.file1hasFile && this.state.file1 ? (<div className="img-preview"><span>{this.state.file1.name}</span><img src={URL.createObjectURL(this.state.file1)} /></div>) : null}
                    </div>
                  </div>


                </div>

                {/* 
                <div hidden={!this.state.showExpiryDate}>
                  <label htmlFor="expiryDate">
                    <b>Expiry Date</b>
                  </label>
                  <input
                    id="expiryDate"
                    type="date"
                    className="form-control"
                    onChange={this.handleExpiryDateInput}
                  />
                </div> */}

                <div hidden={!this.state.showDocumentNumber}>
                  <label htmlFor="documentNumber">
                    <p dangerouslySetInnerHTML={
                      { __html: t("docuUpload.IDnum", { interpolation: { escapeValue: false } }) }}>
                    </p>
                  </label>
                  <input
                    id="documentNumber"
                    type="text"
                    className="form-control"
                    onChange={this.handleDocumentNumberInput}
                  />
                </div>
              </div>
              {t("docuUpload.text2")}
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={() => this.handleFormSubmit(this.props)}
              >
                {t("submit")}
              </button>
              {t("docuUpload.text3")}
            </form>
          </div>
        )}
      </React.Fragment>

    );
  }
}

interface OtherProps extends RouteComponentProps { }

interface PropsFromState {
  loggedIn: boolean;
  user?: UserInfo;
  lng: string;
  documentTypes: DocumentTypeResult[];
  upload: any;
  fileUploadResult?: any;
  hide?: () => void;
  //updatePassword: typeof updatePassword
}

interface PropsFromDispatch {
  fileUpload: (request: FileUploadRequest) => any;
  getDocumentTypes: () => DocumentTypeResult[];
  //password: PasswordState;
}

const mapStateToProps = ({
  user,
  contentful,
  password,
  fileUpload,
}: AppState) => {
  let status = false;


  return {
    user: user.user,

    lng: getContainer().getSettingsService().getActiveLocale().code,
    loggedIn: LoginStatus.LOGGED_IN == user.status,
    documentTypes: fileUpload.documentTypes,
    upload: fileUpload,
    fileUploadResult: fileUpload.result
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fileUpload: (request: FileUploadRequest) => dispatch(fileUpload(request) as any),
  getDocumentTypes: () => dispatch(getDocumentTypes() as any),
  hide: () => { dispatch(showAccountVerificationModal(false)); dispatch(showCashierModal(false)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploadPageJoiabet);
