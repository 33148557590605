import React from "react";
import "./RegisterView.scss";
import { UserInfo } from "../../http/protocol";
import {
    LoginStatus,
    Status
} from "../../http/Types";
import { connect } from "react-redux";
import { AppState } from "../../RootReducer";
import { Redirect } from "react-router";
import CompleteRegisterForm from "./CompleteRegisterForm";
import Page from "../../layout/Page";
import Root from "../../layout/Root";
import { Dispatch } from "redux";
import { getContainer } from "../../ioc/IOCSetup";
import { ContentModelName, CompleteRegistrationPayload } from "../../contenful/types";
import { fetchContent } from "../../contenful/ContentfulActions";
import { fetchUserInfo } from "../../user/UserActions";
import { logout, showLogin } from '../../user/UserActions';
import {COUNTRIES} from "./Countries";

type Props = {
    status?: UserInfo;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    completeRegisterContent?: CompleteRegistrationPayload;
    loggedIn?: boolean
    showLogin: () => void;
    loginIsShowing: boolean;
    fetchUserDetails: typeof fetchUserInfo;
};

export class RegisterView extends React.Component<Props>{
    componentDidMount() {
        if (this.props.status === undefined) {
            this.props.fetchUserDetails(localStorage.getItem("sessionKey"));
        }

        getContainer().getSettingsService().getCountryAvailable().then((r: any) => { 
        let country = COUNTRIES.filter(e =>r.countries && r.countries.includes(e.code));
        getContainer().getSettingsService().setCountryData(country);
      
        });
        if (!this.props.isFetching) {

            if (this.props.completeRegisterContent === undefined) {
                this.props.fetchContent(ContentModelName.COMPLETEREGISTRATION, getContainer().getSettingsService().getActiveLocale().code);
            }

        }
      
    }
    render() {

        const { isFetching, loggedIn, status, loginIsShowing } = this.props;


        if (!loggedIn && !loginIsShowing) {
            this.props.showLogin();

        }

        if (status && status.registrationStatus == "PLAYER") {
            return <Redirect to={"/"} />
        }
        return (
            <Root footer={true} header={true}>

                <Page noPaddingTop={true}>
                    {loggedIn ? (
                        <div className="main-content">
                            <div className={"complete-register-container"} >

                                <header className={"top-container gradient"}>
                                    <div className="row px-4">
                                        <div className="col-lg-4">  <h3>{this.props.completeRegisterContent && this.props.completeRegisterContent.completeRegistration}</h3></div>
                                        <div className="col-lg-8 align-self-center">   <span>{this.props.completeRegisterContent && this.props.completeRegisterContent.completeRegistrationLabel}</span></div>

                                    </div>
                                    <hr />
                                    <div className={"register-form-container"}>
                                        <CompleteRegisterForm />
                                    </div>

                                </header>


                            </div>
                        </div>
                    ) : null}
                </Page>
            </Root>
        );
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchUserDetails: (sessionKey: UserInfo) => dispatch(fetchUserInfo(sessionKey) as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
    showLogin: () => dispatch(showLogin(true)),
});
const mapStateToProps = (app: AppState) => (
    {
        status: app.user.user,
        loggedIn: LoginStatus.LOGGED_IN == app.user.status,
        loginIsShowing: app.user.displayLogin,
        completeRegisterContent: app.contentful.completeRegistration
    }
);
export default connect(mapStateToProps, mapDispatchToProps)(RegisterView as any);