import React from "react";
import "./SettingsModal.scss";
import { Status, UserInfo } from "../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../RootReducer";
import { Redirect } from "react-router";
import Root from "../layout/Root";
import MetaHelmet from "../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { Dispatch } from "redux";
import { getContainer } from "../ioc/IOCSetup";
import { fetchContent } from "../contenful/ContentfulActions";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Modal from "react-bootstrap/Modal"
import { showSettingsModal } from "../user/UserActions";
import { hasClass } from "../utils/Helpers";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import UpdatePasswordPage from "./UpdatePasswordPage";

import { t } from "../i18n";

type Props = {
    status?: Status;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    activeLng?: string;
    show?: boolean,
    hide?: () => void;
    getActiveBonuses: () => void;
    loggedIn?: boolean
    madeDeposit?: boolean;
    isNewUser?: boolean;
    bonuses?: any;
    user: UserInfo;
};
var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

interface State {
    modal2Show: boolean;
    path: string;
    showChangePassword: boolean;
}
export class SettingsModal extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            modal2Show: false,
            path: '',
            showChangePassword: false

        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {



        document.addEventListener('mousedown', this.handleClickOutside);



    }
    componentDidUpdate(prevProps: Props) {


    }
    closed = (event: any) => {
        event.preventDefault();
        this.props.hide && this.props.hide();

        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);

    }
    handleClickOutside = (event: any) => {
        const target = event.target


        if (this.props.show) {
            if (!hasClass(target, 'modal-content')) {
                console.error("outside modal");
                if (this.props.loggedIn) {
                    this.closed(event)
                } else {
                    this.setState({ modal2Show: true })
                }
            }
        }
    }

    convertToDate(dateString: any) {
        //  Convert a "dd/MM/yyyy" string into a Date object
        console.error(dateString);
        let d = dateString.split("-");
        let dat = new Date(d[2].split(' ')[0] + '/' + d[1] + '/' + d[0] + ' ' + d[2].split(' ')[1]);

        return dat;
    }

    onClickChangePass() {
        if (!this.state.showChangePassword) {
            this.setState({ showChangePassword: true });
        } else {
            this.setState({ showChangePassword: false });
        }
    }

    render() {
        let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();

        return (
            <React.Fragment>
                <Modal size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.show}
                    onHide={() => this.props.hide && this.props.hide()}
                    className="settings-modal"
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => {

                            this.props.hide && this.props.hide();


                        }}><span aria-hidden="true">×</span><span className="sr-only">{t("settingsModal.close")}</span></button>
                    </Modal.Header>

                    <Modal.Body className="settings-container">
                        <h3>{t("settingsModal.settings")}</h3>
                        <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/settings.svg"} className="modal-icon" />
                        <div className="container-fluid mt-4">


                            <div className="row  user-details" >
                                <label className="text-left d-block col-12">{t("settingsModal.accDetails")}</label>
                                <div className="col-6 text-left">
                                    {t("settingsModal.userName")}
                                </div>
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.userId}
                                </div>
                                <div className="col-6 text-left">
                                    {t("settingsModal.fullName")}
                                </div>
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.firstName} {this.props.user && this.props.user.lastName}
                                </div>
                                <div className="col-6 text-left">
                                    {t("settingsModal.bDay")}
                                </div>
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.birthDate }

                                </div>
                                <div className="col-6 text-left">
                                    {t("settingsModal.email")}
                                </div>
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.email}
                                </div>
                                {mainLobby === "https://joiabet.com/" ? null:(<><div className="col-6 text-left">
                                    {t("settingsModal.add")}
                                </div>
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.address}
                                </div>
                                <div className="col-6 text-left">
                                    {t("settingsModal.city")}
                                </div>
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.city}
                                </div>
                                <div className="col-6 text-left">
                                    {t("settingsModal.zip")}
                                </div>
                                
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.postalCode}
                                </div>
                                <div className="col-6 text-left">
                                    {t("settingsModal.country")}
                                </div>
                                <div className="col-6 right-content">
                                    {this.props.user && this.props.user.country}
                                </div></>)}
                                <div className="col-6 text-left">
                                    {t("settingsModal.KYC")}
                            </div>
                            <div className="col-6 right-content">
                                {t(`${this.props.user && this.props.user.kycStatus}`)}
                            </div>
                            </div>
                        </div>

                        <div className="d-flex row mt-4">
                            <div className="col-6 text-left">
                                <label className="text-left font-weight-bold d-block col-12 ">{t("settingsModal.pass")}</label>
                            </div>
                            <div className="col-6">
                                <button
                                    className={`btn btn-cta btn-back `}

                                    onClick={() => { this.onClickChangePass(); }}

                                >
                                    {t("settingsModal.changePass")}
                                </button>
                            </div>
                        </div>
                        {this.state.showChangePassword ? <UpdatePasswordPage /> : null}






                    </Modal.Body>
                </Modal>


            </React.Fragment>



        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hide: () => { dispatch(showSettingsModal(false)) },
});
const mapStateToProps = (app: AppState) => (
    {

        show: app.user.displaySettingsModal,
        user: app.user.user,
        activeLng: getContainer().getSettingsService().getActiveLocale().code
    }
);
export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal as any);