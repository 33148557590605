import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
interface Props {
    history: any;
    children: any;
}

const ScrollToTop: React.FC<Props> = ({history,children}) => {

  useEffect(() => {
    const unlisten = history.listen(() => {
      if(history.location.pathname.split('/')[0] === "casino" && history.location.pathname.split('/')[1] === "game" || history.location.pathname.split('/')[1] === "livecasino" && history.location.pathname.split('/')[2] === "game") {
        window.scrollTo(0, 0);
      } else if(history.location.pathname.split('/')[0] === "livecasino" && history.location.pathname.split('/')[2] || history.location.pathname.split('/')[0] === "casino" && history.location.pathname.split('/')[2] ) {

      } else {
        window.scrollTo(0, 0);
      }
    
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop as any);