import React from "react";
import { Status, UserInfo } from "../../http/protocol";
import { connect } from "react-redux";
import { AppState } from "../../RootReducer";
import { Redirect } from "react-router";
import { Dispatch } from "redux";
import { getContainer } from "../../ioc/IOCSetup";
import './Sidebar.scss'
import { ContentModelName, RegisterPayload, CompleteRegistrationPayload } from "../../contenful/types";
import { fetchContent } from "../../contenful/ContentfulActions";
import { logout, showAccountVerificationModal, showBonusModal, showCashierModal, showLogin, showSettingsModal, showSignUp } from "../../user/UserActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import getSymbolFromCurrency from 'currency-symbol-map'
import {

    faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { UserState } from "../../user/UserReducer";
import LanguageMenu from "../language/LanguageMenu";

import { t } from "../../i18n";
import { sideBarStatus } from "../../actions/HeaderActions";

type Props = {
    status?: Status;
    fetchContent: typeof fetchContent;
    isFetching?: boolean;
    registerContent?: RegisterPayload;
    completeRegisterContent?: CompleteRegistrationPayload;
    activeLng?: string;
    show?: boolean,
    showLogin?: () => void;
    showSignUp?: () => void;
    logout?: () => void;
    showBonusModal?:() => void;
    showAccountVerificationModal?:() => void;
    showSettingsModal?:() => void;
    sideBarClose?:() => void;
    showCashierModal?:(selectedCashier:string) => void;
    loggedIn?: boolean
    madeDeposit?: boolean;
    isNewUser?: boolean;
    user?: UserInfo;
    balances?: any;
    userBalance?:UserState;
};
interface State {
    modal2Show: boolean;
    path: string;
}
export class SidebarView extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            modal2Show: false,
            path: ''
        };
    }
    componentDidMount() {
        let lang = getContainer().getSettingsService().getLocalesData();

        let path ;
        if(lang && lang.length >= 2) {
             path = window.location.pathname.split("/")[2];
        } else {
           path = window.location.pathname.split("/")[1];
        }
        this.setState({ path: path });



    

    }
    handleOnClick = (catid: any, catname: any) => {
        document.body.classList.remove('nav-open');
        if (catid && catname) {
            this.props.selectCategory && this.props.selectCategory(catid);

            this.props.selectCategoryName && this.props.selectCategoryName(catname);
            this.props.history.push(`/casino/${catid}/${toSEOFriendlyString(catname)}`);
        }
        this.props.sideBarClose && this.props.sideBarClose();
    }

    closeNav = () => {
        document.body.classList.remove('nav-open');
        

    }

    handleCasino = () => {
        document.body.classList.remove('nav-open');
        this.props.history.push(`/casino`);

    }


    handlePromotion = () => {
        document.body.classList.remove('nav-open');
        this.props.history.push(`/promotion`);

    }

    handlen = () => {
        document.body.classList.remove('nav-open');
        this.props.history.push(`/promotion`);

    }
    handleLivecasino = () => {
        document.body.classList.remove('nav-open');
        this.props.history.push(`/livecasino/23/popular-live%20games`);

    }
    handleClickremoveNavOpen = () => {
        document.body.classList.remove('nav-open');
        this.props.sideBarClose && this.props.sideBarClose();
    }
    handleClickremoveNavOpenAndPushHistory = (link:string) => {
        document.body.classList.remove('nav-open');
        this.props.history.push(link);
        this.props.sideBarClose && this.props.sideBarClose();
    }
  generateSideBarrMenu = (menu: any[])=> {
        let activeLang = getContainer().getSettingsService().getActiveLocale().code;
        return (menu.map((menu : any) => {
          return (   <li className="menu-v2-item " key={menu.translation[activeLang]}>
          <Link to={menu.link} onClick={() => this.handleClickremoveNavOpenAndPushHistory(menu.link)} className="menu-link ">
              <img src={menu.icon} className="menu-v2-icon" />
              <label>{menu.translation[activeLang]}</label>
          </Link>
      </li>)
           
      }))
      }

    render() {

        const kycProvider = getContainer().getSettingsService().getKYCProvider();
  const kycEnabled = getContainer().getSettingsService().getKYCenabled();
  const kycTriggers = getContainer().getSettingsService().getKYCtriggers();
  let headerMenu = getContainer().getSettingsService().getHeaderMenu();
  let activeCurrecy = localStorage.getItem("activeCurrency") || "";
        return (
            <React.Fragment>
                {/* Sidebar */}
                <div className="b-sidebar">
              
                    {this.props.loggedIn && this.props.user ?


                        (
                            <div className=" d-inline-flex  align-items-center flex-wrap justify-content-center" style={{ width: "100%", marginBottom: "10px" }}>
                                <Link onClick={() => { this.handleClickremoveNavOpen(); }} to={"/profile"} ><img
                                    src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/user.png"}
                                    className="img-fluid avatar-40 rounded-circle avatar-b-side"
                                    alt="user"
                                /> </Link>
                                <Dropdown className="d-flex justify-content-center">
                                    <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-user"

                                    >
                                        
                                        <div
                                            className="iq-user-dropdown search-toggle p-0 d-flex align-items-center"
                                            data-toggle="iq-user-dropdown"
                                        >

                                            <span>{`${this.props.user.firstName !== null ? this.props.user.firstName : "New User"} ${
                                  this.props.user.lastName !== null ? this.props.user.lastName : ""
                                }`}<br/>
                                          {getSymbolFromCurrency(activeCurrecy)} 
                                                    {this.props.balances ? this.props.balances : "0.00"}</span>

                                                    <FontAwesomeIcon icon={faAngleDown} color="#000" className="down" style={{ margin: "10px auto" }}  />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                 <Dropdown.Item className="bonus-balance">

                                 {t("balance")}: {getSymbolFromCurrency(activeCurrecy)} ; {this.props.userBalance && this.props.userBalance.balances ? (this.props.userBalance && this.props.userBalance.balances
                            ) : "0.00"}
                            </Dropdown.Item>


                            <Dropdown.Item className="bonus-balance">

                            {t("bonus")}: {getSymbolFromCurrency(activeCurrecy)}  {this.props.userBalance && this.props.userBalance.bonusBalance ? (this.props.userBalance && this.props.userBalance.bonusBalance
                            ) : "0.00"}
                            </Dropdown.Item>


                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        ) : null

                    }
                    {this.props.loggedIn ? null : (
                        <div className="b4login">
                            <div className="menu-link btn btn-cta login" onClick={() => {
                                this.props.showLogin && this.props.showLogin();
                                this.handleClickremoveNavOpen();
                            }}>
                                   <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/log-in.svg"} className="menu-v2-icon-before-login" />
                                {t("logIn")}
                            </div>
                            {this.state.path === 'signup' ? (null) : (<div onClick={() => { this.props.showSignUp && this.props.showSignUp(); this.handleClickremoveNavOpen(); }} className="menu-link btn btn-cta signup">
                            <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/sign-up.svg"} className="menu-v2-icon-before-login" />
                               {t("loginModal.signUp")}
                            </div>)}

                        </div>

                    )}
                    {headerMenu ? (<ul className="menu-v2-container d-inline-flex  align-items-center flex-wrap justify-content-center"> {this.generateSideBarrMenu(headerMenu)}</ul>):(<ul className="menu-v2-container d-inline-flex  align-items-center flex-wrap justify-content-center">
                        <li className="menu-v2-item ">
                            <Link to="/casino" onClick={() => this.handleClickremoveNavOpen()} className="menu-link ">
                                <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/casino.svg"} className="menu-v2-icon" />
                                <label>{t("casino")}</label>
                            </Link>
                        </li>
                        <li className="menu-v2-item ">
                            <Link to="/livecasino/23/popular-live%20games" onClick={() => this.handleClickremoveNavOpen()} className="menu-link">
                                <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/live-casino.svg"} className="menu-v2-icon" />
                                <label>{t("liveCasino")}</label>
                            </Link>
                        </li>
                        {/* <li className="menu-v2-item ">
                            <Link to="/sports" onClick={() => this.handleClickremoveNavOpen()} className="menu-link">
                                <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/sports.svg"} className="menu-v2-icon" />
                                <label>{t("sports")}</label>
                            </Link>
                        </li> */}
                        {/* <li className="menu-v2-item ">
                            <Link to="/sports/live" onClick={() => this.handleClickremoveNavOpen()} className="menu-link">
                                <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/live-betting.svg"} className="menu-v2-icon" />
                                <label>{t("liveBetting")}</label>
                            </Link>
                        </li> */}
                        <li className="menu-v2-item ">
                            <Link to="/promotions" onClick={() => this.handleClickremoveNavOpen()} className="menu-link">
                                <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/promotions.svg"} className="menu-v2-icon" />
                                <label>{t("promotions")}</label>
                            </Link>
                        </li>

                    </ul>)
                    }
                    {this.props.loggedIn ? (
                    <div className="b-menus ">
                        <ul className="b-menu">
                   
                            <li className="menu-item">
                            <a  onClick={() => {this.handleClickremoveNavOpen(); this.props.showCashierModal &&  this.props.showCashierModal("deposit");} }      className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/deposit.svg"} className="menu-v2-icon-after-login" />
                                    {t("deposit")}
                                </a>
                            </li>
                            <li className="menu-item">
                            <a  onClick={() => {this.handleClickremoveNavOpen(); this.props.showCashierModal &&  this.props.showCashierModal("withdraw");} }      className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/withdrawal.svg"} className="menu-v2-icon-after-login" />
                                    {t("withdrawPpageTitle")}
                                </a>
                            </li>
                            <li className="menu-item">
                            <a  onClick={() => {this.handleClickremoveNavOpen(); this.props.showCashierModal &&  this.props.showCashierModal("pendingWithdraw");} }      className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/cancel-withdrawal.svg"} className="menu-v2-icon-after-login" />
                                    {t("sidebarView.cancel")}
                                </a>
                            </li>
                            { kycProvider === "omegasys" && kycEnabled ? ( <li className="menu-item">
                                <a onClick={() => {this.handleClickremoveNavOpen(); this.props.showAccountVerificationModal &&  this.props.showAccountVerificationModal();} }className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/account-verification.svg"} className="menu-v2-icon-after-login" />
                                    {t("accountVerificationModal.actVerify")}
                                </a>
                            </li>):null}
                           
                            {/* <li className="menu-item">
                            <a  onClick={() => {this.handleClickremoveNavOpen(); this.props.showCashierModal &&  this.props.showCashierModal();} } className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/transaction-history.svg"} className="menu-v2-icon-after-login" />
                                    Transaction History
                                </a>
                            </li> */}
                            <li className="menu-item">
                                <a  onClick={() => {this.handleClickremoveNavOpen(); this.props.showBonusModal &&  this.props.showBonusModal();} } className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/info.png"} className="menu-v2-icon-after-login" />
                                    {t("bonusModal.info")}
                                </a>
                            </li>
                            <li className="menu-item">
                                <Link to="/profile" onClick={() => this.handleClickremoveNavOpen()} className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/mobilenav/myaccount.png"} className="menu-v2-icon-after-login" />
                                    {t("buttonMyAccount")}
                                </Link>
                            </li>
                            <li className="menu-item">
                                <a onClick={() => {this.handleClickremoveNavOpen(); this.props.showSettingsModal &&  this.props.showSettingsModal();} }  className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/settings.png"} className="menu-v2-icon-after-login" />
                                    {t("settings")}
                                </a>
                            </li>
                            {/* <li className="menu-item">
                                <Link to="/help" onClick={() => this.handleClickremoveNavOpen()} className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/help.png"} className="menu-v2-icon-after-login" />
                                    Help
                                </Link>
                            </li> */}
                            <li className="menu-item">
                                <Link to="" onClick={() => {
                                            this.props.logout && this.props.logout();
                                            this.handleClickremoveNavOpen();
                                        }} className="menu-link">
                                    <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/log-out.png"} className="menu-v2-icon-after-login" />
                                    {t("logOut")}
                                </Link>
                            </li>

                        </ul>
                    </div>):null 
                    }

                

         
                </div>
           
            </React.Fragment>



        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showLogin: () => dispatch(showLogin(true)),
    showSignUp: () => dispatch(showSignUp(true)),
    showBonusModal: () => dispatch(showBonusModal(true)),
    showAccountVerificationModal: () => dispatch(showAccountVerificationModal(true)),
    showSettingsModal: ()=> dispatch(showSettingsModal(true)),
    showCashierModal:(selectedCashier:string)=> dispatch(showCashierModal(true,selectedCashier)),
    logout: () => dispatch(logout() as any),
    sideBarClose:() => dispatch(sideBarStatus(false) as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
});
const mapStateToProps = (app: AppState) => (
    {
        status: app.user.registration && app.user.registration.status,
        registerContent: app.contentful.register,
        show: app.user.displaySignup,
        user: app.user.user,
        userBalance:app.user,
        completeRegisterContent: app.contentful.completeRegistration,
        loggedIn: app.user.user?.loggedIn,
        balances: app.user.balances,
        madeDeposit: app.user.user && app.user.user.madeDeposit,
        isNewUser: app.user.user && app.user.user.newUser,
        activeLng: getContainer().getSettingsService().getActiveLocale().code
    }
);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarView as any));
