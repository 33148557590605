import React from "react";
import {connect} from "react-redux";
import {fetchUserInfo, setActiveCurrency,getEligibleOptInBonusPlans, getActiveBonuses} from "../user/UserActions";
import {Dispatch} from "redux";
import {ContentModelName} from "../contenful/types";
import {fetchContent, fetchMultipleContent} from "../contenful/ContentfulActions";
import {AppState} from "../RootReducer";
import {AccountBalance, CurrencyDefinition, UserInfo} from "../http/protocol";
import {LoginStatus} from "../http/Types";
import {AmplitudeEvent} from "../amplitude/AmplitudeEvent";
import {getContainer} from "../ioc/IOCSetup";
import {AmplitudeService} from "../amplitude/AmplitudeService";


type AllProps = PropsFromDispatch & PropsFromState;

export class UserDetails extends React.Component<AllProps>{
    componentDidMount(): void {
        let amplitudeService: AmplitudeService | null = getContainer().getAmplitudeService();
        if(amplitudeService) {
            let homescreen:boolean = (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches);
            amplitudeService.logEvent(AmplitudeEvent.LOADED_SITE, {homescreen: homescreen});
        }
        if(localStorage.getItem("sessionKey")) {
        this.props.fetchUserDetails();
        this.props.getEligibleOptInBonusPlans();
  
                 }
        let activeCurrency = localStorage.getItem("activeCurrency") || this.props.activeCurrency;
        this.props.currencies && activeCurrency === undefined && this.setActiveCurrency();
    }



    componentDidUpdate(prevProps: AllProps): void {
        let activeCurrency = localStorage.getItem("activeCurrency") || this.props.activeCurrency;
        this.props.currencies && activeCurrency === undefined && this.setActiveCurrency();

    }
    setActiveCurrency() {
        //Need a active currency to be set when page loads.
        console.log("Currencies: ", this.props.currencies)
        const firstCurrency = this.props.currencies && this.props.currencies.length > 0 && this.props.currencies[0].code || "";
        this.props.setActiveCurrency && this.props.setActiveCurrency(firstCurrency);
    }
    render() {
        return <div></div>
    }
}

interface PropsFromState {
    balances?: AccountBalance[];
    bonuses?:any;
    lng: string;
    userInfo?: UserInfo;
    loggedIn: boolean;
    currencies?: CurrencyDefinition[];
}

interface PropsFromDispatch {
    fetchUserDetails : () => void;
    getEligibleOptInBonusPlans: () => void;
    getActiveBonuses: () => void;
    fetchContent: typeof fetchContent;
    fetchMultipleContent: typeof fetchMultipleContent;
    setActiveCurrency: typeof setActiveCurrency;
    activeCurrency?: string;
}

let stateToProps = ({user, settings} : AppState) => {
    return {
        balances : user.balances,
        activeCurrency : user.activeCurrency,
        lng: getContainer().getSettingsService().getActiveLocale().code,
        userInfo: user.user,
        loggedIn : LoginStatus.LOGGED_IN == user.status,
        currencies: settings.settings && settings.settings.currencies,
        optInBonusPlans:user.optInBonusPlans,
        bonuses:user.bonuses
    }
};

let dispatchToProps = (dispatch : Dispatch) => ({
    fetchUserDetails :  (sessionKey:UserInfo)=>dispatch(fetchUserInfo(localStorage.getItem("sessionKey")) as any),
    fetchContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchContent(contentModelName, locale) as any),
    fetchMultipleContent: (contentModelName: ContentModelName, locale?: string) => dispatch(fetchMultipleContent(contentModelName, locale) as any),
    setActiveCurrency : (currency:string)=>dispatch(setActiveCurrency(currency) as any),
    getEligibleOptInBonusPlans: (sessionKey:UserInfo)=>dispatch(getEligibleOptInBonusPlans(localStorage.getItem("sessionKey")) as any),
    getActiveBonuses: (sessionKey:UserInfo)=>dispatch(getActiveBonuses(localStorage.getItem("sessionKey")) as any),
});

export default connect(stateToProps, dispatchToProps)(UserDetails as any);