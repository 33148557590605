import React from "react";
import "./styles/LanguageMenu.scss";
import { FlagIcon } from "react-flag-kit";
import {Locale} from "../../contenful/types";
import {getCountryByLocale} from "../../i18n";
import flagOffset from './flagOffset.json';
import DropdownArrow from "../../layout/DropdownArrow";


const ActiveItem = (props : {locale? : Locale, clicked: boolean, animateArrow? : boolean, onClick : (language?: string) => void }) => {
    let language, country = "";
    let langCountry = props.locale && props.locale.code.split("-");
    let offset = "5%";
    if(langCountry && langCountry.length == 2) {
        console.log("country found!")
        language = langCountry[0];
        country = langCountry[1];
        //@ts-ignore
        let fo = flagOffset[props.locale.code];
        if(fo && fo.length > 0) {
            offset = fo;
        }
    }


    return (
        <div className={"dropdownItem active"}>
            {props.locale &&
            <div className={"item active"}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className={"flagIcon"}>
                        {country && country.length > 0 ?
                            <div><FlagIcon code={getCountryByLocale(props.locale.code) as any} size={25} style={{position:"absolute", left:`-${offset}`, top:`-${offset}`, clip:"auto"}}/> {props.locale.name}</div>
                            : <i className="material-icons" style={{width:"24px", fontSize:"24px"}}>language</i>}
                    </span>
                    {props.animateArrow ? <DropdownArrow className="activeItemComponent" showDropdown={props.clicked} marginLeft={`25px`} /> :
                    <i className="fas fa-sort-down" style={{verticalAlign:"top", right:"-15px", position:"relative"}}/>}

                </div>

            </div>
            }
        </div>
    )
}

export default ActiveItem;