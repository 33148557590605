import {Dispatch} from "redux";
import {getContainer} from "../ioc/IOCSetup";

import {BonusConfig, BonusOffer, UserBonusView} from "../http/protocol";
import {UserBonusService} from "./UserBonusService";
import {AppState} from "../RootReducer";
import {RequestState} from "./BonusReducer";


export enum BonusActionTypes {
    BONUS_OFFERS_REQUEST = "@bonus/offers/request",
    BONUS_OFFERS_RESPONSE ="@bonus/offers/response",
    BONUS_OFFERS_ERROR = "@bonus/offers/error",
    BONUS_NEXT_REQUEST = "@bonus/next/request",
    BONUS_NEXT_RESPONSE ="@bonus/next/response",
    BONUS_NEXT_ERROR = "@bonus/next/error",
    ACTIVE_BONUSES_REQUEST = "@bonus/active/request",
    ACTIVE_BONUSES_RESPONSE ="@bonus/active/response",
    ACTIVE_BONUSES_ERROR = "@bonus/active/error",
    CLAIM_BONUS_REQUEST = "@bonus/claim-offer/request",
    CLAIM_BONUS_RESPONSE ="@bonus/claim-offer/response",
    CLAIM_BONUS_ERROR = "@bonus/claim-offer/error"
}

export type BonusActions =
    | { type : BonusActionTypes.BONUS_OFFERS_REQUEST }
    | { type : BonusActionTypes.BONUS_OFFERS_RESPONSE, offers : BonusOffer[] }
    | { type : BonusActionTypes.BONUS_OFFERS_ERROR }
    | { type : BonusActionTypes.BONUS_NEXT_REQUEST }
    | { type : BonusActionTypes.BONUS_NEXT_RESPONSE, bonuses : BonusConfig[] }
    | { type : BonusActionTypes.BONUS_NEXT_ERROR }
    | { type : BonusActionTypes.ACTIVE_BONUSES_REQUEST }
    | { type : BonusActionTypes.ACTIVE_BONUSES_RESPONSE, activeBonuses : UserBonusView[] }
    | { type : BonusActionTypes.ACTIVE_BONUSES_ERROR }
    | { type : BonusActionTypes.CLAIM_BONUS_REQUEST, offerId : number }
    | { type : BonusActionTypes.CLAIM_BONUS_RESPONSE, userBonus : UserBonusView}
    | { type : BonusActionTypes.CLAIM_BONUS_ERROR }


export const claimBonusOffer = (offerId : number) => {
    return async (dispatch : Dispatch, getState : () => AppState) => {
        if(getState().bonus.claimOfferStatus == RequestState.IN_PROGRESS) {
            return;
        }
        dispatch({ type : BonusActionTypes.CLAIM_BONUS_REQUEST});
        let service : UserBonusService = getContainer().getUserBonusService();
        try {
            let userBonus:UserBonusView = await service.claimBonusOffer(offerId);
            dispatch({ type : BonusActionTypes.CLAIM_BONUS_RESPONSE, userBonus : userBonus});
            localStorage.setItem("offersSeen", "0")
            return userBonus;
        } catch (e) {
            dispatch({ type : BonusActionTypes.CLAIM_BONUS_ERROR});
        }
    }
}
export const fetchBonusInfo = () => {
    return async (dispatch : Dispatch) => {
        let service : UserBonusService = getContainer().getUserBonusService();
        try {
            //first fetch any active bonuses
           dispatch({ type : BonusActionTypes.ACTIVE_BONUSES_RESPONSE})
           let active = await service.getActiveBonuses();
           dispatch({ type : BonusActionTypes.ACTIVE_BONUSES_RESPONSE, activeBonuses : active });
           if(!active || active.length == 0) {
               //no active bonuses, fetch active offers
                try {
                    dispatch({ type : BonusActionTypes.BONUS_OFFERS_REQUEST})
                    let offers = await service.getActiveBonusOffers();
                    dispatch({ type : BonusActionTypes.BONUS_OFFERS_RESPONSE, offers : offers });
                    if(!offers || offers.length == 0) {
                        //if no offers fetch "next" bonuses
                        dispatch(fetchNextBonuses() as any)
                    }
                } catch (e) {
                    dispatch({ type : BonusActionTypes.BONUS_OFFERS_ERROR, error : e})
                }
           }
        } catch (e) {
            dispatch({ type : BonusActionTypes.ACTIVE_BONUSES_ERROR, error : e})
        }
    }
}
export const fetchActiveBonuses = () => {
    return async (dispatch : Dispatch) => {
        dispatch({ type : BonusActionTypes.ACTIVE_BONUSES_REQUEST})
        try {
            let service : UserBonusService = getContainer().getUserBonusService();
            let active : UserBonusView[] = await service.getActiveBonuses();
            dispatch({ type : BonusActionTypes.BONUS_OFFERS_RESPONSE, activeBonuses : active });
        } catch (e) {
            dispatch({ type : BonusActionTypes.ACTIVE_BONUSES_ERROR, error : e})
        }
    }
}
export const fetchBonusOffers = () => {
    return async (dispatch : Dispatch) => {
        dispatch({ type : BonusActionTypes.BONUS_OFFERS_REQUEST})
        try {
            let service : UserBonusService = getContainer().getUserBonusService();
            let offers : BonusOffer[] = await service.getActiveBonusOffers();
            dispatch({ type : BonusActionTypes.BONUS_OFFERS_RESPONSE, offers : offers });
        } catch (e) {
            dispatch({ type : BonusActionTypes.BONUS_OFFERS_ERROR, error : e})
        }
    }
}



export const fetchNextBonuses = () => {
    return async (dispatch : Dispatch, getState : () => AppState) => {

        dispatch({ type : BonusActionTypes.BONUS_NEXT_REQUEST})
        try {
            let service : UserBonusService = getContainer().getUserBonusService();
            let settings = await getState().settings;
            let currencies = settings.settings && settings.settings.currencies;
            let bonuses : BonusConfig[] = [];

            if(currencies === undefined) {
                dispatch({ type : BonusActionTypes.BONUS_NEXT_ERROR});
                return;
            }

            if(currencies != undefined) {
                await currencies.forEach((curr) => {
                    curr.code && service.getNextBonuses(curr.code).then((res) => {
                        bonuses.push(...res);
                        dispatch({ type : BonusActionTypes.BONUS_NEXT_RESPONSE, bonuses : bonuses });
                    }).catch((e:any) => {
                    });

                })

            }

        } catch (e) {
            dispatch({ type : BonusActionTypes.BONUS_NEXT_ERROR, error : e});
        }
    }
}

