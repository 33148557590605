import {LaunchUrl, PokerPlayerCount} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class PokerService {
    constructor(private http : HttpService) {}

    getGameLaunchUrl() : Promise<LaunchUrl>{
        return this.http.get("/poker/launch");
    }

    getPokerPlayerCount() : Promise<PokerPlayerCount>{
        return this.http.get("/poker/playercount");
    }
}



