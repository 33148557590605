import {HttpService} from "../http/HttpService";
import { UpdatePasswordRequest, UpdatePasswordResult, UpdateProfileRequest, UpdateProfileResult } from "../http/protocol";

export class ProfileService  {
  constructor(private http : HttpService) {}

  updateProfile(request : UpdateProfileRequest) : Promise<UpdateProfileResult> {
    return this.http.get("/ips/updatePlayerInfo?sessionKey=" + localStorage.getItem('sessionKey') + 
      "&firstName=" + request.firstName + "&lastName=" + request.lastName);
  }
}