import { Dispatch } from "redux";
import { UpdateProfileRequest, UpdateProfileResult } from "../http/protocol";
import { getContainer } from "../ioc/IOCSetup";
import { selectCurrency } from "../payments/DepositActions";
import { AppState } from "../RootReducer";
import { fetchUserInfo, setActiveCurrency } from "../user/UserActions";

export enum ProfileActionType {
  UPDATE_PROFILE_REQUEST = "@profile/edit/request",
  UPDATE_PROFILE_SUCCESS = "@profile/edit/success",
  UPDATE_PROFILE_ERROR = "@profile/edit/error"
}

export type ProfileActions =
    | { type : ProfileActionType.UPDATE_PROFILE_REQUEST}
    | { type : ProfileActionType.UPDATE_PROFILE_SUCCESS, result : UpdateProfileResult }
    | { type : ProfileActionType.UPDATE_PROFILE_ERROR, error : Error }

export function updateProfile(request: UpdateProfileRequest) {
  console.log('PROCESS UPDATE PROFILE', request)
  return (dispatch : Dispatch, getState :() => AppState) => {
    dispatch({type : ProfileActionType.UPDATE_PROFILE_REQUEST});
    getContainer().getProfileService().updateProfile(request)
        .then((r: UpdateProfileResult) => {
          console.log(r.status, "STATUS");
            if (r.status !== undefined) {
                if (r.status == 'SUCCESS') {
                  dispatch({type : ProfileActionType.UPDATE_PROFILE_SUCCESS, result: r});
                  dispatch(fetchUserInfo(localStorage.getItem('sessionKey')) as any);
                } else {
                  dispatch({ type : ProfileActionType.UPDATE_PROFILE_ERROR, error : r.errors});
                }
            }
    }).catch(err => {
      dispatch({ type : ProfileActionType.UPDATE_PROFILE_ERROR, error : err});
    })
  }
}