import {HttpService} from "../http/HttpService";
import {PushSubscription} from "../http/protocol";


export class NotificationsService {
    constructor(private http : HttpService) {}

    getPublicKey():Promise<string> {
        return this.http.get('/notifications/key');
    }

    subscribeTo(subscription:PushSubscription, userId?:number):Promise<Response> {
        return this.http.post('/notifications/subscribe?userId='+userId, subscription);
    }
    unSubscribeTo(subscription:PushSubscription):Promise<Response> {
        return this.http.post('/notifications/unsubscribe', subscription);
    }
}