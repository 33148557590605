import {NavigationActions, NavigationActionTypes} from "./NavigationActions";

export interface NavigationState {
    showUserNavigation?: boolean;
    tab: number
    sportsbookVisible:boolean;
}
const initial : NavigationState = { showUserNavigation : false, tab: 0, sportsbookVisible:false}
export function navigationReducer( state = initial, action : NavigationActions) {
    switch (action.type) {
        case NavigationActionTypes.SHOW_USER_NAVIGATION:
            return {...state, showUserNavigation : action.show};
        case NavigationActionTypes.SET_ACTIVE_TAB:
            return {...state, tab : action.tab};
        case NavigationActionTypes.SHOW_SPORTSBOOK:
            if(action.show) {localStorage.setItem("loadSportsbook", "true")}
            return {...state, sportsbookVisible : action.show};
        default:
            return state;
    }
}