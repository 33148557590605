import {DepositActions, DepositActionType} from "./DepositActions";
import {PaymentDialog, PaymentMethod, TransferLimit, DepositMethods, ProcessDepResult, WithdrawalMethod} from "../http/protocol";
import {PaymentMehtodsByCurrency, WithdrawalMethodsByCurrency} from "../http/Types";
import {combineReducers} from "redux";
import { WithdrawActions, WithdrawActionType, prepareWithdrawal } from './WithdrawActions';
import actions from "redux-form/lib/actions";

export enum PaymentStateStatus {
    NONE,
    DEPOSIT_INITIATED,
    DEPOSIT_INITIALIZED,
    DEPOSIT_ERROR_INITIALIZING
}

export enum WithdrawStateStatus {
    NONE,
    WITHDRAW_INITIATED,
    WITHDRAW_INITIALIZED,
    WITHDRAW_ERROR_INITIALIZING
}
export enum DepositPhase {
    ERROR,
    CHOOSE_METHOD,
    CHOOSE_AMOUNT,
    PROVIDER_CONTENT,
    DEPOSIT_RESULT
}

export enum WithdrawPhase {
    ERROR,
    CHOOSE_METHOD,
    CHOOSE_PROVIDER,
    CHOOSE_ADDRESS,
    CHOOSE_AMOUNT,
    CONFIRM_WITHDRAW,
    WITHDRAW_RESPONSE,
    KYC_VERIFICATION,
    REQUEST_DENIED
}
export interface MethodAndContext {
    method: PaymentMethod;
    context : WithdrawContext;
}

export enum WithdrawContextStatus {
    IN_PROGRESS, OK, ERROR
}
export interface WithdrawContext {
    permitted? : "YES" | "REJECTED_NO_PREVIOUS_DEPOSIT_FOUND" | "DEPOSITS_WAITING_TO_CLEAR";
}

export interface WithdrawDialog {
    accepted: boolean;
    result: string;
}
export interface ACHWithdrawContext extends WithdrawContext{
    bankName : string;
    routingNumber : string;
    accountNumber : string;
}

export interface WithdrawState {
    status? : WithdrawStateStatus;
    phase? : WithdrawPhase;
    withdrawDialog? : WithdrawDialog;
    methods? : WithdrawalMethod[];
    selectedWithdrawMethod: WithdrawalMethod;
    currencies?: string[];
    showWithdrawModal?: boolean;
    activeCurrency? : string;
    activeMethod? : PaymentMethod;
    amount?: string;
    amountValid?: boolean;
    recipientAddress?:string;
    withdrawContext? : WithdrawContext;
    withdrawContextStatus? : WithdrawContextStatus;
    showPossibleErrors?: boolean;
    continueUrl: string;
    bankCode?:string,
    accountNumber?:string;
    accountHolderName?:string;
    pendingWithdrawal?:any;
    prepareWithdrawal?:any;
    mobileNumber?:string;
    accountId?:string;
    ifscCode?:string;
}

export interface DepositState {
    status? : PaymentStateStatus;
    phase? : DepositPhase;
    depositDialog? : PaymentDialog;
    methods? : PaymentMehtodsByCurrency
    currencies?: string[];
    showDepositModal?: boolean;
    showCashierModal?: boolean;
    activeCurrency? : string;
    activeMethod? : PaymentMethod;
    amount?: string;
    showPossibleErrors? : boolean;
    amountValid?: boolean;
    amountPresets? : string[];
    useAsStore?: boolean;
    depositResult?: ProcessDepResult;
    sendingPayment?: boolean;
    sendingPaymentFailed?:boolean;
    depositMethods?:DepositMethods;
    error?:string;
    selectedDepositMethod:DepositMethods;
    continueUrl: string;
    continueId: string;
}

export interface PaymentsState {
    deposit : DepositState;
    withdraw : WithdrawState;
}

const deposit : DepositState = {
    phase : DepositPhase.CHOOSE_METHOD,
    showDepositModal : false,
    showCashierModal : false,
    status : PaymentStateStatus.NONE,
    currencies : [],
    amount : "",
    showPossibleErrors : false,
    amountValid : false,
    amountPresets : ["500", "1000", "2000"],
    selectedDepositMethod: null as any,
    continueUrl: ""
};

const withdraw : WithdrawState = {
    phase : WithdrawPhase.CHOOSE_METHOD,
    showWithdrawModal : false,
    status : WithdrawStateStatus.NONE,
    currencies : [],
    amount : "",
    amountValid : false,
    withdrawContextStatus : WithdrawContextStatus.OK,
    showPossibleErrors: false,
    selectedWithdrawMethod: null as any,
    continueUrl: "",
    pendingWithdrawal:[],
    prepareWithdrawal:""
};

export function depositReducer(state : DepositState = deposit, action : DepositActions) : DepositState {
    switch (action.type) {
        case DepositActionType.SELECT_CURRENCY:
            return handleProvidersResponse({...state, activeCurrency : action.currency}, state.methods);
        case DepositActionType.SHOW_DEPOSIT:
            return {...state, showDepositModal : action.show};
        case DepositActionType.SHOW_CASHIER:
            return {...state, showCashierModal : action.show};
        case DepositActionType.INIT_DEPOSIT_REQUEST:
            return  {...state, status : PaymentStateStatus.DEPOSIT_INITIATED, phase : DepositPhase.PROVIDER_CONTENT, depositDialog : undefined, depositResult : undefined, sendingPayment : false, sendingPaymentFailed : false};
        case DepositActionType.INIT_DEPOSIT_RESPONSE:
            return  {...state, status : PaymentStateStatus.DEPOSIT_INITIALIZED, phase : DepositPhase.PROVIDER_CONTENT, continueUrl : action.continueUrl, amount : state.amount, amountValid : false, showPossibleErrors: false, error:action.message};
        case DepositActionType.INIT_DEPOSIT_RESULT:
            return  {...state, phase : DepositPhase.DEPOSIT_RESULT, depositResult : action.depositResult as any};
        case DepositActionType.SHOW_POSSIBLE_DEPOSIT_ERRORS:
            return {...state, showPossibleErrors : true};
        case DepositActionType.INIT_DEPOSIT_ERROR:
            return  {...state, status : PaymentStateStatus.DEPOSIT_ERROR_INITIALIZING, depositDialog : undefined};
        case DepositActionType.PROVIDERS_RESPONSE:


            return handleProvidersResponse(state,action.methods);
        case DepositActionType.PROVIDERS_ERROR:
            return {...state, phase : DepositPhase.ERROR };
        case DepositActionType.SELECT_METHOD:
            if(action.phase) {
                return handleSelectMethod(state,action.method, action.phase);
            } else {
                return handleSelectMethod(state,action.method);
            }
        case DepositActionType.UPDATE_AMOUNT:

            return {...state, amount : action.amount, amountValid : validateDepositAmount(action.amount, action.limit)};
        case DepositActionType.SET_USE_AS_STORE:
            return {...state, useAsStore: action.useAsStore}
        case DepositActionType.SET_SENDING_PAYMENT:
            return {...state, sendingPayment: action.sendingPayment}
        case DepositActionType.SET_SENDING_PAYMENT_FAILED:
            return {...state, sendingPaymentFailed: action.sendingPaymentFailed}
        case DepositActionType.RESET:
            return {...state, amount: action.amount, amountValid : false, showPossibleErrors: false};
        case DepositActionType.UPDATE_PHASE:
            return {...state, phase : action.phase};
        default:
            return state;
    }
}
export function withdrawReducer(state : WithdrawState = withdraw, action : WithdrawActions) : WithdrawState {
    switch (action.type) {
        case WithdrawActionType.WITHDRAW_CONTEXT_REQUEST:
            return {...state,  withdrawContextStatus : WithdrawContextStatus.IN_PROGRESS };
            case WithdrawActionType.PENDING_WITHDRAWAL:

                return {...state,  pendingWithdrawal : action.withdrawals };
           case WithdrawActionType.PREPARE_WITHDRAWAL:

                return {...state,  prepareWithdrawal : action.status };
        case WithdrawActionType.WITHDRAW_CONTEXT_ERROR:
            return { ...state, withdrawContextStatus : WithdrawContextStatus.ERROR, withdrawContext : { permitted : "REJECTED_NO_PREVIOUS_DEPOSIT_FOUND"}} ;
        case WithdrawActionType.SELECT_CURRENCY:
            return handleProviderWithdrawResponse({...state, activeCurrency : action.currency}, state.methods);
        case WithdrawActionType.SHOW_WITHDRAW:
            return {...state, showWithdrawModal : action.show};
        case WithdrawActionType.INIT_WITHDRAW_REQUEST:
            return  {...state, status : WithdrawStateStatus.WITHDRAW_INITIATED, withdrawDialog : undefined};
        case WithdrawActionType.INIT_WITHDRAW_RESPONSE:
            console.log('from withdraw reducer', action);
            console.log('state from reducer', state)
            return  {...state, status : WithdrawStateStatus.WITHDRAW_INITIALIZED, phase :  WithdrawPhase.WITHDRAW_RESPONSE, withdrawDialog : action.dialog, continueUrl : action.continueUrl };
        case WithdrawActionType.INIT_WITHDRAW_ERROR:
            let phase = WithdrawPhase.ERROR;
            if((action.error as any) === 'INSUFFICIENT_FUND') {
                phase = WithdrawPhase.REQUEST_DENIED;
            }
            return  {...state, status : WithdrawStateStatus.WITHDRAW_ERROR_INITIALIZING, withdrawDialog : undefined, phase: phase};
        case WithdrawActionType.PROVIDER_RESPONSE:
            return handleProviderWithdrawResponse(state,action.methods);
        case WithdrawActionType.PROVIDER_ERROR:
            return {...state, phase : WithdrawPhase.ERROR };
        case WithdrawActionType.SELECT_METHOD_WITH_CONTEXT:
            if(action.payload.context.permitted != "YES") {
               return { ...state, withdrawContextStatus : WithdrawContextStatus.ERROR, withdrawContext : action.payload.context} ;
            }
            return handleSelectPaymentMethod(state, action.payload.method, action.payload.context);
        case WithdrawActionType.SELECT_METHOD:
            return handleSelectPaymentMethod(state,action.method);
        case WithdrawActionType.UPDATE_WITHDRAW_ADDRESS:
            return {...state, recipientAddress: action.recipientAddress};
        case WithdrawActionType.SET_AMOUNT:
            return  {...state, phase : WithdrawPhase.CHOOSE_AMOUNT, withdrawDialog : undefined};
        case WithdrawActionType.CONFIRM_WITHDRAW:
            return {...state, phase : WithdrawPhase.CONFIRM_WITHDRAW};
        case WithdrawActionType.UPDATE_AMOUNT:
            return {...state, amount : action.amount, amountValid : validateAmount(action.amount, action.limit)};
        case WithdrawActionType.RESET:
            return handleProviderWithdrawResponse(state,state.methods);
        case WithdrawActionType.SHOW_POSSIBLE_WITHDRAW_ERRORS:
            return {...state, showPossibleErrors : true};
        default:
            return state;
    }
}
export function validateDepositAmount(amount? : string, limit? : TransferLimit) {
    if(amount && amount !="" && Number(amount) > 0) {
        if(limit && limit.minDepositAmount !== undefined && limit.maxDepositAmount !== undefined) {
            let a = Number(amount);
            if(a >= limit.minDepositAmount && a <= limit.maxDepositAmount) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    }
    return false;
}

export function validateAmount(amount? : string, limit?:TransferLimit) {
    if(amount && amount !="" && Number(amount) > 0) {
        console.log()
        if(limit && limit.minWithdrawAmount !== undefined && limit.maxWithdrawAmount !== undefined) {
            let a = Number(amount);
            if(a >= limit.minWithdrawAmount && a <= limit.maxWithdrawAmount) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    }
    return false;
}
export function handleSelectMethod(state : DepositState, method : PaymentMethod, phase? : DepositPhase) {
    if(method.amountRequired == true && (state.useAsStore === undefined)) {
        return  {...state,
            activeMethod : method,
            phase : phase || DepositPhase.CHOOSE_AMOUNT,
        };
    } else {
        return  {...state,
            activeMethod : method,
            phase : phase || DepositPhase.PROVIDER_CONTENT,
        };
    }
}

export function handleSelectPaymentMethod(state: WithdrawState, method : PaymentMethod, context?: WithdrawContext) {
    //Change when new value added to paymentMethod that identifies crypto

    if(method && method.achBankTransfer || (method.code == 'ACH')) {
        return {...state,
            activeMethod : method,
            withdrawContext : context,
            withdrawContextStatus : WithdrawContextStatus.OK,
            phase : WithdrawPhase.CHOOSE_AMOUNT };
    } else if(method && method.amountRequired) {
        return {...state,
        activeMethod : method,
        phase : WithdrawPhase.CHOOSE_PROVIDER};
    } else {
        return {...state,
        activeMethod : method,
        phase : WithdrawPhase.CHOOSE_ADDRESS};
    }
}
export function handleProvidersResponse(state : DepositState, methods? : PaymentMehtodsByCurrency) : DepositState {
    // console.error(methods,'handleProvidersResponse');

    if(!methods) {
        return {...state, phase : DepositPhase.ERROR, depositMethods:methods }
    }
    let currencies = getCurrencies(methods);
    let currency = state.activeCurrency || localStorage.getItem("activeCurrency");

    if(!currency) {
        currency = currencies[0];
    }

    if(!currency) {
        return {...state, phase : DepositPhase.ERROR, depositMethods:methods }
    }
    let base = {  methods : methods, activeCurrency : currency, currencies : currencies, status : PaymentStateStatus.NONE, depositDialog : undefined };
    //skip choose provider step if only one method for currency

        return  {...state, ...base,
            activeMethod : undefined,
            phase : DepositPhase.CHOOSE_METHOD,
            activeCurrency: currency,
            depositMethods:methods
        };



}

export function handleProviderWithdrawResponse(state : WithdrawState, methods? : WithdrawalMethod[]) : WithdrawState {
    return {...state, methods: methods}


}

export function getCurrencies(methods: PaymentMehtodsByCurrency) {
    let currencies = [];
    for(let x in methods) {
        console.log(x, "currencies");
        currencies.push(x);
    }
    return currencies;
}

export const paymentsReducer =  combineReducers({
    deposit : depositReducer,
    withdraw : withdrawReducer
});
