import {HttpService} from "../http/HttpService";
import {PlayerTransactionsRequest, PlayerTransactionsResponse, TransactionResult} from "../http/protocol";


export class TransactionService {
    constructor(private http : HttpService) {}

    listTransactions(req : PlayerTransactionsRequest) : Promise<PlayerTransactionsResponse> {
        return this.http.post("/transaction/list",req);
    }

    getTransactionHistory(date:any) : Promise<{[key:string]: TransactionResult[]}> {
        if(date) {
            return this.http.get("/ips/getTransactionHistory?sessionKey=" + localStorage.getItem('sessionKey')+"&date="+date);
        } else {
            return this.http.get("/ips/getTransactionHistory?sessionKey=" + localStorage.getItem('sessionKey'));
        }

    }
}