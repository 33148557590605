import { TransactionResult } from "../http/protocol";
import { TransactionActions, TransactionActionType } from "./TransactionActions";

export interface TransactionHistoryState {
  error? : Error;
  transactions?: TransactionResult[];
}

const initialState : TransactionHistoryState = {transactions: [], error: undefined};

export function transactionHistoryReducer (state = initialState, action: TransactionActions): TransactionHistoryState {
  
  switch (action.type) {
      case TransactionActionType.GET_TRANSACTIONS_REQUEST:
          return {...state}
      case TransactionActionType.GET_TRANSACTIONS_SUCCESS:
          return {...state, error: undefined, transactions: action.transactions}
      case TransactionActionType.GET_TRANSACTIONS_ERROR:
          return {...state, error: action.error}
      default:
          return state;
  }
}