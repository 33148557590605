import React, { Component, lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./RootReducer";
import LoginModal from "./ui/LoginModal";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router'

import { getContainer, wireApplication } from "./ioc/IOCSetup";
import { faBars, faChevronLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import WindowSizeContainer from "./ui/WindowSizeContainer";
// import {init as initAnalytics, RouteAnalytics} from "./Analytics";
// import {SettingsService} from "./settings/SettingsService";
// import {getContainer} from "./ioc/IOCSetup";
import Loader from "./layout/Loader";
import HomePage from "./HomPage/HomePage";
import { GAWrapper } from './googleAnalytics/GAWrapper';
import UserDetails from "./ui/UserDetails";
import PromotionPage from "./ui/PromotionView";
import CountryBlocked from "./ui/CountryBlocked";
// import {AppIosInstallGuideComponent} from "./ui/AppIosInstallGuideComponent";
// import NotificationsModal from "./ui/notifications/NotificationsModal";
import RegisterView from "./user/ui/RegisterView";
import TermsAndConditions from "./user/ui/TermsAndConditionView";
import FAQ from "./user/ui/FAQView";
import ResponsibleGaming from "./user/ui/ResponsibleGamingView";
import KYCPolicy from "./user/ui/KYCPolicyView";
import AMLPolicy from "./user/ui/AMLPolicyView";
import CookiePolicy from "./user/ui/CookiePolicyView";
import PrivacyPolicy from "./user/ui/PrivacyPolicyView";
import BonusTerms from "./user/ui/BonusTermsView";
import RegisterSuccess from "./user/ui/RegisterSuccess";
import CompleteRegisterSuccess from "./user/ui/CompleteRegisterSuccess";
import CompleteRegisterView from "./user/ui/CompleteRegisterView";
import SportsView from "./sports/SportsView";
import Song88SportsComponent from "./sports/providers/Song88SportsComponent";
// import NavMenu from "./ui/navigation/NavMenu.test";
import MatiUpdater from "./ui/MatiUpdater";
import FreshChatWidget from "./ui/FreshChatWidget";
// import CashierModal from "./ui/CashierModal";
import BalanceUpdater from "./ui/BalanceUpdater";
import { isIOS } from "react-device-detect";
import CasinoPage from "./casino/ui/CasinoPage";
import LoginForm from "./ui/LoginForm";
import LiveCasinoPage from "./livecasino/ui/LiveCasinoPage";
import ScrollToTop from "./ScrollToTop";
import WelcomBonusView from "./ui/Promotions/WelcomBonus";
import LiveCasinoWelcomeBonus from "./ui/Promotions/LiveCasinoWelcomeBonus";
import JackpotSlot from './user/ui/JackpotSlotView';
import LiveCasinoSeoView from './user/ui/LiveCasinoSeoView';
import AboutUs from './user/ui/AboutUsView';
import SlotCasinoSeoVIew from './user/ui/SlotCasinoSeoVIew';
import SportsbettingSeoVIew from './user/ui/SportsbettingSeoVIew';
import SportsbookWelcomeBonus from './ui/Promotions/SportsbookWelcomeBonus';
import SportsbookMaharajaBoost from './ui/Promotions/SportsbookMaharajaBoost';
import LandingCasinoView  from './landing/casino/LandingCasinoView';
import LandingSportsView  from './landing/sports/LandingSportsView';
import RegisterModal from './user/ui/RegisterModal';

import SidebarView from './ui/sidebar/SidebarView';
import BonusModal from './bonus/BonusModal';

import AccountVerificationModal from './kyc/AccountVerificationModal';
import SettingsModal  from './settings/SettingsModal';

import PNGSquareUp from './ui/Promotions/PNGSquareUp';
import AviatorLandingPageView from './landing/casino/AviatorLandingPageView';

import LandingPageView from './landing/LandingPageView';
import EveryMondayBonus from './ui/Promotions/EveryMondayBonus';

import CashierModal from './ui/CashierModal';
import SecondWelcomeBonus from './ui/Promotions/SecondWelcomeBonus';
import WeekendBonus from './ui/Promotions/WeekendBonus';
import PromotionTNCsVIew from './ui/Promotions/PromotionTNCsVIew';
import HomePageV2 from './HomPage/HomePageV2';
import MaintenancePageView from './maintenance/MaintenancePageView';
import MaintenanceJoiabetPageView from './maintenance/MaintenanceJoiabetPageView';


const TransactionRequestSuccessPage = lazy(() => import("./ui/deposit/TransactionRequestSuccessPage"));
const WithdrawalPage = lazy(() => import("./withdraw/WithdrawalPage"));
// const OnboardingPage = lazy(() => import("./onboardingpage/OnboardingPage"));
const ProfilePage = lazy(() => import("./profilepage/ProfilePage"));
const ExitCasinoGame = lazy(() => import("./casino/ui/game/ExitCasinoGame"));
const CasinoGameView = lazy(() => import("./casino/ui/game/CasinoGameView"));
const MobileCasinoGameView = lazy(() => import("./casino/ui/mobileGameView/MobileCasinoGameView"));
const SettingsPage = lazy(() => import("./settings/SettingsPage"));
const ResetPasswordPage = lazy(() => import("./onboardingpage/resetPasswordPage/ResetPasswordPage"));
const UpdatePasswordPage = lazy(() => import("./settings/UpdatePasswordPage"));
const DocumentUploadPage = lazy(() => import("./kyc/DocumentUploadPage"));
// const EditProfilePage = lazy(() => import("./profilepage/EditProfilePage"));
const ExitLiveCasinoGame = lazy(() => import("./livecasino/ui/game/ExitLiveCasinoGame"));
const LiveCasinoGameView = lazy(() => import("./livecasino/ui/game/LiveCasinoGameView"));
const MobileLiveCasinoGameView = lazy(() => import("./livecasino/ui/mobileGameView/MobileLiveCasinoGameView"));

const DepositStatusView = lazy(() => import("./ui/deposit/DepositStatusView"));


const StorePage = lazy(() => import("./storepage/StorePage"));
library.add(faBars, faEye, faEyeSlash, faChevronLeft);
type IosIcon = {
    type: string;
    size: string;
    href: string;
}
type IosSplashscreen = {
    type: string;
    width: string;
    height: string;
    pixelRatio: string;
    href: string;
}

type IosMedia = {
    icons: IosIcon[];
    splashScreens: IosSplashscreen[];
}

interface Props {
    locale?: string;
}

interface State {
}
class App extends Component<Props, State> {

    constructor(props: Props) {
        super(props);


    }
    
    componentDidMount() {
        isIOS && this.setIosMedia();
        if (!document.location.pathname.includes("sportsbook")) {
            localStorage.setItem("loadSportsbook", "false");
        }
       
        let lobby = getContainer().getSettingsService().getMainLobbyUrl();
            let link = document.querySelector("link[rel~='icon']");
            
      
            if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
            }
            if(lobby === "https://maharajafortune.com/") {
                link.href = '/assets/images/favicon.png';
           const script = document.createElement("script");
            script.src = "//fw-cdn.com/2346572/2960938.js";
            script.setAttribute("chat", true);
                document.body.appendChild(script);
            //@ts-ignore
            window.fcWidgetMessengerConfig = {
                siteId: 'maharajafortune',
                tags: ["maharajafortune"],
                faqTags : { 
                tags : ['maharajafortune'] ,
                filterType:'category'
                },
                config: {
                    headerProperty: {
                        appName: 'MaharajaFortune',
                        appLogo: 'https://crunchequation.com/clients/maharajafortune/logo.png',
                        backgroundColor: '#f8aa00'
                    }
                }
            }
            } else {
                link.href = '/assets/images/favicon-joiabet.ico';

                const script = document.createElement("script");
                script.src = "//fw-cdn.com/2346572/2960938.js";
                script.setAttribute("chat", true);
                script.setAttribute("widgetId", "8069c5b5-e2a1-4575-b4c8-255434bfe877");
                    document.body.appendChild(script);
                //@ts-ignore
                window.fcWidgetMessengerConfig = {
                    tags: ["joiabet"]
                  }
            }
     
          

    }

    setIosMedia() {
        // let iosMediaJSON:IosMedia = this.settings.settingsData && this.settings.settingsData.iosMediaJSON && JSON.parse(this.settings.settingsData.iosMediaJSON);
        // //Set app icon
        // let iconLink:HTMLLinkElement = document.createElement('link');
        // iconLink.rel = "apple-touch-icon";
        // if(iosMediaJSON && iosMediaJSON.icons && iosMediaJSON.icons.length > 0 && iosMediaJSON.icons[0].href) {
        //     iconLink.href = iosMediaJSON.icons[0].href;
        // }
        // document.head.appendChild(iconLink);

        // //set app splashscreens
        // iosMediaJSON && iosMediaJSON.splashScreens && iosMediaJSON.splashScreens.map((screen) => {
        //     let splashscreen:HTMLLinkElement = document.createElement("link");
        //     if(screen.href) {
        //         splashscreen.href = screen.href;
        //     }
        //     splashscreen.media = `(device-width: ${screen.width}) and (device-height: ${screen.height}) and (-webkit-device-pixel-ratio: ${screen.pixelRatio}) and (orientation: portrait)`;
        //     splashscreen.rel = "apple-touch-startup-image";
        //     document.head.appendChild(splashscreen);
        // })
    }

    render() {
      let template = getContainer().getSettingsService().getThemeTemplate();
      let maintenance = getContainer().getSettingsService().getMaintenance();
        return (
            <Provider store={configureStore()}>
                <Router basename={this.props.locale ? this.props.locale : ""}>
            {maintenance && template !== 2? (<Route exact path="/" component={MaintenancePageView} />):template === 2 && maintenance ?(<Route exact path="/" component={MaintenanceJoiabetPageView} />):(
                    <Suspense fallback={<div><Loader /></div>}>
                        <UserDetails />
                        <LoginModal />
              
                        <FreshChatWidget />

                   

                        <BalanceUpdater />

                        <MatiUpdater />
                        <ScrollToTop />
                        <GAWrapper initialized={true}>
                            <Switch>
                                {template === 2 ? (   <Route exact path="/" component={HomePageV2} />):(<Route exact path="/" component={HomePage} />) }
                           
                                <Route exact path="/blocked/:country" component={CountryBlocked} />

        
                                <Route exact path="/log-in" component={LoginForm} />
                                <Route exact path="/profile" component={ProfilePage} />
                                {/* <Route exact path="/deposit" component={StorePage} /> */}
                                <Route exact path="/transaction/success" component={TransactionRequestSuccessPage} />
                                {/* <Route exact path="/withdraw" component={WithdrawalPage} /> */}
                                <Route exact path={"/casino/exit"} component={ExitCasinoGame} />
                                <Route exact path="/casino/playforfun/:gameId/:provider" component={CasinoGameView} />
                                <Route exact path="/promotions" component={PromotionPage} />
                                {/* <Route exact path="/promotions/welcomebonus" component={WelcomBonusView} /> */}

                                <Route exact path="/depositstatus/:orderId" component={DepositStatusView} />
                                <Route exact path="/landing/:url" component={LandingPageView} />
                                <Route exact path="/promotions/:url" component={PromotionTNCsVIew} />
                               
                                {/* <Route exact path="/promotions/sportsbook-welcomebonus" component={SportsbookWelcomeBonus} />
                                <Route exact path="/promotions/sportsbook-maharaja-boost" component={SportsbookMaharajaBoost} />
                                <Route exact path="/promotions/every-monday-deposit" component={EveryMondayBonus} />
                                <Route exact path="/promotions/second-welcome" component={SecondWelcomeBonus} />
                                <Route exact path="/promotions/weekend-bonus" component={WeekendBonus} /> */}
                                {/* <Route exact path="/promotions/png-square-up" component={PNGSquareUp} /> */}
                            
                                <Route exact path="/casino/playforreal/:gameId/:provider" component={CasinoGameView} />
                                <Route exact path="/casino/game/:gameId/:provider" component={MobileCasinoGameView} />
                                <Route path="/casino/:search?" component={CasinoPage} />
                                <Route exact path={"/signup"} component={RegisterView} />
                                {template === 2 ? (<Route exact path={"/sports"} component={SportsView} />):null}
                                {template === 2 ? (<Route exact path={"/sports/:maharajaboost"} component={SportsView} /> ):null}
                                
                                <Route exact path={"/terms"} component={TermsAndConditions} />
                                <Route exact path={"/faq"} component={FAQ} />
                                <Route exact path={"/responsible-gaming"} component={ResponsibleGaming} />
                                <Route exact path={"/kyc-policy"} component={KYCPolicy} />
                                <Route exact path={"/aml-policy"} component={AMLPolicy} />
                                <Route exact path={"/jackpotslot"} component={JackpotSlot} />
                                <Route exact path={"/about-livecasino"} component={LiveCasinoSeoView} />
                                <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
                                <Route exact path={"/cookie-policy"} component={CookiePolicy} />
                                <Route exact path={"/bonus-terms"} component={BonusTerms} />
                                <Route exact path={"/about-us"} component={AboutUs} />
                                <Route exact path={"/about-slot"} component={SlotCasinoSeoVIew} />
                                <Route exact path={"/about-sports"} component={SportsbettingSeoVIew} />
                                <Route exact path={"/signup/success"} component={RegisterSuccess} />
                                <Route exact path={"/signup/complete"} component={CompleteRegisterSuccess} />
                                <Route exact path={"/signup/full"} component={CompleteRegisterView} />
                                 {/* <Route exact path={"/settings"} component={SettingsPage} /> */}
                             {/*   <Route exact path={"/settings/updatePassword"} component={UpdatePasswordPage} /> */}
                                <Route exact path={"/documents/upload"} component={DocumentUploadPage} />
                                <Route path={"/settings/:resetpassword"} component={ResetPasswordPage} />
                                <Route exact path="/livecasino/playforfun/:gameId/:provider" component={LiveCasinoGameView} />
                                <Route exact path="/livecasino/playforreal/:gameId/:provider" component={LiveCasinoGameView} />
                                <Route exact path="/livecasino/game/:gameId/:provider" component={MobileLiveCasinoGameView} />
                                <Route path="/livecasino/:search?" component={LiveCasinoPage} />
                                <Route exact path={"/livecasino/exit"} component={ExitLiveCasinoGame} />

                                {/* <Route exact path={"/profile/edit"} component={EditProfilePage} /> */}
                                <Redirect to="/" />
                           
                            </Switch>
                        </GAWrapper>
                        <RegisterModal />
                        <BonusModal />
                        <CashierModal />
                        <AccountVerificationModal />
                        <SettingsModal />
                        <SidebarView/>
                    </Suspense>)}
                </Router>
            </Provider>
        );
    }
}

export default App;
