import React from 'react';
import './PromotionView.scss';
import { Bonuses, Bonus } from '../http/protocol';
import { connect } from 'react-redux';
import { AppState } from '../RootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { Redirect } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import Root from '../layout/Root';
import MetaHelmet from '../utils/MetaHelmet';
import { Helmet } from 'react-helmet';
import SamplePromoImage from 'https://content.adminemx.com/maharajafortune/images/promotion-sample-image.jpg';
import { HTML5_FMT } from 'moment';
import { logout, showLogin } from '../user/UserActions';
import { Link } from 'react-router-dom';
import { getContainer } from '../ioc/IOCSetup';

import { t } from "../i18n";

let staticPromotion = {
	status: 'SUCCESS',
	bonuses: [
		{
			id: 69,
			bonusPlanName: 'Maharaja Fortune Welcome Bonus Offer',
			status: 'RELEASED',
			expiryDate: '2021-09-21',
			triggerDate: '2021-08-15 18:04:53',
			amount: '10.00',
			wagerRequirement: '100.00',
			amountWagered: '100.00',
			triggerType: 'PLTFRM_BON'
		},
		{
			id: 70,
			bonusPlanName: 'Free Spin',
			status: 'RELEASED',
			expiryDate: '2021-09-21',
			triggerDate: '2021-08-15 18:04:53',
			amount: '10.00',
			wagerRequirement: '100.00',
			amountWagered: '100.00',
			triggerType: 'PLTFRM_BON'
		}
	]
};

interface Props extends RouteComponentProps {
	promotion?: Bonus;
	isloggedIn: boolean;
	showLogin: () => void;
	isDeposited: boolean;
	optInBonusPlans: any;
}
interface State {
	show: boolean;
	promotions?: any;
}

export class PromotionView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = { show: false };
		this.hideModal = this.hideModal.bind(this);

		this.showModal = this.showModal.bind(this);

		this.claimClick = this.claimClick.bind(this);
	}
	componentDidMount(): void {
		let promotions = getContainer().getSettingsService().getPromotions();
		let enablePromo = promotions &&  promotions.filter(function (obj: any) {
			return obj.isPromo && obj.isEnabled;
		})

		this.setState({ promotions: enablePromo });

	}
	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (this.props.isloggedIn !== prevProps.isloggedIn) {
			let promotions = getContainer().getSettingsService().getPromotions();
			let enablePromo =promotions &&   promotions.filter(function (obj: any) {
				return obj.isPromo && obj.isEnabled;
			})
			if (this.props.isloggedIn) {
				enablePromo.filter((arrayEl: any) => {
					return this.props.optInBonusPlans && this.props.optInBonusPlans.some((filterEl: any) => filterEl.bonusPlanId.toString() === arrayEl.bonusPlanId) || arrayEl.isDepositTrigger !== true;

				});
			}
			this.setState({ promotions: enablePromo });

		}
	}
	promoThumbnailGenerator = () => {
		let lang = getContainer().getSettingsService().getLocalesData();
		let activeLang = getContainer().getSettingsService().getActiveLocale().code;					
		if (this.props.isloggedIn) {
			return (<div className='row'>
				{this.state.promotions && this.state.promotions.filter((arrayEl: any) => {
					return this.props.optInBonusPlans &&  this.props.optInBonusPlans.some((filterEl: any) => filterEl.bonusPlanId.toString() === arrayEl.bonusPlanId);

				}).map((e: any) => {
					return (
						<div className="promotion col-lg-3 col-md-6 col-sm-6 col-12" key={e.bonusPlanId}>
							<div className="promotion-box">
								<div className='promo-product'>{t(`${e.product}`)}</div>
								{e.bonusPlanId && sessionStorage.getItem("bonusPlanId") == e.bonusPlanId ? (
									<img
										className="img-fluid claimed-badge"
										src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Claimed-badge.png'}
										alt={'Welcome Bonus'}
										title=""
									/>
								) : null}

								<img
									className="img-fluid"
									src={e.thumbnailImage}
									alt={'Welcome Bonus'}
									title=""
								/>
								<div className="row promodesc">
									<div className="col-6">
									
										{lang && lang.length >= 2 ? (<h5> {e.promotionTCL[activeLang]}</h5>):(		<h5> {e.promotionTCL}</h5>)}
		
									</div>
									<div className="col-6">
										<Link
											className="btn btn-primary  btn-rounded promo-btn"
											to={e.termsAndCondition}
										>
											{t("readMore")}
										</Link>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>)
		} else {
			return (<div className='row'>
				{this.state.promotions && this.state.promotions.map((e: any) => {
					return (
						<div className="promotion col-lg-3 col-md-6 col-sm-6 col-12" key={e.promotionTCL}>
							<div className="promotion-box">
								<div className='promo-product'>{t(`${e.product}`) }</div>
								{e.bonusPlanId && sessionStorage.getItem("bonusPlanId") == e.bonusPlanId ? (
									<img
										className="img-fluid claimed-badge"
										src={'https://content.adminemx.com/maharajafortune/images/promotionpage/Claimed-badge.png'}
										alt={'Welcome Bonus'}
										title=""
									/>
								) : null}

								<img
									className="img-fluid"
									src={e.thumbnailImage}
									alt={'Welcome Bonus'}
									title=""
								/>
								<div className="row promodesc">
									<div className="col-6">
									{lang && lang.length >= 2 ? (<h5> {e.promotionTCL[activeLang]}</h5>):(		<h5> {e.promotionTCL}</h5>)}
		

									</div>
									<div className="col-6">
										<Link
											className="btn btn-primary  btn-rounded promo-btn"
											to={e.termsAndCondition}
										>
											{t("readMore")}
										</Link>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>)
		}
	}
	showModal() {
		this.setState({ show: true });
	}
	hideModal() {
		this.setState({ show: false });
	}
	claimClick() {
		if (this.props.isloggedIn) {
			this.props.history.push(`/deposit`);
		} else {
			this.props.showLogin();
		}
	}
	render() {
	
		const title: string = t("meta.promotionView.title") || '';
		const subtitle: string = t("meta.promotionView.subtitle") || '';
		const fullTitle: string = t("meta.promotionView.fullTitle") || '';
		const description: string = t("meta.promotionView.description") || '';
		const keywords: string = t("meta.promotionView.keywords") || '';
		const baserHref: string = t("meta.promotionView.baserHref") || '';
		let mainLobby = getContainer().getSettingsService().getMainLobbyUrl();
		return (
			<Root footer={true} header={true}>
				{MetaHelmet(title, description, keywords)}

				<div className="main-content">
					<div className={'promotion-container'}>
						<header className={'top-container gradient'}>
							<h4>{t("promoSec.promoTitle")}</h4>
						</header>

						{this.promoThumbnailGenerator()}
			
						{/* // <div className="promo-text-wrap">
						// 	<p dangerouslySetInnerHTML={
                        //         { __html: t("promoSec.promoText", { interpolation: { escapeValue: false } })}}>
                        //     </p>
						// </div> */}
					
					</div>
				</div>
			</Root>
		);
	}
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
	showLogin: () => dispatch(showLogin(true))
});
const mapStateToProps = (app: AppState) => ({

	isloggedIn: app.user.user && app.user.user.loggedIn,
	optInBonusPlans: app.user.optInBonusPlans,
	isDeposited: app.user.user && app.user.user.madeDeposit

});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PromotionView as any));

