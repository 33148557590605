import React, { Component } from "react";
import { connect } from "react-redux";
import { AppState } from "../RootReducer";
import { Dispatch } from "redux";
import { Spinner } from "../ui/Spinner";
import { LoginStatus } from "../http/Types";
import { Redirect } from "react-router";
import { Bundle, KycStatus, ListBundlesResponse, RegistrationResponse, Settings, TransferLimit, UserInfo } from "../http/protocol";
import { fetchStoreBundles, selectBundle } from "../store/StoreActions";
import { StoreStateStatus } from "../store/StoreReducer";
import Root from "../layout/Root";
import Page from "../layout/Page";
import Header from "../layout/Header";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BundleComponent from "./BundleComponent";
import ErrorBoundary from "./ErrorBoundary";
import PaymentFlow from "./PaymentFlow";
import { selectCurrency, updateDepositAmount, setUseAsStore, showCashier } from "../payments/DepositActions";
import { GlobalPayload } from "../contenful/types";
import Tabbar from "../ui/navigation/Tabbar";
import { t } from "../i18n";
import getLoggedInRedirect from "../utils/LoggedInRedirect";
import MetaHelmet from "../utils/MetaHelmet";
import { Helmet } from "react-helmet";
import { fetchUserInfo, login, logout, setAppMode, tokenLogin } from "../user/UserActions";
interface State {
    animate: boolean;
    startPlaying: boolean;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps;

export class StorePageNoFooter extends Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            animate: false,
            startPlaying: false
        }
    }

    componentDidMount(): void {
        // if (this.props.bundles === undefined) {
        //     this.props.fetchStoreBundles();
        //     this.props.setUseAsStore && this.props.setUseAsStore(true);
        // }
        if (this.props.user === undefined) {
            this.props.fetchUserDetails(localStorage.getItem("sessionKey"));
        }
    }

    componentWillUnmount(): void {
        this.props.selectBundle(undefined); //think over this
    }

    handleOnClickBundle = (bundleId: number) => {
        let bundles = this.props.bundles;
        if (bundles !== undefined && bundles.items !== undefined) {
            if (bundleId !== undefined) {
                let bundle = bundles.items.find((bundle) => bundle.id === bundleId);
                if (bundle !== undefined) {
                    if (bundle.price && (bundle.price.amount !== undefined && bundle.price.currency !== undefined)) {
                        this.props.update && this.props.update(bundle.price.amount.toString());
                        this.props.selectCurrency && this.props.selectCurrency(bundle.price.currency);
                        this.props.selectBundle(bundleId);
                    }
                }
            }

        }
    }

    handleGoBack = () => {
        if (this.props.show && this.props.show) { // cashiermodal is open and active
            if (this.props.selectedBundleId === undefined) {
                this.props.hide();
            } else {
                this.props.selectBundle(undefined);
            }
        } else {
            if (this.props.selectedBundleId === undefined) {
                this.props.history.goBack();
            } else {
                this.props.selectBundle(undefined);
            }
        }
    };

    handleClickStartPlaying = () => {
        this.setState({ startPlaying: true });
    }

    comparePrice = (a: number | undefined, b: number | undefined) => {
        return (a || b) ? (!a ? -1 : !b ? -1 : a - b) : 0;
    }

    compare = (a: Bundle, b: Bundle) => {
        if (a.price && b.price) {
            return this.comparePrice(a.price.amount, b.price.amount);
        } else {
            return 0;
        }

    }

    render() {
        const { isFetching, bundles, loggedIn, settings, user, selectedBundleId, global, show } = this.props;

        if (this.state.startPlaying && settings) {
            return this.props && <Redirect to={getLoggedInRedirect(settings)} />
        }
    
        if (user !== undefined) {


            if (!loggedIn) {

                return <Redirect to={"/"} />
            }
        }
        // if (user.kycStatus !== KycStatus.PENDING) {
        //     return <Redirect to={"/documents/upload"}/>
        // }

   
    

        return (
 
            <div>

          
                {
                    isFetching
                        ? <Spinner />
                        : (
                        <React.Fragment>
                                        <PaymentFlow />
                         </React.Fragment>
                        )
                }
            </div>
        )
    }

}


interface PropsFromState {
    isFetching: boolean;
    status?: StoreStateStatus;
    settings?: Settings;
    loggedIn: boolean;
    bundles?: ListBundlesResponse;
    user?: UserInfo;
    selectedBundleId?: number;
    show?: boolean;
    global?: GlobalPayload;
    registration?: RegistrationResponse;
}

interface PropsFromDispatch {
    fetchStoreBundles: typeof fetchStoreBundles;
    selectBundle: typeof selectBundle;
    update?: (amount: string) => void;
    selectCurrency?: (currencyCode: string) => void
    setUseAsStore?: (setUseAsStore: boolean) => void
    hide: () => void;
    fetchUserDetails: typeof fetchUserInfo;
}


const mapStateToProps = ({ settings, store, user, payments }: AppState) => {
    return {
        isFetching: store.isFetching,
        status: store.status,
        settings: settings.settings,
        bundles: store.bundles,
        loggedIn: LoginStatus.LOGGED_IN == user.status,
        user: user.user,
        registration: user.registration,
        selectedBundleId: store.selectedBundleId,
        show: payments.deposit.showCashierModal,

    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchUserDetails: (sessionKey: UserInfo) => dispatch(fetchUserInfo(sessionKey) as any),
        fetchStoreBundles: () => dispatch(fetchStoreBundles() as any),
        selectBundle: (bundleId?: number) => dispatch(selectBundle(bundleId) as any),
        update: (amount: string) => dispatch(updateDepositAmount(amount)),
        selectCurrency: (currencyCode: string) => dispatch(selectCurrency(currencyCode)),
        setUseAsStore: (useAsStore: boolean) => dispatch(setUseAsStore(useAsStore)),
        hide: () => dispatch(showCashier(false)),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorePageNoFooter));